import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import NavLink from "react-router-dom/NavLink";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import "../../../Styles/issues.css";
import firebase from "../../Login/firebaseConfig";
import { Dialog } from "@material-ui/core";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default class MobHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      theposition: 0,
      heading: this.props.heading,
      sub_heading: this.props.sub_heading,
      url: this.props.url,
      userName: this.props.user,
      login: this.props.login,
      logo: this.props.logo,
      share : this.props.share ? this.props.share : '',
      invite_friends_dialog : false ,
      laod : this.props.load,
      open : this.props.openDialog,
      loading : false,

    };
    if(this.state.heading === ''){}
    else{this.setState({loading:true})}
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  


  saveLoc=()=>{
    window.scrollTo(0,0);
    localStorage.setItem('loc',window.location.href)
  }

  closeDialog = () => {
    this.setState({
     
      invite_friends_dialog : false
    });
  };
  
  clickOnInvite = () => {
    this.setState({
      invite_friends_dialog : true
    })
  }

  
  componentWillReceiveProps(props) {
    this.setState({
      heading: props.heading,
      sub_heading: props.sub_heading,
      url: props.url,
      logo: props.logo,
      share : props.share ? props.share : ''
    });

    if(this.state.heading === ''){}
    else{this.setState({loading:true})}
    this.forceUpdate();
  }

  // logout
  logOut = e => {
    e.preventDefault();

    firebase.auth().signOut();
    localStorage.clear();

    setTimeout(this.goToLogin, 100);
  };

  goToLogin = () => {
    window.location.assign("/");
  };

  // Listening Scroll Position

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    this.setState({
      theposition: scrolled
    });
  };

  

  render() {

    
    const toggleDrawer = (side, open) => event => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      this.setState({ ...this.state, [side]: open });
    };

    const fullList = side => (
      <div
        className={useStyles.fullList}
        role="presentation"
        onClick={toggleDrawer(side, false)}
        onKeyDown={toggleDrawer(side, false)}
      >
        {localStorage.current_user ? (
          <>
            {/* <img  
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  style ={{borderRadius:'80px' , cursor:'pointer', objectFit:'cover'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  /> */}
            <NavLink
              style={{ textDecoration: "none" }}
              to={`/${JSON.parse(localStorage.getItem("current_user")).slug}`}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <p color="default" className="wall_web_links">
                    My Profile
                  </p>
                </div>
              </div>
            </NavLink>

            <NavLink style={{ textDecoration: "none" }} to="/edit-profile">
              <div className="container">
                <div className="row justify-content-center">
                  <p color="default" className="wall_web_links">
                    {" "}
                    Edit-profile{" "}
                  </p>
                </div>
              </div>
            </NavLink>

            <NavLink
              style={{ textDecoration: "none" }}
              onClick={e => this.logOut(e)}
              to="/login"
            >
              <div className="container">
                <div className="row justify-content-center">
                  <p color="default" className="wall_web_links">
                    {" "}
                    Logout{" "}
                  </p>
                </div>
              </div>
            </NavLink>
          </>
        ) : (
          <NavLink style={{ textDecoration: "none" }}   onClick={this.saveLoc}  to="/login">
            <div className="container">
              <div className="row justify-content-center">
                <p color="default" className="wall_web_links">
                  {" "}
                  Login{" "}
                </p>
              </div>
            </div>
          </NavLink>
        )}
      </div>
    );

    return (
      <div
        className="wall_web_small"
        style={
          this.state.url === ""
            ? {
                backgroundImage: `linear-gradient( to top , rgba(0,0,0,.8),
                             transparent),linear-gradient( to bottom , rgba(0,0,0,.8), transparent)
                             , url(${require("../../../logo/first_issue.jpg")})`,
                // height: `${this.state.share === false ? '500px' : '580px'}`,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                marginBottom:'60px',
                paddingBottom:'130px',
                borderBottom:'1px solid #ffffff36'
              }
            : {
                backgroundImage: `linear-gradient( rgba(0,0,0,.8), rgba(0,0,0,.8))
                             , url(${this.state.url})`,
                // height: `${this.state.share === false ? '500px' : '580px'}`,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                marginBottom:'60px',
                paddingBottom:'130px',
                borderBottom:'1px solid #ffffff36'
              }
        }
      >
        {this.state.login === "false" ? (
          ""
        ) : this.state.theposition < 0.000001 ? (
          <AppBar style={{ background: "transparent", boxShadow: "none" }}>
            <Toolbar>
              <div className="container-fluid justify-content-start">
                <div className="row justify-content-start">
                  <NavLink to="/">
                    <img
                      src={require("../../../logo/wallmag_white.png")}
                      alt='Logo'
                      height="20px"
                    />
                  </NavLink>
                </div>
              </div>

              <div className="justify-content-end">
                <MenuIcon
                  variant="permanent"
                  color="inherit"
                  onClick={toggleDrawer("top", true)}
                />

                <Drawer
                  anchor="top"
                  open={this.state.top}
                  onClose={toggleDrawer("top", false)}
                >
                  {fullList("top")}
                </Drawer>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          <AppBar  style={{background:'black', borderBottom:'1px solid #ffffff36'}} >
            <Toolbar>
              <div className="container-fluid justify-content-start">
                <div className="row justify-content-start">
                  <NavLink to="/">
                    <img
                      src={require("../../../logo/wallmag_white.png")}
                      alt='Logo'
                      height="20px"
                    />
                  </NavLink>
                </div>
              </div>

              <div className="justify-content-end">
                <MenuIcon
                  variant="permanent"
                  color="inherit"
                  onClick={toggleDrawer("top", true)}
                />

                <Drawer
                  anchor="top"
                  open={this.state.top}
                  onClose={toggleDrawer("top", false)}
                >
                  {fullList("top")}
                </Drawer>
              </div>
            </Toolbar>
          </AppBar>
        )}

        
        <div className="container-fluid mob_header_div">
          {/* Heading of The Header  */}
          <div className="row "></div>
          
          {/* Heading of The Header  */}
          <div className="row ">
            {this.state.logo === 'true'  ? (
              <div
                className="col-md-12 col-lg-12 col-xl-12"
                style={{ marginTop: "200px" }}
              >
                <img
                  src=""
                  alt='Logo'
                  style={{ height: "160px", width: "200px", display: "none" }}
                />
              </div>
            ) : (
              <div
                className="col-md-12 col-lg-12 col-xl-12"
                style={{ marginTop: "120px" }}
              >
                <img
                  className='main-logo'
                  src={this.state.logo ? this.state.logo  : require("../../../logo/wallgold.png")}
                  alt='Logo'
                  style={{ height: "160px", width: "160px" }}
                />
              </div>
            )}

            <div className="col-1 col-sm-1 col-md-2"></div>
            <div className="col-10 col-sm-10 col-md-8 col-lg-12 col-xl-12 ">
              <div className="row justify-content-center ">
                <p id="mb_issue_desc"> {this.state.heading} </p>
              </div>
            </div>
            <div className="col-1 col-sm-1 col-md-2"></div>

            {/* Sub_Heading of The Header */}
            <div className="col-1 col-sm-1 col-md-2"></div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-12 col-xl-12 mob_sub_heading">
              {/* <div className='row justify-content-center '> */}
              <p id="mob_header_sub_heading"> {this.state.sub_heading} </p>
              {/* </div> */}
            </div>
            <div className="col-1 col-sm-1 col-md-2"></div>

            {
              this.state.logo === 'true' ? '' : 

              !this.state.heading ? <div style={{height:'260px'}}></div>:
            <div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'56px'}}>
              <div className="row justify-content-center ">
                  <div className='invite-friends'  onClick={() => this.state.open()} style={{width:'240px'}}>
                      <p className='invite-frnds-text'>
                          Invite Friends  
                      </p>

                      <img className='invite-frnds-logo' src={require('../../../logo/share.svg')} />

                  </div>  
              </div>
            </div>
            }
          </div>
        
        
        </div>
        
        


      </div>
    );
  }
}
