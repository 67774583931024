import {ALL_POST } from './../types';
const initialState = {
    all_post_data: [],
    all_post_load: false,
    token: ""
}
export default function AllPostReducer(state = initialState, action) {
  
  
    if(state.all_post_load){
     switch(action.type){
        case ALL_POST : 
            return {...state, all_post_data: state.all_post_data.concat(action.data.items) , all_post_load: true , token: action.data.nextPageToken};
         default : return state

 }
 }else{
    switch(action.type){
        case ALL_POST : 
            return {...state, all_post_data: action.data.items , all_post_load: true , token: action.data.nextPageToken};
         default : return state

 }
 }
    
      
}