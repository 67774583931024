import React, { Component } from "react";
import "../../Styles/Team.css";
import Header from "../Header/Header";
import Axios from "axios";
import { team_wallmag } from "../common/AppConstants";
import { Image } from "react-bootstrap";
import FooterWallmag from "../Footer/Footer";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import CircularProgress from '@material-ui/core/CircularProgress';

var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default class Team extends Component {
  state = {
    data: null,
    loading: false
  };

  componentDidMount() {
    this.setState({loading: true})
    Axios.get(team_wallmag).then((res) => {
      this.setState({
        data: res.data,
      });
      //console.log(res);
    }).finally(() => {
      this.setState({loading: false})
    });
  }

  render() {
    return (
      <div style={{ background: "white" }}>
        <Header
          heading="People Behind WallMag"
          sub_heading="We do what we love and love what we do"
        />

{this.state.loading ?
            <p style={{marginTop: "30px", fontSize: "30px", fontWeight: 600, textAlign:"center"}}><CircularProgress style={{color: "black"}} /></p>

            :
        <div className="container mt-4">
          <div className="row">
        {
            this.state.data !== null
              ? this.state.data.items.map((value) => {
                  return (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
                      <div className="row">
                        <div className="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 mt-4">
                          <Image
                            className="pro-pic"
                            roundedCircle
                            src={value.profilePicture}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf";
                            }}
                          />
                        </div>

                        <div
                          className="col-8 col-sm-8 col-md-9 col-lg-9 col-xl-9 mt-4"
                          style={{ marginLeft: "-15px" }}
                        >
                          <p className="emp_name">{value.fullName}</p>
                          <p
                            className="about_emp"
                            // style={{ float: "left", marginRight: "10px" }}
                          >
                            {value.designation}
                          </p>
                          {value.dateOfJoining ? (
                            <p
                              className="about_emp"
                              style={{ marginBottom: "-5px" }}
                            >
                              {
                                months[
                                  String(
                                    new Date(
                                      parseInt(value.dateOfJoining)
                                    ).getMonth()
                                  )
                                ]
                              }{" "}
                              {String(
                                new Date(
                                  parseInt(value.dateOfJoining)
                                ).getFullYear()
                              )}{" "}
                              -{" "}
                              {value.isCurrentWorking ? (
                                "Present"
                              ) : (
                                <>
                                  {
                                    months[
                                      String(
                                        new Date(
                                          parseInt(value.dateOfCompletion)
                                        ).getMonth()
                                      )
                                    ]
                                  }{" "}
                                  {String(
                                    new Date(
                                      parseInt(value.dateOfCompletion)
                                    ).getFullYear()
                                  )}
                                </>
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                          {value.linkedInurl ?
                      <p style={{marginTop:"10px", textAlign: "left" }}>
                      <LinkedInIcon
                        style={{ height: "26px", width: "26px", marginRight: "10px" }}
                      />
                      <a
                        href={value.linkedInurl}
                        rel="noreferrer"
                        className="member-link"
                      >
                        {value.linkedInurl}
                      </a>
                    </p>
                    :
                    ''}
                          <p className="emp-desc" style={{marginTop: "10px",}}>{value.details}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
  }
<br/><br/>
        <FooterWallmag />
      </div>
    );
  }
}
