import React from 'react'
import Header from '../IssuePages/Header/Header'
import './../../Styles/Awards/AwardDetails.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterWallmag from '../Footer/Footer';
import MobHeader from '../IssuePages/Header/Mob_Header';
import Columns from 'react-columns'
import Axios from 'axios';
import { feeds } from '../common/AppConstants';
import firebase from '../Login/firebaseConfig'

var data = []

var queries = [
  
    {
    columns: 3,
    query: 'min-width: 300px'
  },{
    columns: 3,
    query: 'min-width: 600px'
  }
  , {
    columns: 4,
    query: 'min-width: 1000px'
  }];



class AwardDetails extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            key : 'home',
            key1:'home',
            about : true,
            sub : false
        }

    }

    componentDidMount(){

        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})


        Axios.get(feeds).then(res=>{
            // //console.log(res)
            data =  res.data.items
        })
    }


    // when click on About
    clickOnAbout = () => {
        this.setState({
            about : true,
            sub : false
        })
    }


    // when click on Submissions
    clickOnSub = () => {
        this.setState({
            about : false,
            sub : true
        })

    }

    render(){
        return(
            <>
{
    window.innerWidth < 768 ?
    
    

    <>

    {/* HeADER of the page */}


        <MobHeader
                url='https://www.theabbeycatering.com/wp-content/uploads/2013/11/pexels-photo-1024993-1080x375.jpeg'
                heading='A Global Awards
                for Emerging Talent '
                sub_heading='Push the Boundaries of Visual Storytelling'
        />

    {/* Header ends Here */}

            {/* Body Part Starts from here */}
            <div className='container-fluid' style={{marginTop:'72px'}}>

<div className='row' style={{justifyContent:'center'}}>
        {
            this.state.about === true ? 

            <p  onClick={e=>this.clickOnAbout()} className='Tnav_head' style={{marginRight:'40px'}}>About</p>

            :

            <p  onClick={e=>this.clickOnAbout()} className='Fnav_head' style={{marginRight:'40px'}}>About</p>
        }

        {
            this.state.sub === true ? 
            
            <p id='click_sub' onClick={e=>this.clickOnSub()} className='Tnav_head'>Submissions (2K)</p>

            :

            <p id='click_sub' onClick={e=>this.clickOnSub()} className='Fnav_head'>Submissions (2K)</p>

        }
        
        
    </div>
    
        
{
this.state.about === true ? 

<div className='container'>


    <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>

            <p className='about_'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus id arcu hendrerit efficitur.
                Morbi fermentum posuere tempor. Duis eu vehicula est. Ut id sapien nisl.
                Aliquam sed porttitor ex. Nulla blandit tincidunt sem eleifend vulputate.
                Nam vitae odio imperdiet, eleifend odio condimentum, varius ipsum. Nullam cursus, dui eu auctor consequat,
                sem elit faucibus quam, eu dapibus neque sapien sit amet nulla. Vestibulum ultricies ullamcorper nibh vitae congue. 
            </p> 

    </div>      
{/* ------------------------------------------------- */}
<div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
    <div style={{alignSelf:'center'}}>
            <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
        </div>
</div>

{/* ------------------------------ */}
    <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>

        <div>
            <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
            <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
            <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
        </div>
    </div>   
{/* ------------------------------ */}

    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
        <div>
            <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
        </div>

        <div>
            <p className='small_desc'>Starting: 1 October, 2019</p>        
        </div>
    </div>  

{/* ------------------------------ */}
    
    <div className='row' style={{justifyContent:'center'}}>
        <div>
            <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
        </div>

        <div>
            <p className='small_desc'>Only for mobile photographers </p>        
        </div>
    </div>  
{/* ------------------------------ */}

<div className='container payment_button' style={{width:'100%', marginTop:'48px'}}>
<div className="container butt ">
    <div className="container">
        <p id='pre'> Participate </p>
        <p id='inr'>INR 500 including GST</p>
        </div>
    </div>  
</div>

{/* ------------------------------ */}

                <div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                    <p className='Candid2019'>#Sponsers</p>
                </div>
{/* ------------------------------ */}

            <div className='row' style={{marginTop:'40px'}}>
                <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                    <img className='juPh' id='ju_image_category'  src='https://www.alainelkanninterviews.com/wp-content/uploads/2013/11/01_testino.jpg' alt='#juriesMobiographer1' />
                    <p className='ju_name'>alain elkann</p>
                    <p className='ju_desc'>Media Sponser</p>
                </div>

                <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                    <img className='juPh' id='ju_image_category'  src='https://www.alainelkanninterviews.com/wp-content/uploads/2013/11/01_testino.jpg' alt='#juriesMobiographer2' />
                    <p className='ju_name'>alain elkann</p>
                    <p className='ju_desc'>Media Sponser</p>
                </div>

                <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                    <img className='juPh' id='ju_image_category'  src='https://www.alainelkanninterviews.com/wp-content/uploads/2013/11/01_testino.jpg' alt='#juriesMobiographer3' />
                    <p className='ju_name'>alain elkann</p>
                    <p className='ju_desc'>Media Sponser</p>
                </div>
            </div>



{/* --------------------------------- */}

                <div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                    <p className='Candid2019'>#Juries</p>
                </div>
{/* ------------------------------ */}

    <div className='row' style={{marginTop:'40px'}}>
        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
            <img className='juPh'  id='ju_image_category'  src='https://www.davidnoton.com/public/images/Home/david-new.jpg' alt='#juriesFashiongraphy1' />
            <p className='ju_name'>David Noton</p>
            <p className='ju_desc'>Create Director</p>
        </div>

        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
            <img className='juPh'  id='ju_image_category'  src='https://www.davidnoton.com/public/images/Home/david-new.jpg' alt='#juriesFashiongraphy2' />
            <p className='ju_name'>David Noton</p>
            <p className='ju_desc'>Create Director</p>
        </div>

        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
            <img className='juPh'  id='ju_image_category'  src='https://www.davidnoton.com/public/images/Home/david-new.jpg' alt='#juriesFashiongraphy3' />
            <p className='ju_name'>David Noton</p>
            <p className='ju_desc'>Create Director</p>
        </div>
    </div>



{/* --------------------------------- */}
<div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
     <div className="vl"></div>
</div>

<div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
        <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
</div>



</div>
                                        :
    <div style={{marginTop:'60px'}}>
                                           
        <Columns queries={queries}>  

            
                {data.map((value,i)=>{
                return(
                    <>
                        <img style={{borderRadius:'14px', padding:'8px'}} src={value.fileProperties[0].publicUrl} alt='submissions' />
                    </>
                )
                })
            }
                </Columns>
                                                    
    </div>                                                

}



</div>
{/* Body part ends here */}


    {/* Footer here */}
        <div style={{marginTop:'160px'}}>
            <FooterWallmag />
        </div>
    {/* ---------------------  */}
            

    </>
        
    

                                :
// for large scrrens (greater than 767)

            <>

            {/* HeADER of the page */}


                <Header
                        url='https://www.theabbeycatering.com/wp-content/uploads/2013/11/pexels-photo-1024993-1080x375.jpeg'
                        heading='#Candid2019'
                        sub_heading='Wedding Photography Contest 2019'
                />

            {/* Header ends Here */}

            {/* Body Part Starts from here */}
                <div className='container-fluid' style={{marginTop:'72px'}}>

                <div className='row' style={{justifyContent:'center'}}>
                        {
                            this.state.about === true ? 

                            <p  onClick={e=>this.clickOnAbout()} className='Tnav_head' style={{marginRight:'40px'}}>About</p>

                            :

                            <p  onClick={e=>this.clickOnAbout()} className='Fnav_head' style={{marginRight:'40px'}}>About</p>
                        }

                        {
                            this.state.sub === true ? 
                            
                            <p id='click_sub' onClick={e=>this.clickOnSub()} className='Tnav_head'>Submissions (2K)</p>

                            :

                            <p id='click_sub' onClick={e=>this.clickOnSub()} className='Fnav_head'>Submissions (2K)</p>

                        }
                        
                        
                    </div>
                    
                        
{
    this.state.about === true ? 

            <div className='container'>


                    <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>

                            <p className='about_'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus id arcu hendrerit efficitur.
                                Morbi fermentum posuere tempor. Duis eu vehicula est. Ut id sapien nisl.
                                Aliquam sed porttitor ex. Nulla blandit tincidunt sem eleifend vulputate.
                                Nam vitae odio imperdiet, eleifend odio condimentum, varius ipsum. Nullam cursus, dui eu auctor consequat,
                                sem elit faucibus quam, eu dapibus neque sapien sit amet nulla. Vestibulum ultricies ullamcorper nibh vitae congue. 
                            </p> 
   
                    </div>      

{/* ------------------------------ */}
                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div style={{alignSelf:'center'}}>
                            <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
                        </div>

                        <div style={{marginLeft:'40px'}}>
                            <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
                            <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
                            <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
                        </div>
                    </div>   
{/* ------------------------------ */}

                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div>
                            <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Starting: 1 October, 2019</p>        
                        </div>
                    </div>  

{/* ------------------------------ */}
                    
                    <div className='row' style={{justifyContent:'center'}}>
                        <div>
                            <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Only for mobile photographers </p>        
                        </div>
                    </div>  
{/* ------------------------------ */}

<div className='container payment_button' style={{width:'100%', marginTop:'48px'}}>
                <div className="container butt ">
                    <div className="container">
                        <p id='pre'> Participate </p>
                        <p id='inr'>INR 500 including GST</p>
                        </div>
                    </div>  
                </div>

{/* ------------------------------ */}

                                <div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                                    <p className='Candid2019'>#Sponsers</p>
                                </div>
{/* ------------------------------ */}

                            <div className='row' style={{marginTop:'40px'}}>
                                <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                    <img className='juPh' id='ju_image_category'  src='https://www.alainelkanninterviews.com/wp-content/uploads/2013/11/01_testino.jpg' alt='#juriesMobiographer1' />
                                    <p className='ju_name'>alain elkann</p>
                                    <p className='ju_desc'>Media Sponser</p>
                                </div>

                                <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                    <img className='juPh' id='ju_image_category'  src='https://www.alainelkanninterviews.com/wp-content/uploads/2013/11/01_testino.jpg' alt='#juriesMobiographer2' />
                                    <p className='ju_name'>alain elkann</p>
                                    <p className='ju_desc'>Media Sponser</p>
                                </div>

                                <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                    <img className='juPh' id='ju_image_category'  src='https://www.alainelkanninterviews.com/wp-content/uploads/2013/11/01_testino.jpg' alt='#juriesMobiographer3' />
                                    <p className='ju_name'>alain elkann</p>
                                    <p className='ju_desc'>Media Sponser</p>
                                </div>
                            </div>



{/* --------------------------------- */}

                                <div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                                    <p className='Candid2019'>#Juries</p>
                                </div>
{/* ------------------------------ */}

                    <div className='row' style={{marginTop:'40px'}}>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                            <img className='juPh'  id='ju_image_category'  src='https://www.davidnoton.com/public/images/Home/david-new.jpg' alt='#juriesFashiongraphy1' />
                            <p className='ju_name'>David Noton</p>
                            <p className='ju_desc'>Create Director</p>
                        </div>

                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                            <img className='juPh'  id='ju_image_category'  src='https://www.davidnoton.com/public/images/Home/david-new.jpg' alt='#juriesFashiongraphy2' />
                            <p className='ju_name'>David Noton</p>
                            <p className='ju_desc'>Create Director</p>
                        </div>

                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                            <img className='juPh'  id='ju_image_category'  src='https://www.davidnoton.com/public/images/Home/david-new.jpg' alt='#juriesFashiongraphy3' />
                            <p className='ju_name'>David Noton</p>
                            <p className='ju_desc'>Create Director</p>
                        </div>
                    </div>



{/* --------------------------------- */}
                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                     <div className="vl"></div>
                </div>

                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                        <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
                </div>
                


                </div>
                                                        :
                    <div style={{marginTop:'60px'}}>
                                                           
                        <Columns queries={queries}>  
            
                            
                                {data.map((value,i)=>{
                                return(<>

                                        <img style={{borderRadius:'14px', padding:'8px'}} src={value.fileProperties[0].publicUrl} alt='submissions' />

                                </>)
                                })
                            }
                                </Columns>
                                                                    
                    </div>                                                

}



                </div>
            {/* Body part ends here */}


            {/* Footer here */}
                <div style={{marginTop:'160px'}}>
                    <FooterWallmag />
                </div>
            {/* ---------------------  */}
                    

            </>
                
                
                }


            </>
        )
    }
}

export default AwardDetails