import React from "react";
import "./AboutUsMobile.css";
import MobileHeader from "./MobileHeader";
import FooterWallmag from "../Footer/Footer";

function AboutUsMobile() {
  return (
    <div className="about-us-page-375">
      <div className="overlap-group6">
        <div className="spacer"></div>
        <div className="mob_top_about">
          <MobileHeader
            url=""
            heading="About Us"
            sub_heading="We are a global community of photographers and storytellers"
          />
        </div>
        {/* <div className="group-1937">
          <img
            className="mask-group-208"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/mask-group-208@1x.png"
          />
        </div> */}
      </div>
      <div className="overlap-group3">
        <img
          className="pexels-yogendra-singh-1438461 animate-enter"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/pexels-yogendra-singh-1438461-3@1x.png"
        />
        <div className="aboutus montserrat-bold-white-39px animate-enter1">
          ABOUT
          <br />
          US
        </div>
      </div>
      <p className="a-social-initiative montserrat-light-white-12px animate-enter2">
        A social initiative that started to&nbsp;&nbsp;
        <br />
        empower artists all around the globe <br />
        to do more and achieve more.&nbsp;&nbsp;Our <br />
        commitment is to help artists so <br />
        they can create a world full of <br />
        possibilities for those who need <br />
        them. That’s why we created <br />
        WallMag, to empower artists to <br />
        create real value among those, who <br />
        create!
      </p>
      <div className="overlap-group1">
        <p className="text-1 montserrat-bold-white-16px">
          &#34;Art must be life - <br />
          it must belong to everybody&#34;
          <br />
        </p>
        <img
          className="pexels-asif-pav-3093525"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/pexels-asif-pav-3093525-3@1x.png"
        />
        <p className="how-is-it-that-in-a montserrat-light-white-10px">
          How is it that in a world that&#39;s evolving so <br />
          quickly that artists all around still <br />
          suffering? Strangely enough, art is all <br />
          around us all the time but artists all <br />
          around us are suffering from their own <br />
          passion. There seems to be an emptiness <br />
          with the current suffering of an artist and <br />
          today&#39;s generation of artists are <br />
          disconnected from real value.{" "}
        </p>
        <div className="text-2 montserrat-bold-white-38px">
          Artist are
          <br />
          suffering!
        </div>
        <div className="name montserrat-light-white-12px">Maria Abramovie</div>
      </div>
      <div className="address montserrat-bold-white-30px">
        4 ways we <br />
        can empower <br />
        artists globally
      </div>
      {/* <p className="text-3 montserrat-light-white-11px">
        Some text would come here
      </p> */}
      <div className="group-2814">
        <div className="flex-col">
          <div className="group-2674">
            <div className="group-2674-1">
              <div className="group-2674-2">
                <div className="overlap-group4">
                  <img
                    className="path-2967"
                    src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/path-2967-3@1x.png"
                  />
                  <img
                    className="path-2968"
                    src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/path-2968-3@1x.png"
                  />
                  <img
                    className="path-2969"
                    src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/path-2969-3@1x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="text-12 montserrat-light-white-12px">
            Delivery of affordable <br />
            learning resources
          </p>
          <div className="group-2676">
            <div className="overlap-group5">
              <div className="spacer-1"></div>
              <div className="group-2676-1">
                <img
                  className="icon-awesome-hand-holding-usd"
                  src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/icon-awesome-hand-holding-usd-3@1x.png"
                />
              </div>
            </div>
          </div>
          <p className="text-13 montserrat-light-white-12px">
            Facilitation of&nbsp;&nbsp;
            <br />
            Monetization Resources
          </p>
        </div>
        <div className="flex-col-1">
          <div className="group-2675">
            <div className="group-2675-1">
              <div className="group-2675-2">
                <div className="overlap-group4-1">
                  <img
                    className="path-2970"
                    src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/path-2970-3@1x.png"
                  />
                  <img
                    className="path-2971"
                    src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/path-2971-3@1x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-15 montserrat-light-white-12px">
            Recognition of
            <br />
            talented artists
          </div>
          <div className="group-2677">
            <img
              className="icon-awesome-id-card"
              src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/icon-awesome-id-card-3@1x.png"
            />
          </div>
          <div className="text-14 montserrat-light-white-12px">
            Identification
            <br />
            with digital presence
          </div>
        </div>
      </div>
      <div className="overlap-group2">
        <div className="our-mission">Our Mission</div>
        <p className="our-mission-is-to-up montserrat-light-white-12px">
          Our mission is to uplift Global Art <br />
          by connecting artists and <br />
          businesses through World-Class <br />
          infrastructure and Technology. <br />
          Our commitment is to help artists <br />
          so they can create a world <br />
          full of possibilities for those who <br />
          need them a world where <br />
          businesses and individuals look <br />
          forward to living.&nbsp;&nbsp;
        </p>
      </div>
      <div className="flex-row">
        <img
          className="nude-neutral-pr-feed-template5"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/nude-neutral-product-review-instagram-feed-template-5--3@1x.png"
        />
        <img
          className="nude-neutral-pr-m-feed-template"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/nude-neutral-product-review-instagram-feed-template-3@1x.png"
        />
      </div>
      <div className="flex-row-1">
        <img
          className="nude-neutral-pr-feed-template1"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/nude-neutral-product-review-instagram-feed-template-1--3@1x.png"
        />
        <img
          className="image-14"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/image-14-3@1x.png"
        />
      </div>
      <div className="group-2801">
        <img
          className="image-15"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d76ec1e2b7d015015487/img/image-15-3@1x.png"
        />
        <div className="ajeet-kumar-meena">Ajeet Kumar Meena</div>
        <div className="managing-director">Managing Director</div>
      </div>
      <p className="just-like-a-human-br montserrat-light-white-12px">
        Just like a human brain works <br />
        best when synapses talk to <br />
       each other seamlessly, we believe <br />
        that the art sector can deliver <br />
        the best to our artist  when <br />
        businesses, change  makers <br />
        and consumers exchange ideas <br />
        and share best practices.  <br />
        We are that platform for artists.
      </p>
      {/* <img
        className="mask-group-213"
        src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/mask-group-213@1x.png"
      />
      <h1 className="title">WallMag</h1>
      <div className="text-9">Where Photos Meet Stories</div>
      <p className="text-8">
        A community platform to empower photographers <br />
        of every kind through learning, improving and growing resources
        <br />
        so our photographers can do more and achieve more.
      </p>
      <div className="group-1986">
        <div className="navigatenext-24px">
          <div className="text-4">Join Membership for Free</div>
          <img
            className="path-2769"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/path-2769@1x.png"
          />
          <img
            className="path-2755"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/path-2755@1x.png"
          />
        </div>
      </div>
      <div className="group-1363">
        <div className="flex-col-2">
          <div className="the-company montserrat-bold-white-20px">The Company</div>
          <p className="text-5 montserrat-normal-white-14px">
            About Us
            <br />
            <br />
            The Team
            <br />
            <br />
            Our App
            <br />
            <br />
            WhatsApp Group
            <br />
            <br />
            Sitemap
          </p>
        </div>
        <Group420
          contactUs="Contact Us"
          text8={
            <>
              Get in Touch
              <br />
              <br />
              Advertise
              <br />
              <br />
              Careers
            </>
          }
        />
        <div className="flex-col-3">
          <div className="place montserrat-bold-white-20px">Legal</div>
          <div className="text-7 montserrat-normal-white-14px">
            Privacy Policy
            <br />
            <br />
            Terms of Use
          </div>
        </div>
      </div>
      <div className="overlap-group">
        <div className="group-1507">
          <img
            className="lnk"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/lnk@1x.png"
          />
          <img
            className="group-1507-item"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/fb@1x.png"
          />
          <img
            className="group-1507-item"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/path-2042@1x.png"
          />
        </div>
        <div className="group-1561">
          <div className="group-1417">
            <div className="overlap-group4-2">
              <img
                className="insta"
                src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/insta@1x.png"
              />
              <div className="rectangle-1883 border-1px-dove-gray"></div>
              <p className="text-10 montserrat-light-white-14px">We are active on Instagram</p>
            </div>
          </div>
          <p className="text-11 montserrat-light-white-14px">© WallMag Global PVT LTD 2020</p>
        </div>
      </div> */}
      <div id="footer_content">
        <FooterWallmag />
      </div>
    </div>
  );
}

export default AboutUsMobile;

function Group420(props) {
  const { contactUs, text8 } = props;

  return (
    <div className="group-420">
      <div className="contact-us montserrat-bold-white-20px">{contactUs}</div>
      <div className="text-6 montserrat-normal-white-14px">{text8}</div>
    </div>
  );
}
