import React from 'react';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
class Arrows extends React.Component{
    render(){
        return(<div>
            {this.props.left && <div className="leftarrow"  onClick={e => this.props.clickHandler(this.props.direction)} >  <div ><KeyboardArrowLeft style={{fontSize: 24 , marginTop: 5}}/> </div> </div> }
            {this.props.right && <div className="rightarrow"><div onClick={e => this.props.clickHandler(this.props.direction)} ><KeyboardArrowRight style={{fontSize: 24 , marginTop: 5}}/> </div></div> }
        </div>)
    }
}
export default Arrows;