import CardImg1 from "./assets/card_img_1.png";
import CardImg2 from "./assets/card_img_2.png";
import CardImg3 from "./assets/card_img_3.png";
import CardImg4 from "./assets/card_img_4.png";
import CardImg5 from "./assets/card_img_5.png";

let card1 = {}

var MissionsData = [
    { cardImg: CardImg1, title: "Travel Photography Contest 2021", closing: "Closing in 2 days", entry: "Paid entry", win: "Win upto INR 1 Lakh" },
    { cardImg: CardImg2, title: "Nature Photography Contest 2021", closing: "Closing in 2 days", entry: "Paid entry", win: "Win upto INR 1 Lakh" },
    { cardImg: CardImg3, title: "Monuments Photography Contest 2021", closing: "Closing in 2 days", entry: "Paid entry", win: "Win upto INR 1 Lakh" },
     { cardImg: CardImg4, title: "Travel Photography Contest 2021", closing: "Closing in 2 days", entry: "Paid entry", win: "Win upto INR 1 Lakh" },
    { cardImg: CardImg5, title: "Weddings Photography Contest 2021", closing: "Closing in 2 days", entry: "Paid entry", win: "Win upto INR 1 Lakh" },
];

// const MissionsData = [

// CardImg1, CardImg2, CardImg3, CardImg4, CardImg5

//    ]
   export default MissionsData