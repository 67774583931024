import React from "react";
import "./MissionsV1.css";
import DesktopHeader from "../AboutUsV1/DesktopHeader";
import MobileHeader from "../AboutUsV1/MobileHeader";
import FooterWallmag from "../Footer/Footer";
import MissionsBG from "./assets/missions_bg.png";
import MissionsData from "./MissionsData";
import firebase from "../Login/firebaseConfig";
import { get_missions, get_all_contests } from "../common/AppConstants";
import axios from "axios";
import CardComponent from "./CardComponent";

var missions = [];
var contests = [];
class MissionsV1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      props : this.props
  }
  }

  componentDidMount() {
    window.scroll(0, 0);

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        localStorage.setItem("user", user.ra);
      }
    });

    this.getMissions();
    this.getContests();
  }

  getContests = async () => {
    await axios
      .get(get_all_contests)
      .then((res) => {
        console.log(res.data);
        contests = res.data;
        this.forceUpdate();
      })
      .catch((error) => {});
  };

  getMissions = async () => {
    await axios
      .get("https://wallmag-2.appspot.com/_ah/api/missionApi/v1/mission/")
      .then((res) => {
        console.log(res.data);
        missions = res.data;
        this.forceUpdate();
      })
      .catch((error) => {});
  };

  render() {
    console.log("Missions V1:", missions);
    console.log("contests V1:", contests);
    const { classes } = this.props;
    return (
      <div className="missions-root">
        {window.innerWidth < 768 ? (
          <div>
            <MobileHeader url="" />
          </div>
        ) : (
          <div className="web_top_about">
            <DesktopHeader url="" />
          </div>
        )}
        {/* {contests.items.map((value, index) => {
                return (
                  <p className="title_mission">{value.hashTag}</p>)
                })} */}

        <div className="missions-mask-group-291">
          <img className="missions-mask-group-290" src={MissionsBG} />
        </div>
        <div className="missions-head">
          <img
            className="missions-path-2762"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6077e120b2522d11528ced6b/img/path-2762-1@1x.png"
          />
          <h1 className="missions-title">Challenges</h1>
          <p className="missions-text-2">
            Win prizes while improving your talent
          </p>
        </div>
        <div className="missions-group-3524">
          <div className="missions-group-3301">
            <h1 className="number montserrat-semi-bold-white-32px">4</h1>
            <div className="my-winnings glacialindifference-regular-normal-white-14px">
              MY
              <br />
              WINNINGS
            </div>
          </div>
          <img
            className="line-237"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6077b65554d0489b8747539e/img/line-237@1x.png"
          />
          <div className="missions-group-3301">
            <h1 className="number montserrat-semi-bold-white-32px">2</h1>
            <div className="my-winnings glacialindifference-regular-normal-white-14px">
              MY
              <br />
              ENTRIES
            </div>
          </div>
        </div>
        <CardComponent awardsData={missions} props = {this.state.props} />
        <div className="foot-buttons">
          <div className="rectangle-2557">
            <p className="show-past-winners glacialindifference-regular-normal-white-16px">
              Show past winners
            </p>
          </div>
          <div className="show-upcoming-challenges glacialindifference-regular-normal-white-16px">
            SHOW UPCOMING CHALLENGES
          </div>
        </div>
        <div className="missions-footer">
          <div className="host-a-contest montserrat-semi-bold-white-32px">
            Host a contest
          </div>
          <p className="text-1 montserrat-normal-white-14px">
            Host your own contest and we will help your brand get boosted
          </p>

          <div className="lets-talk glacialindifference-regular-normal-white-16px">
            Let's talk
          </div>
        </div>
        <div className="footer_content">
          <FooterWallmag />
        </div>
      </div>
    );
  }
}

export default MissionsV1;
