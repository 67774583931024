import React, { Component } from "react";
import "./../../Styles/Awards/AwardDetails.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Collapsible from "react-collapsible";
import { CircularProgress } from "@material-ui/core";
import { get_mission_by_slug, get_faq_section } from "../common/AppConstants";
import ReactHtmlParser from "react-html-parser";
import MissionTime from "./MissionTime";

var sections = [];
var mission_data = [];

export default class MissionPageMob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slug: props.match.params.slug,
    };
  }

  componentDidMount = () => {
    axios
      .get(get_mission_by_slug + this.state.slug)
      .then((res) => {
        mission_data = res.data;
        this.getSectionList(res.data.faqSectionId);
        this.forceUpdate();
      })
      .catch((error) => {
        if (error.message.status) {
          if (error.response.status === 404) {
            this.props.history.replace("/page-not-found");
          }
        }
      });
  };

  //---------------------------------Get Section List----------------------------------------------------
  getSectionList = (id) => {
    axios.get(get_faq_section + id).then((res) => {
      sections = res.data;
      this.forceUpdate();
    });
  };

  render() {
    return (
      <div style={{ background: "black" }}>
        {console.log(mission_data)}
        {/* ----------------------------------------------------------------------------------------------- */}
        {/* ---------------------------------Header---------------------------------------------------- */}
        <div

          style={
            mission_data.coverUrl === ""
              ? {
                  backgroundImage: `linear-gradient( to top , rgba(0,0,0,.8),
                             transparent),linear-gradient( to bottom , rgba(0,0,0,.8), transparent)
                             , url(${require("../../logo/first_issue.jpg")})`,
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  marginBottom: "60px",
                  paddingBottom: "130px",
                  borderBottom: "1px solid #ffffff36",
                }
              : {
                  backgroundImage: `linear-gradient( rgba(0,0,0,.8), rgba(0,0,0,.8))
                             , url(${mission_data.coverUrl})`,
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  marginBottom: "60px",
                  paddingBottom: "130px",
                  borderBottom: "1px solid #ffffff36",
                }
          }
        >
          <div className="container-fluid mob_header_div">
            {/* ---------------------------------Heading with logo---------------------------------------------- */}
            <div className="row ">
              {mission_data.headerLogo === "true" ? (
                <div
                  className="col-md-12 col-lg-12 col-xl-12"
                  style={{ marginTop: "200px" }}
                >
                  <img
                    src=""
                    alt="Logo"
                    style={{ height: "160px", width: "200px", display: "none" }}
                  />
                </div>
              ) : (
                <div
                  className="col-md-12 col-lg-12 col-xl-12"
                  style={{ marginTop: "120px" }}
                >
                  <img
                    className="main-logo"
                    src={
                      mission_data.headerLogo
                        ? mission_data.headerLogo
                        : require("../../logo/wallgold.png")
                    }
                    alt="Logo"
                    style={{ height: "160px", width: "160px" }}
                  />
                </div>
              )}
              <div className="col-1 col-sm-1 col-md-2"></div>
              <div className="col-10 col-sm-10 col-md-8 col-lg-12 col-xl-12 ">
                <div className="row justify-content-center ">
                  <p id="mb_issue_desc"> {mission_data.hashTag} </p>
                </div>
              </div>
              <div className="col-1 col-sm-1 col-md-2"></div>
              {/* ---------------------------------Subheading---------------------------------------------------- */}
              <div className="col-1 col-sm-1 col-md-2"></div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-12 col-xl-12 mob_sub_heading">
                <p id="mob_header_sub_heading"> {mission_data.title} </p>
              </div>
              {/* ---------------------------------Invite Firends-------------------------------------------------- */}
              {/* <div className="col-1 col-sm-1 col-md-2"></div>
            {
              mission_data.headerLogo === 'true' ? '' : 
              !mission_data.hashTag ? <div style={{height:'260px'}}></div>:
            <div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'56px'}}>
              <div className="row justify-content-center ">
                  <div className='invite-friends'  onClick={() => this.state.open()} style={{width:'240px'}}>
                      <p className='invite-frnds-text'>
                          Invite Friends  
                      </p>
                      <img className='invite-frnds-logo' src={require('../../logo/share.svg')} />
                  </div>  
              </div>
            </div>
            } */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {/* ----------------------------------------------------------------------------------------------- */}
          {/* ---------------------------------Prize Text---------------------------------------------------- */}
          <div className="container-fluid" style={{ position: "relative" }}>
            <div className="container">
              {mission_data.prizeText ? (
                mission_data.prizeText.length < 15 ? (
                  <></>
                ) : (
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      border: "1px solid #b8a33b",
                      padding: "15px",
                    }}
                  >
                    <div style={{ marginLeft: "15px" }}>
                      <p
                        className="about_v1"
                        style={{ textAlign: "left", color: "white" }}
                      >
                        {ReactHtmlParser(mission_data.prizeText)}
                      </p>
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        {/* ----------------------------------Quotation------------------------------------------------------ */}
        <div
          className="row"
          style={{
            justifyContent: "center",
            marginTop: "101px",
            display: "flex",
          }}
        >
          <p className="quotation">{mission_data.quotation}</p>
        </div>
        {/* ------------------------------------------------------------------------------------------------ */}
        {/* ---------------------------------Description---------------------------------------------------- */}
        <div
          className="row"
          style={{
            justifyContent: "center",
            marginTop: "60px",
            display: "flex",
          }}
        >
          <p
            className="about_v1"
            style={{ textAlign: "center", padding: "0px 80px" }}
          >
            {ReactHtmlParser(mission_data.description)}
          </p>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        {/* ---------------------------------What To Upload-------------------------------------------------- */}
        <div
          className="row"
          style={{
            justifyContent: "center",
            marginTop: "30px",
            display: "flex",
          }}
        >
          {mission_data.aboutHtml?<p className="what-to-upload">What to upload?</p>
          :''}
          
        </div>
        <div
          className="row"
          style={{
            justifyContent: "center",
            marginTop: "60px",
            display: "flex",
          }}
        >
          <p
            className="about_v1"
            style={{ textAlign: "center", padding: "0px 80px" }}
          >
            {ReactHtmlParser(mission_data.whatToUpload)}
          </p>
        </div>
        {/* -------------------------------------------------------------------------------------------------- */}
        {/* ---------------------------------------Timeline--------------------------------------------------- */}
        <div
          className="row justify-content-center"
          style={{ marginTop: "28px" }}
        >
          <div className="col-md-12 col-lg-12 col-xl-12 row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12 row justify-content-center">
              <div
                className="row"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "40px",
                }}
              >
                <div>
                  {mission_data.timelines ? (
                    mission_data.timelines[0].title === "" ? (
                      ""
                    ) : (
                      <MissionTime clr="white" data={mission_data} />
                    )
                  ) : (
                    <CircularProgress
                      color="secondary"
                      style={{ marginTop: "20px" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------------------------------------------------------------------------------------------- */}
        {/* ----------------------------------------Juries---------------------------------------------------- */}
        <div
          className="row"
          style={{
            marginTop: "40px",
            marginBottom: "80px",
            justifyContent: "center",
          }}
        >
          {mission_data.juries ? (
            mission_data.juries.map((value, i) => {
              if (value.userSlug === "") {
                return "";
              } else {
                return (
                  <>
                    {i === 0 ? (
                      <div
                        className="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        style={{
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <p id="issue1_desc">#Juries</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-4">
                      <a
                        href={value.redirectLink}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          className="juPh"
                          id="ju_image_category"
                          src={value.user.photoUrl}
                          alt="#juriesFashiongraphy1"
                        />
                      </a>
                      <a
                        href={value.redirectLink}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p
                          style={{ opacity: "1", marginTop: "24px" }}
                          className="ju_name_white"
                        >
                          {value.user.displayName}
                        </p>
                      </a>
                      <a
                        href={value.redirectLink}
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="ju_desc_white">{value.bio}</p>
                      </a>
                    </div>
                  </>
                );
              }
            })
          ) : (
           ''
          )}
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        {/* -------------------------------------Questions--------------------------------------------------- */}
        <div className="container" style={{ marginTop: "40px" }}>
          {sections.questions ? (
            sections.questions.map((value, index) => {
              return (
                <div class style={{ textAlign: "start" }}>
                  <Collapsible
                    trigger={
                      <div
                        className="row question mt-2"
                        style={{ background: "#2d2c2c" }}
                      >
                        <div className="col-10 col-sm-10 col-md-10">
                          <p style={{ color: "white" }}>{value.questionText}</p>
                        </div>
                        <div className="col-2 col-2 col-md-2">
                          <img
                            src={require("../../logo/down-arrow.svg")}
                            alt="Down Arrow"
                            style={{
                              height: "20px",
                              width: "20px",
                              opacity: ".7",
                            }}
                          />
                        </div>
                      </div>
                    }
                  >
                    <p className="answer mt-4">
                      {/* {ReactHtmlParser(this.state.data.body.replace(/<img/g , `<img onclick="zoomImage(this.src)" class ='img-fluid' `))}  */}
                      {ReactHtmlParser(
                        value.answerHtml.replace(/<p/g, "<p style=color:white;")
                      )}
                    </p>
                  </Collapsible>
                </div>
              );
            })
          ) : (
            ''
          )}
        </div>

        {/* <div className="container" style={{ marginTop: "60px" }}>
          {sections.questions ? (
            sections.questions.map((value, index) => {
              return (
                <div class style={{ textAlign: "start" }}>
                  <Collapsible
                    trigger={
                      <div className="row question mt-2">
                        <div className="col-10 col-sm-10 col-md-10">
                          <p>{value.questionText}</p>
                        </div>
                        <div className="col-2 col-2 col-md-2">
                          <img
                            src={require("../../logo/down.svg")}
                            alt="Down Arrow"
                            style={{
                              height: "20px",
                              width: "20px",
                              opacity: ".7",
                              position: "absolute",
                              right: "20px",
                            }}
                          />
                        </div>
                      </div>
                    }
                  >
                    <p className="answer" style={{ color: "white" }}>
                      <br />
                      {ReactHtmlParser(value.answerHtml)}
                    </p>
                  </Collapsible>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div> */}
        {/* ------------------------------------------------------------------------------------------------- */}
        {/* ---------------------------------Sponsors-------------------------------------------------------- */}
        <div
          className="row"
          style={{
            marginTop: "40px",
            justifyContent: "center",
            borderTop: "1px solid #ffffff36",
          }}
        >
          {mission_data.sponsors ? (
            mission_data.sponsors.map((value, i) => {
              return (
                <>
                  {i === 0 ? (
                    <div
                      className="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      style={{
                        justifyContent: "center",
                        marginTop: "40px",
                        marginBottom: "40px",
                      }}
                    >
                      <p id="issue1_desc" style={{ opacity: "0.8" }}>
                        #Partners
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-4">
                    <a
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={value.website}
                    >
                      <img
                        alt="jury_image"
                        className="juPh"
                        id="ju_image_category"
                        src={value.logo}
                      />
                      <p
                        style={{ opacity: "1", marginTop: "24px" }}
                        className="ju_name_white"
                      >
                        {value.name}
                      </p>
                      <p className="ju_desc_white">{value.type}</p>
                    </a>
                  </div>
                </>
              );
            })
          ) : (
            ''
          )}
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        {/* -------------------------------------Chat-------------------------------------------------------- */}
        <div style={{ marginTop: "140px", paddingBottom: "100px" }}>
          <img
            src={require("../../logo/chatw.svg")}
            alt="chat_logo"
            style={{ height: "40px", width: "40px" }}
          />
          <p className="about__" style={{ marginTop: "20px", color: "white" }}>
            {" "}
            Question or problem?
          </p>
          <p className="about__" style={{ color: "white" }}>
            {" "}
            Contact our team:
            <b>
              {" "}
              <a
                href="https://tawk.to/chat/5dcbea87d96992700fc73ae3/default"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </b>
          </p>
          <p className="about__" style={{ color: "white" }}>
            {" "}
            and we’ll reply within 24 hours.
          </p>
        </div>
      </div>
    );
  }
}
