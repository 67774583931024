import React from 'react';
import '../../Styles/MyDialog/Invite.css';
import {FacebookIcon,  TwitterIcon,  WhatsappIcon,  LinkedinIcon, 
         FacebookMessengerShareButton, FacebookMessengerIcon} from 'react-share';
import {FacebookShareButton, LinkedinShareButton,  TwitterShareButton, WhatsappShareButton} from 'react-share';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import cogoToast from 'cogo-toast';
import { DesktopWindows } from '@material-ui/icons';



class Invite extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            title : this.props.title ? this.props.title : '',
            close : this.props.closeFunc ,
            props: this.props.props.match.url,
            // props : this.props.props.match.params.slug ? this.props.props.match.params.slug : this.props.props.match.params.missionTag 
        }
        
    }

      

    render(){
        console.log("share url", 'wallmag.io'+this.state.props)
        return(
            <>
            <div className='row'>
            <img
                alt="cancel"
                src={require("../../logo/cancel.svg")}
                className='cross-icon'
                onClick={this.state.close}
            />
            </div>
            {
                window.innerWidth < 768
            ?
           <div style={{padding:'50px 30px 30px 30px'}}>
                <div className='row'  style={{justifyContent:'center'}}>
                    <img src={require('../../logo/sharedtrophy.svg')} alt='share'/>
                </div>

                <div className='row mt-2'  style={{justifyContent:'center'}}>
                    <p className='invite-peoples'>
                        Invite your photography friends
                    </p>
                </div>

                <div className='row'  style={{justifyContent:'center'}}>
                    <p className='invite-subtitle'>
                        {this.state.title} is open for all for photographers to participate
                    </p>
                </div>

                <div className='row' style={{marginTop:'66px'}}>
                    <div className=' col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content'>
                        <img src={require('../../logo/checked2.svg')} alt='reward' />
                        <div className='flex'>
                        <p className='invite-dialog-content-text'>
                            Participate with
                            2 simple steps
                        </p>
                    </div>
                    </div>

                    <div className=' col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content'>
                    <img src={require('../../logo/reward2.svg')} alt='reward' />
                    <p className='invite-dialog-content-text'>
                                    Get recognized
                                 nationally
                        </p>
                    </div>

                    <div className=' col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content' style={{marginTop:'62px'}}>
                    <img src={require('../../logo/camera2.svg')} alt='reward' />
                    <p className='invite-dialog-content-text'>
                    Improve your
photography
                        </p>
                    </div>

                    <div className=' col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content' style={{marginTop:'62px'}}>
                    <img src={require('../../logo/money2.svg')} alt='reward' />
                    <p className='invite-dialog-content-text'>
                    Earn with your
photography talent
                        </p>
                    </div>
                </div>



         </div>
            :
            <div style={{padding:'50px'}}>
            <div className='row'  style={{justifyContent:'center'}}>
                <img src={require('../../logo/sharedtrophy.svg')} alt='share'/>
            </div>

            <div className='row mt-2'  style={{justifyContent:'center'}}>
                <p className='invite-peoples'>
                    Invite your photography friends
                </p>
            </div>

            <div className='row'  style={{justifyContent:'center'}}>
                <p className='invite-subtitle'>
                {this.state.title} is open for all the photographers to participate
                </p>
            </div>

            <div className='row' style={{marginTop:'66px'}}>
                <div className='row col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content'>
                    <img src={require('../../logo/checked2.svg')} alt='reward' />
                    <p className='invite-dialog-content-text'>
                        Participate with
                        2 simple steps
                    </p>
                </div>

                <div className='row col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content'>
                <img src={require('../../logo/reward2.svg')} alt='reward' />
                <p className='invite-dialog-content-text'>
                                Get recognized
                             nationally
                    </p>
                </div>

                <div className='row col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content' style={{marginTop:'62px'}}>
                <img src={require('../../logo/camera2.svg')} alt='reward' />
                <p className='invite-dialog-content-text'>
                Improve your
photography
                    </p>
                </div>

                <div className='row col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 invite-content' style={{marginTop:'62px'}}>
                <img src={require('../../logo/money2.svg')} alt='reward' />
                <p className='invite-dialog-content-text'>
                Earn with your
photography talent
                    </p>
                </div>
            </div>



     </div>

    }
         <div className='invite-background' style={{marginTop:'58px',paddingBottom:'30px'}}>
            <div className='row' style={{marginTop:'30px', justifyContent:'center'}}>

                <div className='row icons_dad' style={{alignSelf:'center', padding:'10px', justifyContent:'center'}}>

                <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' 
                title={"Hunt's Over! I've found the perfect photography contest for you. " + this.state.title + " powered by WallMag. Follow for more info: "} url={'wallmag.io'+this.state.props}>
                            <WhatsappIcon className='icons' round size='40px' />
                        </WhatsappShareButton>

                        <FacebookShareButton     style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={'wallmag.io'+this.state.props}>
                                <FacebookIcon  className='icons' round size='40px'/>
                        </FacebookShareButton> 

                        <FacebookMessengerShareButton style={{outline:'none', cursor:'pointer'}} appId='421685814838229' className='sc'   url={'wallmag.io'+this.state.props}>
                            <FacebookMessengerIcon className='icons' round size='40px' />
                        </FacebookMessengerShareButton>
                                                  
                        <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'wallmag.io'+this.state.props}>
                            <LinkedinIcon className='icons' round size='40px' />
                        </LinkedinShareButton>
                                                      
                        <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'wallmag.io'+this.state.props} 
                        title={"Hunt's Over! I've found the perfect photography contest for you. " + this.state.title + " powered by WallMag. Follow for more info: "}>
                            <TwitterIcon className='icons' round size='40px' />
                        </TwitterShareButton>

                </div>
            </div>




                <div style={{textAlignLast:'center'}}>
                    <CopyToClipboard text={'wallmag.io'+this.state.props}
                        onCopy={() => cogoToast.info('Contest link has been copied for Share')}>
                        <p className='copy-link' style={{cursor:'pointer'}}>
                                            <u>Copy share link</u>
                                        </p>
                        </CopyToClipboard>
                       
                    </div>

           </div>
  
           </>
        )
    }
}

export default Invite;