import {CATEGORY } from './../types';
const initialState = {
    story_category: {},
    load :false
}
export default function StoryReducer(state = initialState, action) {
 switch(action.type){
   
    case CATEGORY : 
   
        return {...state, story_category: action.data ,load : true};
        
    default : return state
 }    
}