import React from "react";
import "./../../Styles/Awards/Contests.css";
import { Card } from "@material-ui/core";
import firebase from "../Login/firebaseConfig";
import { NavLink } from "react-router-dom";
import { get_all_active_missions } from "../common/AppConstants";
import axios from "axios";

var missions = [];
var activeMissions = [];

var current = new Date(1624515683316);
var start= new Date(1620196200000);
var end= new Date(1620801000000);
var finale= new Date(1620714600000);

class Contests extends React.PureComponent {
  constructor(props) {
    super(props);

    missions = this.props.data;
    this.state = {
      load: false,
    };

    this.forceUpdate();
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        localStorage.setItem("user", user.ra);
      }
    });

    // this.getAllMissions()
  }

  componentWillReceiveProps(props) {
    missions = props.data;
  }

  // getAllMissions = async () => {
  //   await axios.get(get_all_active_missions)
  //   .then(res=>{
  //      activeMissions = res.data
  //      this.forceUpdate()
  //   })
  //   .catch(error=>{
  //   })
  // }

  render() {
    return (
      <div>
        {missions.items ? (
          <div className="container rs_div">
            {this.props.subTitle ? (
              <div className="row justify-content-center">
                <p id="subTitleForContest">{this.props.subTitle}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <></>
        )}
        {/* -------------------------------------------------------------------------------------- */}
        {/* ----------------------------------- Missions ----------------------------------------- */}
        <div className="container m-cards">
          <div className="row justify-content-center">
            {missions.items ? (
              missions.items.map((value, index) => {
                return (
                  <div
                    style={{ cursor: "pointer", marginTop: "50px" }}
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  >
                    <NavLink
                      to={`/missions/${value.slug}/${
                        value.currentTime < value.startTime
                          ? ""
                          : value.currentTime < value.finaleTime
                          ? ""
                          : value.currentTime < value.endTime
                          ? "finale"
                          : "winners"
                      }`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card style={{ border: "#00000010 1px solid" }}>
                        <div>
                          {/* ----------------------------------------------------------------------- */}
                          {/* ----------------------------- Cover URL ------------------------------- */}
                          <div
                            style={{
                              backgroundImage: `linear-gradient( to top , rgba(0,0,0,.5),
                                                transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                                                , url(${value.coverUrl})`,
                              height: "200px",
                              width: "100%",
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="hosted_logo">
                          <img
                            src={value.hostedLogoUrl}
                            alt="Hosted Logo"
                            style={{
                              margin: "9px",
                              height: "35px",
                              width: "35px",
                            }}
                          />
                        </div>
                        {/* ----------------------------------------------------------------------------- */}
                        {/* ----------------------------- Title (Hash Tag) ------------------------------ */}
                        <div>
                          <p className="title_mission">{value.hashTag}</p>
                          <p className="hosted-by-WallMag">
                            {value.hostedText}
                          </p>

                          <div class="container-fluid">
                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div className="img-div col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <img
                                  src={require("../../logo/junk.svg")}
                                  alt="Junk"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginLeft: "12px",
                                  }}
                                />
                              </div>
                              {/* ------------------------------------------------------------------------ */}
                              {/* ---------------------------- Description ------------------------------- */}
                              <div
                                style={{ marginLeft: "-30px" }}
                                className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10"
                              >
                                <p className="Wedding-Photography-Awards-2019">
                                  {value.shortDescription}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* ---------------------------------------------------------------------------- */}
                          {/* -------------------------- Prize Description ------------------------------- */}
                          <div class="container-fluid">
                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div className="img-div col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <img
                                  src={require("../../logo/icon-awards.svg")}
                                  alt="Awards"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginLeft: "12px",
                                  }}
                                />
                              </div>

                              <div
                                style={{ marginLeft: "-30px" }}
                                className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10"
                              >
                                <p className="Wedding-Photography-Awards-2019">
                                  {value.shortPrizeDescription}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* ------------------------------------------------------------------------- */}
                          {/* ----------------- Button (Starting, Started, Finished ------------------- */}
                          <div class="container-fluid">
                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div className="img-div col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <img
                                  src={require("../../logo/clock.svg")}
                                  alt="Clock"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginLeft: "12px",
                                  }}
                                />
                              </div>

                              <div
                                style={{ marginLeft: "-30px" }}
                                className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10"
                              >
                                {/* Starting */}
                                {value.currentTime < value.startTime ? (
                                  <p className="Wedding-Photography-Awards-2019">
                                    {/* Starting : {" "} */}
                                    {String(
                                      new Date(parseInt(value.startTime))
                                    ).slice(0, 15)}
                                    ,{" "}
                                    {String(
                                      new Date(parseInt(value.startTime))
                                    ).slice(16, 18) < 12
                                      ? String(
                                          new Date(parseInt(value.startTime))
                                        ).slice(16, 18)
                                      : String(
                                          new Date(parseInt(value.startTime))
                                        ).slice(16, 18) - 12}
                                    :
                                    {String(
                                      new Date(parseInt(value.startTime))
                                    ).slice(19, 21)}
                                    {String(
                                      new Date(parseInt(value.startTime))
                                    ).slice(16, 18) < 12
                                      ? " AM"
                                      : " PM"}
                                  </p>
                                ) : // Started (vote for finalsts)
                                value.currentTime < value.finaleTime ? (
                                  <p className="Wedding-Photography-Awards-2019">
                                    {String(
                                      new Date(parseInt(value.finaleTime))
                                    ).slice(0, 15)}
                                    ,{" "}
                                    {String(
                                      new Date(parseInt(value.finaleTime))
                                    ).slice(16, 18) < 12
                                      ? String(
                                          new Date(parseInt(value.finaleTime))
                                        ).slice(16, 18)
                                      : String(
                                          new Date(parseInt(value.finaleTime))
                                        ).slice(16, 18) - 12}
                                    :
                                    {String(
                                      new Date(parseInt(value.finaleTime))
                                    ).slice(19, 21)}
                                    {String(
                                      new Date(parseInt(value.finaleTime))
                                    ).slice(16, 18) < 12
                                      ? " AM"
                                      : " PM"}
                                  </p>
                                ) : //  Started (vote for winners)
                                value.currentTime < value.endTime ? (
                                  <p className="Wedding-Photography-Awards-2019">
                                    {String(
                                      new Date(parseInt(value.endTime))
                                    ).slice(0, 15)}
                                    ,{" "}
                                    {String(
                                      new Date(parseInt(value.endTime))
                                    ).slice(16, 18) < 12
                                      ? String(
                                          new Date(parseInt(value.endTime))
                                        ).slice(16, 18)
                                      : String(
                                          new Date(parseInt(value.endTime))
                                        ).slice(16, 18) - 12}
                                    :
                                    {String(
                                      new Date(parseInt(value.endTime))
                                    ).slice(19, 21)}
                                    {String(
                                      new Date(parseInt(value.endTime))
                                    ).slice(16, 18) < 12
                                      ? " AM"
                                      : " PM"}
                                  </p>
                                ) : (
                                  // Award has been finished
                                  <p className="Wedding-Photography-Awards-2019">
                                    Finished
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          {value.currentTime < value.startTime ? (
                            <div
                              style={{
                                height: "40px",
                                background: "#000000",
                                margin: "10px",
                                borderRadius: "3px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="row justify-content-center">
                                <p
                                  style={{ marginTop: "10px"}}
                                  className="participate"
                                >
                                  Learn More
                                </p>
                              </div>
                            </div>
                          ) : value.currentTime < value.finaleTime ? (
                            <div
                              style={{
                                height: "40px",
                                background: "#000000",
                                margin: "10px",
                                borderRadius: "3px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="row justify-content-center">
                                <p
                                  style={{ marginTop: "10px" }}
                                  className="participate"
                                >
                                  Participate
                                </p>
                              </div>
                            </div>
                          ) : value.currentTime < value.endTime ? (
                            <div
                              style={{
                                height: "40px",
                                background: "#000000",
                                margin: "10px",
                                borderRadius: "3px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="row justify-content-center">
                                <p
                                  style={{ marginTop: "10px",width: "150px"  }}
                                  className="participate"
                                >
                                  Vote for finalists
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                height: "40px",
                                background: "#000000",
                                margin: "10px",
                                borderRadius: "3px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="row justify-content-center">
                                <p
                                  style={{ marginTop: "10px" }}
                                  className="participate"
                                >
                                  View results
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </Card>
                    </NavLink>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Contests;
