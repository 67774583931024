import React from 'react'
// import Footer from '../Footer/Footer'
import MobHeader from '../IssuePages/Header/Mob_Header';
import Header from '../IssuePages/Header/Header';
import '../../Styles/ForBrands/ForBrands.css'
import FooterWallmag from '../Footer/Footer';

class BookPhotoshoots extends React.Component{



    componentDidMount(){
        window.scroll(0,0)
    }


    render(){
        return(
            <div style={{background:'#ffffff'}}>

                   {/* Header for Mobile Devices */}
                   <div className='mob_top_get_featured'>
                 <MobHeader 
                    url=''  
                    clicked='yes' 
                    heading='Book Photoshoots
                                Anywhere, Anytime.' 
                    sub_heading="Produce professional photo and video with our talent"/>
            </div>
            
            {/* Header for desktops  */}
                <div className='web_top_get_featured'>
                <Header 
                    id='issue1_header'
                    url=''   
                    clicked='yes' 
                    heading='Book Photoshoots
                                Anywhere, Anytime.' 
                    sub_heading="Produce professional photo and video with our talent"/>
            </div>

            <div className='container'>

                <p className='fst_heading' >
                    On-Demand Photography
                </p>

                <p className='fst_subheading' >
                    Photoshoots delivered anytime and anywhere all over India 
                    for any purpose is what we call On-Demand Photography.  
                </p>



                <p className='topics2' > <span>How</span>  it works?</p>


                <p className='fst_subheading' >
                      Every demand get fulfilled by our verified photographer,
                      editors, designers, printing partner and production partner. 
                </p>


                <img
                    src={require('../../logo/photoshoot.png')}
                    alt='Photoshoot'
                    style={{marginTop:'100px'}}
                />



                <p className='topics2' > <span>Why</span> choose us?</p>


                <p className='fst_subheading'>
                    We are the India's biggest community of professional photographers organizing photography awards, contest, producing high quality photography magazine. We understand the in-depth of photography industry. 
                </p>

                <p className='fst_subheading'>
                    Our highly experienced design team, production partner and cutting 
                    edge technology offers rich and authentic video and photography experience.
                </p>

                <div className='row justify-content-center'>
                <div className='Rectangle-806'>
                    <p className='Book-Photoshoot'>
                        Book Photoshoot
                    </p>

                    <p className='Book-Photoshoot-Desc'>
                        We will contact you within 24 hours
                    </p>
                </div>
                </div>
            </div>

            <div style={{marginTop:'250px'}}>
                <FooterWallmag />
            </div>

            </div>
        )
    }
}

export default BookPhotoshoots