import React from 'react';


export default class Winners extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            close : this.props.closeFunc ,
        }
        
    }

    render(){
        return(
            <>
            <div className='row'>
            <img
                alt="cancel"
                src={require("../../logo/cancel.svg")}
                className='cross-icon'
                style={{cursor:'pointer'}}
                onClick={this.state.close}
            />
             </div>
             <div  style={{padding:'50px'}}>
            <div  style={{marginTop:'72px',}}  className='row'>
                <p className='awards-title' style={{color:'black', fontSize:'24px'}}>
                    WallMag Winners
                </p>
            </div>


                <div className='row' style={{marginTop:'62px'}}>

                    <div className='col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6'>

                    {/* <div className="hosted_logo"> */}
                        <img
                            src={require('../../logo/1.png')}
                            alt='Hosted Logo'
                            style={{
                                margin: '0px',
                                height: '35px',
                                width: '35px',
                                top: '-15px',
                                position: 'absolute',
                                left: '-1px',
                            }}
                        />
                        {/* </div> */}
                    <div
                            style={{
                            backgroundImage: `
                            linear-gradient( to top , rgba(0,0,0,0.3),
                                                transparent),linear-gradient( to bottom , rgba(0,0,0,0.3), transparent)
                                                , url(${require('../../logo/home_top.jpg')})`,
                            height: "200px",
                            width: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat"
                            }}
                        />

                    <p>
                        Meet Chris Bray- A Professional Photographer and Instructor
                        1st Winner of WallMag Sunset Photography Contest 2020
                    </p>  
                    </div>
                
                    <div className='col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6'>

            {/* <div className="hosted_logo"> */}
            <img
            src={require('../../logo/1.png')}
            alt='Hosted Logo'
            style={{
                    margin: '0px',
                    height: '35px',
                    width: '35px',
                    top: '-15px',
                    position: 'absolute',
                    left: '-1px',
            }}
            />
            {/* </div> */}
            <div
            style={{
                backgroundImage: `
                linear-gradient( to top , rgba(0,0,0,0.3),
                                    transparent),linear-gradient( to bottom , rgba(0,0,0,0.3), transparent)
                                    , url(${require('../../logo/home_top.jpg')})`,
                height: "200px",
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat"
            }}
            />

            <p>
            Meet Chris Bray- A Professional Photographer and Instructor
            1st Winner of WallMag Sunset Photography Contest 2020
            </p>  
            </div>

                    <div className='col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6'>

                    {/* <div className="hosted_logo"> */}
                        <img
                        src={require('../../logo/1.png')}
                        alt='Hosted Logo'
                        style={{
                                margin: '0px',
                                height: '35px',
                                width: '35px',
                                top: '-15px',
                                position: 'absolute',
                                left: '-1px',
                        }}
                        />
                    {/* </div> */}
                    <div
                        style={{
                            backgroundImage: `
                            linear-gradient( to top , rgba(0,0,0,0.3),
                                                transparent),linear-gradient( to bottom , rgba(0,0,0,0.3), transparent)
                                                , url(${require('../../logo/home_top.jpg')})`,
                            height: "200px",
                            width: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat"
                        }}
                        />

                    <p>
                        Meet Chris Bray- A Professional Photographer and Instructor
                        1st Winner of WallMag Sunset Photography Contest 2020
                    </p>  
                    </div>

                    <div className='col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6'>

                {/* <div className="hosted_logo"> */}
                    <img
                    src={require('../../logo/1.png')}
                    alt='Hosted Logo'
                    style={{
                            margin: '0px',
                            height: '35px',
                            width: '35px',
                            top: '-15px',
                            position: 'absolute',
                            left: '-1px',
                    }}
                    />
                {/* </div> */}
                <div
                    style={{
                        backgroundImage: `
                        linear-gradient( to top , rgba(0,0,0,0.3),
                                            transparent),linear-gradient( to bottom , rgba(0,0,0,0.3), transparent)
                                            , url(${require('../../logo/home_top.jpg')})`,
                        height: "200px",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat"
                    }}
                    />

                <p>
                    Meet Chris Bray- A Professional Photographer and Instructor
                    1st Winner of WallMag Sunset Photography Contest 2020
                </p>  
                </div>

                </div>
            </div>          
            </>
        )
    }
}