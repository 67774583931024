import React from 'react'
import {connect} from "react-redux"
// import NavLink from 'react-router-dom/NavLink'
import {wallmag_all, wallmag_rem_post} from '../../Store/action/AllWallmagPostsAction'
import {related_feeds, related_rem_post} from '../../Store/action/RelatedFeedsAction'
import './RelatedFeeds.css'


class RelatedFeeds extends React.Component{

    constructor(props){
        super(props)
        //console.log(props)
       this.state = {
           id : this.props.id
       }
    }
    

    
// goto Article
    goToArticle=(seo)=>{
        this.props.props.history.push(`/story/${seo}`)
        // window.location.assign(`/story/${seo}`)
    }

    componentDidMount(){

        this.props.related_feeds(this.state.id)
        
    }

    
    

    render(){

        return(


            <div className='container'>

                    {
                        this.props.feeds_load===true && this.props.feeds_data!==undefined ? 
                        <>
                        <div className='container rs_div'>
                            <div className='row justify-content-center'>
                                <p id='categories_header' style={{color:'black'}}>
                                     More For You
                                </p>
                            </div>
                        </div>

                        </>
                                        :
                                        <></>
                    }

                <div className='row' style={{justifyContent:'center'}}>
                     {
                        this.props.feeds_load===true && this.props.feeds_data!==undefined ? 
                        this.props.feeds_data.map(value =>{
                            return(
                                <div className='col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3' style ={{marginTop : '26px'}}>
                                    {/* <NavLink to={`/story/${value.seoUrl}`} style={{ textDecoration: 'none' }}> */}
                                        <img 
                                            onClick={e=>this.goToArticle(value.seoUrl)}
                                            src={value.fileProperties ? 
                                                    value.fileProperties[0].publicUrl : value.fileProperty.publicUrl}
                                            alt={value.title}
                                            height = '260px'
                                            width = 'auto'
                                            style = {{objectFit : 'cover' , borderRadius:'6px', cursor:'pointer'}}
                                          />          

                                         <p 
                                         style={{cursor:'pointer'}}
                                         onClick={e=>this.goToArticle(value.seoUrl)} 
                                         id='title'>{value.title}</p> 
                                    {/* </NavLink> */}
                                </div>
                            )
                   
                        }) : <></>
                }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      wall_data : state.wallmag_all_posts.wallmag_post_data,
      wall_load : state.wallmag_all_posts.wallmag_post_load ,
      feeds_data : state.rel_feeds_data.related_feeds_data,
      feeds_load : state.rel_feeds_data.related_feeds_load
    };
  };
  export default connect(mapStateToProps,
                              {wallmag_all, wallmag_rem_post, related_feeds, related_rem_post})(RelatedFeeds);