import {WALLMAG_ALL } from './../types';
const initialState = {
    wallmag_post_data: [],
    wallmag_post_load: false
}
export default function AllPostReducer(state = initialState, action) {
  
  
    if(state.wallmag_post_load){
    switch(action.type){
        case WALLMAG_ALL : 
            return {...state, wallmag_post_data: state.wallmag_post_data.concat(action.data.items) , wallmag_post_load: true };
         default : return state

 }
 }else{
    switch(action.type){
        
        case WALLMAG_ALL : 
            return {...state, wallmag_post_data: action.data.items , wallmag_post_load: true };
         default : return state

 }
 }
    
      
}