import React from 'react'
import { useMediaQuery } from "react-responsive"
import AboutUsDesktop from './AboutUsDesktop';
import AboutUsTablet from './AboutUsTablet';
import AboutUsMobile from './AboutUsMobile';
import { mediaQueries } from "./responsive"

function AboutUs() {

    const isMobile = useMediaQuery({ query: mediaQueries.mobileQuery });
    const isTablet = useMediaQuery({ query: mediaQueries.tabletQuery });
    const isDesktop = useMediaQuery({ query: mediaQueries.desktopQuery });
    const isDesktopLarge = useMediaQuery({ query: mediaQueries.desktopLargeQuery });
    
    if(isMobile) {
        return <AboutUsMobile />
    }
    else if(isTablet) {
        return <AboutUsTablet />
    }

    else {
        return <AboutUsDesktop />
    }

    // else{
    //     return <AboutUsDesktopLarge />
    // }
}

export default AboutUs
