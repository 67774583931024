import React, { Component } from 'react';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../../../node_modules/froala-design-blocks/dist/css/froala_blocks.css';
import './Form.css'


class Form extends Component {
  render() {
    return (
  <div className="container main">
 
    <div className="row justify-content-start shape">
      <div className="col-12 col-md-9 col-lg-8">
        <div className="fdb-box fdb-touch">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 " style={{textAlign:'start'}}>
              <h1 className='subs'>Stay connected</h1>
              <p className="lead"> Get the latest articles, offers and feature release on your inbox </p>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-12 col-lg-10">
              <div className="input-group">
                <input type="text" className="form-control plc_hldr" placeholder="Enter your email address" />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
  </div>
    )

  }
}

export default Form
