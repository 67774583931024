import React from 'react';
import { makeStyles, rgbToHex } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import './Header.css';
import DownloadApp from './DownloadApp';
import Features from './Features';
import Testimonial from './Testimonial';
import Form from './Form';
import Footer from './Footer';
import Team from './Team';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, animateScroll as scroll } from "react-scroll";
import NavLink from 'react-router-dom/NavLink'



const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
});

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
      
      
      
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 1 : 0,
    });
  }
  
  ElevationScroll.propTypes = {
    children: PropTypes.node.isRequired,
    window: PropTypes.func,
  };


function Header() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const fullList = side => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >   
    <div className='container'> 
    <div className='row justify-content-center'>
            <a href='#home'  style={{textDecoration:'none'}}><p className='wall_web_links'>Home</p></a>
    </div>
    </div>
    <div className='container'> 
    <div className='row justify-content-center'>
            <a href='#features' style={{textDecoration:'none'}}><p className='wall_web_links'>Features</p></a>
    </div>
    </div>
    <div className='container'>        
    <div className='row justify-content-center'> 
            <a href='#reviews' style={{textDecoration:'none'}}><p className='wall_web_links'>Reviews</p></a>
    </div>
    </div>
    <div className='container'>             
    <div className='row justify-content-center'>
            <a href='#team' style={{textDecoration:'none'}}><p className='wall_web_links'>Team</p></a>
    </div>
    </div>
           
    </div>
  );

  return (

    

    <div className='header_main_div'>

     {/* scroll position */}
     {window.scrollTo(0,0)}

     <div className='wall_web_small'>
     <ElevationScroll >
        
        
        <AppBar color='white'>
          <Toolbar>
            <div className='container-fluid justify-content-start'>
            <div className='row justify-content-start'>
              <NavLink to='/'>
                  <img src='../../imgs/wm.png' alt='Logo' height='40px'/>
              </NavLink>    
         </div>
            </div>
            <div className='justify-content-end'>
            <MenuIcon  variant='permanent' color='inherit' onClick={toggleDrawer('top', true)} />

<Drawer anchor="top" open={state.top} onClose={toggleDrawer('top', false)}>
  {fullList('top')}
</Drawer>
</div>
          </Toolbar>
        </AppBar>  
        
      </ElevationScroll>
    
    
   </div>


<div className='wall_web_large'>
<div className={classes.root} >
      <CssBaseline />

      <ElevationScroll>
        
        
        <AppBar color='white'>
          <Toolbar>
          <div className='container-fluid justify-content-start'>
            <div className='row justify-content-start'>
            <NavLink to='/'>
                  <img src='../../imgs/wm.png' alt='Logo' height='40px'/>
            </NavLink>      
         </div>
            </div>

            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration= {500}>
            
                <a href='#home' style={{textDecoration:'none'}}>
                  <p className='wall_web_links'> Home </p> 
                </a>
            </Link>

       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Link
              activeClass="active"
              to="features"
              spy={true}
              smooth={true}
              offset={-70}
              duration= {500}>     

               <a href='#features' style={{textDecoration:'none'}}>
                 <p className='wall_web_links'> Features </p>
               </a>
              </Link>  

     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Link
              activeClass="active"
              to="reviews"
              spy={true}
              smooth={true}
              offset={-70}
              duration= {500}>     

            <a href='#reviews' style={{textDecoration:'none'}}>
              <p className='wall_web_links'> Reviews </p>
            </a>
          </Link>

      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Link
              activeClass="active"
              to="team"
              spy={true}
              smooth={true}
              offset={-70}
              duration= {500}>    

             <a href='#team' style={{textDecoration:'none'}}>
               <p className='wall_web_links'> Team </p>
              </a>
          </Link>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          </Toolbar>
        </AppBar>  
      </ElevationScroll>
      <Toolbar />
    


    </div>

    
</div>



<div id='home'> <DownloadApp/> </div>

<div id='features'> <Features /> </div>
<div id='reviews'> <Testimonial/> </div>
<div id='team'> <Team/> </div>
<div> <Form /> </div>
<div> <Footer/> </div>
    </div>
  );
}

export default Header;