import { WALLMAG_ALL } from './../types'



export const Wallmag_All = (data)=>{
  return {
    type: WALLMAG_ALL,
    data,
  }
}

export const wallmag_all  = (data) => {
    return (dispatch) => {
     
            dispatch(Wallmag_All (data));
        
      };
  };

  export const wallmag_rem_post = (data) =>{
    return (dispatch) =>{
      dispatch(Wallmag_All(data))
    }
  }
