import {RELATED_FEEDS} from './../types';



const initialState = {
    related_feeds_data: {},
    related_feeds_load :false,
}
export default function RelatedFeedsReducer(state = initialState, action) {
   
        switch(action.type){
   
            case RELATED_FEEDS : 
            console.log(action)
                return {...state, related_feeds_data: action.data.items , related_feeds_load: true};
            
                default : return state
         }    

    }

 