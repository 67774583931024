import React from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../Styles/Category.css'
import {connect} from "react-redux";
import {story_category} from "./../../Store/action/StoryAction";
import NavLink from 'react-router-dom/NavLink'



class Category extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            data : '',
            clr : this.props.clr,
            clrTxt : this.props.clrTxt,
            catData : this.props.data
          }
         console.log(this.props);

    }

 
 
    componentDidMount(){

      
      if(this.props.load){
    
        }else{
          this.props.story_category(this.state.catData);
          
        }

    }

    render(){

      //   window.innerWidth > 1400
      // ? (this.slidesToShow =4)
      // : window.innerWidth < 1400 && window.innerWidth > 1130
      // ? (this.slidesToShow = 4)
      // : window.innerWidth < 1130 && window.innerWidth > 800
      // ? (this.slidesToShow = 3)
      // : window.innerWidth < 800 && window.innerWidth > 500
      // ? (this.slidesToShow = 2)
      // : window.innerWidth < 500
      // ? (this.slidesToShow = 1)
      // : (this.slidesToShow = 1)

  
      // Setting for Slick Slider
    // var settings = {
    //   dots: false,
    //   infinite: false,
    //   speed: 300,
    //   slidesToShow: this.slidesToShow,
    //   slidesToScroll : 1,
    //   arrows: true,
   
    //   responsive: [
    //     {
    //       breakpoint: 1400,
    //       settings: {
    //         slidesToShow: this.slidesToShow,
    //         arrows: true,
    //         infinite: false,
    //         dots: false
    //       }
    //     },
    //     {
    //       breakpoint: 500,
    //       settings: {
    //         slidesToShow: this.slidesToShow,
    //         slidesToScroll: this.slidesToShow
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: this.slidesToShow,
    //         slidesToScroll: this.slidesToShow
    //       }
    //     }
    //   ]
    // }
        

  return(
        // <div className='container top'>

        //     <div className='container rs_div'>
        //       <div className='row justify-content-center'>
        //         <p id='categories_header' style={{color:this.state.clr}}>
        //           Explore Collections
        //         </p>
        //       </div>
        //     </div>

        //   <hr className='hr'/> 

        // {
        //   this.props.load ? (
        //     <Slider {...settings}
        //     ref={c => (this.slider = c)}
        //     style={{ overflow: "hidden" }}
        //     >
              
        //   { this.props.story_category_data.items.map((value , index)=>{
        //       return (
        //             <div className='container cat_main_div'>
                          
        //               <NavLink to={`/category/${value.id}`} className="grid-item">
        //               <div
        //                   className='container cats_image'
        //                   style = {{backgroundImage 
        //                               :
        //                                 `linear-gradient( to top , rgba(0,0,0,1),rgba(0,0,0,0), transparent)
        //                                 , url(${value.coverFileProperty.publicUrl})`, borderRadius : '5px',
        //                     }}
                          
        //                     />

        //                 </NavLink>

        //                       <div className='row justify-content-center category_val_div'>
        //                             <p className='category_val' > {value.name} </p>
        //                     </div>
        //                 </div>
        //                 )
        //             })
        //           }
        //         </Slider>)
        //         : (
        //             console.log()
        //         )}


               
        //       </div>
        
        
        
        <>

            <div className='container rs_div'>
              <div className='row justify-content-center'>
                 <p id='categories_header' style={{color:this.state.clr}}>
                   Magazine
                 </p>
               </div>
             </div>

             {/* <hr className='hr'/>  */}


        <div className='container category_parent_div'>
          <div className='row'>
            <div className=' col-md-1 col-lg-2 col-xl-2'></div>

            <div className='container-fluid col-10 col-sm-10 col-md-10 col-lg-8 col-xl-8'>
          <div className='row category_row'  style={{justifyContent:'center'}}>


 
            
          { this.props.load ? this.props.story_category_data.items.map((value , index)=>{
            return(
              <div className='col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3 mt-2 mb-2 justify-content-center'>
                <NavLink style={{textDecoration:'none'}} to={`/category/${value.id}`} className="grid-item">
                  <img 
                    id='image_category'
                    src={value.logoFileProperty.publicUrl} 
                    alt={value.name}  
                    />
                  <p id="category_title" style={{color:this.state.clrTxt}}>{value.name}</p>
                  </NavLink>
              </div>
            )
          })
          :
          ''
          }
          

              </div>
            </div>

            <div className=' col-md-1 col-lg-2 col-xl-2'></div>

          </div>
        </div>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
      story_category_data: state.story_cat.story_category,
      load : state.story_cat.load
    };
  };
  export default connect(  mapStateToProps,{story_category })(Category);