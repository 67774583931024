import React from 'react';
import '../../Styles/EditProfile.css';
import { Button, createMuiTheme, DialogActions, TextField} from '@material-ui/core';
// import { FormControlLabel, Checkbox, Button } from '@material-ui/core';
import cogoToast from 'cogo-toast';
import { update_user } from '../common/AppConstants';
import { Progress } from 'react-sweet-progress';
import {storage} from '../Login/firebaseConfig';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import firebase from '../Login/firebaseConfig';
import ReactGA from 'react-ga';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';


const theme = createMuiTheme({
    typography: {
      // Use the system font.
      fontFamily:
        // '-apple-system,system-ui,BlinkMacSystemFont,' 
        // +
        'Montserrat',
    },
  })

// import withFirebaseAuth from "react-auth-firebase";


// var actionCodeSettings = {
//     url: 'https://wallmag.io/',
//     iOS: {
//       bundleId: 'com.example.ios'
//     },
//     android: {
//       packageName: 'com.example.android',
//       installApp: true,
//       minimumVersion: '12'
//     },
//     handleCodeInApp: true,
//     // When multiple custom dynamic link domains are defined, specify which
//     // one to use.
//     dynamicLinkDomain: "example.page.link"
//   };

var spec = ['null'];
var countCheck = 0;
var progress = false;
var are_you_a_photographer = true;
var interested = false;
var mobile, wildlife, creative, wedding, event, portrait,product, fine_art, fashion, architectural, travel, advertising, 
    lifestyle, photojournalism, sports, aerial, scientific, stock = false;

export default class EditProfile extends React.Component{

    constructor(props){
        super(props)

        try{
            are_you_a_photographer =  (JSON.parse(localStorage.getItem('current_user'))).photographer
            interested = (JSON.parse(localStorage.getItem('current_user'))).photoshoot
            spec = (JSON.parse(localStorage.getItem('current_user'))).speciality ? (JSON.parse(localStorage.getItem('current_user'))).speciality : ['null']
            
            if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('wedding')>=0){
                wedding = true
            }
            if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('wildlife')>=0){
                wildlife = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('event')>=0){
                event = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('portrait')>=0){
                portrait = true
            }
             if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('fineArt')>=0){
                fine_art = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('fashion')>=0){
                fashion = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('architectural')>=0){
                architectural = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('travel')>=0){
                travel = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('advertising')>=0){
                advertising = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('lifestyle')>=0){
                lifestyle = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('photojournalism')>=0){
                photojournalism = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('sports')>=0){
                sports = true
            }
              if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('aerial')>=0){
                aerial = true
            }
             if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('scientific')>=0){
                scientific = true
            }
             if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('stock')>=0){
                stock = true
            }
             if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('product')>=0){
                product = true
            }
            if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('mobile')>=0){
                mobile = true
            }
            if((JSON.parse(localStorage.getItem('current_user'))).speciality.indexOf('creative')>=0){
                creative = true
            }
            this.forceUpdate()

            this.state={
                image :  (JSON.parse(localStorage.getItem('current_user'))).photoUrl,
                im_url : '',
                name: (JSON.parse(localStorage.getItem('current_user')).displayName),
                short_bio:(JSON.parse(localStorage.getItem('current_user'))).title,
                mobile:(JSON.parse(localStorage.getItem('current_user'))).contactNo,
                facebook:(JSON.parse(localStorage.getItem('current_user'))).facebookUrl,
                instagram:(JSON.parse(localStorage.getItem('current_user'))).instagramUrl,
                website:(JSON.parse(localStorage.getItem('current_user'))).websiteUrl,
                url : (JSON.parse(localStorage.getItem('current_user'))).photoUrl,
                isPhotographer : (JSON.parse(localStorage.getItem('current_user'))).photographer,
                state : (JSON.parse(localStorage.getItem('current_user'))).state ,
                city : (JSON.parse(localStorage.getItem('current_user'))).city,
                pin_code : (JSON.parse(localStorage.getItem('current_user'))).pinCode,
                address :  (JSON.parse(localStorage.getItem('current_user'))).address,
                cam_gear : (JSON.parse(localStorage.getItem('current_user'))).cameraGear ,
                experience : (JSON.parse(localStorage.getItem('current_user'))).experience,
                im_url:'', // for upload cover image (firebase)
                imageUrl:'', // for download Cover Image
                crop: {
                  aspect:1,
                  unit: 'px', // default, can be 'px' or '%'
                x: 130,
                y: 50,
                width: 200,
                height: 200
                 },
                 dimensions: {
                   lx:0,
                   rx:0,
                   ty:0,
                   by:0
                 } , 
                 imghw :{
                   hgt:0,
                   wdt:0
                 },
                 image_cropper : false,
            }
        }catch{
            are_you_a_photographer = true;
            //console.log('catch')
            // when New user comes from email and password method
            this.state={
                image :'',
                im_url : '',
                name: '',
                short_bio:'',
                mobile:'',
                facebook:'',
                instagram:'',
                website:'',
                isPhotographer : false,
                state :'' ,
                city : '',
                pin_code : '',
                address : '',
                cam_gear : '',
                experience : '',
                im_url:'', // for upload cover image (firebase)
                imageUrl:'', // for download Cover Image
                crop: {
                  aspect:1,
                  unit: 'px', // default, can be 'px' or '%'
                  x: 130,
                  y: 50,
                  width: 200,
                  height: 200
                 },
                 dimensions: {
                   lx:0,
                   rx:0,
                   ty:0,
                   by:0
                 } , 
                 imghw :{
                   hgt:0,
                   wdt:0
                 },
                 image_cropper : false
            }
        }
        
        
        ReactGA.initialize('UA-117934873-2', {
            gaOptions: {
              userId: JSON.parse(localStorage.getItem('current_user')) ? JSON.parse(localStorage.getItem('current_user')).displayName+'_'+JSON.parse(localStorage.getItem('current_user')).id : 'Guest_User' 
            }
          });
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scroll(0,0)
    }


    componentDidMount(){
    
        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})

        if(JSON.parse(localStorage.getItem('current_user'))){
            if((JSON.parse(localStorage.getItem('current_user'))).contactNo===''){
                cogoToast.info('Please Update Your Contact Info')
        }else if(!(JSON.parse(localStorage.getItem('current_user'))).contactNo){
            cogoToast.info('Please Update Your Contact Info')
        }
        }else{
            cogoToast.info('Please update Your Profile Information')
        }
           
    }

    // add speciality
    addSpeciality = (e, value)=>{

        if(value === 'travel'){
            travel = true
        }else if(value === 'wildlife'){
            wildlife = true
        }
        else if(value === 'wedding'){
            wedding = true
        }else if(value === 'event'){
            event = true
        }else if(value === 'portrait'){
            portrait = true
        }else if(value === 'fineArt'){
            fine_art = true
        }else if(value === 'fashion'){
            fashion = true
        }else if(value === 'architectural'){
            architectural = true
        }else if(value === 'advertising'){
            advertising = true
        }else if(value === 'lifestyle'){
            lifestyle = true
        }else if(value === 'photojournalism'){
            photojournalism = true
        }else if(value === 'sports'){
            sports = true
        }else if(value === 'aerial'){
            aerial = true
        }else if(value === 'scientific'){
            scientific = true
        }else if(value === 'stock'){
            stock = true
        }else if(value === 'product'){
            product = true
        }else if(value === 'mobile'){
            mobile = true    
        }else if(value === 'creative'){
            creative = true
        }else{

        }

        spec.push(value)
        //console.log(spec) 

        this.forceUpdate()
    }


    // remove speciality
    removeSpeciality =(e, value) => {

        if(value === 'travel'){
            travel = false
        }else if(value === 'wildlife'){
            wildlife = false
        }else if(value === 'wedding'){
            wedding = false
        }else if(value === 'event'){
            event = false
        }else if(value === 'portrait'){
            portrait = false
        }else if(value === 'fineArt'){
            fine_art = false
        }else if(value === 'fashion'){
            fashion = false
        }else if(value === 'architectural'){
            architectural = false
        }else if(value === 'advertising'){
            advertising = false
        }else if(value === 'lifestyle'){
            lifestyle = false
        }else if(value === 'photojournalism'){
            photojournalism = false
        }else if(value === 'sports'){
            sports = false
        }else if(value === 'aerial'){
            aerial = false
        }else if(value === 'scientific'){
            scientific = false
        }else if(value === 'stock'){
            stock = false
        }else if(value === 'product'){
            product = false
        }else if(value === 'mobile'){
            mobile = false
        }else if(value === 'creative'){
            creative = false
        }
        else{

        }



        var i = spec.indexOf(value);
        if(-1 !== i) {
            spec.splice(i, 1);
        }
        //console.log(spec) 
        this.forceUpdate()
    }


    updateuserProfilePic = () => {
        
            progress = true
            this.setState({
                percent : 0
            })

            const {im_url} = this.state
            let imageName = (new Date).getTime()
            const uploadtask =  storage.ref('profile_pictures/image'+imageName).put(im_url)
            uploadtask.on('state_changed' , 
            (snapshot) =>{
                
               this.setState({
                            percent : (snapshot.bytesTransferred /snapshot.totalBytes * 100)-10
                        })  
            },
              (error) =>{
                       alert(error)
              } ,
               () =>{
                // Complete Function
                storage.ref('profile_pictures/').child("image"+imageName).getDownloadURL().then(url =>{
                
          axios.post(`https://wallmag-2.appspot.com/_ah/api/uploadApi/v1/uploadImageByUrlHighResByAllEdge`,null ,{
            params : {            
              bottomY : this.state.dimensions.by ,
              imageUrl : url ,
              insertInDb : false ,
              leftX : this.state.dimensions.lx ,
              rightX : this.state.dimensions.rx , 
              shouldStoreImage : true ,
              topY : this.state.dimensions.ty,
      
          }
        })
            .then(res => {
              if(res.data.error){
                this.setState({
                  percent : 60
                })
                alert(res.data.error.errors.message)
              }
              else{
              this.setState({
                crop: {
                  aspect:0.9
                 },
                image : res.data.publicUrl,
                imgProp : res.data,
              })
              this.updateUserImage(url)
             
            }
            }).catch(error=>{
              this.setState({
                Progress : false
              })
            })
            
          }).catch(error=>{
            this.setState({
              Progress:false
            })
          })
    
                      })
    }



       //   save User Profile Info
       saveUserProfileInfo = (e) =>{

        if(this.state.name === ''){
            cogoToast.info('Please mention your Name')
        }else{

        if(this.state.im_url === '' && this.state.url !== ''){

            this.updateUser(this.state.url)
        }
        }
        }

    // updating user info

    updateUser = (url) => {

        if(this.state.name === ''){
            cogoToast.info('Please enter your full name')
        }else if(!this.state.mobile === ''){
            if(this.state.mobile.length < 7 || this.state.mobile.length > 15  ){
            alert('Please add a valid mobile number')
        }} else{

        axios.put(update_user+localStorage.id,
              
            {
               "contactNo" : `+91${this.state.mobile}`,
               'displayName' : this.state.name ? this.state.name.trim() : '',
               'title' : this.state.short_bio ? this.state.short_bio.trim() : '',
               'websiteUrl' : this.state.website ? this.state.website.trim() : '',
               'facebookUrl' : this.state.facebook ? this.state.facebook.trim() : '',
               'instagramUrl' : this.state.instagram ?  this.state.instagram.trim() : '',
               'photoUrl' : JSON.parse(localStorage.getItem('current_user')).photoUrl ?  JSON.parse(localStorage.getItem('current_user')).photoUrl :null ,
               'photographer' : are_you_a_photographer,
               'photoshoot'  : interested,
               'state' : this.state.state ?  this.state.state.trim() : '',
               'city' :  this.state.city ?  this.state.city.trim() : '',
               'pinCode' :  this.state.pin_code ?  this.state.pin_code : '',
               'address' :  this.state.address ?  this.state.address.trim() : '',
               'cameraGear' : this.state.cam_gear ?  this.state.cam_gear.trim() : '',
               'experience' : this.state.experience ?  this.state.experience : '',
               'speciality': spec
           }
           ).then(response => {
               // //console.log(response)
               this.setState({
                   title:'',
                   story:'',
                   image: '',
                   im_url:''
               })
               
               // changes into localstorage after updating user data
               var user_data = JSON.parse(localStorage.getItem('current_user')) 

               user_data.displayName = response.data.displayName
               user_data.contactNo = response.data.contactNo
               user_data.title = response.data.title
               user_data.websiteUrl = response.data.websiteUrl
               user_data.facebookUrl = response.data.facebookUrl
               user_data.instagramUrl = response.data.instagramUrl
               user_data.photoUrl = response.data.photoUrl
               user_data.photographer = response.data.photographer
               user_data.photoshoot = response.data.photoshoot
               user_data.state = response.data.state
               user_data.city = response.data.city
               user_data.pinCode = response.data.pinCode
               user_data.address = response.data.address
               user_data.cameraGear = response.data.cameraGear
               user_data.experience = response.data.experience
               user_data.speciality = response.data.speciality


               localStorage.setItem(
                   'current_user',JSON.stringify(user_data)
               )



               localStorage.setItem(
                   'image', response.data.photoUrl
               )
               cogoToast.success('Your Profile has been updated !')
            
    
            window.location.assign(localStorage.getItem('loc'))
        
               
           }).catch(error =>{
               cogoToast.error('There is an error here (An error occored. Please referesh and try again)')
           })

      
            
       
     
    }
    }

    closeDialog = () => {
        this.setState({
            image_cropper : false
        })
    }


    updateUserImage = (url) => {

        if(this.state.name === ''){
            cogoToast.info('Please enter your full name')
        }else{

        axios.put(update_user+localStorage.id,
              
            {
               "contactNo" : `+91${this.state.mobile}`,
               'displayName' : this.state.name ? this.state.name.trim() : '',
               'title' : this.state.short_bio ? this.state.short_bio.trim() : '',
               'websiteUrl' : this.state.website ? this.state.website.trim() : '',
               'facebookUrl' : this.state.facebook ? this.state.facebook.trim() : '',
               'instagramUrl' : this.state.instagram ?  this.state.instagram.trim() : '',
               'photoUrl' : this.state.image ?  this.state.image : JSON.parse(localStorage.getItem('current_user')).photoUrl,
               'photographer' : are_you_a_photographer,
               'photoshoot'  : interested,
               'state' : this.state.state ?  this.state.state.trim() : '',
               'city' :  this.state.city ?  this.state.city.trim() : '',
               'pinCode' :  this.state.pin_code ?  this.state.pin_code : '',
               'address' :  this.state.address ?  this.state.address.trim() : '',
               'cameraGear' : this.state.cam_gear ?  this.state.cam_gear.trim() : '',
               'experience' : this.state.experience ?  this.state.experience : '',
               'speciality': spec
           }
           ).then(response => {
               // //console.log(response)
               this.setState({
                   percent : 100,
                   title:'',
                   story:'',
                   image: '',
                   im_url:''
               })
               progress = false
               this.setState({
                image_cropper : false
            })
           
               
               // changes into localstorage after updating user data
               var user_data = JSON.parse(localStorage.getItem('current_user')) 

               user_data.displayName = response.data.displayName
               user_data.contactNo = response.data.contactNo
               user_data.title = response.data.title
               user_data.websiteUrl = response.data.websiteUrl
               user_data.facebookUrl = response.data.facebookUrl
               user_data.instagramUrl = response.data.instagramUrl
               user_data.photoUrl = response.data.photoUrl
               user_data.photographer = response.data.photographer
               user_data.photoshoot = response.data.photoshoot
               user_data.state = response.data.state
               user_data.city = response.data.city
               user_data.pinCode = response.data.pinCode
               user_data.address = response.data.address
               user_data.cameraGear = response.data.cameraGear
               user_data.experience = response.data.experience
               user_data.speciality = response.data.speciality


               localStorage.setItem(
                   'current_user',JSON.stringify(user_data)
               )



               localStorage.setItem(
                   'image', response.data.photoUrl
               )
               cogoToast.success('Your Profile Picture has been updated !')
               progress = false
               this.setState({
                progress: false
            })
            
    
        
               
           }).catch(error =>{
               cogoToast.error('There is an error here (An error occored. Please referesh and try again)')
           })

      
            
       
     
    }
    }

    
            // On value change 
            change = (e)=>{
                this.setState({
                  [e.target.id] : e.target.value,
                  open : false
                })
              }


        // is photographer
        IsPhotographer =(e, is_photographer) => {
            are_you_a_photographer = is_photographer

            if(is_photographer == false){
                interested = false;
            }
            this.forceUpdate()
        }

        PaidPhotography = (e,is_paid)=>{
            interested = is_paid
            this.forceUpdate()
        }



// I am a photographer

handleCheckedChange = () =>{
    countCheck +=1
  
    if(countCheck%2===0){
      this.setState({
        isPhotographer : false
      })
  
  
    }else{
      this.setState({
        isPhotographer : true
      })
      
    }

 
   
  }




        // Select Image
        pick_image = e =>{
            if(e.target.files[0]){

                if(e.target.files[0]){
                    if(e.target.files[0].type == 'image/jpeg' || 
                        e.target.files[0].type == 'image/Jpg' || 
                        e.target.files[0].type == 'image/png' )
                        {

                            if(e.target.files[0].size <= 10485760){

                                const image = e.target.files[0]
                                let reader = new FileReader();
                                reader.onload = e =>{    
                                  this.setState({image: e.target.result});
                                   this.setState({im_url  : image , file_props_count : 0})
                                }
                                reader.readAsDataURL(e.target.files[0]);
                                this.setState({image_cropper : true})

                            }else{
                                cogoToast.warn('File limitation 10 MB (Image larger than 10MB is not allowed)')

                            }

                        }else{
                            cogoToast.warn('Other files are not allowed to upload such as PDF etc.. (Only Image format is allowed)')
                        }
            } } }


    // Back Button Pressed

    backButton=()=>{

    if(JSON.parse(localStorage.getItem("current_user")).displayName === undefined){
        // this.updateUserName()
        cogoToast.info('You have to Complete Your Profile')
    }else{
        window.history.back()
    }

    }


    // Updating userName
    updateUserName = () => {

        axios.put(update_user+localStorage.id,
                
            {
                
                'displayName' : this.state.name ? this.state.name.trim() : '',
            
            }
            ).then(response => {
                // //console.log(response)
                this.setState({
                    percent : 100,
                    title:'',
                    story:'',
                    image: '',
                    im_url:''
                })
                
                // changes into localstorage after updating user data
                var user_data = JSON.parse(localStorage.getItem('current_user')) 

                user_data.displayName = response.data.displayName
                user_data.contactNo = response.data.contactNo
                user_data.title = response.data.title
                user_data.websiteUrl = response.data.websiteUrl
                user_data.facebookUrl = response.data.facebookUrl
                user_data.instagramUrl = response.data.instagramUrl
                user_data.photoUrl = response.data.photoUrl
                user_data.photographer = response.data.photographer
                user_data.photoshoot = response.data.photoshoot
                user_data.state = response.data.state
                user_data.city = response.data.city
                user_data.pinCode = response.data.pinCode
                user_data.address = response.data.address
                user_data.cameraGear = response.data.cameraGear
                user_data.experience = response.data.experience
                user_data.speciality = response.data.speciality


                localStorage.setItem(
                    'current_user',JSON.stringify(user_data)
                )

                localStorage.setItem(
                    'image', response.data.photoUrl
                )
                cogoToast.success('Your Profile has been updated !')
                progress = false
                this.setState({
                progress: false
            })
            

            window.location.assign('/')
        
                
            }).catch(error =>{
                cogoToast.error('There is an error here (An error occored. Please referesh and try again)')
            })}


// handle on crop
handleOnCropChange = (crop) =>{
    this.setState({
                crop : crop ,
                dimensions :{
                   lx:crop.x/this.state.imghw.wdt,
                   rx:(crop.x+crop.width)/this.state.imghw.wdt,
                   ty:crop.y/this.state.imghw.hgt,
                   by:(crop.y+crop.height)/this.state.imghw.hgt,
                }  ,
                } , )
  }
  
  
  
  handleImageLoaded = (image) =>{
    this.setState({
      imghw:{
        hgt:image.offsetHeight,
        wdt:image.offsetWidth
      },
    //   image:image
    })
  }
  
    render(){
        return(
            <div style={{overflow:'hidden'}}>
                <div className='arrow3'>
                    <img style={{cursor:'pointer'}} onClick={e=> this.backButton()} className='back_arrow' src={require('../../logo/back_button.svg')} alt='back button' height='35px' />
                </div>
                
                <p className='headingEditProfile' style={{marginTop:'10px'}}>Edit Your Profile</p>
                <br></br>

                <div className='container' >
                          <label htmlFor="raised-button-file">
                                <img  src={require('../../logo/cover.svg')} alt='change' height='40px'
                                         style={{cursor:'pointer', position:'absolute', marginLeft:'65px', marginTop:'60px'}} />
                            </label>
                        {
                            !JSON.parse(localStorage.getItem('current_user')) ? 
                            <></>
                            :
                            JSON.parse(localStorage.getItem('current_user')).photoUrl ? 

                            <label htmlFor="raised-button-file">
                                <img 
                                    src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                    alt='user profile' style={{borderRadius:'80px', objectFit:'cover', cursor:'pointer'}} 
                                    height='160px' width='160px'/>
                            </label>    
                            :
                            <label htmlFor="raised-button-file">
                                <img 
                                    src='https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf' 
                                    alt='user profile' style={{borderRadius:'80px', objectFit:'cover', cursor:'pointer'}} 
                                    height='160px' width='160px'/>
                            </label>
                        }

            </div>
            
            {/* Input - images */}
           
                                   <input
                                        accept="image/*"
                                        id="raised-button-file"
                                        type="file"
                                        accept="image/x-png,image/jpeg"
                                        style={{display:'none'}}
                                        onChange={this.pick_image}
                                   />

                <div>
                    <br></br>
                    <br></br>

                    <ThemeProvider theme={theme}>
                    <TextField
                        className='tf2'
                        variant="standard"
                        margin="dense"
                        id="name"
                        type="Name"
                        // label='Full Name'
                        placeholder="Full Name"
                        value={this.state.name}
                        variant="outlined"
                        onChange={e => this.change(e)}
                    />
                    </ThemeProvider>
                    <br></br>

                     <ThemeProvider theme={theme}>
                    <TextField
                        className='tf2'
                        variant="standard"
                        margin="dense"
                        id="short_bio"
                        type="Name"
                        // label='Short Bio'
                        variant="outlined"
                        placeholder="Short Bio"
                        multiline = {true}
                        style={{marginTop:'38px'}}
                        value={this.state.short_bio}
                        onChange={e => this.change(e)}
                    />
                    </ThemeProvider>

                    <br></br>

                    <div className='row' style={{display:'flex', justifyContent:'center'}}>
                     
                    <div   className='row cc-flagE'>
                        <img className='flagE' src={require('../../logo/indian-flag.svg')} alt='flag'/> 
                        <p className='ccE'>+91</p>
                    </div>
                    
                    <div>
                    <ThemeProvider theme={theme}>
                    <TextField
                        className='tf2'
                        variant="standard"
                        margin="dense"
                        id="mobile"
                        type="number"
                        // label='Mobile'
                        variant="outlined"
                        placeholder="Mobile No."
                        style={{marginTop:'38px', width:'200px'}}
                        value={this.state.mobile}
                        onChange={e => this.change(e)}
                    />
                    </ThemeProvider>
                    </div>
                    </div>

                    <br></br>

                    <ThemeProvider theme={theme}>
                    <TextField
                        className='tf2'
                        variant="standard"
                        margin="dense"
                        id="website"
                        type="Name"
                        // label='Instagram / Website'
                        variant="outlined"
                        placeholder="Instagram / Website"
                        style={{marginTop:'15px'}}
                        value={this.state.website}
                        onChange={e => this.change(e)}
                    />
                    </ThemeProvider>

        <div className='row justify-content-center' style={{ marginTop:'50px',textAlignLast:'center'}}>
            <p className='Are-you-a-photographer'>
                Are you a photographer?
            </p>
        </div>


    <div className='row' style={{marginTop:'10px'}}>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>

            {are_you_a_photographer === false ? 
            <div className='row justify-content-center'>
            <div onClick={e=>this.IsPhotographer(e,true)} className="btn-yes-no">
                <p className='yes-no'> Yes</p>
            </div>

            <div className="btn-yes-no_">
                 <p className='yes-no_'> No</p>
            </div>
            </div>

            :

            <div className='row justify-content-center'>
            <div className="btn-yes-no_">
                <p className='yes-no_'> Yes</p>
            </div>


            <div onClick={e=>this.IsPhotographer(e,false)}  className="btn-yes-no">
                 <p className='yes-no'> No</p>
            </div>
            </div>
    }

        </div>    

    </div>


{/* ------------------- */}

{
    are_you_a_photographer === false ? 
    <></>
:
    <div className='row ' style={{marginTop:'49px'}}>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
    </div>
    
{
    are_you_a_photographer === false ? 
    <></>
    :
    <>
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div className='row justify-content-center'>
                <div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>

        <div className='row justify-content-center' style={{marginTop:'25px'}}>
            <div className='Rectangle-1582_'>
                <div style={{marginTop:'25px'}}>
                        <p className='Are-you-a-photographer' >
                            Select your speciality
                        </p>
                </div>   
         <div>
  
  <div className='row justify-content-center'>
    {
        wedding === true ? 

            <div onClick={e=>this.removeSpeciality(e,'wedding')} className="btn-speciality">
                <p className='speciality_yes-no_'> Wedding </p>
            </div>
        :
            <div onClick={e=>this.addSpeciality(e,'wedding')}  className="btn-speciality_">
                <p className='speciality_yes-no'> Wedding </p>
            </div>

    }

{
        wildlife === true ? 
        
            <div onClick={e=>this.removeSpeciality(e,'wildlife')} className="btn-speciality">
                <p className='speciality_yes-no_'> Wildlife </p>
            </div>
        :
            <div onClick={e=>this.addSpeciality(e,'wildlife')}  className="btn-speciality_">
                <p className='speciality_yes-no'> Wildlife </p>
            </div>

    }


{
        mobile === true ? 
        
            <div onClick={e=>this.removeSpeciality(e,'mobile')} className="btn-speciality">
                <p className='speciality_yes-no_'> Mobile </p>
            </div>
        :
            <div onClick={e=>this.addSpeciality(e,'mobile')}  className="btn-speciality_">
                <p className='speciality_yes-no'> Mobile </p>
            </div>

    }

{
        creative === true ? 
        
            <div onClick={e=>this.removeSpeciality(e,'creative')} className="btn-speciality">
                <p className='speciality_yes-no_'> Creative </p>
            </div>
        :
            <div onClick={e=>this.addSpeciality(e,'creative')}  className="btn-speciality_">
                <p className='speciality_yes-no'> Creative </p>
            </div>

    }

    {
        event === true ? 

            <div onClick={e=>this.removeSpeciality(e,'event')} className="btn-speciality">
                <p className='speciality_yes-no_'> Event </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e,'event')}  className="btn-speciality_">
                <p className='speciality_yes-no'> Event </p>
            </div>

    }

    {
        portrait === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'portrait')}  className="btn-speciality">
                <p className='speciality_yes-no_'> Portrait </p>
            </div>
        :

            <div onClick={e=>this.addSpeciality(e,'portrait')}  className="btn-speciality_">
                <p className='speciality_yes-no'> Portrait </p>
            </div>
    }

    {
        fine_art === true ?
        
            <div onClick={e=>this.removeSpeciality(e, 'fineArt')} className="btn-speciality">
                <p className='speciality_yes-no_'>  Fine Art </p>
            </div>
    :

            <div onClick={e=>this.addSpeciality(e, 'fineArt')} className="btn-speciality_">
                <p className='speciality_yes-no'>  Fine Art </p>
            </div>
        
    }

    {
        product === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'product')} className="btn-speciality">
                <p className='speciality_yes-no_'> Product </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'product')} className="btn-speciality_">
            <p className='speciality_yes-no'> Product </p>
            </div>
    }


{
        fashion === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'fashion')} className="btn-speciality">
                <p className='speciality_yes-no_'> Fashion </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'fashion')} className="btn-speciality_">
            <p className='speciality_yes-no'> Fashion </p>
            </div>
    }

    
{
        architectural === true ? 

            <div onClick={e=>this.removeSpeciality(e,'architectural')} className="btn-speciality">
                <p className='speciality_yes-no_'> Architectural </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'architectural')} className="btn-speciality_">
            <p className='speciality_yes-no'> Architectural </p>
            </div>
    }

    
{
        travel === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'travel')} className="btn-speciality">
                <p className='speciality_yes-no_'> Travel </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'travel')} className="btn-speciality_">
            <p className='speciality_yes-no'> Travel </p>
            </div>
    }

    
{
        advertising === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'advertising')} className="btn-speciality">
                <p className='speciality_yes-no_'> Advertising </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e,'advertising')} className="btn-speciality_">
            <p className='speciality_yes-no'> Advertising </p>
            </div>
    }


{
        lifestyle === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'lifestyle')} className="btn-speciality">
                <p className='speciality_yes-no_'> Lifestyle </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'lifestyle')} className="btn-speciality_">
            <p className='speciality_yes-no'> Lifestyle </p>
            </div>
    }

{
        photojournalism === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'photojournalism')} className="btn-speciality">
                <p className='speciality_yes-no_'> Photojournalism </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'photojournalism')} className="btn-speciality_">
            <p className='speciality_yes-no'> Photojournalism </p>
            </div>
    }

{
        sports === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'sports')} className="btn-speciality">
                <p className='speciality_yes-no_'> Sports </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'sports')} className="btn-speciality_">
            <p className='speciality_yes-no'> Sports </p>
            </div>
    }

{
        aerial === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'aerial')} className="btn-speciality">
                <p className='speciality_yes-no_'> Aerial </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'aerial')} className="btn-speciality_">
            <p className='speciality_yes-no'> Aerial </p>
            </div>
    }

{
        scientific === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'scientific')} className="btn-speciality">
                <p className='speciality_yes-no_'> Scientific </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'scientific')} className="btn-speciality_">
            <p className='speciality_yes-no'> Scientific </p>
            </div>
    }

    {
        stock === true ? 

            <div onClick={e=>this.removeSpeciality(e, 'stock')} className="btn-speciality">
                <p className='speciality_yes-no_'> Stock </p>
            </div>

        :

            <div onClick={e=>this.addSpeciality(e, 'stock')}  className="btn-speciality_">
            <p className='speciality_yes-no'> Stock </p>
            </div>
    }



    </div>



        </div>
    </div>

<div className='container'>
    <div className='row justify-content-center' style={{marginTop:'49px'}}>
            <div className='Rectangle-1582'>
                <div style={{marginTop:'25px'}}>
                        <p className='Are-you-a-photographer' >
                            Are you interested in Earning Opportunities from WallMag?
                        </p>
                 </div>   
                 <div>
                 {interested === true ? 
          
          
          <div className='row justify-content-center'>
            
            <div  className="btn-yes-no2_">
                <p className='yes-no_'> Yes</p>
            </div>


            <div onClick={e=>this.PaidPhotography(e, false)} className="btn-yes-no2">
                 <p className='yes-no'> No</p>
            </div>



            
            </div>

            :

            <div className='row justify-content-center'>
            <div  onClick={e=>this.PaidPhotography(e, true)}  className="btn-yes-no2">
                <p className='yes-no'> Yes</p>
            </div>


            <div  className="btn-yes-no2_">
                 <p className='yes-no_'> No</p>
            </div>
            </div>
    }
                </div>
            </div>
        </div>
</div>

</div>

</div>
                       </div>        

        </div>
            </div>
   


       

            </>
    
}



    </div>            
                
    }
    {
        interested === true ? 
        <div>
             <br></br>
             <ThemeProvider theme={theme}>
                    <TextField
                            className='tf2'
                            variant="standard"
                            margin="dense"
                            id="state"
                            type="Name"
                            // label='State'
                            variant="outlined"
                            placeholder="State"
                            style={{marginTop:'38px'}}
                            value={this.state.state}
                            onChange={e => this.change(e)}
                        />
                </ThemeProvider>
                        <br></br>

                        <ThemeProvider theme={theme}>
                        <TextField
                            className='tf2'
                            variant="standard"
                            margin="dense"
                            id="city"
                            type="Name"
                            // label='City'
                            variant="outlined"
                            placeholder="City"
                            style={{marginTop:'38px'}}
                            value={this.state.city}
                            onChange={e => this.change(e)}
                        />
                        </ThemeProvider>
                         <br></br>

                         <ThemeProvider theme={theme}>    
                         <TextField
                            className='tf2'
                            variant="standard"
                            margin="dense"
                            id="pin_code"
                            type="number"
                            // label='Pin Code'
                            variant="outlined"
                            placeholder="Pin Code"
                            style={{marginTop:'38px'}}
                            value={this.state.pin_code}
                            onChange={e => this.change(e)}
                        />
                        </ThemeProvider>
                        <br></br>

                        <ThemeProvider theme={theme}>
                        <TextField
                            className='tf2'
                            variant="standard"
                            margin="dense"
                            id="address"
                            type="Name"
                            // label='Address'
                            variant="outlined"
                            placeholder="Address"
                            style={{marginTop:'38px'}}
                            value={this.state.address}
                            onChange={e => this.change(e)}
                        />
                        </ThemeProvider>


                        <br></br>

                        <ThemeProvider theme={theme}>
                        <TextField
                            className='tf2'
                            variant="standard"
                            margin="dense"
                            id="cam_gear"
                            type="Name"
                            // label='Describe your camera gear'
                            variant="outlined"
                            placeholder="Describe your camera gear"
                            style={{marginTop:'38px'}}
                            value={this.state.cam_gear}
                            onChange={e => this.change(e)}
                        />
                        </ThemeProvider>
                         <br></br>

                         <ThemeProvider theme={theme}>
                         <TextField
                            className='tf2'
                            variant="standard"
                            margin="dense"
                            id="experience"
                            type="number"
                            // label='Year of experience'
                            variant="outlined"
                            placeholder="Year of experience"
                            style={{marginTop:'38px'}}
                            value={this.state.experience}
                            onChange={e => this.change(e)}
                        />
                        </ThemeProvider>

                  <br></br>



        </div>    
        :
        <></>
    }
    
{/* ------------------ */}
                    <div className='container payment_button' style={{width:'100%', marginTop:'48px', marginBottom:'48px'}}>
                            <a onClick={e=>this.saveUserProfileInfo(e)}>
                                <div className="container butt121 ">
                                        <p id='pre_'> Save </p>
                                </div>  
                            </a>
                        </div>

                </div>

                {/* --------------------------------------- */}

                        <div style={{marginTop:'200px'}}>
                        </div>

                {/* ------------------------------- */}

            
            
            {/* Dialog for Image Cropper */}
            <Dialog
                open={this.state.image_cropper}
                onClose={e => this.closeDialog()}
                disableBackdropClick = {true}
            >
                <div style={{position:'relative'}}>
                    <img 
                        style={{position:'absolute', right:'10px', top:'15px', cursor:'pointer'}} 
                        onClick={(e) => this.closeDialog()}
                        src={require('../../logo/cancel.svg')} alt='cross-icon' />
                           <ReactCrop
                                style={{margin:'45px 20px 0px 20px', maxHeight:'600px'}}
                                src={this.state.image} 
                                crop = {this.state.crop} 
                                onImageLoaded = {this.handleImageLoaded}
                                onComplete = {this.handleOnCropComplete}
                                onChange={this.handleOnCropChange} />
                </div>
                    <DialogActions>
                        <div
                            className='container butt-crop' 
                            onClick={() => this.updateuserProfilePic()}>
                                 <div className="container">
                                    <p id="pre2">Crop & Add</p>
                                 </div>   
                    </div>

                    </DialogActions>
                        
            </Dialog>
            
            
            
                <Dialog 
                    open={progress} 
                    onClose={e => this.closeDialog()}
                    disableBackdropClick={true}>
                        
                        
                    <div style={{marginLeft:'100px', marginRight:'100px', marginTop:'30px', marginBottom:'30px'}}>

                        <Progress type="circle" percent={this.state.percent} status="success" />
     
                    </div>
    
            </Dialog> 
  
    </div>
        )
    }
}