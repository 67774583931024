import React, { Component } from 'react';
// import Iframe from 'react-iframe';
// import '../../../../node_modules/bootstrap/dist/css/bootstrap.css'
// import '../../../../node_modules/froala-design-blocks/dist/css/froala_blocks.css';
import './Features.css'
// import Testimonial from './Testimonial';

class Features extends Component {
  render() {
    return <section className="bk">
    <div className="container large">
    <div className="row text-lg-right align-items-center">
      <div className="col-12 col-sm-6 col-lg-3">
        <img alt="imiage" height='90px' width='90px' className="fdb-icon" src="./imgs/icons/1.png" />
        <h4 className='topics'>Wallpaper Magazine</h4>
        <p className='about'>Discover Amazing pictures and stories on your mobile homescreen.</p>

        <img alt="iimiage" height='90px' width='90px' className="fdb-icon mt-3 mt-xl-5" src="./imgs/icons/2.png" />
        <h4 className='topics'>Engaging Stories</h4>
        <p className='about'>Every story is a gateway to learning and wisdom.</p>

      </div>

      <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
        <img alt="imiage" height='90px' width='90px' className="fdb-icon" src="./imgs/icons/3.png" />
        <h4 className='topics'>7 Topics</h4>
        <p className='about'>Amazing Places, Personal Growth, Science, Brilliant Products, Culture, Quotes, Photography</p>

        <img alt="imiage" height='90px' width='90px' className="fdb-icon mt-3 mt-xl-5" src="./imgs/icons/4.png" />
        <h4 className='topics'>Photographers Book</h4>
        <p className='about'>Discover photos from world top photographers.</p>

      
      </div>

      <div className="col-7 col-sm-4 col-lg-4 m-auto pt-5 pt-lg-0 order-lg-1">
        <div className='row a'>
        <img alt="imiage" className="img-fluid wallImage" src="../../imgs/wallSecond.png" />
      </div>
      </div>
    </div>
  </div>







  <div className="container small">
    <div className="row text-lg-right align-items-center">

    <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <img alt="imiage" className="img-fluid wallImage" src="../../imgs/wallSecond.png" />
      </div>
      </div>

    
    

      <div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
      <div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
        <img alt="imaige" height='90px' width='90px' className="fdb-icon" src="./imgs/icons/1.png" />
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <h4  className='topics'>Wallpaper Magazine</h4>
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <p className='description'>Discover Amazing pictures and stories on your mobile Homescreen</p>
        </div>
        </div>

</div>
</div>






<div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
      <div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
        <img alt="imaige" height='90px' width='90px' className="fdb-icon" src="./imgs/icons/2.png" />
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <h4  className='topics'>Engaging Stories</h4>
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <p className='description'>Every story is a gateway to learning and wisdom.</p>
        </div>
        </div>

</div>
</div>




      <div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
      <div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
        <img alt="imiage" height='90px' width='90px' className="fdb-icon" src="./imgs/icons/3.png" />
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <h4  className='topics'>7 Topics</h4>
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <p className='description'>Amazing Places, Personal Growth, Science, Brilliant Products, Culture, Quotes, Photography.</p>
        </div>
        </div>

</div>
</div>


<div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
      <div className="col-12 col-sm-12 col-md-12">
      <div className='row justify-content-center'>
        <img alt="imiage" height='90px' width='90px' className="fdb-icon" src="./imgs/icons/4.png" />
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <h4  className='topics'>Photographers Book</h4>
        </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <div className='row justify-content-center'>
        <p className='description'>Discover photos from world top photographers</p>
        </div>
        </div>

</div>
</div>



    </div>
  </div>

  
</section>

  }
}

export default Features