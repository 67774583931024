import React from 'react'
import { Dialog } from '@material-ui/core';
import ReactPlayer from 'react-player';
import './VideoPlayer.css';

const VideoPlayer = (props) => {
    return (
        <>
        <img 
            className='vid-img'
            src={props.thumbnail}
            alt='Thumbnail'
            onClick={props.openPayment}/>

                    {
                              props.videoOverlay ? 
                              <img 
                              className='pp-button'
                              src={require('../../../logo/pause.svg')}
                              alt='Pause Button'
                              onClick={props.closePayment}/>

                              :

                              <img 
                                className='pp-button'
                                src={require('../../../logo/playwall.svg')}
                                alt='Play Button'
                                onClick={props.openPayment}/>
                            }



   {/* Diaog for Video Player */}
       
        <Dialog
            open={props.videoOverlay}
            close={props.closePayment}
            fullScreen   
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none"
              }
            }}>
    <div 
      style={{
        backgroundColor:'transparent' , 
        height:'100%' , 
        width:'100%', 
        position:'relative'}}>
        
        
        <img 
          style={{position:'absolute', top:'40px', right:'15px', height:'35px', opacity:'0.8', cursor : 'pointer'}}
          onClick={props.closePayment} 
          src={require('../../../logo/cancel2.svg')}
          alt="Cancel Button"
          />
        
        <ReactPlayer 
                style={{margin:'auto', marginTop:'100px'}}
                height='80%'
                width='80%'
                playing
                controls = {true}
                url={props.coverMovie} />
    </div>
        </Dialog>

        </>
    )
};

export default React.memo( VideoPlayer);