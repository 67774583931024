import React from 'react'
import MobHeader from '../Header/Mob_Header'
import Header from '../Header/Header'
import axios from 'axios';
import { get_SectionList_byTopic, get_topicBySlug } from '../common/AppConstants';
import ReactHtmlParser from 'react-html-parser'
import '../../Styles/Faq.css'
import Collapsible from 'react-collapsible';
import FooterWallmag from '../Footer/Footer'
import { CircularProgress } from '@material-ui/core';


var sections = []

class FaqSection extends React.Component{

    constructor(props){
        super(props)

        //console.log(this.props.match.params.faqSection);

        this.state={
            heading : localStorage.topic_title,
            sub_heading : localStorage.topic_desc,
            slug : this.props.match.params.faqSection
        }
    }


    componentDidMount(){
        window.scrollTo(0,0)

        if(localStorage.topic_id){
            // if user came from (FAQ page)
            this.getSectionList(localStorage.topic_id)
            localStorage.removeItem('topic_id')
            localStorage.removeItem('topic_desc')
            localStorage.removeItem('topic_title')
            
        }else{

            // getting section info by slug
           
            axios.get(get_topicBySlug+this.state.slug)
                 .then(res=>{
                     //console.log(res);
                     this.setState({heading : res.data.title , sub_heading : res.data.description})
                     this.forceUpdate()
                     this.getSectionList(res.data.id)
                 })
                 .catch(error=>{
                     alert(error.message)
                 })
        }
        
    }


    // get section list
    getSectionList = (id)=>{

        axios.get(get_SectionList_byTopic + id)
             .then(res=>{
                sections = res.data
                this.forceUpdate()
             })
    }



    render(){

        return(
            <div style={{background:'#ffffff'}}>

            {/* Header for Mobile Devices  */}

                 <div className='mob_top_get_featured'>
                 <MobHeader 
                    url=''  
                    clicked='yes' 
                    heading={this.state.heading} 
                    sub_heading={this.state.sub_heading}/>
            </div>

                        
            {/* Header for desktops  */}
            <div className='web_top_get_featured'>
                <Header heading={this.state.heading}
                        sub_heading={this.state.sub_heading}
                        url=''
                        />
            </div>            

            <div className='container' style={{marginTop:'60px'}}>
                    <div className='row'>
                        
                        {
                            sections.items ?
                            sections.items.map((value, index) =>{
                                return(
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 mb-3'>
                                        <p className='section'>{index+1}. {value.name}</p>
                                
                                        {value.questions.map((value, index)=>{
                                            return (
                                                <div class style={{textAlign:'start'}}>

                                            <Collapsible trigger={<p className='question'>{value.questionText}</p>}>
                                                <p className='answer' style={{color: "black"}}>{ReactHtmlParser(value.answerHtml)}</p>
                                            </Collapsible>
                                                                        
                                                </div>


                                            )
                                            
                                        })}
                                    </div>
                                )
                            }
                                )

                                :
                                <></>
                            }

                        </div>
                        </div>    
                    {
                        sections.items ? 
                        <div>
                            <FooterWallmag />
                        </div>
                        :
                        <CircularProgress />

}
                        

            </div>
        )
    }
}

export default FaqSection