import React from 'react'
import Header from '../IssuePages/Header/Header'
import './../../Styles/Awards/HomeScreen.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterWallmag from '../Footer/Footer';
import MobHeader from '../IssuePages/Header/Mob_Header';



class MarketPlace extends React.Component{



    componentDidMount(){
        window.scroll(0,0)
    }

      // go to upload page
      goToUploadPage = (e) => {

        e.preventDefault()

        this.props.history.push('/upload')
    }

    render(){
        return(
            <>
{
    window.innerWidth < 768 ?
    
    

    <div style={{background:'white'}}>

    {/* HeADER of the page */}


        <MobHeader
               url={require('../../logo/market-place.jpg')}
               heading='Turn your passion
               into Earnings '
                sub_heading='Sell your images securely with WallMag'
        />

    {/* Header ends Here */}

          {/* Body Part Starts from here */}
          <div className='container' style={{marginTop:'72px'}}>
                    
                    <div className='row' style={{justifyContent:'center'}}>
                        <p className='our-model'>Our Model</p>
                    </div>

                    <hr className='sep_'></hr>


                    <img src={require('../../logo/money.svg')} alt='money'  style={{height:'52px', padding:'3px', marginTop:'40px'}} />
                    <div className='row' style={{justifyContent:'center', marginTop:'20px'}}>
                    
                        <p className='mp_lg_texts'>Make 50% every time you sell</p>    
                    </div>   

                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'></div>
                        
                        <div className=' col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                  
                            <p className='mp_small_texts'>A photo can sell again and again to multiple
                                                        clients. You will earn money every time</p>    
                        </div>
                    </div>   
                    


                    <img src={require('../../logo/copyright.svg')} alt='copyright'  style={{height:'52px', padding:'3px', marginTop:'21px'}} />
                    <div className='row' style={{justifyContent:'center', marginTop:'20px'}}>
                    
                        <p className='mp_lg_texts'>Always Keep your copyrights</p>    
                    </div> 

                    <div className='row'>
                        <div className=' col-sm-4 col-md-4 col-lg-4 col-xl-4'></div>
                        
                        <div className=' col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                          
                            <p className='mp_small_texts'>You choose which photos you want to sell
                                                            and retain the copyright to your work.</p>    
                        </div>
                    </div>   



                    <img src={require('../../logo/checked.svg')} alt='checked' style={{height:'52px', padding:'3px', marginTop:'21px'}} />
                    <div className='row' style={{justifyContent:'center', marginTop:'20px'}}>
                    
                        <p className='mp_lg_texts'>Upload with ease</p>    
                    </div> 

                    <div className='row'>
                        <div className=' col-sm-4 col-md-4 col-lg-4 col-xl-4'></div>
                        <div className=' col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <p className='mp_small_texts'>Upload your images in 2 simple steps
                                                                    within 30 seconds</p>    
                        </div>
                    </div>   



{/* ------------------------------------- */}

                    <div className='container payment_button' style={{width:'100%', marginTop:'48px'}}>
                <div className="container butt1 " onClick={e=>this.goToUploadPage(e)}>
                    <div className="container">
                        <p id='pre_'> Upload Now </p>
                        <p id='inr'>In 2 simple Steps</p>
                        </div>
                    </div>  
                </div>

{/* ------------------------------ */}


                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                     <div className="vl"></div>
                </div>

                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                        <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
                </div>
                




                </div>
            {/* Body part ends here */}



    {/* Footer here */}
        <div style={{marginTop:'160px'}}>
            <FooterWallmag />
        </div>
    {/* ---------------------  */}
            

    </div>
        
    

                                :
// for large scrrens (greater than 767)

<div style={{background:'white'}}>


            {/* HeADER of the page */}


                <Header
                        url={require('../../logo/market-place.jpg')}
                        heading='Turn your passion
                        into Earnings '
                        sub_heading='Sell your images securely with WallMag'
                        />

            {/* Header ends Here */}

            {/* Body Part Starts from here */}
                <div className='container' style={{marginTop:'72px'}}>
                    
                    <div className='row' style={{justifyContent:'center'}}>
                        <p className='our-model'>Our Model</p>
                    </div>

                    <hr className='sep_'></hr>



                    <div style={{justifyContent:'center', marginTop:'65px'}}>
                    <img src={require('../../logo/money.svg')} alt='money'  style={{height:'72px', padding:'3px', marginTop:'4px', marginBottom:'10px'}} />
                        <p className='mp_lg_texts'>Make 50% every time you sell</p>    
                    </div>   

                    <div className='row'>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-3'></div>
                        
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6'>
                  
                            <p className='mp_small_texts'>A photo can sell again and again to multiple
                                                        clients. You will earn money every time</p>    
                        </div>
                    </div>   
                    



                    <div style={{justifyContent:'center', marginTop:'40px'}}>
                    <img src={require('../../logo/copyright.svg')} alt='money'  style={{height:'72px', padding:'3px', marginTop:'4px', marginBottom:'10px'}} />
                        <p className='mp_lg_texts'>Always Keep your copyrights</p>    
                    </div> 

                    <div className='row'>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-3'></div>
                        
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6'>
                          
                            <p className='mp_small_texts'>You choose which photos you want to sell
                                                            and retain the copyright to your work.</p>    
                        </div>
                    </div>   


                    <div  style={{justifyContent:'center', marginTop:'40px'}}>
                    <img src={require('../../logo/checked.svg')} alt='money'  style={{height:'72px', padding:'3px', marginTop:'4px', marginBottom:'10px'}} />
                        <p className='mp_lg_texts'>Upload with ease</p>    
                    </div> 

                    <div className='row'>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-3'></div>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6'>
                                <p className='mp_small_texts'>Upload your images in 2 simple steps
                                                                    within 30 seconds</p>    
                        </div>
                    </div>   



{/* ------------------------------------- */}

                    <div className='container payment_button' style={{width:'100%', marginTop:'110px'}}>
                <div className="container butt1 " onClick={e=>this.goToUploadPage(e)}>
                    <div className="container">
                        <p id='pre_'> Upload Now </p>
                        <p id='inr'>In 2 simple Steps</p>
                        </div>
                    </div>  
                </div>

{/* ------------------------------ */}


                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                     <div className="vl"></div>
                </div>

                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                        <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
                </div>
                




                </div>
            {/* Body part ends here */}


            {/* Footer here */}
                <div style={{marginTop:'160px'}}>
                    <FooterWallmag />
                </div>
            {/* ---------------------  */}
                    

            </div>
                
                
                }


            </>
        )
    }
}

export default MarketPlace