import React from 'react'

class ForgottenPassword extends React.Component{

  

    render(){
        return(
            <div>
                
            </div>
        )
    }
}

export default ForgottenPassword