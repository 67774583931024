import React from 'react';
import ReactImageFallback from 'react-image-fallback';
import '../../Styles/MyDialog/Invite.css';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme, TextField} from '@material-ui/core';
import cogoToast from 'cogo-toast';

var diamond = [20,40,60,80,100]
var gold = [4,8,12,16,20]
var silver = [2,4,6,8,10]
var bronze = [1,2,3,4,5]
var scores = []

class JuryVote extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            close : this.props.onClose,
            like : this.props.like,
            id : this.props.id,
            score : '',
            desc : '',
            index:6,
            badge : this.props.badge
        }
  

        switch(this.state.badge){
            case '1': scores = gold;    break;
            case '2': scores = silver;  break;
            case '3': scores = bronze;  break;
            case '4': scores = diamond; break;
        }
    }

    // on Change value
    change = (e)=>{
        this.setState({
          [e.target.name] : e.target.value,
          open : false
        })
      }

    // Voting
    voting = (score,index) => {
        this.setState({score,index})
    }

    // Vote User
    voteUser = () =>{
        if(this.state.desc === ''){
            cogoToast.warn('Justify your vote with a comment!')
        }else{
            this.state.like(this.state.desc , this.state.score, this.state.id )
        }
        
    }

    render(){
        return(
            <div style={{padding:'20px'}}>
                <div className='row image_uploaded' style={{justifyContent:'flex-end'}}>
                        
                        <img 
                            className='cancel'
                            alt='cancel'
                            src={require('../../logo/cancel.svg')} 
                            style={{ cursor:'pointer'}} 
                            onClick={() => this.state.close()}/>

                    </div>
            
                    <div className='row image_uploaded' style={{justifyContent:'center', position:'relative', marginTop:'5px'}}>
                    <ReactImageFallback
                        src={JSON.parse(localStorage.getItem('current_user')).photoUrl}
                        fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                        style={{height:'96px', width:'96px', borderRadius:'60px', objectFit:'cover',border:'1px solid #ffffff50'}}
                        alt="cool image should be here"
                        className="my-image" />

                    <img 
                        src={require('../../logo/4.png')} 
                        alt='Jury' 
                        style={{position:'absolute', height:'30px',width:'30px',bottom:'4px'}}/>    

                    </div>

                    <div className='row' style={{justifyContent:'center'}}>
                        <p className='invite-peoples' style={{fontWeight:'600'}}>
                            Cast your vote
                        </p>
                    </div>

                    <div className='row' style={{justifyContent:'center', marginTop:'20px'}}>

                        {
                            scores.map((result,index) => (
                                
                                    this.state.index === index ? 
                                        <div className='Ellipse-282-selected' onClick={() => this.voting(result,index)}>
                                            <p style={{color:'white'}} className='invite-peoples' >{result}</p>
                                        </div>
                                        :
                                        <div className='Ellipse-282' onClick={() => this.voting(result,index)}>
                                            <p className='invite-peoples' >{result}</p>
                                        </div>
                                
                            ))
                        }
                       
                    </div>

                    <div className='row' style={{justifyContent:'center', marginTop:'20px'}}>
                        
                        {
                            window.innerWidth < 768 ? 
                    <textarea 
                        name='desc' id='textarea_desc' 
                        className='newTextBox' 
                        placeholder='Justify your vote with a comment' cols="35" rows="5"  
                           
                            onChange={e => this.change(e)}
                            >


                </textarea>
                            :
                            <textarea 
                            name='desc' id='textarea_desc' 
                            className='newTextBox' 
                            placeholder='Justify your vote with a comment' cols="50" rows="6"  
                               
                                onChange={e => this.change(e)}
                                >
    
    
                    </textarea>

                        }
                    </div>

                    <div className='row' style={{justifyContent:'center', marginTop:'20px'}}>
                        {
                            this.state.score === '' ? '' :
                        
                                <div className='row Rectangle-1957' onClick={() => this.voteUser()}>
                                    <img style={{height:'24px', width:'24px'}} src={require('../../logo/liked.svg')} alt='like' />
                                    < p className='vote60'> 
                                        Vote {this.state.score}
                                    </p>
                                </div>
                        }
                    </div>
            </div>
        )
    }
}

export default JuryVote;