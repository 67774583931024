import React from "react";
import "./AboutUsDesktop.css";
import DesktopHeader from "./DesktopHeader";
import FooterWallmag from "../Footer/Footer";
import pexelsYogendraSingh from "./assets/pexels-yogendra-singh-1438461.png"
import pexelsAsifPav from "./assets/pexels-asif-pav-3093525.png"
import imageAjeet from "./assets/image-15.png"

function AboutUsDesktop() {
  return (
   
    <div className="about-us-page-1280">
    <div className="flex-col-4">
      <div className="overlap-group5">
        <div className="spacer"></div>
          <div className="web_top_about">
            <DesktopHeader url="" />
          </div>
        </div>
        <div className="overlap-group" >
          <img
            className="pexels-yogendra-singh-1438461 animate-enter"
            src={pexelsYogendraSingh}
          />
          <h1 className="title montserrat-bold-white-98px animate-enter1">
            ABOUT
            <br />
            US
          </h1>
          <p className="a-social-initiative montserrat-light-white-16px animate-enter2">
            A social initiative that started to empower artists all around the <br />
            globe to do more and achieve more.&nbsp;&nbsp;Our commitment is to help <br />
            artists so they can create a world full of possibilities for those who <br />
            need them. That’s why we created WallMag, to empower artists to <br />
            create real value among those, who create!
          </p>
        </div>
        <div className="overlap-group2">
          <div className="text-1 montserrat-bold-white-36px">
            &#34;Art must be life - it must belong to everybody&#34;
            <br />
          </div>
          <div className="name montserrat-light-white-22px">Maria Abramovie</div>
        </div>
      </div>
      <div className="flex-row-5">
        <div className="flex-col-1">
          <img
            className="pexels-asif-pav-3093525"
            src={pexelsAsifPav}
          />
          <div className="address montserrat-bold-white-42px">
            4 ways we <br />
            can empower <br />
            artists globally
          </div>
          {/* <div className="text-5 montserrat-light-white-22px">Some text would come here</div> */}
        </div>
        <div className="flex-col">
          <div className="text-2 montserrat-bold-white-56px">
            Artist are
            <br />
            suffering!
          </div>
          <p className="how-is-it-that-in-a montserrat-light-white-16px">
            How is it that in a world that&#39;s evolving so quickly that artists all around still suffering? Strangely
            enough, art is all around us all the time but artists all around us are suffering from their own passion.
            There seems to be an emptiness with the current suffering of an artist and today&#39;s generation of artists
            are disconnected from real value.{" "}
          </p>
          <div className="flex-row-1">
            <div className="group-2674">
              <div className="group-2674-2">
                <div className="group-2674-1">
                  <div className="overlap-group3-2">
                  <img
                      className="path-2967"
                      src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2967-1@1x.png"
                    />
                    <img
                      className="path-2968"
                      src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2968-1@1x.png"
                    />
                    <img
                      className="path-29"
                      src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2969-1@1x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-2675">
              <div className="group-2675-2">
                <div className="group-2675-1">
                  <div className="overlap-group3-1">
                  <img
                      className="path-29"
                      src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2970-1@1x.png"
                    />
                    <img
                      className="path-2971"
                      src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2971-1@1x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row-3">
            <p className="text-3 montserrat-light-white-14px">
              Delivery of affordable <br />
              learning resources
            </p>
            <div className="text-15 montserrat-light-white-14px">
              Recognition of
              <br />
              talented artists
            </div>
          </div>
          <div className="flex-row-4">
            <div className="group-2676-1">
              <div className="overlap-group4">
                <div className="spacer-1"></div>
                <div className="group-2676">
                <img
                    className="icon-awesome-hand-holding-usd"
                    src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/icon-awesome-hand-holding-usd-1@1x.png"
                  />
                </div>
              </div>
            </div>
            <div className="group-2677">
              <img
                className="icon-awesome-id-card"
                src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/icon-awesome-id-card-1@1x.png"
              />
            </div>
          </div>
          <div className="flex-row">
            <p className="text-4 montserrat-light-white-14px">
              Facilitation of&nbsp;&nbsp;
              <br />
              Monetization Resources
            </p>
            <div className="text-14 montserrat-light-white-14px">
              Identification
              <br />
              with digital presence
            </div>
          </div>
        </div>
      </div>
      <div className="group-2813">
        <div className="our-mission montserrat-bold-white-50px">Our Mission</div>
        <p className="our-mission-is-to-up montserrat-light-white-16px">
          Our mission is to uplift Global Art by connecting artists and businesses through
          <br />
          World-Class infrastructure and Technology. Our commitment is to help artists
          <br />
          so they can create a world full of possibilities for those who need them
          <br />A world where businesses and individuals look forward to living.&nbsp;&nbsp;
        </p>
      </div>
      <div className="group-2764">
      <img
          className="nude-neutral-pr-feed-template5"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060cf0525846d394e22f524/img/nude-neutral-product-review-instagram-feed-template-5--1@1x.png"
        />
        <img
          className="nude-neutral-pr-m-feed-template"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060cf0525846d394e22f524/img/nude-neutral-product-review-instagram-feed-template-1@1x.png"
        />
        <img
          className="nude-neutral-pr-feed-template1"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060cf0525846d394e22f524/img/nude-neutral-product-review-instagram-feed-template-1--1@1x.png"
        />
        <img
          className="image-14"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060cf0525846d394e22f524/img/image-14-1@1x.png"
        />
      </div>
      <div className="flex-row-2">
        <div className="group-2801">
          <img
            className="image-15"
            src={imageAjeet}
          />
          <div className="ajeet-kumar-meena">Ajeet Kumar Meena</div>
          <div className="managing-director">Managing Director</div>
        </div>
        <p className="just-like-a-human-br montserrat-light-white-16px">
          Just like a human brain works best when synapses talk to each <br />
          other seamlessly, we believe that the art sector can deliver the <br />
          best to our artist when businesses, change makers and consumers <br />
          exchange ideas and share best practices. - We are that platform for <br />
          artists.
        </p>
      </div>
      <div id="footer_content">
        <FooterWallmag />
      </div>
    </div>
  );
}

export default AboutUsDesktop;

// function Group420(props) {
//   const { contactUs, text8 } = props;

//   return (
//     <div className="group-420">
//       <div className="contact-us montserrat-bold-white-20px">{contactUs}</div>
//       <div className="text-8 montserrat-normal-white-14px">{text8}</div>
//     </div>
//   );
// }
