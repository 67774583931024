import { PRODUCT_API , LOAD } from './../types'
import axios from 'axios'
import { Wallmag_All } from './AllWallmagPostsAction';

const apiUrl = 'https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getFeedsByEntityIdV2?entityId=cover_stories&limit=4';



export const load = ()=>{
  return {
    type: LOAD
  }
}
export const Home_Top = (data)=>{
  return {
    type: PRODUCT_API,
    data,
  }
}

export const home_top  = () => {
    return (dispatch) => {
        return axios.get(apiUrl )
          .then(response => {
            dispatch(Home_Top (response.data));
            dispatch(Wallmag_All(response.data))
          })
          .catch(error => {
            // console.log(error.message);
          });
      };
  };