import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavLink from 'react-router-dom/NavLink'
import '../../../Styles/ProfileHeader.css'
import { loadProgressBar } from 'axios-progress-bar'
import '../../../../node_modules/axios-progress-bar/dist/nprogress.css'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import firebase from "../../Login/firebaseConfig";
// import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { get_followers, follow_user,  get_followings } from '../../common/AppConstants';
import { Dialog, DialogTitle, AppBar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import cogoToast from 'cogo-toast';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ReactImageFallback from 'react-image-fallback';


const formatter = buildFormatter(frenchStrings)


var followers = []
var followings = []
var followingIds = new Set()
class  ProfilePageHeader extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            theposition: 0,
            heading : this.props.heading ,
            sub_heading : this.props.sub_heading,
            clicked : this.props.clicked,
            url : '' , 
            userName : this.props.user,
            anchor : null , 
            userData : this.props.data,
            followers_dialog:false,
            following_dialog:false,
            facebookUrl:'',
            instagramUrl:''
            // facebookUrl : this.props.data.facebookUrl ? this.props.data.facebookUrl : '',
            // instagramUrl :  this.props.data.instagramUrl ? this.props.data.instagramUrl : '',
        }
        
    }

    componentWillReceiveProps(props){
      loadProgressBar()
      
      followingIds = new Set(JSON.parse(localStorage.getItem('followingIds')))
      
      this.forceUpdate()

      this.setState({
        userData : this.props.data
      })

    }
    

  
    saveLoc=()=>{
      localStorage.setItem('loc',window.location.href)
    }
    
    componentDidMount() {
      

      if(this.props.url===undefined){
        this.setState({
          url : ''
    })
      }else{
        this.setState({
            url : this.props.url
      })
    }
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }



      // go to voting-system article
      goToInfo=()=>{
        window.location.assign('/story/voting-system')
        
      }


                    // Redirect to Profile page 
                    goToProfile = (from ,userSlug) =>{
                        
                      // //console.log(this.props)
                      localStorage.setItem('other_user_slug',userSlug)
                      // this.props.props.history.push('/'+userSlug)
                  
                      window.location.assign('/'+userSlug)
                   
              }






      followUser = (e, userId,isFollow)=>{
    
        e.preventDefault()
        axios.put(follow_user, {
            isFollow : isFollow,
            userId : userId
        }).then(response =>{
            
            if(isFollow===true){
                followingIds.add(userId)
            }else{
                followingIds.delete(userId)
            }
            
            localStorage.setItem('followingIds' ,JSON.stringify( Array.from(followingIds)))
            this.forceUpdate()
    
        }).catch(error=>{
            // //console.log(error)
        })
        
    }


    loginFirst =(e)=>{

      e.preventDefault()

      window.location.assign('/login')
    
    }
      

    // get followings 
    getFollowings = (user_id) => {

      
      axios.put(get_followings+'?userId='+user_id)
      .then(res=>{
        
        followings = res.data.items
        this.setState({
          following_dialog:true
        })
        this.forceUpdate()
      })
    }

      // get Followers
      getFollowers = (user_id) => {

          axios.put(get_followers+'?userId='+user_id)
          .then(res=>{
              followers = res.data.items
              this.setState({
                followers_dialog:true
              })
              this.forceUpdate()
          })
      }



      // No followers
      Nofollowers=()=>{
        cogoToast.info("you don't have any followers !")
      }



      // No followings
      noFollowings=()=>{
        cogoToast.info("You are not following anyone !")
      }











      // close dialog
      closeDialog = ()=>{
        this.setState({
          followers_dialog : false,
          following_dialog : false
        })
      }


      // Menu
      handle_menu =(event)=>{
        this.setState({
          anchor : event.currentTarget
        })
      }

      // Menu Close
      handleClose=(e)=>{

        e.preventDefault()

        this.setState({
          anchor : null
        })
      }

      // logout
      logOut = (e) =>{
        e.preventDefault()

        firebase.auth().signOut()
        localStorage.clear()
        
        setTimeout(this.goToLogin, 100)

        // window.location.assign('/')
        
        this.setState({
          anchor : null
        })
        
      }

      goToLogin=()=>{
        window.location.assign('/')
      }


// Listening Scroll Position

      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
        this.setState({
          theposition: scrolled,
        })
      }


    render(){
    
      if(localStorage.current_user){

      }else{
          // return <Redirect to='/login' />
      }


  return (
    <div className='head_div'
           style ={this.state.url==='' ? 
            {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                                     transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                                     , url(${require('../../../logo/bg11.jpg')})`,
                                        height:'600px' , width:'100%' , backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat' }
  
            :
            {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                                     transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                                     , url(${this.state.url})`,
                                        height:'600px' , width:'100%', backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat'  ,
    }} 
    
    >
      
      {/* <div>
      <img src={`${this.state.url}`} height='360px'  width='100%' style={{objectFit:'cover'}}/> */}

    
      <CssBaseline />
    
     

        {this.state.theposition <0.12999                 ?
         //  if clause
            <AppBar  style={{ background: 'transparent', boxShadow: 'none'}}>
             <Toolbar>
                <NavLink to='/'>
                  <img id='wall_logo' src={require('../../../logo/wallmag_white.png')} alt='Logo' />
                </NavLink> 
                    <div className='container-fluid'  style={{alignSelf:'baseline'}}>
                      <div className='row justify-content-end'>
                        <div className='row mt-3'>
 
                     {/*---------------User------------- */}

                     {
                              localStorage.user ? 
                              <>
                              <img  
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  alt='Logo'
                                  style ={{borderRadius:'600px' , cursor:'pointer', objectFit:'cover',border:'1px solid #ffffff50'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  />

                                  <Menu
                                  style={{marginTop:'40px'}}
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  onClose={e=>this.handleClose(e)}
                                  >
                                  
                                  <NavLink to='/'  style={{textDecoration:'none'}}>

                                  <MenuItem>
                                      Home
                                  </MenuItem>
                                  </NavLink>

                                  <NavLink to={'/edit-profile'}  style={{textDecoration:'none'}}> 

                                  <MenuItem>
                                        Edit Profile
                                  </MenuItem>
                                  </NavLink>


                                  <hr></hr>
                                  <MenuItem onClick={e=>this.logOut(e)}>Logout</MenuItem>
                                  </Menu>
                                  </>
                              :
                              <NavLink style={{ textDecoration: 'none' }}  onClick={this.saveLoc}  to='/login' >
                                  <p color='default' className='links'> Login </p>
                             </NavLink>
                         }
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      
                        </div>
                    </div>
                    </div>
               </Toolbar>
             </AppBar>
         
                :

          // else clause
             <AppBar   style={{background:'black', borderBottom:'1px solid #ffffff36'}} >
             
                <Toolbar>
                <NavLink to='/'>
                 <img id='wall_logo2' src={require('../../../logo/wallmag_white.png')} alt='Logo'  />
                </NavLink>
                 <div className='container-fluid '  style={{alignSelf:'baseline'}}>
                    <div className='row justify-content-end '>
                    <div className='row mt-3'>
                     
                       {/*---------------User------------- */}

                        
                       {
                              localStorage.user ? 
                              <>
                              <img  
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  alt='Logo'
                                  style ={{borderRadius:'600px' , cursor:'pointer', objectFit:'cover',border:'1px solid #ffffff50'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  />

                                  <Menu
                                    style={{marginTop:'40px'}}
                                    id="simple-menu"
                                    anchorEl={this.state.anchor}
                                    keepMounted
                                    open={Boolean(this.state.anchor)}
                                    onClose={e=>this.handleClose(e)}
                                >
                                    <NavLink to='/'  style={{textDecoration:'none'}}>

                                  <MenuItem>
                                    Home
                                  </MenuItem>
                                  </NavLink>

                                  
                                    <NavLink to={'/edit-profile'}  style={{textDecoration:'none'}}> 
                                    <MenuItem>

                                        Edit Profile
                                        </MenuItem>
                                    </NavLink>

                                  <hr></hr>
                                  <MenuItem onClick={e=>this.logOut(e)}>Logout</MenuItem>
                                  </Menu>
                                  </>
                              :
                              <NavLink style={{ textDecoration: 'none', color:'#fff' }}  onClick={this.saveLoc}  to='/login' >
                                 <p color='default' className='links2'> Login </p>
                              </NavLink>
                         }

                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        
                    </div>
                    </div>

               </Toolbar>
        </AppBar>
          }
      <Toolbar />



{/*-----------------Profile----------- */}


               <div className='container desc_div' > 

                      {/* Image of The User  */}
                      <div className='row '>   
                  <div className='col-md-12 col-lg-12 col-xl-12'>
                      <div className='row justify-content-center '>

                      {
                          this.state.userData === '' ?
                          <img src={(JSON.parse(localStorage.getItem('current_user')).photoUrl)} 
                                alt='user' 
                                height='120px' 
                                width='120px'
                                style={{borderRadius:'180px', objectFit:'cover',border:'1px solid #ffffff50'}}
                           />
                           :
                           <img src={this.state.userData.photoUrl} 
                              alt='user' 
                              height='120px' 
                              width='120px'
                              style={{borderRadius:'180px', objectFit:'cover',border:'1px solid #ffffff50'}}
                           />

                      }
                      </div>
                  </div> 

                {/* Heading of The Header  */}
                    
                  <div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'40px'}}>
                      <div className='row justify-content-center '>
                        {
                          this.state.userData === '' ?
                          <>
                          <p id='user_name' > {(JSON.parse(localStorage.getItem('current_user')).displayName)} </p>  
                          {
                            JSON.parse(localStorage.getItem('current_user')).badge === '0' ? 
                            <></>
                            :
                            JSON.parse(localStorage.getItem('current_user')).badge === '1' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            JSON.parse(localStorage.getItem('current_user')).badge === '2' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            JSON.parse(localStorage.getItem('current_user')).badge === '3' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                          } 
                          
                          </>
                          :
                          <>
                          <p id='user_name' > {this.state.userData.displayName} </p>
                          {
                            this.state.userData.badge === '0' ? 
                            <></>
                            :
                            this.state.userData.badge === '1' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            this.state.userData.badge === '2' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            this.state.userData.badge === '3' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>

                          } 
                          </>
                        }
                          
                      </div>
                  </div> 
                  
                  {/* Sub_Heading of The Header */}
                    <div className='col-md-12 col-lg-12 col-xl-12'>
                      <div className='row justify-content-center '>

                  {/* {
                    this.state.userData === '' ?

                             JSON.parse(localStorage.getItem('current_user')).facebookUrl === '' ?
                                <></>
                                  :
                                <a href={(JSON.parse(localStorage.getItem('current_user')).facebookUrl).indexOf('https://')=== 0 ?
                                          JSON.parse(localStorage.getItem('current_user')).facebookUrl 
                                          :
                                          'https://'+JSON.parse(localStorage.getItem('current_user')).facebookUrl
                                        } 
                                    
                                    target='_blank'>
                                <img src={require('../../../logo/fb.svg')} alt='fb_logo' style={{height:'24px', width:'24px', opacity:'0.8', cursor:'pointer'}} />
                              </a>

                              :

                              this.state.facebookUrl ===''  ?
                              <></>
                                :
                              <a href={(this.state.facebookUrl).indexOf('https://') === 0 ?
                                        this.state.facebookUrl  
                                        :
                                        'https://'+this.state.facebookUrl
                                      }
                                  target='_blank'>
                              <img src={require('../../../logo/fb.svg')} alt='fb_logo' style={{height:'24px', width:'24px', opacity:'0.8', cursor:'pointer'}} />
                            </a>

                  }
                   
                  {
                    this.state.userData === '' ?
                       
                          (JSON.parse(localStorage.getItem('current_user')).instagramUrl==='' ) ?

                            <></> :
                            
                            <a href={ (JSON.parse(localStorage.getItem('current_user')).instagramUrl).indexOf('https://') === 0 ?
                                      JSON.parse(localStorage.getItem('current_user')).instagramUrl
                                      :
                                      'https://'+JSON.parse(localStorage.getItem('current_user')).instagramUrl} 
                              target='_target'>
                            <img src={require('../../../logo/insta.svg')} alt='insta_logo' style={{height:'24px', width:'24px', marginLeft:'12px', opacity:'0.8', cursor:'pointer'}} />
                            </a>

                            :

                            this.state.instagramUrl===''  ?

                            <></> :
                            
                            <a href={ (this.state.instagramUrl).indexOf('https://') === 0 ?
                                       this.state.instagramUrl
                                       :
                                      'https://'+this.state.instagramUrl} target='_target'>
                            <img src={require('../../../logo/insta.svg')} alt='insta_logo' style={{height:'24px', width:'24px', marginLeft:'12px', opacity:'0.8', cursor:'pointer'}} />
                            </a>
                  } */}


                      {/* <p id='who_are_you'> Photographer </p>  */}
                      </div>
                  </div>


                   {/* Details of The User */}
                   <div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'40px'}}>
                      <div className='row justify-content-center '>
                      <div className='col-md-3 col-lg-3 col-xl-3'></div>

                      {/* ------------------------------------ */}

                          <div className='col-md-6 col-lg-6 col-xl-6'>
                              <div className='row'>


                              <div className='col-md-4 col-lg-4 col-xl-4'>

                                {
                                  this.state.userData === '' ?
                                  <>
                                    <p className='counts'> {(new Set(JSON.parse(localStorage.getItem('myPostIds')))).size} </p> 
                                    <p className='cfb'>Photographs</p> 
                                  </>
                                  :
                                  <>
                                  {
                                    this.state.userData.userAnalytics.myPostIds ?

                                    <>
                                      <p className='counts'>{this.state.userData.userAnalytics.myPostIds.length} </p> 
                                      <p className='cfb'>Photographs</p> 
                                    </>
                                    :
                                    <>
                                      <p className='counts'>0</p> 
                                      <p className='cfb'>Photographs</p>
                                    </>

                                  }
                                    
                                  </>
                                }
 
                              </div>

                              <div className='col-md-4 col-lg-4 col-xl-4'>

                                {
                                  this.state.userData === '' ?
                                    <>
                                      {(new Set(JSON.parse(localStorage.getItem('followersIds')))).size===0 ? 
                                      
                                         <>                               
                                              <p className='counts' style={{cursor:'pointer'}} onClick={e=>this.Nofollowers()}>
                                                {(new Set(JSON.parse(localStorage.getItem('followersIds')))).size} 
                                              </p> 
                                              <p className='cfb'> Followers </p> 
                                              </>
                                                            :
                                    <>
                                                <p className='counts' style={{cursor:'pointer'}} onClick={e=>this.getFollowers(JSON.parse(localStorage.getItem('current_user')).id)}>
                                                {(new Set(JSON.parse(localStorage.getItem('followersIds')))).size} 
                                             </p> 
                                             <p className='cfb'> Followers </p> 
                                    </>
                                  } 

                                    </>
                                    :
                                    <>
                                    {
                                      this.state.userData.userAnalytics.followersIds ?
                                      <>
                                        <p className='counts' style={{cursor:'pointer'}}  onClick={e=>this.getFollowers(this.state.userData.id)}> 
                                            {this.state.userData.userAnalytics.followersIds.length} 
                                        </p>
                                         
                                        <p className='cfb'> Followers </p> 
                                      </>
                                      :
                                      <>
                                        <p className='counts'  onClick={e=>this.Nofollowers()}> 0 </p> 
                                        <p className='cfb'> Followers </p> 
                                      </>
                                    }

                                   </>
                                }

                              </div>
                              
                              <div className='col-md-4 col-lg-4 col-xl-4'>

                                {
                                  this.state.userData === '' ?
                                  <>
                                  {(new Set(JSON.parse(localStorage.getItem('followingIds')))).size===0 ?
                                
                                        <>                                   
                                      <p 
                                        className='counts'  
                                        style={{cursor:'pointer'}} 
                                        onClick={e=>this.noFollowings()}> 
                                                    
                                                    {(new Set(JSON.parse(localStorage.getItem('followingIds')))).size} 
                                       </p> 
                                       
                                      <p className='cfb'> Following </p> </>

                                        :
                                        <>                                    
                                       <p 
                                        className='counts'  
                                        style={{cursor:'pointer'}} 
                                        onClick={e=>this.getFollowings(JSON.parse(localStorage.getItem('current_user')).id)}> 
                                                    
                                                    {(new Set(JSON.parse(localStorage.getItem('followingIds')))).size} 
                                       </p> 
                                       
                                      <p className='cfb'> Following </p> </>
                                
                                
                                }

                                  </>
                                  :
                                  <>
                                  {
                                    this.state.userData.userAnalytics.followingIds ? 
                                    <>
                                      <p className='counts' style={{cursor:'pointer'}}  
                                      onClick={e=>this.getFollowings(this.state.userData.id)}
                                      > 
                                        {this.state.userData.userAnalytics.followingIds.length} 
                                      </p> 


                                      <p className='cfb'> Following </p> 
                                    </>
                                    :
                                    <>
                                      <p className='counts'   style={{cursor:'pointer'}} 
                                                              onClick={e=>this.noFollowings()}>
                                        0
                                      </p> 
                                      <p className='cfb'> Following </p> 
                                    </>
                                  }

                                  </>
                                }
                                 
                              </div>

                              {/* cfb --> comments faourites bookmarks */}
                              </div>
                          </div>

                          {/* --------------------------------------- */}


                          <div className='col-md-3 col-lg-3 col-xl-3'></div>
                      </div>
                  </div>
                  
                  </div>
                </div> 


{/* dialog for follower List (Large Screen devices)*/}
                <Dialog
                  open={this.state.followers_dialog} 
                  onClose={this.closeDialog}
                  disableBackdropClick={true}>

{/* <DialogTitle> */}
                     
                    
                     <div className='row usersListDialog' style={{justifyContent:'space-between'}}>
                                {
                                  followers.length === 1 ? 
                                      <p id='totalLikers'>{followers.length} follower</p>
                                      :
                                      <p id='totalLikers'>{followers.length} followers</p>
                                }
                            
                    
                         <img 
                             src={require('../../../logo/cancel.svg')} 
                             alt='Cancel'
                             style={{marginTop:'-10px', cursor:'pointer'}} 
                             onClick={e=>this.setState({followers_dialog:false})}/>
                     </div>
                     
 
                     
                 {/* </DialogTitle> */}

                 <List style={{width:'500px'}}>

{
    followers ? 
        followers.map((value,indexNo)=>{
            return(
                <ListItem>
            
                <ListItemAvatar>
                    {/* <img 
                        src={value.photoUrl} 
                        alt='user'
                        style={{height:'60px', width:'60px', borderRadius:'60px', objectFit:'cover'}}
                    /> */}

                <ReactImageFallback
                        src={value.photoUrl} 
                        fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                        style={{height:'60px', width:'60px', borderRadius:'60px', objectFit:'cover',border:'1px solid #ffffff50'}}
                        alt="cool image should be here"
                        className="my-image" />
                </ListItemAvatar>
                <div>
                  <div className='row'>
                    <p style={{cursor:'pointer'}} onClick={e=>this.goToProfile(1,value.slug)} id='nameOfLikers'>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                    {
                            value.badge === '0' ? 
                            <></>
                            :
                            value.badge === '1' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            value.badge === '2' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            value.badge === '3' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                          } 
                  </div>  
                    <p id='desig'>
                      Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                    </p>
                </div>
                
                {
                  !JSON.parse(localStorage.getItem('current_user')) ? <>
                  

                 { new Set(JSON.parse(localStorage.getItem('followingIds'))).has(value.id) ? 

                                <div className='following_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                      onClick={e=>this.loginFirst(e)}>
                                    <p id='following_dialog_text'> Following </p>
                                </div>
                                :
                                <div className='follow_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                onClick={e=>this.loginFirst(e)}>
                                <p id='foll_dialog_text'> Follow </p>
                                </div> 
                }
                  
                  </> :
                    (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                        
                        <></> 
                            :

                            new Set(JSON.parse(localStorage.getItem('followingIds'))).has(value.id) ? 

                                <div className='following_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                      onClick={e=>this.followUser(e, value.id,false)}>
                                    <p id='following_dialog_text'> Following </p>
                                </div>
                                :
                                <div className='follow_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                onClick={e=>this.followUser(e, value.id,true)}>
                                    <p id='foll_dialog_text'> Follow </p>
                                </div> 
                }

                </ListItem>

            )
        })        
            
        :
        <></>
}




</List>



                    </Dialog>
{/* dialog for follower List (Large Screen devices)*/}





{/* dialog for following List (Large Screen devices)*/}
                <Dialog
                  open={this.state.following_dialog} 
                  onClose={this.closeDialog}
                  disableBackdropClick={true}>

{/* <DialogTitle> */}
                     
                    
                     <div className='row usersListDialog' style={{justifyContent:'space-between'}}>
                                {
                                  followings.length ? 
                                  followings.length === 1 ? 
                                      <p id='totalLikers'>{followings.length} following</p>
                                      :
                                      <p id='totalLikers'>{followings.length} followings</p>
                                      :
                                      <p id='totalLikers'>0 followings</p>

                                }
                            
                    
                         <img 
                             src={require('../../../logo/cancel.svg')} 
                             alt='Cancel'
                             style={{marginTop:'-10px', cursor:'pointer'}} 
                             onClick={e=>this.setState({following_dialog:false})}/>
                     </div>
                     
 
                     
                 {/* </DialogTitle> */}

                 <List style={{width:'500px'}}>

    {
        followings ? 
        followings.map((value,indexNo)=>{
            return(
                <ListItem>
            
                <ListItemAvatar>
                    {/* <img 
                        src={value.photoUrl} 
                        alt='user'
                        style={{height:'60px', width:'60px', borderRadius:'60px', objectFit:'cover'}}
                    /> */}

                    
                                          <ReactImageFallback
                                                    src={value.photoUrl} 
                                                    fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                                                    style={{height:'60px', width:'60px', borderRadius:'60px', objectFit:'cover',border:'1px solid #ffffff50'}}
                                                    alt="cool image should be here"
                                                    className="my-image" />
                </ListItemAvatar>
                <div>
                  <div className='row'>
                    <p style={{cursor:'pointer'}} onClick={e=>this.goToProfile(1,value.slug)}  id='nameOfLikers'>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                    {
                            value.badge === '0' ? 
                        <></>
                            :
                            value.badge === '1' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            value.badge === '2' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            value.badge === '3' ? 
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                            :
                            <img onClick={e=>this.goToInfo()} src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
                          } 
                  </div>  
                    
                    <p id='desig'>
                    Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                    </p>
                </div>
                
                {
                  !JSON.parse(localStorage.getItem('current_user')) ? 
                  <>
                      {  new Set(JSON.parse(localStorage.getItem('followingIds'))).has(value.id) ? 

                        <div className='following_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                              onClick={e=>this.loginFirst(e)}>
                            <p id='following_dialog_text'> Following </p>
                        </div>
                        :
                        <div className='follow_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                        onClick={e=>this.loginFirst(e)}>
                            <p id='foll_dialog_text'> Follow </p>
                        </div> 
                      }
                  </>
                  
                  
                  :
                  
                    (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                        
                        <></> 
                            :

                            new Set(JSON.parse(localStorage.getItem('followingIds'))).has(value.id) ? 

                                <div className='following_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                      onClick={e=>this.followUser(e, value.id,false)}>
                                    <p id='following_dialog_text'> Following </p>
                                </div>
                                :
                                <div className='follow_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                onClick={e=>this.followUser(e, value.id,true)}>
                                    <p id='foll_dialog_text'> Follow </p>
                                </div> 
                }

                </ListItem>

            )
        })        
            
        :
        <></>
}




</List>



                    </Dialog>
{/* dialog for following List (Large Screen devices)*/}


                
              </div> 
  );
        }
}

export default ProfilePageHeader