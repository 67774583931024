import React from 'react';
import Header from '../IssuePages/Header/Header';
import MobHeader from '../IssuePages/Header/Mob_Header';
import "./../../Styles/Awards/ContestPage.css";
import { get_missions, get_all_contests } from '../common/AppConstants';
import firebase from '../Login/firebaseConfig';
import axios from 'axios';
import { CircularProgress, Dialog } from '@material-ui/core';
import Contests from '../Home/Contests';
import FooterWallmag from '../Footer/Footer';
import Winners from '../MyDialogs/Winners';


var missions = []
var contests = []


class ContestPage extends React.Component {

    constructor(props){
        super(props)

        console.log(props)
        this.state = {
            past_winners_dialog : false,
            props : this.props
        }
    }

    componentDidMount(){

        window.scroll(0,0)
        
        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})

            this.getMissions()
            this.getContests()
   
    }


    // Show Past Winners
    showPastWinners = (e) => {

        e.preventDefault();
        
        this.setState({
            past_winners_dialog : true
        })

    }


       // Contests Data
    
       getContests = async () => {
        await axios.get(get_all_contests)
        .then(res => {
            contests = res.data
            this.forceUpdate()
        })
        .catch(error => {
        })
    }


    // close Dialog

    closeDiaogs = () => {

        this.setState({
            past_winners_dialog : false
        })
    }

        // Getting All Missions

        getMissions = async () => {
            await axios.get(get_missions)
            .then(res=>{
               missions = res.data
               this.forceUpdate()
            })
            .catch(error=>{
            })
        }

    render(){
        console.log("Missions: ", missions)
        return(
            <div style={{background:'#000000', paddingBottom:'60px'}}>


                {
                    window.innerWidth < 768 ?
                    
                        <MobHeader
                                url={require('../../logo/contest-bg.png')}
                                heading='WIN PRIZES WHILE
                                IMPROVING YOUR PHOTOGRAPHY'
                                logo = 'true'
                                // sub_heading='Push the Boundaries of Visual Storytelling'
                        />

                        :
                        <Header
                                        url={require('../../logo/contest-bg.png')}
                                        heading='WIN PRIZES WHILE
                                        IMPROVING YOUR PHOTOGRAPHY'
                                        logo = 'true'
                                        // share = {false}
                                        // sub_heading='Push the Boundaries of Visual Storytelling'
                                />
            }


            

            {missions.items ?
             <>
               <div className='container'>

                {/* Awards */}
                <div className='row' style={{padding:'0px', margin:'0px'}}> 
                    <p style={{marginTop:'72px'}} className='awards-title'>
                        PHOTOGRAPHY AWARDS
                    </p>
                </div>
                <p className='awards-desc'>
                    National Level Recognition for Photography Talents
                </p>
                </div>
                <Contests
                    // subTitle = 'A national recognition for emerging photography talent'
                    data = {missions}
                    props = {this.state.props}
                />
            </>
            :
            <div className='row' style={{justifyContent:'center', margin:'24px 0px 0px 0px'}}>
                <CircularProgress color='secondary' />
            </div>
            }





                {contests.items ?
                <>
                         {/* Contests */}

                <div className='container'>
                    <div className='row' style={{padding:'0px', margin:'0px'}}> 
                        <p style={{marginTop:'144px'}} className='awards-title'>
                            PHOTOGRAPHY CONTESTS
                        </p>
                    </div>
                    <p className='awards-desc'>
                        Enter your submissions for free and win exciting prizes
                    </p>
                </div>
                <Contests
                    // subTitle = 'A national recognition for emerging photography talent'
                    data = {contests}
                    props = {this.state.props}

                />
                </>
                :
                    ""
                }

                {/* <div className='row' style={{margin:'86px 0px 0px 0px', padding:'0px', justifyContent:'center'}}>
                    <p className='all-coming' style={{background:'#d2573d', cursor:'pointer'}}>
                        SHOW ALL UPCOMING
                    </p>
                </div>

                <div className='row' style={{margin:'10px 0px 0px 0px', padding:'0px', justifyContent:'center'}}>
                    <p className='all-coming' onClick={(e) => this.showPastWinners(e)} style={{border:'1px solid white', cursor:'pointer'}}>
                        SHOW PAST WINNERS
                    </p>
                </div> */}

                <div style={{marginTop:'72px'}}>
                    <FooterWallmag />
                </div>
                

                {/* Dialog For Past Winners */}

        <Dialog
            fullWidth
            open={this.state.past_winners_dialog}
            close={this.closeDiaogs}>
                
            <Winners closeFunc = {this.closeDiaogs}/>
           
        </Dialog>


            </div>
        )
    }
}

export default ContestPage;