import React from 'react'
import Header from '../IssuePages/Header/Header'
import './../../Styles/Awards/HomeScreen.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterWallmag from '../Footer/Footer';
import MobHeader from '../IssuePages/Header/Mob_Header';
import axios from 'axios';
import { get_missions, get_all_contests } from '../common/AppConstants';
// import Iframe from 'react-iframe'
import firebase from '../Login/firebaseConfig'
// import ReactHtmlParser from 'react-html-parser'
// import Mission from '../CommComp/Missions/Missions'
import Contests from '../Home/Contests';
import CustButton from '../CommComp/Button/Button';


var missions = []
var contests = []

class Missions extends React.Component{
    constructor(props){
        super(props)
        console.log(props)
        if(props.match.path === '/missions'){
            this.state = {
                awardsBtn : true,
                contestBtn : false
            }
        }else{
            this.state = {
                awardsBtn : false,
                contestBtn : true
            }
        }
        

    }


    componentDidMount(){

        window.scroll(0,0)
        
        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})

            this.getMissions()
            this.getContests()
   
    }


    // Contests Data
    
    getContests = () => {
        axios.get(get_all_contests)
        .then(res => {
            contests = res.data
            console.log(contests)
            this.forceUpdate()

        })
        .catch(error => {

        })
    }

    // Getting All Missions

    getMissions = () => {
        axios.get(get_missions)
        .then(res=>{
            // //console.log(res);
           missions = res.data
           this.forceUpdate()
        })
        .catch(error=>{
           // //console.log(error);
        })
    }

    // pressed
    award_pressed = () => {
      
            this.setState({
                awardsBtn : true,
                contestBtn : false
            })

            window.history.pushState("", "", "/missions");
        }

    contest_pressed = () => {
        
            this.setState({
                awardsBtn : false,
                contestBtn : true
            })
            window.history.pushState("", "", "/contests");

    }
 
    // go to mission detail page
    goTodetailPage =(e, mission_data)=>{

        e.preventDefault()
        // localStorage.setItem('mission_detail',JSON.stringify(mission_data))
        
        this.props.history.push('missions/'+mission_data.slug)
    }

    render(){
        return(
            <div style={{background:'white'}}>
{
    window.innerWidth < 768 ?
    <>
          <MobHeader
                url={require('../../logo/awardHeader.jpg')}
                heading='Win While
                Improving your Photography talent'
                sub_heading='Push the Boundaries of Visual Storytelling'
        />
        


   {/* Body Part Starts from here */}
   <div className='container' style={{marginTop:'32px'}}>

  <div className='row' style={{margin:'20px'}}>
            <div style={{marginLeft:'auto', marginRight:'5px'}}>
            <CustButton text='🏆 AWARDS' pressed={this.award_pressed} btnPressed = {this.state.awardsBtn}/> 
            </div>

            <div style={{marginRight:'auto', marginLeft:'5px'}}>
                <CustButton text='📷 CONTESTS' pressed={this.contest_pressed} btnPressed = {this.state.contestBtn}/>
            </div>    


  
                   
                   
           </div>
         
           { this.state.awardsBtn ? 
            missions.items ?
             <>
            <div className="row justify-content-center">
            <p id="awards-text">Photography Awards</p>
          </div>  
            <Contests
                        subTitle = 'A national recognition for emerging photography talent'
                        data = {missions}
                        />
                        </>
                        :
                        ''
                        :
            contests.items ? 
            <>
              <div className="row justify-content-center">
              <p id="awards-text">Photography Contests</p>
            </div>  
            <Contests
                        subTitle = 'Free Entry Weekly & Monthly Contests'
                        data = {contests}
                        /> 
                </>
                    :

                    <></>
        }       

<div style={{ marginTop: "80px" }}>
                    <img src={require("../../logo/chat.svg")} alt="chat_logo" />
                    <p className="about_" style={{ marginTop: "20px" }}>
                      {" "}
                      Question or problem?
                    </p>
                    <p className="about__">
                      {" "}
                      Contact our team from<b> <a href='https://tawk.to/chat/5dcbea87d96992700fc73ae3/default'
                            target='_blank' rel="noopener noreferrer">
                                                here</a></b>
                    </p>
                    <p className="about__"> and we’ll reply within 24 hours.</p>
                  </div>



                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                     <div className="vl"></div>
                </div>

                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                        <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
                </div>
                




                </div>
            {/* Body part ends here */}


        <div style={{marginTop:'160px'}}>
            <FooterWallmag />
        </div>          

    </>
        
    

                                :
// for large scrrens (greater than 767)

            <>
                <Header
                        url={require('../../logo/awardHeader.jpg')}
                        heading='Win While
                        Improving your Photography talent'
                        sub_heading='Push the Boundaries of Visual Storytelling'
                />


    {/* Body Part Starts from here */}
        <div className='container'>

           
        <div className='row' style={{margin:'40px'}}>
            <div style={{marginLeft:'auto', marginRight:'15px'}}>
            <CustButton text='🏆 AWARDS' pressed={this.award_pressed} btnPressed = {this.state.awardsBtn}/> 
            </div>

            <div style={{marginRight:'auto', marginLeft:'15px'}}>
                <CustButton text='📷 CONTESTS' pressed={this.contest_pressed} btnPressed = {this.state.contestBtn}/>
            </div>    
                    
                   
                   
           </div>
        
           <div className="row justify-content-center">
              <p id="awards-text">Photography {this.state.awardsBtn ? 'Awards' : 'Contests'}</p>
            </div>


        { this.state.awardsBtn ? 
            missions.items ?
             
            <Contests
                        subTitle = 'A national recognition for emerging photography talent'
                        data = {missions}
                        />
                        :
                        ''
                        :
            contests.items ? 
            
            <Contests
                        subTitle = 'Free Entry Weekly & Monthly Contests'
                        data = {contests}
                        /> 

                    :

                    <></>
        }     


<div style={{ marginTop: "140px" }}>
                        <img
                          src={require("../../logo/chat.svg")}
                          alt="chat_logo"
                        />
                        <p className="about__" style={{ marginTop: "20px" }}>
                          {" "}
                          Question or problem?
                        </p>

                        <p className="about__">
                          {" "}
                          Contact our team from<b> <a href='https://tawk.to/chat/5dcbea87d96992700fc73ae3/default'
                            target='_blank' rel="noopener noreferrer">
                                                here</a></b>
                        </p>
                        <p className="about__">
                          {" "}
                          and we’ll reply within 24 hours.
                        </p>
                      </div>

                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                     <div className="vl"></div>
                </div>

                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                        <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
                </div>
                




                </div>
            {/* Body part ends here */}


                <div  style={{marginTop:'160px'}}>
                    <FooterWallmag />
                </div>
                    

            </>
                
          }


            </div>
        )
    }
}

export default Missions
