import React from 'react'
import Header from '../Header/Header';
import MobHeader from '../Header/Mob_Header'
import FooterWallmag from '../Footer/Footer';
// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-117934873-2');
// ReactGA.pageview(window.location.pathname + window.location.search);

function About_Us(props){
    
    return(
        <>
        {window.scrollTo(0,0)}

            <div className='mob_top_about'>
              <MobHeader url=''  heading='About Us' sub_heading='We are a global community of photographers and storytellers'/>
            </div>
            
            <div className='web_top_about'>
            <Header url=''   heading='About Us' sub_heading='We are a global community of photographers and storytellers'/>
            </div>

       

            <div className='container'>
{/* vision */}
            <div className='container vision_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>Vision</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                    Create economic opportunity for photographers and storytellers through technology and experience.
                    We want to bring stories from around the world every corner to every pocket using internet 
                </p>

{/* mission */}

<div className='container mission_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>Mission</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                    The mission of WallMag is simple: Empowerment of photographers and artists by
                    bringing their work to a huge audience through technology to do more
                </p>

            


            {/* WallMag Story */}



            <div className='container story_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>WallMag Story</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                WallMag began in a small living room in 2017 and was officially
launched in May 5, 2018</p>
<p id='v_content'>
The CEO Ajeet Kumar Meena
have diverse background in Technology, Design and Business.
He started the company for the love of photography and storytelling. 
</p>
<p id='v_content'>
Currently WallMag is recognised by Rajasthan Government Startup
program as gold QRate holder. Our app have won various design awards. 
We are a diverse team of experience from different corner of the country
                </p>

            </div>


{/* Footer Div */}
        <div id='footer_content'>
            <FooterWallmag />
        </div>


      
        </>
    )
}

export default About_Us