import {CATEGORY_DATA} from './../types';



const initialState = {
    category_data: {},
    category_data_load :false,
}
export default function CatrgoryPostReducer(state = initialState, action) {


    var key = action.cat_ID
    var token= 'token' ;
    var obj = {...state};
    
    

    if(state[key]){

        switch(action.type){
   
            case CATEGORY_DATA :

                obj[key] = state[key].concat(action.data.items)
                obj[key][token] = action.data.nextPageToken
               
                return obj
            
                default : return state
         }    

    } else{

        switch(action.type){
   
            case CATEGORY_DATA : 
            obj[key] = action.data.items ;
            obj[key][token] = action.data.nextPageToken
                return obj
            
                default : return state
         }    
    }

 
}