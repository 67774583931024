import firebase from "firebase/app";
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import axios from 'axios'
export const config = {
    apiKey: "AIzaSyCK5B3fKFh3ODgvgygF6PgqaJpMOwa-aE4",
    authDomain: "wallmag-2.firebaseapp.com",
    databaseURL: "https://wallmag-2.firebaseio.com",
    projectId: "wallmag-2",
    storageBucket: "wallmag-2.appspot.com",
    messagingSenderId: "34055314939"
};
firebase.initializeApp(config);
let newToken;
const storage = firebase.storage()
// if(localStorage.user){
//     axios.interceptors.request.use(config => {
//         const token = 'Bearer '+localStorage.user
//         config.headers.Authorization = token;
//             return config;
//         }, error => {
//             // handle the error
//             return Promise.reject(error);
//         });
// }
// if(firebase.auth().user){
//     firebase.auth().onAuthStateChanged( function(user){
//         if(user){
//             localStorage.setItem(
//                 'user' , user.ra
//             )
//             axios.interceptors.request.use(config => {
//             const token = 'Bearer '+user.ra
//             config.headers.Authorization = token;
//                 return config;
//             }, error => {
//                 // handle the error
//                 return Promise.reject(error);
//             });
//       }else{
//         }
//     })
// }else{
// }
axios.interceptors.request.use(config => {
    if (firebase.auth().currentUser !== null) {
        firebase_func();
    }
    if (JSON.parse(localStorage.getItem('current_user'))) {
        const token = 'Bearer ' + localStorage.user;
        config.headers.Authorization = token;
    } else {
        const token = 'Bearer ' + new Date().getTime() + '_GpyTJy7zgV5qaGaBH1VF'
        config.headers.Authorization = token;
    }
    return config;
}, error => {
    // handle the error
    return Promise.reject(error);
});
const intercept = axios.interceptors.response.use(
    response => response,
    error => {
        // Reject promise if usual error
        console.log(error);
        // if (error.response!== null && error.response.status!=='undefined' && error.respose.status !== 401) {
        //     return Promise.reject(error);
        // }
        /* 
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        const originalRequest = error.config;
        const status = error.response ? error.response.status : null
        if (status === 401) {
            if (firebase.auth.currentUser === null) {
                return Promise.reject(error);
            }
            return firebase.auth().currentUser.getIdToken().then(res => {
                localStorage.setItem('user', res);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res;
                // 3) return originalRequest object with Axios.
                return axios(originalRequest);
            })
        }
        // return Error object with Promise
        return Promise.reject(error);
        // return axios.post('/api/refresh_token', {
        //     'refresh_token': this._getToken('refresh_token')
        // }).then(response => {
        //     saveToken();
        //     error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
        //     return axios(error.response.config);
        // }).catch(error => {
        //     destroyToken();
        //     this.router.push('/login');
        //     return Promise.reject(error);
        // }).finally(createAxiosResponseInterceptor);
    }
);
async function firebase_func() {
    try {
        newToken = await firebase.auth().currentUser.getIdToken();
    }
    catch (error) {
        console.error('oops, something went wrong!', error);
    }
    console.log("this console should be executed after console.log(dataUrl)")
}
export {
    storage, firebase as default
}