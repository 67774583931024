import React from 'react'
import "../../Styles/Advertise.css"

function Advertise() {
    return (
        <div id="advertise">
        <div class="advertise">
            <div class="advertise-404">
                <h1>Coming Up!</h1>
                <h2>Page under construction</h2>
            </div>

        </div>
                    <div className="home-btn"><a href="/">Homepage</a></div>
        </div>
    )
}

export default Advertise
