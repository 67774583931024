import React from 'react'
import Header from '../Header/Header'
import axios from 'axios'
import { get_faq_topics } from '../common/AppConstants'
import { Card, Button, CircularProgress } from '@material-ui/core'
import '../../Styles/Faq.css'
import MobHeader from '../Header/Mob_Header'
import FooterWallmag from '../Footer/Footer'

var sections = []

class Faq extends React.Component{

    
    componentDidMount(){
        
        window.scrollTo(0,0)

        this.getAllSections()

    }


    // Redirect to Section Page
    goToSectionPage =(e, value)=>{

        localStorage.setItem(
            'topic_id' , value.id
        )
        localStorage.setItem(
            'topic_desc',  value.description
        )
        localStorage.setItem(
            'topic_title' , value.title
        )

        this.props.history.push('/faq/'+value.slug)
    }

    // get All Sections
    getAllSections = () =>{

        axios.get(get_faq_topics)
             .then(response=>{
                 //console.log(response);
                sections = response.data
                this.forceUpdate()
             })
             .catch(error=>{
                
             })
    }

    render(){
        return(
            <div style={{background:'#ffffff'}}>
                 <div className='mob_top_get_featured'>
                 <MobHeader 
                    url=''  
                    clicked='yes' 
                    heading='In Search of True Photography' 
                    sub_heading="Most frequently asked questions about the WallMag platform"/>
            </div>

                        
            {/* Header for desktops  */}
            <div className='web_top_get_featured'>
                <Header heading='FAQ & Help'
                        sub_heading='Most frequently asked questions about the WallMag platform'
                        url=''
                        />
            </div>            

                <div className='container' style={{marginTop:'60px'}}>
                    <div className='row'>
                        {
                            sections.items ?
                            sections.items.map((value, index) =>{
                                return(
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3 mb-3'>
                                        <Card >
                                           <div style={{margin:'5px'}}>
                                            <p className='section_name'>
                                                {value.title}
                                            </p>

                                            <p className='section_desc'>
                                                {value.description}
                                            </p>

                                        <div className='row' 
                                             style={{justifyContent:'start', marginLeft:'20px', marginBottom:'20px'}}>

                                            <Button 
                                                onClick={e=> this.goToSectionPage(e, value)}
                                                style={{background:'#000000', color : '#ffffff',outline:'none'}} 
                                                variant='contained'>
                                                
                                                Read More
                                            </Button>
                                        </div>    
                                        </div>
                                        </Card>
                                    </div>    
                                )
                            })
                            :
                            <></>
                        }
                    </div>                    
                </div>        

                        {
                            sections.items ?
                            <div  >
                            <FooterWallmag />
                            </div>
                            :
                            <CircularProgress />
                        }
                <div>
                    
                </div>
            </div>
        )
    }
}

export default Faq