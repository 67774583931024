import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavLink from 'react-router-dom/NavLink'
import '../../Styles/Home_Header.css'
import '../../../node_modules/axios-progress-bar/dist/nprogress.css'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import firebase from "../Login/firebaseConfig";


class  Home_Header extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            theposition: 0,
            anchor : null,
            // userName : this.props.user,
            // heading : this.props.heading ? this.props.heading : '',
            // join : this.props.join ? this.props.join : false,
        }
        console.log(props)
        
    }
    

    saveLoc=()=>{
      localStorage.setItem('loc',window.location.href)
    }

    componentDidMount() {
      // //console.log(this.state.userName);
      if(this.props.url===undefined){
        this.setState({
          url : ''
    })
      }else{
        this.setState({
            url : this.props.url
      })
    }
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }

      
      // Menu
      handle_menu =(event)=>{
        this.setState({
          anchor : event.currentTarget
        })
      }

      // Menu Close
      handleClose=(e)=>{

        e.preventDefault()

        this.setState({
          anchor : null
        })
      }

      // logout
      logOut = (e) =>{
        e.preventDefault()

        firebase.auth().signOut()
        localStorage.clear()
        this.setState({
          anchor : null
        })
        
      }


// Listening Scroll Position

      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
        this.setState({
          theposition: scrolled,
        })
      }


    render(){
    


  return (
    <div className='head_div'
           style ={this.state.url==='' ? 
            {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                                     transparent),linear-gradient( to bottom , rgba(0,0,0,1), transparent)
                                     , url(${require('../../logo/home_top.jpg')})`,
                                        paddingBottom:'200px' , width:'100%' , backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat' }
  
            :
            {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                                     transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                                     , url(${this.state.url})`,
                                        height:'360px' , width:'100%', backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat'  ,
    }} 
    
    >
      
      {/* <div>
      <img src={`${this.state.url}`} height='360px'  width='100%' style={{objectFit:'cover'}}/> */}

    
      <CssBaseline />
    
     

        {this.state.theposition <0.06400
                 ?
         //  if clause
            <AppBar  style={{ background: 'transparent', boxShadow: 'none'}}>
             <Toolbar>
               <NavLink to='/'>
                <img id='wall_logo' src={require('../../logo/wallmag_white.png')}  />
               </NavLink> 
                  <div className='container-fluid' style={{alignSelf:'baseline'}}>
                    <div className='row justify-content-end'>
                      <div className='row mt-3'>

                     
                     {/*---------------User------------- */}

                       
                     {
                              localStorage.current_user ? 
                              <>
                              <img  
                              alt='icon'
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                  }}
                                  style ={{borderRadius:'80px' , cursor:'pointer', objectFit:'cover',border:'1px solid #ffffff50'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  />


                                  <Menu
                                    style={{marginTop:'40px'}}
                                    id="simple-menu"
                                    anchorEl={this.state.anchor}
                                    keepMounted
                                    open={Boolean(this.state.anchor)}
                                    onClose={e=>this.handleClose(e)}
                                    >


                                  
                                    <NavLink to={`/${JSON.parse(localStorage.getItem('current_user')).slug}`}  style={{textDecoration:'none'}}>
                                    <MenuItem>
                                     My Profile
                                     </MenuItem>
                                    </NavLink>
                                

                                    <NavLink to={'/edit-profile'}  style={{textDecoration:'none'}}> 
                                    <MenuItem>
                                        Edit Profile
                                    </MenuItem>
                                    </NavLink>
                                
                                  <hr></hr>
                                  <MenuItem onClick={e=>this.logOut(e)}>Logout</MenuItem>
                                  </Menu>
                                  </>
                              :
                              <NavLink style={{ textDecoration: 'none' }}  onClick={this.saveLoc}  to='/login' >
                                  <p color='default' className='links'> Login </p>
                             </NavLink>
                         }

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  
                      
                        </div>
                    </div>
                    </div>
               </Toolbar>
             </AppBar>
         
                :

          // else clause
             <AppBar  style={{background:'black', borderBottom:'1px solid #ffffff36', zIndex:'1'}} >
             
                <Toolbar>
                <NavLink to='/'>
                 <img alt='wall_logo' id='wall_logo2' src={require('../../logo/wallmag_white.png')}  />
                </NavLink>
                 <div className='container-fluid '  style={{alignSelf:'baseline'}}>
                    <div className='row justify-content-end '>
                    <div className='row mt-3'>
                       
                       {/*---------------User------------- */}

                        
                        {
                               localStorage.current_user ? 
                              <>
                              
                              <img  
                              alt='icon'
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                  }}
                                  style ={{borderRadius:'80px' , cursor:'pointer', objectFit:'cover',border:'1px solid #ffffff50'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  />

                                  <Menu
                                    style={{marginTop:'40px'}}
                                    id="simple-menu"
                                    anchorEl={this.state.anchor}
                                    keepMounted
                                    open={Boolean(this.state.anchor)}
                                    onClose={e=>this.handleClose(e)}
                                >
                                  
                                  
                                  
                                    <NavLink to={`/${JSON.parse(localStorage.getItem('current_user')).slug}`}  style={{textDecoration:'none'}}>
                                    <MenuItem>
                                     My Profile
                                     </MenuItem>
                                    </NavLink>
                                  
                                    <NavLink to={'/edit-profile'}  style={{textDecoration:'none'}}> 
                                    <MenuItem>
                                       Edit Profile
                                    </MenuItem>
                                    </NavLink>
        
        
                                  <hr></hr>
                                  <MenuItem onClick={e=>this.logOut(e)}>Logout</MenuItem>
                                  </Menu>
                                  </>
                              :
                              <NavLink style={{ textDecoration: 'none'}}  onClick={this.saveLoc}  to='/login' >
                                 <p style={{color:'#fff'}} color='default' className='links2' > Login </p>
                              </NavLink>
                         }

                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                        </div>
                        
                    </div>
                    </div>

               </Toolbar>
        </AppBar>
          }
      <Toolbar />

      <div className='container desc_div' > 

{/* Heading of The Header  */}
 <div className='row ' style={{marginTop:'120px'}}>   
  <div className='col-md-12 col-lg-12 col-xl-12'>
      <div className='row justify-content-center '>
          <img alt='WallMag_logo' src={require('../../logo/wallmag_white.png')} style={{height:'80px', width:'120px'}} />
      </div>
  </div> 

  <div className='col-md-12 col-lg-12 col-xl-12 mt-3'>
      <div className='row justify-content-center '>
          <p id='wallmag'> WallMag </p>
      </div>
  </div> 
  

  <div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'-10px'}}>
      <div className='row justify-content-center '>
          <pre><p id='home_desc2'>Empowering Photography</p></pre>

      </div>
  </div> 

  {
              localStorage.current_user ? 
              

    <div className='col-md-12 col-lg-12 col-xl-12'>
      {/* <div className='row justify-content-center '>
      <NavLink to='/upload'>
        <button className='button1' style={{outline:'none'}}>
                  Sell Images
                </button> 
      </NavLink>
      </div> */}
  </div>


              :

              
    <div className='col-md-12 col-lg-12 col-xl-12 mt-4'>
    <div className='row justify-content-center '>
      <NavLink to='/login' onClick={this.saveLoc} >
        <button className='button1' style={{outline:'none'}}>
                  Get Started
                </button> 
      </NavLink>        
    </div>
</div>

  }
  
  </div>
</div> 
              </div> 
  );
        }
}

export default Home_Header