import React from 'react'
import './Button.css'

const CustButton = props => {
    console.log(props)
    return(
        
            props.btnPressed === true ? 

            <div className="container cust-btn-pressed" onClick={props.pressed}>
             <div className='row' style={{position:'relative' , margin : 'auto'}}>
               
                <p className='cust-btn-textP'>
                    {props.text}
                </p>
            </div>
           
        </div>

            :


        
        <div className="container cust-btn"  onClick={props.pressed}>
            <div className='row' style={{position:'relative', margin : 'auto'}}>
               
                <p className='cust-btn-text'>
                    {props.text}
                </p>
            </div>
           
        </div>
    )
};

export default React.memo(CustButton);