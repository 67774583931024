import React from 'react'
import Header from '../Header/Header';
import { Button } from '@material-ui/core';
import MobHeader from '../Header/Mob_Header'
import FooterWallmag from '../Footer/Footer';
// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-117934873-2');
// ReactGA.pageview(window.location.pathname + window.location.search);

function Get_Featured(props){
    
    return(
        <>
        {window.scrollTo(0,0)}
        <div className='mob_top_get_featured'>
              <MobHeader url=''  clicked='yes' heading='Featuring Emerging Photographers' sub_heading='We empower photographers like you to do more '/>
            </div>
            
            <div className='web_top_get_featured'>
            <Header url='../../logo/topimg.jpg'   clicked='yes' heading='Featuring Emerging Photographers' sub_heading='We empower photographers like you to do more '/>
            </div>
        

            <div className='container'>
                <div className='row justify-content-center'>

                    <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1'></div>
                
                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
{/* WallMag */}
            <div className='container vision_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>WallMag</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                The world runs on creativity, innovation and collaboration. This ideology is the driving force behind WallMag, an online publication featuring emerging talent from all over the globe. Delve into a collection of deliciously inspiring creative pieces which have been handpicked to shine a much deserved spotlight on brilliant individuals. Connecting people from all ages and backgrounds through their passion for all things photography and storytelling, WallMag aims to build a united community and spark much needed conversations. We understand the photography industry often goes undervalued which is why we have provided a platform for your talent to be recognised by the entire world.
                </p>

{/* Why */}

<div className='container mission_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>Why</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                WallMag is a magazine which allows you to send in a submission every 2 
months in a year, for your chance to have your work featured in a one-
page spread. Sound like an exciting opportunity that you want to sink 
your teeth into? Having your work featured in this creative platform could 
be that one difference that makes your portfolio stand out against the 
next creative. Don’t be shy! Absolutely free to submit, this is an exciting 
way to get your name out there.
                </p>

            


            {/* Who */}



            <div className='container story_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>Who</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                Consider yourself an up and coming creative? We are looking for emerging creatives for our publication.
                </p>




  {/* What */}



  <div className='container story_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>What</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                We are looking for creative pieces that best represent your style. 
Please note: Only one submission per applicant.
                </p>




                  {/* How */}



            <div className='container story_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>How</p>
              </div>
            </div>
            
            <hr className='hr_vision'/>

                <p id='v_content'>
                1. Save your creative piece in JPEG, PNG, or PDF file formats with low resolution image.

      </p>
      <p id='v_content'>
2. Fill the Google Form  <a href='https://forms.gle/wMSzMZrvc6LBVpws5'  rel="noopener noreferrer"   target="_blank"><b>here</b></a> .

Once you have been successfully selected as a contributor, you will receive further instructions on how to submit your final design.
          
</p>
</div>
<div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1'></div>


     <div className='mt-4'>
       <a style={{textDecoration:'none'}}  rel="noopener noreferrer"  href='https://forms.gle/wMSzMZrvc6LBVpws5' target="_blank">
        <Button variant='contained' color='default'>Get Featured</Button>
      </a>
     </div>

</div>

     
            </div>


{/* Footer Div */}
        <div id='footer_content'>
            <FooterWallmag />
        </div>


      
        </>
    )
}

export default Get_Featured