import { createMuiTheme, TextField } from "@material-ui/core";
import React from "react";
import "./SendOtp.css";
import Axios from "axios";
import { send_otp_to_user, verify_otp } from "../common/AppConstants";
import cogoToast from "cogo-toast";
import Otp from "./otp";
import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

var otp = false;
var count = 60;
var fourDigitOtp = 0;

const theme = createMuiTheme({
  typography: {
    // Use the system font.
    fontFamily:
      // '-apple-system,system-ui,BlinkMacSystemFont,'
      // +
      "Montserrat",
  },
});

var pattern = new RegExp(/^[0-9\b]+$/);
class MobileVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: JSON.parse(localStorage.getItem("current_user")).contactNo
        ? JSON.parse(localStorage.getItem("current_user")).contactNo
        : "",
      otp: "",
      resend: false,
      close: props.closeDialog,
    };
  }

  componentDidMount = () => {
    // custom rule
    ValidatorForm.addValidationRule("isMinLength", (value) => {
      if (value.length >= 2) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule("isMaxLength", (value) => {
      if (value.length === 10) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule("isPhoneNumber", (value) => {
      if (pattern.test(value)) {
        return true;
      }
      return false;
    });
  };

  componentWillUnmount() {
    otp = false;
  }

  // On value change
  change = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      open: false,
    });
  };

  sendOTP = () => {
    if (this.state.mobile.length < 7 || this.state.mobile.length > 15) {
      alert("Please add a valid mobile number");
    } else {
      otp = true;
      this.setState({ resend: false });
       this.sendOtpToUser()
      this.forceUpdate();
    }
  };

  resendOtp = () => {
    otp = false;
    setTimeout(() => {
      this.sendOTP();
    }, 0);
    this.forceUpdate();
  };

  // Send Otp To User
  sendOtpToUser = () => {
    fourDigitOtp = Math.floor(1000 + Math.random() * 9000);
    Axios.put(
      send_otp_to_user +
        `?countryCode=+91&mobileNumber=${this.state.mobile}&otp=${fourDigitOtp}`
    ).then((res) => {
      console.log(res.data);
    });
  };

  // Verify OTP
  verifyOtp = () => {
      //console.log("this.state.otp", this.state.otp)
      //console.log("fourDigitOtp", fourDigitOtp)
    if (parseInt(this.state.otp) === fourDigitOtp) {
      Axios.put(
        verify_otp + `?isVerified=true&mobileNumber=${this.state.mobile}`
      )
        .then((res) => {
          cogoToast.success("Phone No. Verified!");

          // User Updating
          localStorage.setItem("current_user", JSON.stringify(res.data));
          localStorage.setItem("user", this.state.user.ra);

          localStorage.setItem("user_ver", this.state.user.emailVerified);
          localStorage.setItem("name", res.data.displayName);
          localStorage.setItem("id", res.data.id);
          localStorage.setItem("image", res.data.photoUrl);

          // Closing the Dialog
          this.state.close();
        })
        .catch((error) => {});
    } else {
      cogoToast.warn("failed");
    }
  };

  editNumber = () => {
    otp = false;
    this.forceUpdate();
  };

  render() {
    return (
      <>
        <div className="row otp-parent">
          <p className="v-m-n">
            {otp === true ? "Verify OTP" : "Verify Mobile Number"}
          </p>

          <img
            alt="cancel"
            src={require("../../logo/cancel.svg")}
            className="cross-icon"
            onClick={this.state.close}
          />
        </div>
        <p className="V-m-n-d">
          Verify your mobile number so you can get updates on this contest. Such
          as your selection announcement.
        </p>
        <br></br>
        {otp === false ? (
          <div className="row mob-no">
            <div className="row cc-flag">
              <img
                className="flag"
                src={require("../../logo/indian-flag.svg")}
                alt="flag"
              />
              <p className="cc">+91</p>
            </div>

            <div className="mb-text">
              <ThemeProvider theme={theme}>
                <ValidatorForm
                  onChange={(e) => this.change(e)}
                  autoComplete="off"
                >
                  <TextValidator
                    classname="MuiInputBase-root"
                    id="mobile"
                    value={this.state.mobile}
                    //   onChange={e => this.change(e)}
                    type="number"
                    variant="outlined"
                    validators={["required", "isPhoneNumber", "isMaxLength"]}
                    errorMessages={[
                      "This field is required",
                      "Please enter only numbers!",
                      "Please enter a valid phone number!",
                    ]}
                  />
                </ValidatorForm>
                {/* <TextField 
                                    classname='MuiInputBase-root' 
                                    id="mobile" 
                                    value={this.state.mobile}
                                    onChange={e => this.change(e)}
                                    type='number'
                                    onKeyPress="return event.keyCode === 8 ||
event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                    variant="outlined" /> */}
              </ThemeProvider>
            </div>
          </div>
        ) : (
          <div className="row otp-no">
            <div className="mb-text">
              <ThemeProvider theme={theme}>
                <TextField
                  classname="MuiInputBase-root"
                  id="otp"
                  type="password"
                  value={this.state.otp}
                  onChange={(e) => this.change(e)}
                  placeholder="****"
                  variant="outlined"
                />
              </ThemeProvider>
            </div>
          </div>
        )}

        <p className="digit-OTP">
          {otp === true ? (
            <div className="row">
              Add the 4 digit OTP sent to your phone +91{this.state.mobile}
              <p onClick={this.editNumber} className="edit-mob">
                (edit)
              </p>
            </div>
          ) : (
            "4 digit OTP will be sent to You"
          )}
        </p>

        <div className="row send-otp-parent">
          {otp === true ? (
            this.state.resend === false ? (
              <p className="resend">
                <Otp sendOtp={this.resendOtp} resend={false} />
              </p>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {otp === true ? (
            <div onClick={() => this.verifyOtp()} className="row send-OTP-btn">
              <p className="send-otp"> Verify OTP</p>
            </div>
          ) : (
            <div onClick={() => this.sendOTP()} className="row send-OTP-btn">
              <p className="send-otp"> Send OTP</p>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default MobileVerification;
