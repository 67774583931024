import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavLink from "react-router-dom/NavLink";
import "../../../Styles/issues.css";
import "../../../../node_modules/axios-progress-bar/dist/nprogress.css";
import firebase from "../../Login/firebaseConfig";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Dialog } from "@material-ui/core";
import Invite from "../../MyDialogs/Invite";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theposition: 0,
      heading: this.props.heading,
      sub_heading: this.props.sub_heading,
      clicked: this.props.clicked,
      url: this.props.url,
      userName: this.props.user,
      anchor: null,
      login : this.props.login,
      logo : this.props.logo,
      share : this.props.share ? this.props.share : false,
      invite_friends_dialog : false ,
      open : this.props.openDialog
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      heading: this.props.heading,
      sub_heading: this.props.sub_heading,
      url: this.props.url,
      logo : this.props.logo,
      share : this.props.share ? this.props.share : false,
    });
    this.forceUpdate()
  }

  componentDidMount() {
    console.log(this.state.userName);
    if (this.props.url === undefined) {
      this.setState({
        url: ""
      });
    } else {
      this.setState({
        url: this.props.url
      });
    }
    window.addEventListener("scroll", this.listenToScroll);
  }

  clickOnInvite = () => {
    this.setState({
      invite_friends_dialog : true
    })
  }

  saveLoc=()=>{
    localStorage.setItem('loc',window.location.href)
    window.scrollTo(0,0)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  // close dialog
  closeDialog = () => {
    this.setState({

      invite_friends_dialog : false
    });
  };

  // Menu
  handle_menu = event => {
    this.setState({
      anchor: event.currentTarget
    });
  };

  // Menu Close
  handleClose = e => {
    e.preventDefault();

    this.setState({
      anchor: null
    });
  };

  // logout
  logOut = e => {
    e.preventDefault();

    firebase.auth().signOut();
    localStorage.clear();

    setTimeout(this.goToLogin, 100);

    this.setState({
      anchor: null
    });
  };

  goToLogin = () => {
    window.location.assign("/");
  };

  // Listening Scroll Position

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    this.setState({
      theposition: scrolled
    });
  };

  render() {
    return (
      <div
        className="head_div"
        style={
          this.state.url === ""
            ? {
                backgroundImage: `linear-gradient( to top , rgba(0,0,0,.6),
                                     transparent),linear-gradient( to bottom , rgba(0,0,0,.6), transparent)
                                     , url(${require("../../../logo/first_issue.jpg")})`,
                // height: `${this.state.share === true ? '800px' : '560px'}`,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                paddingBottom:'60px'
              }
            : {
                backgroundImage: `linear-gradient( rgba(0,0,0,.6), rgba(0,0,0,.6))
                                     , url(${this.state.url})`,
                // height: `${this.state.share === true ? '800px' : '560px'}`,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                paddingBottom:'60px',
              }
        }
      >
        {/* <div>
      <img src={`${this.state.url}`} height='360px'  width='100%' style={{objectFit:'cover'}}/> */}

        <CssBaseline />

        {this.state.login === 'false' ? '' : this.state.theposition < 0.000001 ? (
          //  if clause
          <AppBar style={{ background: "transparent", boxShadow: "none" }}>
            <Toolbar>
              <NavLink to="/">
                <img
                  id="wall_logo"
                  alt='Logo'
                  src={require("../../../logo/wallmag_white.png")}
                />
              </NavLink>
              <div
                className="container-fluid"
                style={{ alignSelf: "baseline" }}
              >
                <div className="row justify-content-end">
                  <div className="row mt-3">
                    {localStorage.user ? (
                      <>
                        <img
                          height="30px"
                          width="30px"
                          src={
                            JSON.parse(localStorage.getItem('current_user'))? 
                              JSON.parse(localStorage.getItem('current_user')).photoUrl
                            :
                            ''
                          }
                          alt='Logo'
                          style={{
                            borderRadius: "600px",
                            cursor: "pointer",
                            objectFit: "cover",border:'1px solid #ffffff50'
                          }}
                          onClick={e => this.handle_menu(e)}
                        />

                        <Menu
                          style={{ marginTop: "40px" }}
                          id="simple-menu"
                          anchorEl={this.state.anchor}
                          keepMounted
                          open={Boolean(this.state.anchor)}
                          onClose={e => this.handleClose(e)}
                        >
                          <NavLink
                            to={
                              "/" +
                              JSON.parse(localStorage.getItem("current_user"))
                                .slug
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>My Profile</MenuItem>
                          </NavLink>

                          <NavLink
                            to={"/edit-profile"}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>Edit Profile</MenuItem>
                          </NavLink>

                          <hr></hr>
                          <MenuItem onClick={e => this.logOut(e)}>
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <NavLink style={{ textDecoration: "none" }}   onClick={this.saveLoc}  to="/login">
                        <p color="default" className="links">
                          {" "}
                          Login{" "}
                        </p>
                      </NavLink>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          // else clause
          <AppBar style={{background:'black', borderBottom:'1px solid #ffffff36'}} color="#000">
            <Toolbar>
              <NavLink to="/">
                <img
                  id="wall_logo2"
                  alt='Logo'
                  src={require("../../../logo/wallmag_white.png")}
                />
              </NavLink>
              <div
                className="container-fluid "
                style={{ alignSelf: "baseline" }}
              >
                <div className="row justify-content-end ">
                  <div className="row mt-3">
                    {localStorage.user ? (
                      <>
                        <img
                          height="30px"
                          width="30px"
                          src={
                            JSON.parse(localStorage.getItem('current_user'))? 
                              JSON.parse(localStorage.getItem('current_user')).photoUrl
                            :
                            ''
                          }
                            
                          alt='Logo'
                          style={{
                            borderRadius: "600px",
                            cursor: "pointer",
                            objectFit: "cover",border:'1px solid #ffffff50'
                          }}
                          onClick={e => this.handle_menu(e)}
                        />

                        <Menu
                          style={{ marginTop: "40px" }}
                          id="simple-menu"
                          anchorEl={this.state.anchor}
                          keepMounted
                          open={Boolean(this.state.anchor)}
                          onClose={e => this.handleClose(e)}
                        >
                          <NavLink
                            to={
                              "/" +
                              JSON.parse(localStorage.getItem("current_user"))
                                .slug
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>My Profile</MenuItem>
                          </NavLink>

                          <NavLink
                            to={"/edit-profile"}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>Edit Profile</MenuItem>
                          </NavLink>

                          <hr></hr>
                          <MenuItem onClick={e => this.logOut(e)}>
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <NavLink style={{ textDecoration: "none" }}   onClick={this.saveLoc}  to="/login">
                        <p color="default" className="links2" style={{color:'#fff'}}>
                          {" "}
                          Login{" "}
                        </p>
                      </NavLink>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        )}
        <Toolbar />

        <div className="container desc_div">
          {/* Heading of The Header  */}

          {
            this.state.logo === 'true' ? <div style={{height:'200px'}}></div> :
          
          <div className="row " style={{ marginTop: "120px" }}>
            <div className="col-md-12 col-lg-12 col-xl-12">
              <img
                className='main-logo'
                src={this.state.logo ? this.state.logo  : require("../../../logo/wallgold.png")}
                alt='Logo'
                style={{ height: "200px", width: "260px" }}
              />
            </div>
          </div>
  }
          {/* Heading of The Header  */}
          <div className="row ">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="row justify-content-center ">
                <p id="issue1_desc"> {this.state.heading} </p>
              </div>
            </div>

            {/* Sub_Heading of The Header */}
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="row justify-content-center ">
                <p id="desc2"> {this.state.sub_heading} </p>
              </div>
            </div>

            {
              this.state.share === false ? '' : 

            <div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'56px'}}>
              <div className="row justify-content-center ">
                  <div className='invite-friends' onClick={() => this.state.open()}>
                      <p className='invite-frnds-text'>
                          Invite Friends  
                      </p>
                      <img className='invite-frnds-logo' src={require('../../../logo/share.svg')} />
                  </div>  
              </div>
            </div>
            }
         
         </div>
        </div>


        {
  window.innerWidth < 768 ?

        <Dialog
          open={this.state.invite_friends_dialog}
          onClose = {() => this.closeDialogInvite()}
          fullScreen
          disableBackdropClick
        >
            <Invite  title={this.state.heading}  closeFunc = {this.closeDialog}/>
        </Dialog>
  :
        <Dialog
          open={this.state.invite_friends_dialog}
          onClose = {() => this.closeDialogInvite()}
          fullWidth
          disableBackdropClick
        >
            <Invite title={this.state.heading}  closeFunc = {this.closeDialog}/>
        </Dialog> 
  }

      </div>
    );
  }
}

export default Header;
