import React from 'react';
import Axios from 'axios';
import '../../Styles/Story.css';
import FooterWallmag from '../Footer/Footer';
// import Category from '../Category/Category';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {connect} from "react-redux";
import {story_category} from "../../Store/action/StoryAction";
import { Chips } from "../chips/Chips";
import { Card } from '@material-ui/core';
import {all_posts} from '../../Store/action/AllPostAction';
import {wallmag_all, wallmag_rem_post} from '../../Store/action/AllWallmagPostsAction'
// import ReactHtmlParser from 'react-html-parser'
import {FacebookIcon,  TwitterIcon,  WhatsappIcon,  LinkedinIcon, 
         PinterestIcon,  TumblrIcon,  PocketIcon} from 'react-share';
import {FacebookShareButton, LinkedinShareButton,  TwitterShareButton, WhatsappShareButton,      
        PinterestShareButton, TumblrShareButton, PocketShareButton} from 'react-share';
import MobHeaderNoImg from '../Header/Mob_HeaderNoImg';
import HeaderNoImg from '../Header/Header_NoImg'
import Helmet from 'react-helmet'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactGA from 'react-ga';
import RelatedFeeds from '../RelatedFeeds/RelatedFeeds';
import Dialog from '@material-ui/core/Dialog';


  // avg for Height --> height/8;
  var avg = 0
  var slideBool = false
  var imgSrcs = [];
  // var photo = [];
//  var data_art = '';
 
 var metatags_names = [];
var metatags_props = [];
 
class Story extends React.Component{

    constructor(props){
        super(props)
        this.zoomImage = this.zoomImage.bind(this)
        window.scroll(0,0)

        this.state = {
            name : this.props.match.params.seoUrl,
            data:'',
            category : '',
            height : window.innerHeight,
            scroll_count : 0,
            canonical:"https://wallmag.io/story/"+this.props.match.params.seoUrl,
            dialogOpen : false,
            art_data : '',
            art_dialog : false,
            article_url : this.props.location.pathname
        }

        ReactGA.initialize('UA-117934873-2', {
          gaOptions: {
            userId: JSON.parse(localStorage.getItem('current_user')) ? JSON.parse(localStorage.getItem('current_user')).displayName+'_'+JSON.parse(localStorage.getItem('current_user')).id : 'Guest_User' 
          }
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentWillMount() {
      this.unlisten = this.props.history.listen((location, action) => {
        this.getPostByUrl(location['pathname'].replace('/story/', ''))
        window.scrollTo(0,0)    
      });
    }
    
    componentWillUnmount() {
        this.unlisten();
    }
    
    
 
    componentDidMount(){

      

          //  1) browser back button event
    window.onpopstate = this.onBackButtonEvent;
      
      this.getPostByUrl(this.state.name)
      
      window.addEventListener("resize", this.updateDimensions.bind(this));
        
      // if Props.load is true then no need to call Api again
        if(this.props.load){}
        else{
          // first time when props.load is false and we need to call this api to get 
          // An Article's Data

          
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.user,
          };
      
          Axios.get(
            "https://wallmag-2.appspot.com/_ah/api/userApi/v1/startUpCallWeb",
            headers
          )
            .then((res) => {
              this.props.story_category(res.data.categories)
              this.props.all_posts(res.data.stories)
              this.props.wallmag_all(res.data.stories)
             
              this.setState({
                catData : res.data.categories
              })
              
            })
            .catch((error) => {
              //console.log(error.message);
            });

          
        }
        

        
    }

      //  2) browser back button event

  onBackButtonEvent = e => {
    e.preventDefault();
    this.setState({
      art_dialog: false,
      dialogOpen : false
    });
  };

    onChange(){
      slideBool = false
    }
    
    // Dimensions updated 
    updateDimensions() {    
      this.setState({ height: window.innerHeight });
    }

    getHeight() {
      var height = window.outerHeight;
      avg = height/8;
      
      return avg
    }

    // Dialog open For art images
    zoomImage=(url)=>{
      
      window.history.pushState('','',this.state.article_url)

      this.setState({
        url_artImage : url.target.src , 
        art_dialog : true
      })
    }


    // Getting Article's Data By using it's URL 

    getPostByUrl=(url)=> {
      
      if(this.props.wall_load){
        
        // if user Come from Another Page like - Home page Articles , Category Page Article
        // then the data will come from Redux (wall_data) 
       
        for(var i =0; i< this.props.wall_data.length ; i++){
          if(url ===  this.props.wall_data[i].seoUrl ){
            this.setState({
              data :  this.props.wall_data[i],
              art_data :  this.props.wall_data[i].body.replace(/<img/g ,
                `<img onclick="zoomImage(this.src)" id='ads' class ='img-fluid art_name' `)
            })
            metatags_names = Object.entries(this.props.wall_data[i].metaTags.names);
            metatags_props = Object.entries(this.props.wall_data[i].metaTags.properties);  

            
            // data_art =  this.props.wall_data[i].body.replace(/<img/g ,
            //   `<img onclick="zoomImage(this.src)" id='ads' class ='img-fluid art_name' `)


      


              Axios.get('https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getBySeoUrl?seoUrl='+url).then(
                response=>{     
                  this.setState({  data : response.data , art_data : response.data.body.replace(/<img/g ,
                    `<img onclick="zoomImage(this.src)" id='ads' class ='img-fluid art_name' `) })  
      
                    var imgs = document.getElementsByClassName("art_name");
                  
                
                    for (var i = 0; i < imgs.length; i++) {
                        imgSrcs.push(imgs[i].src);
                    }
      
      
                    var photo = document.getElementsByClassName(
                      "art_name","img"
                  );
                  
                  for (var j=0; j < photo.length; j++) {
                      photo[j].onclick = this.zoomImage
                  };
      
                
      
                }).catch(error=>{
                  if(error.response.status === 404){
                    this.props.history.replace('/page-not-found')
                  }
                })
               
              
          
          }
        }




      }
      else{ 
        // When user uses Direct URL for this Article , then data will come from SeoURL API 
        Axios.get('https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getBySeoUrl?seoUrl='+this.state.name).then(
          response=>{   
         

            this.setState({  data : response.data , art_data : response.data.body.replace(/<img/g ,
              `<img onclick="zoomImage(this.src)" id='ads' class ='img-fluid art_name' `) })  

              metatags_names = Object.entries(response.data.metaTags.names);
              metatags_props = Object.entries(response.data.metaTags.properties);  
  

              var imgs = document.getElementsByClassName("art_name");
            
          
              for (var i = 0; i < imgs.length; i++) {
                  imgSrcs.push(imgs[i].src);
              }


              var photo = document.getElementsByClassName(
                "art_name","img"
            );
            
            for (var k=0; k < photo.length; k++) {
                photo[k].onclick = this.zoomImage
            };


          }).catch(error=>{
            if(error.res)
            if(error.response.status === 404){
              this.props.history.replace('/page-not-found')
            }
          })
         }
         
         
}


        zoomPic = () => {
          this.setState({
            dialogOpen : true
          })
        }

        handleClose = () => {
          this.setState({
            dialogOpen : false,
            art_dialog : false
          })
        }
        handleCloseArticle = () => {
          window.history.back()
          this.setState({
            dialogOpen : false,
            art_dialog : false
          })
        }

        onImageClick=()=>{
          //console.log('hi')
        }

    render(){


        return( 
      <>

    
        {
              this.state.data === '' ?
                  this.getPostByUrl()
                          :
                <div onresize={this.getHeight()} className='back'> 
       
       ``

            
       <Helmet>
                {metatags_names.map((res) => {
                  return (
                    <meta name={res[0]} content={res[1]} />
                  );
                })}
                
                {
                    metatags_names.map((res)=>{
                        return res[0] === "title" ? (
                            <title>{res[1]}</title>
                          ) : ''
                    })
                }
    
                {metatags_props.map((res) => {
                  return <meta property={res[0]} content={res[1]} />;
                })}
              </Helmet>
                   
    
             
             {window.innerWidth <768 ? (

              // small Devices UI starts from here
           
            this.props.load ? (
              <div>
                <MobHeaderNoImg />
                  <div  className='container-fluid mb-4'>
                    <div className='row'>
                      <div className='col-xl-5 col-lg-5 col-md-5 des'>

                    {/* Title of the Article  */}

                 
                                  <p className='title'>
                                  {this.state.data.title}
                              </p>
                      
                        
  
                      {this.props.load &&
                        this.props.story_category_data.items.map(valee =>
                          this.state.data.linkedEntityIds.map((valle, i) =>
                            valee.id === valle ? (
                              <div>
                                {/* Category of the Article */}
                                 <Chips title={valee.name} color={valee.color}/>{" "} 
                              </div>
                            ) : (
                              ""
                            )  )  )}
  
      <div className='col-xl-1 col-lg-1 col-2 col-sm-2  col-md-1'></div>

      <div className='col-xl-5 col-lg-5 col-md-5 image_div' 
            style={{top:(window.innerHeight-(((window.innerHeight*85)/100)+50))/2}} >

      <div className='container div_post_image'
           style={{top:(window.innerHeight-(((window.innerHeight*85)/100)+50))/2}}>
      
      <div className='row justify-content-center align-content-center'>

      {this.state.data.fileProperties ? (this.state.data.fileProperties[1] ? slideBool = true :
                  slideBool = false) : slideBool = false
                  
                }
                 <Carousel onClickThumb={this.onChange} useKeyboardArrows={true} autoPlay={slideBool} infiniteLoop={slideBool}  showArrows={true} showThumbs={false} showIndicators={slideBool} showStatus={slideBool}
                    width={(window.innerWidth*90)/100>400 ? (400) // width (if clause)
                      : 
                  ((window.innerWidth*90)/100)}
                >
                  {this.state.data.fileProperties ? this.state.data.fileProperties.map((value,i) => {
                   
                   return(

                      <div>

<img src={this.state.data.fileProperties[i].publicUrl} 
                              alt={this.state.data.title}
                              className='post_image'
                              />
                              
                       

                         {/* Description About Image */}
                            {
                              !this.state.data.fileProperties[i].description ?
                                         ""
                                           : 
                            
                            <p className="legend">{this.state.data.fileProperties[i].description}</p>   
                            }
                            </div>  
                   )
    

                 })
                                    :
                                    <div>
                                    <img src={this.state.data.fileProperty.publicUrl} 
                                          alt={this.state.data.title}
                                          className='post_image'
                                         onScrollCapture={this.onChange}
                                          />
            
                                     {/* Description About Image */}
                                        {
                                          !this.state.data.fileProperty.description ?
                                                     ""
                                                       : 
                                        
                                        <p className="legend">{this.state.data.fileProperty.description}</p>   
                                        }
                                        </div>  
                                    
                                    
                                    } 
                       </Carousel>



        </div>
  
  
  <div className='row justify-content-center sc_div  mt-4'>
     
  <FacebookShareButton className='sc' quote={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <FacebookIcon round size='40px'/>
      </FacebookShareButton> 

      <WhatsappShareButton className='sc' title={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <WhatsappIcon round size='40px' />
      </WhatsappShareButton>
  
      <LinkedinShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <LinkedinIcon round size='40px' />
      </LinkedinShareButton>
  
      <PocketShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
        <PocketIcon round size='40px' />
      </PocketShareButton>
  
      <TumblrShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} caption={this.state.data.summary} style={{outline:'none', cursor:'pointer'}}>
        <TumblrIcon round size='40px' />
      </TumblrShareButton>
  
      <PinterestShareButton className='sc' media={this.state.data.fileProperties ? this.state.data.fileProperties[0]['publicUrl'] : this.state.data.fileProperty.publicUrl} 
                            description={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <PinterestIcon round size='40px' />
      </PinterestShareButton>
      
      <TwitterShareButton className='tw' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
        <TwitterIcon round size='40px' />
      </TwitterShareButton>

  </div>
  
  </div>
  
  
  
                        </div>
  
  
  {/* Summary Starts Here*/}
                      <Card className='summary' >
                        <p className='summary_text'>
                           {this.state.data.summary}  
                        </p>
                      </Card>
  {/* Summary Ends Here */}
  
  
  {/* Title Body Starts Here*/}
                    {this.state.data.body==='<p></p>↵' || !this.state.data.body?(
  <></>
                        ):(

                          <Card className='art_body' >
                         <div>
                            <div className='body_text img-fluid'>
                              
                              {/* {ReactHtmlParser(this.state.data.body.replace(/<img/g , `<img onclick="zoomImage(this.src)" class ='img-fluid' `))}   */}
                              <div dangerouslySetInnerHTML={{ __html: 
                  this.state.art_data
                }} />
                            
                            </div>
                            </div>
                          </Card>
                        )}
                   
  {/* Title Body Ends Here */}
                  
  
                     
  
                    </div>
                    
                       <div className='col-xl-1 col-lg-1 col-md-1'></div>
                    </div>  
  
  
          </div>
    
    
            </div>
            
            ):(
              <div>
              <MobHeaderNoImg />
                <div  className='container-fluid mb-4'>
                        <div className='row'>
                            <div className='col-xl-1 col-lg-1 col-md-1' ></div>
                            <div className='col-xl-5 col-lg-5 col-md-5 des'>
                            <p className='title'  >
                                  {this.state.data.title}
                            </p>
  
  
  {/* Summary Starts Here*/}
                              <Card className='summary' >
                                <p className='summary_text'>
                                   {this.state.data.summary}  
                                </p>
                              </Card>
  {/* Summary Ends Here */}
  
  
  {/* Title Body Starts Here*/}
                             {this.state.data.body==='<p></p>↵' || !this.state.data.body?(
  <></>
                    ):(
                      <Card className='art_body'>
                      <p className='body_text'>
                      {/* {ReactHtmlParser(this.state.data.body.replace(/<img/g ,
                         `<img onclick="zoomImage(this.src)" class ='img-fluid'`))}   */}

<div dangerouslySetInnerHTML={{ __html: 
                                  this.state.art_data

                }} />
                      </p>
                      
                      </Card>
                    )}
                           
  {/* Title Body Ends Here */}

                            </div>
                            
  
              <div className='col-xl-1  col-md-1'></div>
            <div className='col-xl-5 col-lg-5 col-md-5 image_div' 
                  style={{top:(window.innerHeight-(((window.innerHeight*85)/100)+50))/2}} >

          <div className='container div_post_image' 
                  style={{top:(window.innerHeight-(((window.innerHeight*85)/100)+50))/2}}>

          <div className='row justify-content-center align-content-center'>
          {this.state.data.fileProperties ? (this.state.data.fileProperties[1] ? slideBool = true :
                  slideBool = false) : slideBool = false
                  
                }
                 <Carousel autoPlay={slideBool} infiniteLoop={slideBool}  showArrows={true} showThumbs={false} showIndicators={slideBool} showStatus={slideBool}
                    width={(window.innerWidth*90)/100>400 ? (400) // width (if clause)
                      : 
                  ((window.innerWidth*90)/100)}

                  height={(window.innerHeight*85)/100>500 ? (500)  // height (if clause)
                               :
                           ((window.innerHeight*85)/100)} // height (else clause)
               >
                  {
                    this.state.data.fileProperties ? this.state.data.fileProperties.map((value,i) => {
                   
                   return(

                      <div>
                        <img src={this.state.data.fileProperties[i].publicUrl} 
                              alt={this.state.data.title}
                              className='post_image'
                              />

                         {/* Description About Image */}
                            {
                              !this.state.data.fileProperties[i].description ?
                                         ""
                                           : 
                            
                            <p className="legend">{this.state.data.fileProperties[i].description}</p>   
                            }
                            </div>  
                   )
    

                 })
                                    :
                                    <div>
                                    <img src={this.state.data.fileProperties.publicUrl} 
                                          alt={this.state.data.title}
                                          className='post_image'
                                          />
            
                                     {/* Description About Image */}
                                        {
                                          !this.state.data.fileProperty.description ?
                                                     ""
                                                       : 
                                        
                                        <p className="legend">{this.state.data.fileProperty.description}</p>   
                                        }
                                        </div>  
                                    
                                    
                                    } 
                       </Carousel>


        
        
  </div>
          
          
  <div className='row justify-content-center sc_div  mt-4'>
        <FacebookShareButton className='sc' quote={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <FacebookIcon round size='40px'/>
      </FacebookShareButton> 

      <WhatsappShareButton className='sc' title={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <WhatsappIcon round size='40px' />
      </WhatsappShareButton>
  
      <LinkedinShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <LinkedinIcon round size='40px' />
      </LinkedinShareButton>
  
      <PocketShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
        <PocketIcon round size='40px' />
      </PocketShareButton>
  
      <TumblrShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} caption={this.state.data.summary} style={{outline:'none', cursor:'pointer'}}>
        <TumblrIcon round size='40px' />
      </TumblrShareButton>
  
      <PinterestShareButton className='sc' media={this.state.data.fileProperties ? this.state.data.fileProperties[0]['publicUrl'] : this.state.data.fileProperty.publicUrl} 
                            description={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
        <PinterestIcon round size='40px' />
      </PinterestShareButton>
      
      <TwitterShareButton className='tw' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
        <TwitterIcon round size='40px' />
      </TwitterShareButton>

  </div>
          
          </div>
          
          
          
                                </div>
                                <div className='col-xl-1 col-lg-1 col-md-1'></div>
                            </div>  
  
  
                  </div>
             </div>
              )
            
// small Device UI ends here


            ) 

                  : 

            (
            // large screens UI starts from here
            
            
            this.props.load ? (


              <div>
                 <HeaderNoImg />
              <div  className='container-fluid'>
              {  
              this.state.data.blog === true ?
              
              
              // when it's a blog

              <div className='row'  style={{marginTop:'-120px'}}>


              <div className='col-xl-2 col-lg-2 col-md-2'></div>
            
              <div className='col-xl-8 col-lg-8 col-md-8 des'>

            {/* Title of The Article */}

            
              <p className='title'>
                  {this.state.data.title}
              </p>


                {this.props.load &&
                  this.props.story_category_data.items.map(valee =>
                    this.state.data.linkedEntityIds.map((valle, i) =>
                      valee.id === valle ? (
                        <div>
                          {/* Category of the Article */}
                           <Chips title={valee.name} color={valee.color}/>{" "} 
                        </div>
                      ) : (
                        ""
                      )
                    )
                  )}


            {/* Summary Starts Here*/}
                            <Card className='summary' >
                              <p className='summary_text'>
                                {this.state.data.summary}  
                              </p>
                            </Card>
            {/* Summary Ends Here */}


              {/* Title Body Starts Here*/}
                            {this.state.data.body==='<p></p>↵' || !this.state.data.body?(
              <></>
                            ):(
                              <Card className='art_body'>
                              <p className='body_text' style={{textAlign:'center'}}>
                              {/* {ReactHtmlParser(this.state.data.body.replace(/<img/g ,
                                 `<img onclick="zoomImage(this.src)" class ='img-fluid' `))}   */}

<div dangerouslySetInnerHTML={{ __html: 
                                  this.state.art_data

                }} />
                              </p>
                              
                              </Card>
                            )}
                          
              {/* Title Body Ends Here */}
            

              </div>
              
              
              <div className='col-xl-2 col-lg-2 col-md-2 image_div' 
                    style={{top:(window.innerHeight-(((window.innerHeight*55)/100)+50))/2, marginBottom:'200px'}} >

            <div className='div_post_image' 
                  style={{top:(window.innerHeight-(((window.innerHeight*65)/100)+50))/2}}>
          
            <div className='row justify-content-center align-content-center'>
              

              <div className='div socialshares'>
              
              
                      <FacebookShareButton className='sc' quote={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
                        <FacebookIcon round size='40px'/>
                      </FacebookShareButton> 

                      <WhatsappShareButton className='sc' title={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
                        <WhatsappIcon round size='40px' />
                      </WhatsappShareButton>

                      <LinkedinShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
                        <LinkedinIcon round size='40px' />
                      </LinkedinShareButton>

                      <PocketShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
                        <PocketIcon round size='40px' />
                      </PocketShareButton>

                      <TumblrShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} caption={this.state.data.summary} style={{outline:'none', cursor:'pointer'}}>
                        <TumblrIcon round size='40px' />
                      </TumblrShareButton>

                      <PinterestShareButton className='sc' media={this.state.data.fileProperties ? this.state.data.fileProperties[0]['publicUrl'] : this.state.data.fileProperty.publicUrl} 
                                            description={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
                        <PinterestIcon round size='40px' />
                      </PinterestShareButton>
                      
                      <TwitterShareButton className='tw' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
                        <TwitterIcon round size='40px' />
                      </TwitterShareButton>

                  </div>

</div>
</div>
</div>
                  <div className='col-xl-1 col-lg-1 col-md-1'></div>
              </div>  



              :


              <div className='row'  style={{marginTop:'-180px'}}>
              <div className='col-xl-1 col-lg-1 col-md-1'></div>
            
              <div className='col-xl-5 col-lg-5 col-md-5 des'>

            {/* Title of The Article */}

            
              <p className='title'>
                  {this.state.data.title}
              </p>


                {this.props.load &&
                  this.props.story_category_data.items.map(valee =>
                    this.state.data.linkedEntityIds.map((valle, i) =>
                      valee.id === valle ? (
                        <div>
                          {/* Category of the Article */}
                           <Chips title={valee.name} color={valee.color}/>{" "} 
                        </div>
                      ) : (
                        ""
                      )
                    )
                  )}


{/* Summary Starts Here*/}
                <Card className='summary' >
                  <p className='summary_text'>
                     {this.state.data.summary}  
                  </p>
                </Card>
{/* Summary Ends Here */}


{/* Title Body Starts Here*/}
              {this.state.data.body==='<p></p>↵' || !this.state.data.body?(
<></>
              ):(
                <Card className='art_body'>
                <p className='body_text'>
                {/* {ReactHtmlParser(this.state.data.body.replace(/<img/g , `<img onclick="zoomImage(this.src)" class ='img-fluid' `))}   */}
                <div dangerouslySetInnerHTML={{ __html: 
                                    this.state.art_data

                }} />

                </p>

                
                </Card>
              )}
             
{/* Title Body Ends Here */}
            

              </div>
              

                <div className='col-xl-1 col-lg-1  col-md-1'></div>
              <div className='col-xl-5 col-lg-5 col-md-5 image_div' 
                    style={{top:(window.innerHeight-(((window.innerHeight*65)/100)+50))/2}} >

            <div className='div_post_image' 
                  style={{top:(window.innerHeight-(((window.innerHeight*65)/100)+50))/2}}>
          
            <div className='row justify-content-center align-content-center'>

         {this.state.data.fileProperties ? (this.state.data.fileProperties[1] ? slideBool = true :
              slideBool = false) : slideBool = false
              
            }

            
             <Carousel 
                  autoPlay={slideBool} 
                  infiniteLoop={slideBool}  
                  showArrows={true} 
                  showThumbs={false} 
                  showIndicators={slideBool} 
                  showStatus={slideBool}
                  onClickItem={()=>this.zoomPic()}
                  >



              {
                this.state.data.fileProperties 
              
                  ? this.state.data.fileProperties.map((value,i) => {
               
               return(

                  <div>
                    

                      <img src={this.state.data.fileProperties[i].publicUrl} 
                          alt={this.state.data.title}
                          className='post_image img-fluid'
                          />  

                     {/* Description About Image */}
                        {
                          !this.state.data.fileProperties[i].description ?
                                     ""
                                       : 
                        
                        <p className="legend">{this.state.data.fileProperties[i].description}</p>   
                        }
                        </div>  
               )
             })
                                :
                                <div>
                                <img src={this.state.data.fileProperty.publicUrl} 
                                      alt={this.state.data.title}
                                      className='post_image img-fluid'
                                      />
        
                                 {/* Description About Image */}
                                    {
                                      !this.state.data.fileProperty.description ?
                                                 ""
                                                   : 
                                    
                                    <p className="legend">{this.state.data.fileProperty.description}</p>   
                                    }
                                    </div>  
                                
                                
                                } 
                   </Carousel>
</div>


<div className='row justify-content-center   mt-4'>
  
  
  <FacebookShareButton className='sc' quote={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
    <FacebookIcon round size='40px'/>
  </FacebookShareButton> 

  <WhatsappShareButton className='sc' title={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
    <WhatsappIcon round size='40px' />
  </WhatsappShareButton>

  <LinkedinShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
    <LinkedinIcon round size='40px' />
  </LinkedinShareButton>

  <PocketShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
    <PocketIcon round size='40px' />
  </PocketShareButton>

  <TumblrShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} caption={this.state.data.summary} style={{outline:'none', cursor:'pointer'}}>
    <TumblrIcon round size='40px' />
  </TumblrShareButton>

  <PinterestShareButton className='sc' media={this.state.data.fileProperties ? this.state.data.fileProperties[0]['publicUrl'] : this.state.data.fileProperty.publicUrl} 
                        description={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name} style={{outline:'none', cursor:'pointer'}}>
    <PinterestIcon round size='40px' />
  </PinterestShareButton>
  
  <TwitterShareButton className='tw' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} style={{outline:'none', cursor:'pointer'}}>
    <TwitterIcon round size='40px' />
  </TwitterShareButton>

</div>

</div>



                  </div>
                  <div className='col-xl-1 col-lg-1 col-md-1'></div>
              </div>  


    


              }
  </div>
  </div>
            ):(
              <div>
                 <HeaderNoImg />
              <div  className='container-fluid mb-4'>
                <div className='row'>
                  <div className='col-xl-1 col-lg-1 col-md-1' ></div>
                     <div className='col-xl-5 col-lg-5 col-md-5 des'>

                      {/* Title of The Article */}
                        <p className='title'>
                          {this.state.data.title} 
                        </p>


{/* Summary Starts Here*/}
                            <Card className='summary' >
                              <p className='summary_text'>
                                 {this.state.data.summary}  
                              </p>
                            </Card>
{/* Summary Ends Here */}


{/* Title Body Starts Here*/}
                           {this.state.data.body==='<p></p>↵' || !this.state.data.body?(
<></>
                  ):(
                    <Card className='art_body'>
                      <div>
                         <p className='body_text'>
                         <div dangerouslySetInnerHTML={{ __html: 
                                   this.state.art_data

                }} />
                         {/* {ReactHtmlParser(this.state.data.body.replace(/<img/g , `<img onclick="zoomImage(this.src)" class ='img-fluid' `))}   */}
                         </p>
                      </div>
                    
                    </Card>
                  )}
                         
{/* Title Body Ends Here */}
                        
                   </div>
      

    <div className='col-xl-1  col-md-1'></div>
    <div className='col-xl-5 col-lg-5 col-md-5 image_div'
           style={{top:(window.innerHeight-(((window.innerHeight*65)/100)+50))/2}} >

    <div className='div_post_image'   
          style={{top:(window.innerHeight-(((window.innerHeight*65)/100)+50))/2}}>

    <div className='row justify-content-center align-content-center'>
    {this.state.data.fileProperties ? (this.state.data.fileProperties[1] ? slideBool = true :
                  slideBool = false) : slideBool = false
                  
                }
                 <Carousel autoPlay={slideBool} infiniteLoop={slideBool}  showArrows={true} showThumbs={false} showIndicators={slideBool} showStatus={slideBool}>
                  {this.state.data.fileProperties ? this.state.data.fileProperties.map((value,i) => {
                   
                   return(

                      <div>
                        <img src={this.state.data.fileProperties[i].publicUrl} 
                              alt={this.state.data.title}
                              className='post_image'
                              />

                         {/* Description About Image */}
                            {
                              !this.state.data.fileProperties[i].description ?
                                         ""
                                           : 
                            
                            <p className="legend">{this.state.data.fileProperties[i].description}</p>   
                            }
                            </div>  
                   )
    

                 })
                                    :
                                    <div>
                                    <img src={this.state.data.fileProperty.publicUrl} 
                                          alt={this.state.data.title}
                                          className='post_image'
                                          />
            
                                     {/* Description About Image */}
                                        {
                                          !this.state.data.fileProperty.description ?
                                                     ""
                                                       : 
                                        
                                        <p className="legend">{this.state.data.fileProperty.description}</p>   
                                        }
                                        </div>  
                                    
                                    
                                    } 
                       </Carousel>
      
</div>
        
        
<div className='row justify-content-center   mt-4'>
      <FacebookShareButton className='sc' quote={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name}>
        <FacebookIcon round size='40px'/>
      </FacebookShareButton> 

      <WhatsappShareButton className='sc' title={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name}>
        <WhatsappIcon round size='40px' />
      </WhatsappShareButton>
  
      <LinkedinShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name}>
        <LinkedinIcon round size='40px' />
      </LinkedinShareButton>
  
      <PocketShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title}>
        <PocketIcon round size='40px' />
      </PocketShareButton>
  
      <TumblrShareButton className='sc' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title} caption={this.state.data.summary}>
        <TumblrIcon round size='40px' />
      </TumblrShareButton>
  
      <PinterestShareButton className='sc' media={this.state.data.fileProperties ? this.state.data.fileProperties[0]['publicUrl'] : this.state.data.fileProperty.publicUrl} 
                            description={this.state.data.title} url={'https://wallmag.io/story/'+this.state.name}>
        <PinterestIcon round size='40px' />
      </PinterestShareButton>
      
      <TwitterShareButton className='tw' url={'https://wallmag.io/story/'+this.state.name} title={this.state.data.title}>
        <TwitterIcon round size='40px' />
      </TwitterShareButton>

        </div>  
      </div>
   </div>
                
        
    <div className='col-xl-1 col-lg-1 col-md-1'></div>

 </div>  

</div>
    </div>
      )

          // large screen UI ends here 

            )}
          
                {/* categories (WallMag) */}
                         {/* <div  className='story_cat_div_'>
                            <Category data={this.state.catData} clrTxt='white'/>
                          </div>  */}


                
                {/* Related Feeds */}

                {window.innerWidth <768 ?
                <div></div>
                :  
                <div style={{marginTop:'200px'}}></div>  
              }
                        <div>
                          <RelatedFeeds props={this.props} id={this.state.data.id}/>
                        </div>

                  
                              {/* Footer of the Page */}
                              <div>
                              <FooterWallmag />
                              </div>
                </div>
               
          }


{/* dialog for art images */}
<Dialog fullScreen open={this.state.art_dialog} onClose={this.handleCloseArticle} >

{
window.innerWidth <768 ?

  <div className='img-fluid' style={
      {backgroundImage:` url(${this.state.url_artImage})`,
         height:'100%' , width:'100%', backgroundSize:'100%' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat'  ,
    position : 'relative'
  }}>

    <img
    onClick={this.handleCloseArticle}
    src={require('../../logo/cancel.svg')} 
    alt='Cancel Logo'
        style={{position:'absolute', top:'20px', right : '20px'}}
        />
      
             </div>

:
<div className='img-fluid' style={
  {backgroundImage:` url(${this.state.url_artImage})`,
     height:'100%' , width:'100%', backgroundSize:'70%' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat'  ,
position : 'relative'
}}>

<img
onClick={this.handleCloseArticle}
src={require('../../logo/cancel.svg')} 
alt='Close Logo'
    style={{position:'absolute', top:'20px', right : '20px'}}
    />
  
         </div>

    }

</Dialog>



{/* Dialog for main images of articles */}
<Dialog fullScreen open={this.state.dialogOpen} onClose={this.handleClose} >
  
                    <Carousel
                          // autoPlay={slideBool} 
                          // infiniteLoop={slideBool}  
                          showArrows={true} 
                          showThumbs={false} 
                          showIndicators={slideBool} 
                          showStatus={slideBool}
                          //  onClickItem={()=>this.zoomPic()}
                          // onClickItem={()=>alert()}
                      >


{this.state.data.fileProperties ? 
          this.state.data.fileProperties.map((value,i) => {
                   
                   return(

                      <div>
                        

                          <img src={this.state.data.fileProperties[i].publicUrl} 
                              alt={this.state.data.title}
                              
                              style={{height:((((window.innerHeight))))}}
                              />  

                         {/* Description About Image */}
                            {
                              !this.state.data.fileProperties[i].description ?
                                         ""
                                           : 
                            
                            <p className="legend">{this.state.data.fileProperties[i].description}</p>   
                            }
                            </div>  
                   )
                 })
                 : ''
                }

    </Carousel>
   
    <img 
      onClick={this.handleClose}
      src={require('../../logo/back.svg')}
      alt='Back Button'
      style={{width:'20px', height:'20px' , position:'absolute' , marginTop:'25px', marginLeft:'25px'}} 
    />
      </Dialog>
    



               </>
            )
    }
}

const mapStateToProps = state => {
    return {
        story_category_data: state.story_cat.story_category, 
        load : state.story_cat.load,
        all_post_data: state.all_post.all_post_data,
        all_post_load : state.all_post.all_post_load,
        wall_data: state.wallmag_all_posts.wallmag_post_data,
        wall_load:state.wallmag_all_posts.wallmag_post_load ,
    };
  };

export default  connect(mapStateToProps,{all_posts , story_category,
                                            wallmag_all,wallmag_rem_post})(Story);