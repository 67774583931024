import React from "react";
import Header from "../IssuePages/Header/Header";
import "./../../Styles/Awards/AwardDetails.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterWallmag from "../Footer/Footer";
import MobHeader from "../IssuePages/Header/Mob_Header";
import axios from "axios";
import Collapsible from "react-collapsible";
import { loadProgressBar } from "axios-progress-bar";
import urlencode  from 'urlencode'
import {get_mission_by_slug,paid_remaining_subs,payment_mode_missions,get_submissions,is_like,get_finalist,
        get_winners,get_inspirations,get_mostVotedSubmissions, // get_SectionList_byTopic,
        get_faq_section,login_api,startup_call, purchase_extra_uploads} from "../common/AppConstants";
import ReactHtmlParser from "react-html-parser";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {Dialog,DialogTitle,DialogActions,DialogContent,Button,
        CircularProgress,createMuiTheme} from "@material-ui/core";
import Img from "react-image";
import PostViewer from "../User/PostViewer/PostViewer";
import frenchStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import firebase from "../Login/firebaseConfig";
import cogoToast from "cogo-toast";
import { ThemeProvider } from "@material-ui/styles";
import ReactGA from "react-ga";
import StackGrid from "react-stack-grid";
import MissionTime from "./MissionTime";
import Helmet from 'react-helmet'
import VideoPlayer from "../CommComp/VideoPlayer/VideoPlayer";
import MobileVerification from "../MobileVerification/SendOTP";
import Zoom from 'react-reveal/Zoom';
import Invite from "../MyDialogs/Invite";


var countForList = [];
var count= 0;

var mapObj = {
  '<p':`<p style="color:white;"`,
  '<h1':`<h1 style="color:white;"`,
  '<h2':`<h2 style="color:white;"`,
  '<h3':`<h3 style="color:white;"`,
  '<h4':`<h4 style="color:white;"`,
  '<h5':`<h5 style="color:white;"`,
  '<h6':`<h6 style="color:white;"`,
  '<strong':`<strong style="color:white;"`,
  '<span style="color: rgb(0, 0, 0);':`<span style="color:white;"`,
};
var sections = [];
var isWinners = true
var isFinalist = true
const theme = createMuiTheme({
  typography: {
    fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,Montserrat"
  }
});

var remaining_subs = 0;
var paid = false;
var submissionsCount = '0';
var p_count = 0;
var prizes = [];
var position = 0;
var post_id = 0;
var finalist = [];
var finalistNPT = '';
var winners = [];
var inspiration = [];
var count = 0;
var dim = [];
var ids = new Set();
var data = [];
var metatags = []
var mission_data = [];
var render = false
var prizeText = ''
var aboutHtml = ''
var whatToUpload = ''
var description = ''
var quotation = ''
var finalistCount = 0

class ContestDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: "home",
      key1: "home",
      about: this.props.match.params.type ? false : true,
      slug: this.props.match.params.missionTag,
      finalist: this.props.match.params.type === "finale" ? true : false,
      winner: this.props.match.params.type === "winners" ? true : false,
      remaining_subs: 0,
      paid: false,
      payment_dialog: false,
      dialogOpen: false,
      pay_dialogOpen: false,
      no_image: false,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      edays: 0,
      ehours: 0,
      emin: 0,
      esec: 0,
      nextPageToken: "",
      ok: false,
      anchor: null,
      mode: "recent",
      metaAvailable : false,
      loading : false,
      videoOverlay : false,
      verify_user_dialog : false,
      mob_verification_dialog : false,
      mob_verification_dialog_mob : false,
      wtu : false , // What to upload?
      watt : false, // What are the timelines?
      mtj : false, // Meet the juries
      srb : false, // Show Ranking Board
      sf : false , // Show FAQ
      srs : false, // Show Recent Submissions
      rankingBoard : false,
      theposition: 0,
      video : false,
      load : false,
      invite_friends_dialog : false,
      firstPoint : false,
      secondPoint : false,
      thirdPoint : false,
      forthPoint : false,
      fifthPoint : false,
      first : '',
      second : '',
      third : '',
      forth : '',
      fifth : '',
      props : this.props,
      refreshPage : this.props.location.data ? false : true ,
    };

    
    window.addEventListener("scroll", this.listenToScroll);


    const params_ = new URLSearchParams(this.props.location.search);

    if (params_.get('id') !== null) {

     
      const params_ = new URLSearchParams(this.props.location.search);
     
      // this.newUser(params_.get('id'),params_.get('name'),params_.get('contactNo'),params_.get('photoUrl'))
      this.sign_up(
        params_.get('id') ? params_.get('id') : '' ,
        params_.get('name') ? params_.get('name') : '' ,
        params_.get('contactNo') ? params_.get('contactNo') : '',
        params_.get('photoUrl') ? params_.get('photoUrl') : '',
        params_.get('organization') ? params_.get('organization') :''
        )
    }else{
      render = true
    }

    if (this.props.match.params.type === "finale") {
      render = true
      this.clickOnFinalist(false);
    }

    if (this.props.match.params.type && this.props.location.search) {
      render = true
      if (this.props.location.search.indexOf("=") >= 0) {
        this.props.history.replace(
          "/post/" +
            this.props.location.search.slice(
              this.props.location.search.indexOf("=") + 1
            )
        );
      }
    }
    this.openCheckout = this.openCheckout.bind(this);

    ReactGA.initialize("UA-117934873-2", {
      gaOptions: {
        userId: JSON.parse(localStorage.getItem("current_user"))
          ? JSON.parse(localStorage.getItem("current_user")).displayName +
            "_" +
            JSON.parse(localStorage.getItem("current_user")).id
          : "Guest_User"
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    this.setState({
      theposition: scrolled,
      scrollPos : document.documentElement.clientHeight  
    });
  };

  sign_up = (id, n, cn, pu , org) => {
    const userId = id.replace(/"/g,'')
    const name = n.replace(/"/g,'')
    const contactNo = cn.replace(/"/g,'')
    const photoUrl = pu.replace(/"/g,'')
    const organization = org.replace(/"/g,'')

    firebase
      .auth()
      .signInWithEmailAndPassword(userId, 'iitdelhialumni')
      .then((response) => {
        // signUp user
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + response.user.ra,
        };

        loadProgressBar();

        axios
          .post(login_api, {
            displayName: name
            ,
            email: response.user.email,
            id: response.user.uid,
            photoUrl: urlencode.decode(photoUrl)
             , contactNo : contactNo
             , organization : organization
          })
          .then((res) => {
            localStorage.setItem("current_user", JSON.stringify(res.data));

            localStorage.setItem("user", response.user.ra);
            localStorage.setItem(
              "name",
              name
            );
            localStorage.setItem("id", res.data.id);
            localStorage.setItem("image",res.data.photoUrl);
            this.startUpApi(headers);
            this.interceptor(response.user.ra);
            render = true

            setTimeout(()=>{

            },200)
            window.location.assign('/missions/'+this.props.match.params.missionTag)  

          });


    
    })
      .catch((err) => {
        if (err.code === "auth/user-not-found") {
          this.newUser(id, n, cn, pu, org);
          // cogoToast.error('User Not Found , Try different Login Method or Check username/password')
        } else {
          cogoToast.error(err.message);
          setTimeout(()=>{

          },200)
        }
      });
  };

  // New user From another Websites
  newUser = (id, n, cn, pu, org) => {
    const userId = id.replace(/"/g,'')
    const name = n.replace(/"/g,'')
    const contactNo = cn.replace(/"/g,'')
    const photoUrl = pu.replace(/"/g,'')
    const organization = org.replace(/"/g,'')

    firebase
      .auth()
      .createUserWithEmailAndPassword(
        userId,
        'iitdelhialumni'
      )
      .then((response) => {
        // signUp user
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + response.user.ra,
        };

        loadProgressBar();

        axios
          .post(login_api, {
            displayName: name
            ,
            email: response.user.email,
            id: response.user.uid,
            photoUrl: urlencode.decode(photoUrl)
             ,
              contactNo : contactNo
              , organization : organization
          })
          .then((res) => {
            localStorage.setItem("current_user", JSON.stringify(res.data));

            localStorage.setItem("user", response.user.ra);
            localStorage.setItem(
              "name",
              name
            );
            localStorage.setItem("id", res.data.id);
            localStorage.setItem("image",res.data.photoUrl);
            this.startUpApi(headers);
            this.interceptor(response.user.ra);
            render = true

            setTimeout(()=>{

            },200)
            window.location.assign('/missions/'+this.props.match.params.missionTag)  

          });


    
    })
      
      .catch((error) => {
        cogoToast.error(error.message); 
        render = true

        setTimeout(()=>{

        },200)
        

      });
  };



  // Show Ranking
   showRanking= () => {
    this.setState({wtu : false,watt:false,mtj:false,srb:true,sf:false,srs:false,rankingBoard : true})

   }

     // Show Ranking
     showRanking_mob= () => {
      this.setState({wtu : false,watt:false,mtj:false,srb:true,sf:false,srs:false,rankingBoard_mob : true})
  
     }

  startUpsetUp = () => {
    ids = new Set(JSON.parse(localStorage.getItem("likedPostIds")));
  };
  

  componentDidMount() {
    window.scrollTo(0,0);
    window.onkeyup = this.keyPressed;

    localStorage.setItem('loc',window.location.href)

    this.tokenUpdate()

    //  1) browser back button event
    window.onpopstate = this.onBackButtonEvent;


    this.startUpsetUp();

    if(this.state.refreshPage === true){
    axios
      .get(get_mission_by_slug + this.state.slug)
      .then(res => {
        mission_data = res.data;
console.log("mission data", mission_data)
        this.getMoreData();

       
      })
      .catch(error => {
        if (error.message.status) {
          if (error.response.status === 404) {
            this.props.history.replace("/page-not-found");
          }
        }
      });
    }else{
      mission_data = this.state.props.location.data;
      this.getMoreData();

    }

   

    this.interval = setInterval(() => {
      const finaleDate = parseInt(mission_data.finaleTime);
      const fdate = this.calculateCountdown(finaleDate);
      fdate ? this.setState(fdate) : this.stop();
    }, 1000);

    this.interval2 = setInterval(() => {
      const finaleDate = parseInt(mission_data.endTime);
      const edate = this.calculateCountdownEnd(finaleDate);
      edate ? this.setState(edate) : this.stop2();
    }, 1000);
  }


  // get Mission Details

  getMoreData = () => {

    if(mission_data.finaleTime < mission_data.currentTime){
      this.setState({srs:true})
    }

    prizeText = mission_data.prizeText;
    aboutHtml = mission_data.aboutHtml;
    whatToUpload = mission_data.whatToUpload ? mission_data.whatToUpload : '';
    quotation = mission_data.quotation ? mission_data.quotation : '';
    description = mission_data.description ? mission_data.description : '';
   
    this.getNumbering()
    this.setState({load : true})

    if(mission_data.coverMovie){
    this.setState({
      video : true
    })
  }else{
    this.setState({
      video : false
    })
  }


    whatToUpload = whatToUpload.replace(/<p|<h1|<h2|<h3|<h4|<h5|<h6|<span style="color: rgb(0, 0, 0);/gi, function(matched){
      return mapObj[matched];
    });

    quotation = quotation.replace(/<p|<h1|<h2|<h3|<h4|<h5|<h6|<span style="color: rgb(0, 0, 0);/gi, function(matched){
      return mapObj[matched];
    });

    description = description.replace(/<p|<h1|<h2|<h3|<h4|<h5|<h6|<span style="color: rgb(0, 0, 0);/gi, function(matched){
      return mapObj[matched];
    });

    prizeText = prizeText.replace(/<p|<h1|<h2|<h3|<h4|<h5|<h6|<span style="color: rgb(0, 0, 0);/gi, function(matched){
    return mapObj[matched];
  });

   aboutHtml = aboutHtml.replace(/<p|<h1|<h2|<h3|<h4|<h5|<h6|<span style="color: rgb(0, 0, 0);/gi, function(matched){
    return mapObj[matched];
  });

    metatags = mission_data.metaTags
    
    this.setState({
      metaAvailable : true
    })

    this.getSectionList(mission_data.faqSectionId);

    
    if(mission_data.finaleTime < mission_data.currentTime){
      this.setState({mode:'top'})
      this.getMostVotedSubmissions()
    }else{
      this.setState({mode:'recent'})

      this.getSubmissions();
    }

    axios.get(paid_remaining_subs + mission_data.id).then(res => {
      paid = mission_data.paymentDone;
      submissionsCount = mission_data.submissionsCount;
      remaining_subs = mission_data.submissionRemaining;

      this.setState({ok: true});

      this.forceUpdate();
    });
  }

  keyPressed = e => {

    if(e.keyCode === 27){
      this.setState({videoOverlay : false})
    }
  };

     // Get Numbering for expandable view 
     getNumbering = () => {

      if(mission_data.aboutHtml){

        count++ ;
        
        this.setState({
          firstPoint : true,
          first : count
        })
      }
      if(mission_data.timelines){
   
        count++ ;
        this.setState({
          secondPoint : true,
          second : count
        })
      }

      
      if(mission_data.juries){
        count++ ;
        countForList.push(count)
        this.setState({
          thirdPoint : true,
          third : count,
        })

      }

      if(mission_data.faqSectionId){
        count++ ;
        countForList.push(count)
        this.setState({
          forthPoint : true,
          forth : count,
        })

      }

      if(parseInt(mission_data.submissionCount) > 0 ){
        count++ ;
        countForList.push(count)
        this.setState({
          fifthPoint : true,
          fifth : count,
        })

      }
    }


  // get section list
  getSectionList = id => {
    this.tokenUpdate()

    axios.get(get_faq_section + id).then(res => {
      sections = res.data;
      this.forceUpdate();
    });
  };

  goToProfile = UserSlug => {
    window.open(`/${UserSlug}`, "_blank");
  };

  componentWillUnmount() {
    window.scrollTo(0,0)

    mission_data = []

    position = 0;
    post_id = 0;
    finalist = [];
    winners = [];
    inspiration = [];
    count = 0;
    data = [];
    p_count = 0;
    prizes = [];

    this.stop();
    this.stop2();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  calculateCountdownEnd(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      eyears: 0,
      edays: 0,
      ehours: 0,
      emin: 0,
      esec: 0,
      emillisec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.eyears = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.eyears * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.edays = Math.floor(diff / 86400);
      diff -= timeLeft.edays * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.ehours = Math.floor(diff / 3600);
      diff -= timeLeft.ehours * 3600;
    }
    if (diff >= 60) {
      timeLeft.emin = Math.floor(diff / 60);
      diff -= timeLeft.emin * 60;
    }
    timeLeft.esec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }
  stop2() {
    clearInterval(this.interval2);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  }

  // Right Click on an Image
  clickRight = e => {
    e.preventDefault();
  };

  // Drag a photo
  onDragPhoto = e => {
    e.preventDefault();
  };

  // Inspiration Post

  getInspiration = () => {
    this.tokenUpdate()

    axios
      .get(get_inspirations + "?missionId=" + mission_data.id)
      .then(res => {
        inspiration = res.data.items;
      })
      .catch(error => {});
  };


  sendCode=()=>{
    firebase.auth().currentUser.sendEmailVerification()

    cogoToast.success('Verification Code ha been Send !!!')
  }



  vote = (e, post_id, like, indexNo) => {
    e.preventDefault();
    this.tokenUpdate()

    if (JSON.parse(localStorage.getItem("current_user"))) {

      firebase.auth().onAuthStateChanged((user) => {

          if(user.emailVerified === false){
              var actionCodeSettings = {
                url: 'https://https://wallmag-2.appspot.com/_ah/api/',
              };
            firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
              .then(function() {
                // Verification email sent.
              })
              .catch(function(error) {
                // Error occurred. Inspect error.code.
              });

            this.setState({
              verify_user_dialog : true
            })
          }else{
            localStorage.setItem("user_ver", 'true');

            if (like === true) {
              data[indexNo].likeCount = parseInt(data[indexNo].likeCount) + 1;
              ids.add(post_id);
            } else {
                data[indexNo].likeCount = parseInt(data[indexNo].likeCount) - 1;
                ids.delete(post_id);
            }
      
          localStorage.setItem("likedPostIds", JSON.stringify(Array.from(ids)));
          this.forceUpdate();
      
          // const headers = {
          //   "Content-Type": "application/json",
          //   Authorization: "Bearer " + localStorage.user
          // };
      
          const params = {
            postId: post_id,
            isLike: like
          };
      
          axios
            .put(is_like, params)
            .then(response => {})
            .catch(error => {
              if (like === true) {
                data[indexNo].likeCount = parseInt(data[indexNo].likeCount) - 1;
                ids.delete(post_id);
              } else {
                data[indexNo].likeCount = parseInt(data[indexNo].likeCount) + 1;
                ids.add(post_id);
              }
      
              localStorage.setItem("likedPostIds", JSON.stringify(Array.from(ids)));
              this.forceUpdate();
            });
        }
    })
    }else{
      this.loginFirst(e);
    }
  };

    // Login First
    loginFirst = e => {
      window.scrollTo(0,0)

      cogoToast.info("You need to login first");
      localStorage.setItem('loc',window.location.href)
      this.props.history.push("/login");
    };

  onBackButtonEvent = e => {
    e.preventDefault();
    this.setState({
      dialogOpen: false,
      invite_friends_dialog : false,
      
    });
    this.goBack();
  };

  //  2) browser back button event
  onBackButtonEvent = e => {
    e.preventDefault();
    this.setState({
      dialogOpen: false,
      invite_friends_dialog : false,
    });
  };

  // handle clicks
  handleClicks = (like, id, image, slug, post_data, position) => {
    if (JSON.parse(localStorage.getItem("current_user"))) {
      post_id = id;
      position = position;

      localStorage.setItem(
        "short_user",
        JSON.stringify(post_data[position].shortUserObject)
      );

      localStorage.setItem("imageUrl", image);

      localStorage.setItem("post_data", JSON.stringify(post_data));
      localStorage.setItem("post_id", id);
      localStorage.setItem(
        "short_user",
        JSON.stringify(post_data[position].shortUserObject)
      );

      window.history.pushState("", "", "/post/" + slug);

      this.setState({
        // theposition: window.pageYOffset,
        post_url: slug,
        dialogOpen: true,
        postData: post_data,
        pos: position
      });
    } else {
      post_id = id;
      position = position;

      localStorage.setItem(
        "short_user",
        JSON.stringify(post_data.shortUserObject)
      );

      localStorage.setItem("imageUrl", image);
      localStorage.setItem("post_data", JSON.stringify(post_data));
      localStorage.setItem("post_id", id);
      localStorage.setItem(
        "short_user",
        JSON.stringify(post_data.shortUserObject)
      );
      window.history.pushState("", "", "/post/" +slug);

      this.setState({
        // theposition: window.pageYOffset,
        post_url: slug,
        dialogOpen: true,
        postData: post_data,
        pos: position
      });
    }
  };

  // dialog close
  handleClose = () => {
    window.history.back();
    this.setState({
      dialogOpen: false
    });

  };

  // close menu
  closeMenu = e => {
    e.preventDefault();

    this.setState({
      anchor: false
    });
  };

  closePaymentDialog = () => {
    this.setState({
      pay_dialogOpen: false,
      videoOverlay : false,
      verify_user_dialog :false,
      mob_verification_dialog : false,
      mob_verification_dialog_mob : false,

    });
  };

  closeRanking = () => {
    this.setState({
      rankingBoard : false,
      srb : false,
      rankingBoard_mob : false
    })
  }

  openPaymentDialog = () => {
    this.setState({
      videoOverlay : true
    });
  };

  // dialog close
  closeDialog = () => {
    window.history.back();

    this.setState({
      payment_dialog: false,
    });
  };

  closeDialogInvite = () => {
    window.history.back();

    this.setState({
      invite_friends_dialog : false,
    });
  };

  // Invite Dialog 
  openDialog = () => {
    this.props.history.push("/missions/" + this.props.match.params.missionTag+'/')
    this.setState({
      invite_friends_dialog : true
    })
  }

  // image Loading effect
  imageLoading = () => {
    this.setState({
      loading: false
    });
    if (document.getElementById("pre")) {
      document.getElementById("pre").remove();
    }
  };

  // participate Dialog
  participateDialog = () => {
    ReactGA.event({
      category: "Participation Clicked (Out)",
      action: mission_data.hashTag
    });

    this.setState({
      pay_dialogOpen: true
    });
  };

  // free user

  participateFree = e => {
    e.preventDefault();
    this.tokenUpdate()

    ReactGA.event({
      category: "Free Participation Clicked",
      action: mission_data.hashTag
    });

    if (JSON.parse(localStorage.getItem("current_user"))) {
      axios
        .post(payment_mode_missions + mission_data.id, {
          razorPaymentId: new Date().getTime(),
          amount: mission_data.participationFees
        })
        .then(res => {
          this.setState({
            pay_dialogOpen: false
          });
          this.refreshPage();
        })
        .catch(error => {});
    } else {
      window.scrollTo(0,0);
      localStorage.setItem('loc',window.location.href)

      this.props.history.push("/login");
    }
  };

  // Payment (Razor Pay)

  openCheckout() {

    this.tokenUpdate()

    if (JSON.parse(localStorage.getItem("current_user"))) {
      ReactGA.event({
        category: "Participation Clicked "+ mission_data.hashTag,
        action: (JSON.parse(localStorage.getItem('current_user'))).id
      });

      let options = {
        // rzp_live_NqUjiqMbRXHnd5
        // rzp_test_Q7F9is7aKMJz5o
        // "key": "rzp_test_Q7F9is7aKMJz5o",
        key: "rzp_live_NqUjiqMbRXHnd5",
        amount: mission_data.participationFees * 100, // 1000 paise = INR 10, amount in paisa
        name: "WallMag Contest",
        description: mission_data.hashTag,
        image: "https://i.imgur.com/3c07Kie.png",
        handler: response => {
          response = response.razorpay_payment_id;

          // Billing Info (Api)

          axios
            .post(payment_mode_missions + mission_data.id, {
              razorPaymentId: response,
              amount: mission_data.participationFees
            })
            .then(res => {
              this.setState({
                payment_dialog: true
              });
              ReactGA.event({
                category: "On Payment Success "+ mission_data.hashTag,
                action: (JSON.parse(localStorage.getItem('current_user'))).id
              });
            })
            .catch(error => {
              ReactGA.event({
                category: "On Payment Failure "+ mission_data.hashTag,
                action: (JSON.parse(localStorage.getItem('current_user'))).id
              });
            });
        },
        modal: {
          ondismiss: () => {
            ReactGA.event({
              category: "Participation Canceled "+ mission_data.hashTag,
              action: (JSON.parse(localStorage.getItem('current_user'))).id
              
            });
          }
        },
        prefill: {
          contact:
            JSON.parse(localStorage.getItem("current_user")).contactNo === ""
              ? ""
              : JSON.parse(localStorage.getItem("current_user")).contactNo,
          email: JSON.parse(localStorage.getItem("current_user")).email
        },
        notes: {
          address: ""
        },
        theme: {
          color: "#000000"
        }
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      window.scrollTo(0,0);
      localStorage.setItem('loc',window.location.href)

      this.props.history.push("/login");
    }
  }

  openCheckoutSecond() {

    this.tokenUpdate()

    if (JSON.parse(localStorage.getItem("current_user"))) {
      ReactGA.event({
        category: "Participation Clicked "+ mission_data.hashTag,
        action: (JSON.parse(localStorage.getItem('current_user'))).id
      
      });

      let options = {
        // rzp_live_NqUjiqMbRXHnd5
        // rzp_test_Q7F9is7aKMJz5o
        // "key": "rzp_test_Q7F9is7aKMJz5o",
        key: "rzp_live_NqUjiqMbRXHnd5",
        amount: (mission_data.extraImageUploadLimitation * mission_data.extraPerImageCost) * 100, // 1000 paise = INR 10, amount in paisa
        name: "WallMag Contest",
        description: `Extra Image ${mission_data.hashTag}`,
        image: "https://i.imgur.com/3c07Kie.png",
        handler: response => {
          response = response.razorpay_payment_id;

          // Billing Info (Api)

          axios
            .post(purchase_extra_uploads + mission_data.id, {
              razorPaymentId: response,
              amount: (mission_data.extraImageUploadLimitation * mission_data.extraPerImageCost),
            })
            .then(res => {
              this.setState({
                payment_dialog: true
              });
              ReactGA.event({
                category: "On Payment Success "+ mission_data.hashTag,
                action: (JSON.parse(localStorage.getItem('current_user'))).id
              });
            })
            .catch(error => {
              ReactGA.event({
                category: "On Payment Failure "+ mission_data.hashTag,
                action: (JSON.parse(localStorage.getItem('current_user'))).id
              });
            });
        },
        modal: {
          ondismiss: () => {
            ReactGA.event({
              category: "Participation Canceled "+ mission_data.hashTag,
              action: (JSON.parse(localStorage.getItem('current_user'))).id
              
            });
          }
        },
        prefill: {
          contact:
            JSON.parse(localStorage.getItem("current_user")).contactNo === ""
              ? ""
              : JSON.parse(localStorage.getItem("current_user")).contactNo,
          email: JSON.parse(localStorage.getItem("current_user")).email
        },
        notes: {
          address: ""
        },
        theme: {
          color: "#000000"
        }
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      window.scrollTo(0,0);
      localStorage.setItem('loc',window.location.href)

      this.props.history.push("/login");
    }
  }

  // go to upload page
  goToUploadPage = e => {
    e.preventDefault();
    window.scrollTo(0,0)
    this.props.history.push("/upload/" + mission_data.id);
  };

  // when click on About
  clickOnAbout = (pageScrollPosition) => {

    if(pageScrollPosition === false){
      window.scrollTo(0,0)
    }

    this.props.history.push("/missions/" + this.props.match.params.missionTag);
    this.setState({
      about: true,
      finalist: false,
      winner: false
    });
  };

  // when click on Finalist
  clickOnFinalist = (pageScrollPosition) => {
    if(pageScrollPosition === false){
      window.scrollTo(0,0)
    }

    this.props.history.push(
      "/missions/" + this.props.match.params.missionTag + "/finale"
    );

    this.setState({
      about: false,
      finalist: true,
      winner: false
    });
  };

  // when click on winner tab
  clickOnWinner = (pageScrollPosition) => {
    this.tokenUpdate()

    if(pageScrollPosition === false){
      window.scrollTo(0,0)
    }
    this.props.history.push(
      "/missions/" + this.props.match.params.missionTag + "/winners"
    );

    this.setState({
      about: false,
      finalist: false,
      winner: true
    });
  };

  // get Most Voted Submissions
  getMostVotedSubmissions = () => {

    this.tokenUpdate()
    this.setState({
      mode: "top"
    });

    data = [];

    axios
      .get(get_mostVotedSubmissions + "missionId=" + mission_data.id)
      .then(res => {
        if (res.data.items) {
          data = res.data.items;
          this.setState({
            nextPageToken: res.data.nextPageToken
          });
        } else {
          this.setState({
            no_image: true
          });
        }

        if(isFinalist === true){
          this.getFinalistPosts()
        }
        
        
        this.forceUpdate();
      })
      .catch(error => {
        if (error.message.status) {
          if (error.response.status === 401) {
            firebase.auth().onAuthStateChanged(function(user) {
              if (user) {
                localStorage.setItem("user", user.ra);
              }
            });
            this.getMostVotedSubmissions();
          }
        }
      });
    
    this.setState({
      anchor: false,
    })
  };

  // get User Submissions
  getSubmissions = () => {
      this.tokenUpdate()

      this.setState({
        anchor: false,
        mode: "recent",
        srs:true
      });

      data = [];

      axios
      .get(get_submissions + "missionId=" + mission_data.id)
      .then(res => {
        if (res.data.items) {
          data = res.data.items;
          this.setState({
            nextPageToken: res.data.nextPageToken
          });
        } else {
          this.setState({
            no_image: true
          });
        }

        if(isFinalist === true){
          this.getFinalistPosts()
        }

      })
      .catch(error => {
        if (error.message.status) {
          if (error.response.status === 401) {
            firebase.auth().onAuthStateChanged(function(user) {
              if (user) {
                localStorage.setItem("user", user.ra);
              }
            });
            this.getSubmissions();
          }
        }
      });
  
  this.setState({
      anchor: false,
  })

};

getFinalistPosts = () => {
  axios
  .get(get_finalist + "?missionId=" + mission_data.id)
  .then(res => {
    finalist = res.data.items;
    finalistNPT = res.data.nextPageToken;
    isFinalist = true
    // for (var i = 0; i <= finalist.length; i++) {
    //   finalist.sort(function(a, b) {
    //     return b.likeCount - a.likeCount;
    //   });
    // }
    finalistCount = res.data.itemCount;

    axios
      .get(get_winners + "?missionId=" + mission_data.id)
      .then(res => {
        winners = res.data.items;
        isWinners = true
        this.forceUpdate();
        
      })
      .catch(error => {
        isWinners = false
        this.forceUpdate()
      });

    this.forceUpdate();
  })
  .catch(error => {
    isFinalist = false
    this.forceUpdate()
  });

this.forceUpdate();

}

  // Load More Finalist
  
  loadMoreFinalist = () => {

    this.setState({loading:true})

    axios
    .get(get_finalist + "?missionId=" + mission_data.id+'&nextPageToken='+finalistNPT)
    .then(res => {
      finalist = finalist.concat(res.data.items)
      finalistNPT = res.data.nextPageToken;
      isFinalist = true
      // for (var i = 0; i <= finalist.length; i++) {
      //   finalist.sort(function(a, b) {
      //     return b.likeCount - a.likeCount;
      //   });
      // }
      
      this.setState({loading:false})
      this.forceUpdate()
  })}

  // Load More Submissions
  loadMore = () => {

    this.setState({
      loading : true
    })
    if (this.state.mode === "recent") {
      this.loadMoreRecentPics();
    } else {
      this.loadMoreTopPics();
    }
  };

  // load more Recent Photos
  loadMoreRecentPics = () => {
    this.tokenUpdate()

    this.setState({loading:true})

    axios
      .get(
        get_submissions +
          "cursor=" +
          this.state.nextPageToken +
          "&limit=20&missionId=" +
          mission_data.id
      )
      .then(res => {
        if (res.data.items) {
          data = data.concat(res.data.items);

          this.setState({
            nextPageToken: res.data.nextPageToken
          });
        } else {
          this.setState({
            no_image: true
          });
          cogoToast.info("No More Images");
        }
        this.setState({
          loading : false
        })
        this.forceUpdate();
      })
      .catch(error => {
        if (error.message.status) {
          if (error.response.status === 401) {
            firebase.auth().onAuthStateChanged(function(user) {
              if (user) {
                localStorage.setItem("user", user.ra);
              }
            });
            this.setState({
              loading : false
            })
            this.loadMoreRecentPics();
          }
        }
      });

     
      this.forceUpdate()
  };

  // Load More Top Photos
  loadMoreTopPics = () => {
    this.tokenUpdate()
    this.setState({loading:true})
    axios
      .get(
        get_mostVotedSubmissions +
          "cursor=" +
          this.state.nextPageToken +
          "&limit=20&missionId=" +
          mission_data.id
      )
      .then(res => {
        if (res.data.items) {
          data = data.concat(res.data.items);

          this.setState({
            nextPageToken: res.data.nextPageToken
          });
        } else {
          this.setState({
            no_image: true
          });
          cogoToast.info("No More Images");
        }
        this.setState({
          loading : false
        })
        this.forceUpdate();
      })
      .catch(error => {
        if (error.message.status) {
          if (error.response.status === 401) {
            firebase.auth().onAuthStateChanged(function(user) {
              if (user) {
                localStorage.setItem("user", user.ra);
              }
            });
            
            this.loadMoreTopPics();
            this.setState({
              loading : false
            })
          }
        }
      });
 
      this.forceUpdate()
  };

  tokenUpdate=()=>{
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        localStorage.setItem("user", user.ra);
      }
    });
  }

  // reload Page
  refreshPage = () => {
    
    this.tokenUpdate()

    axios.get(paid_remaining_subs + mission_data.id).then(res => {
      paid = res.data.paymentDone;
      submissionsCount = res.data.submissionsCount;
      remaining_subs = res.data.submissionRemaining;
    
      this.setState({
        payment_dialog: false
      });
    
      this.forceUpdate();
    });
  };

  // Menu
  handle_menu = event => {
    this.setState({
      anchor: event.currentTarget
    });
  };

  startUpApi = (headers) => {
    axios
      .get(startup_call, {
        headers: headers,
      })
      .then((response) => {
        localStorage.setItem(
          "likedPostIds",
          JSON.stringify(response.data.likedPostIds)
        );
        localStorage.setItem(
          "myPostIds",
          JSON.stringify(response.data.myPostIds)
        );
        localStorage.setItem(
          "followingIds",
          JSON.stringify(response.data.followingIds)
        );
        localStorage.setItem(
          "followersIds",
          JSON.stringify(response.data.followersIds)
        );
        localStorage.setItem(
          "bookmarkedPostIds",
          JSON.stringify(response.data.bookmarkedPostIds)
        );
        localStorage.setItem(
          "reportedPostIds",
          JSON.stringify(response.data.reportedPostIds)
        );
      })
      .catch((error) => {
      });
  };

  interceptor = (acT) => {
    axios.interceptors.request.use(
      (config) => {
        const token = "Bearer " + acT;
        config.headers.Authorization = token;

        return config;
      },
      (error) => {
        // handle the error
        return Promise.reject(error);
      }
    );
  };
  
  render() {
    const countDown = this.state;


return (
    <>
      {
        this.state.metaAvailable === false ? '' :
            
          <Helmet>
                        <title>{metatags.properties['og:title']}</title>
                        <meta name="description" content={metatags.properties['og:description']}/>
                        <link rel="canonical" href='https://https://wallmag-2.appspot.com/_ah/api'/>

                      {/* Facebook Meta Tags */}
                        <meta property="og:title" content={metatags.properties['og:title']}/> 
                        <meta property="og:description" content={metatags.properties['og:description']}/>
                        <meta property="og:image" content={metatags.properties['og:image']}/>
                        <meta property="og:url" content={metatags.properties['og:url']}/>
                        <meta property="fb:page_id" content="439236523173004" />

                        {/* Twitter Meta Tags */}
                        <meta name="twitter:card" content={metatags.names['twitter:card']}/>
                        <meta name="twitter:title" content={metatags.names['twitter:title']}/>
                        <meta name="twitter:description" content={metatags.names['twitter:description']}/>
                        <meta name="twitter:image" content={metatags.names['twitter:image']}/> 

                </Helmet>
              }

        {
        window.innerWidth < 768 ? (
          render === true ?
            <div style={{ background: "black" }}>
                {/* Header of the page */}
          
              <MobHeader
                url={mission_data.coverUrl}
                heading={mission_data.hashTag}
                sub_heading={mission_data.title}
                logo={mission_data.headerLogo}
                share = {true}
                load = {this.state.load}
                openDialog={this.openDialog}

              />

            {
               
                JSON.parse(localStorage.getItem('current_user')) ? 
                JSON.parse(localStorage.getItem('current_user')).isMobileVerified === true ? ''
              :
                  submissionsCount === '0' ? 
                  ''
                  :
                  mission_data.finaleTime > mission_data.currentTime ? 
                <div className='verify-parent'>
                  <p                         
                    className="verify-mob-number"
                    onClick={() => this.setState({mob_verification_dialog_mob : true })}
                    >Verify your phone number to get contest updates</p>
               </div>
               :''
               :''
            }

          {/* Header ends Here */}

          {/* Body Part Starts from here */}
            <div className="container-fluid" style={{ marginTop: "32px",overflow:'hidden' }}>
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-4 col-sm-4 col-md-4">
                {this.state.about === true ? (

                  <div className='Nav_buttons_white_mb'>    
                      <p 
                          className="nav_text_black_mb"> Info</p>
                  </div>

                  ) : (

                  <div className='Nav_buttons_black_mb' onClick={e => this.clickOnAbout(true)}
                  >    
                  <p 
                      className="nav_text_white_mb"> Info</p>
                  </div>

                  )}
                </div>

                <div className="col-4 col-sm-4 col-md-4">
                {
                  this.state.finalist === true ? (
                      <div className='Nav_buttons_white_mb'
                      >    
                      <p 
                          className="nav_text_black_mb"> Finalist</p>
                  </div>
                  ) : (

                      <div className='Nav_buttons_black_mb' onClick={e => this.clickOnFinalist(true)}

                      >    
                      <p 
                          className="nav_text_white_mb"> Finalist</p>
                  </div>
                  )
                  }
                </div>

                <div className="col-4 col-sm-4 col-md-4">
                  {  this.state.winner === true ? (

                    <div className='Nav_buttons_white_mb'>    
                    <p 
                        className="nav_text_black_mb"> Winner</p>
                    </div>

                    ) : (

                    <div className='Nav_buttons_black_mb' onClick={e => this.clickOnWinner(true)}>    
                    <p 
                        
                        className="nav_text_white_mb"> Winner</p>
                    </div>
                    )}
                                    </div>
              </div>

              {this.state.about === true ? (
                <div style={{ position: "relative" }}>

{/* --------------------------- */}


{ 
    (this.addLeadingZeros(countDown.days) === '01' || this.addLeadingZeros(countDown.days) === '00') 
                            &&
                           parseInt(mission_data.finaleTime) > parseInt(mission_data.currentTime)
                           ?
    
                        <div
                          className="row"
                          style={{
                            justifyContent: "center",
                            marginTop: "51px",
                            border: "1px solid #ff6464",
                            padding: "10px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            background:'#d23d3d10',
                          }}
                        >


                          

                            
                              <div className='row'>
                                <p className="finale_time_white mr-2" style={{color:'#d23d3d',margin:'20px'}}>
                                    Ending In {' '}
                                
                            {this.addLeadingZeros(countDown.edays) === "00" ? (
                              <></>
                            ) : (
                              <>
                                
                                    {this.addLeadingZeros(countDown.edays)}
                              

                                
                                  <span> Days </span>
                                
                              </>
                            )}

                            {this.addLeadingZeros(countDown.ehours) === "00" ? (
                              <></>
                            ) : (
                              <>
                               
                                    {this.addLeadingZeros(countDown.ehours)}
                                

                                  <span> Hours </span>
                              </>
                            )}

                            {this.addLeadingZeros(countDown.emin) === "00" ? (
                              <></>
                            ) : (
                              <>
                                    {this.addLeadingZeros(countDown.emin)}

                                  <span> Minutes </span>
                              </>
                            )}

                                {this.addLeadingZeros(countDown.esec)}
                              <span> Seconds </span>
                            </p>
                          </div>

                           
                          {JSON.parse(localStorage.getItem('current_user')) ?
                                          paid === false ? 
                                          
                                          <p 
                                            className="finale_time_white " 
                                            onClick={e => this.openCheckout()}
                                            style={{cursor:'pointer',color:'#d23d3d',fontSize:'16px'}}>
                                              
                                                ( <u>Participate</u> )
                                          </p>
                                          
                                          :
                                          remaining_subs !== '0' ?
                                          <p 
                                            className="finale_time_white "
                                            onClick={e => this.goToUploadPage(e)} 
                                            style={{cursor:'pointer',color:'#d23d3d',fontSize:'16px'}}>
                                              
                                                ( <u>Upload Now</u> )
                                          </p>
                                          :
                                          '' 
                                      :
                                      <p 
                                        className="finale_time_white "
                                        onClick={e => this.openCheckout()} 
                                        style={{cursor:'pointer',color:'#d23d3d',fontSize:'16px'}}>
                                          
                                            ( <u>Participate</u> )
                                      </p>
  }
                                 
                          </div>

:''}

                  {/* ------------------------------ */}

                  {mission_data.prizeText ? 
                   mission_data.prizeText.length < 15 ? 
                  <></> 
                  : 
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      marginTop: "51px",
                      border: "1px solid #b8a33b",
                      padding: "15px",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                  >
                    <div>
                      <p className="about_v1" style={{textAlign:'left'}}>
                        {ReactHtmlParser(prizeText)}
                      </p>
                    </div>
                  </div>
              :
              <></>
              } 

          
              {this.state.video === false ? (
                    <></>
                  ) : (
                    <div
                      className="row"
                      style={{ justifyContent: "center", marginTop: "32px" }}
                    >
                      <div className='img-mission-vid'>
                      {this.state.videoOverlay ? 
                        <VideoPlayer 
                          thumbnail={mission_data.videoThumbnail} 
                          closePayment = {this.closePaymentDialog}
                          openPayment = {this.openPaymentDialog}
                          coverMovie = {mission_data.coverMovie}
                          videoOverlay={this.state.videoOverlay}
                        />
                      :
                        <VideoPlayer 
                          thumbnail ={mission_data.videoThumbnail} 
                          closePayment = {this.closePaymentDialog}
                          openPayment = {this.openPaymentDialog}
                          coverMovie = {mission_data.coverMovie}
                          videoOverlay={this.state.videoOverlay}
                        />
                      }
                  </div>
                </div>
                  )}
                  {/* ------------------------------ */}
              


{/* ------------------------------ */}




                        <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "101px",
                              display:'flex'
                            }}
                          >
                            <p className="quotation">
                             {mission_data.quotation}
                            </p>
                          </div>

{/* -------------------------------------------------------- */}
 
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop:'60px',
                              display:'flex'
                            }}
                          >
                            <p className="about_v1" style={{textAlign:'center', padding:'0px 10px'}}>
                              {ReactHtmlParser(description)}
                            </p>
                          </div> 


{/* ----------------------------------------------------------- */}



                  <div className='row justify-content-center' style={{marginTop:'60px'}}>

                  
                      <div className='col-12 col-sm-12  row justify-content-center'>
                        
                        
                        {
                          this.state.firstPoint === false ? '' : 
                          this.state.wtu === false ? 
                        
                        <div 
                          onClick={()=>this.setState({wtu : true})} 
                          className='Nav_buttons_black_v1' style={{width:'300px',marginTop:'33px'}}>    
                            <p 
                                className="nav_text_white" style={{fontSize:'14px'}}> {this.state.first}. What to upload?</p>
                        </div>
                        :
                        <div className='col-12 col-sm-12  row justify-content-center'>  
                          <div onClick={()=>this.setState({wtu : false})}  className='Nav_buttons_white_v1' style={{width:'300px',marginTop:'33px'}}>    
                              <p 
                                  className="nav_text_black" style={{fontSize:'14px'}}> {this.state.first}. What to upload?</p>
                          </div>

                          <div
                    className="row"
                    style={{ justifyContent: "center", marginTop: "26px" }}
                  >
                    <p className="about_v1" style={{ padding: "10px" }} >
                      {ReactHtmlParser(whatToUpload)}
                    </p>
                  </div>
                        </div>


                        } 
                        </div>
                        
                      {/* 2nd */}
                      
                      <div className='col-12 col-sm-12  row justify-content-center'>
                        {
                          this.state.secondPoint === false ? '' : 
                          this.state.watt === false ? 
                            <div className='row justify-content-center'>

                              <div onClick={()=>this.setState({watt:true})} className='Nav_buttons_black_v1' style={{width:'300px',marginTop:'33px'}}>    
                                  <p 
                                      className="nav_text_white"  style={{fontSize:'14px'}}> {this.state.second}. What are the timelines?</p>
                              </div>

                                {/* ------------------ */}
                                <div style={{display:'none'}}>
                                {mission_data.timelines ? (
                                  mission_data.timelines[0].title === "" ? (
                                    ""
                                  ) : (
                                    <MissionTime clr='white' data={mission_data} />
                                  )
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              
                              
                              {/* ------------------------------ */}
                                    </div>
                        :
                        <div className='col-12 col-sm-12  row justify-content-center'>
                          <div onClick={()=>this.setState({watt : false})}  className='Nav_buttons_white_v1' style={{width:'300px',marginTop:'33px'}}>    
                              <p 
                                  className="nav_text_black" style={{ fontSize:'14px'}}>  {this.state.second}. What are the timelines?</p>
                          </div>

                        
                        {/* ------------------ */}
                        <div>
                    {mission_data.timelines ? (
                      mission_data.timelines[0].title === "" ? (
                        ""
                      ) : (
                        <MissionTime clr='white' data={mission_data} />
                      )
                    ) : (
                      ""
                    )}{" "}
                  </div>
                  {/* ------------------------------ */}
                        </div>
                        } 
                        </div>
                        
                        {/* 3rd */}
                        
                           
                        <div className='col-12 col-sm-12  row justify-content-center'>
                        {
                          this.state.thirdPoint === false ? '' : 
                          this.state.mtj === false ? 
                        
                        <div onClick={()=>this.setState({mtj:true})} className='Nav_buttons_black_v1' style={{width:'300px',marginTop:'33px'}}>    
                            <p 
                                className="nav_text_white" style={{fontSize:'14px'}}>{this.state.third}. Meet the juries

                                </p>
                        </div>
                        :
                        <div className='col-12 col-sm-12  row justify-content-center'>
                          <div onClick={()=>this.setState({mtj : false})}  className='Nav_buttons_white_v1' style={{width:'300px',marginTop:'33px'}}>    
                              <p 
                                  className="nav_text_black" style={{fontSize:'14px'}}> {this.state.third}. Meet the juries

                                  </p>
                          </div>

                          <div
                    className="row"
                    style={{ marginTop: "0px", justifyContent: "center" }}
                  >
                    {mission_data.juries ? (
                      mission_data.juries.map((value, i) => {
                        if (value.userSlug === "") {
                          return ''
                        } else {
                          return (
                            <>
                              {/* --------------------------------- */}

                              {i === 0 ? (
                                <div
                                  className="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                  style={{
                                    justifyContent: "center",
                                    marginTop: "20px"
                                  }}
                                >
                                  <p id="issue1_desc"></p>
                                </div>
                              ) : (
                                <></>
                              )}
                              {/* ------------------------------ */}
                              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-4">
                                <a
                                  href={value.redirectLink}
                                  style={{
                                    textDecoration: "none",
                                    color: "black"
                                  }}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <img
                                    className="juPh"
                                    id="ju_image_category"
                                    src={value.user.photoUrl}
                                    alt="#juriesFashiongraphy1"
                                  />
                                </a>

                                <a
                                  href={value.redirectLink}
                                  style={{
                                    textDecoration: "none",
                                    color: "black"
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p style={{opacity:'1', marginTop:'24px'}} className="ju_name_white">
                                    {value.user.displayName}
                                  </p>
                                </a>

                                <a
                                  href={value.redirectLink}
                                  style={{
                                    textDecoration: "none",
                                    color: "black"
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="ju_desc_white">{value.bio}</p>
                                </a>
                              </div>
                            </>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )}
                  </div>
  </div>

                        } 
                        </div>
                        
                        {/* 4th */}
                       
                        {/* <div className='col-12 col-sm-12  row justify-content-center'>
                        {
                          this.state.srb === false ? 
                        
                        <div onClick={()=>this.showRanking_mob()} className='Nav_buttons_black_v1' style={{width:'300px',marginTop:'33px'}}>    
                            <p 
                                className="nav_text_white" style={{fontSize:'14px'}}>4. Show Ranking Board</p>
                        </div>
                        :

                        <div onClick={()=>this.setState({srb : false})}  className='Nav_buttons_white_v1' style={{width:'300px',marginTop:'33px'}}>    
                            <p 
                                className="nav_text_black" style={{fontSize:'14px'}}>4. Show Ranking Board</p>
                        </div>
                        } 
                        </div> */}

                        {/* 5th */}

                        
                        <div className='col-12 col-sm-12  row justify-content-center'>
                        {
                          this.state.forthPoint === false ? '' : 
                          this.state.sf === false ? 
                        
                        <div onClick={()=>this.setState({sf:true})} className='Nav_buttons_black_v1' style={{width:'300px',marginTop:'33px'}}>    
                            <p 
                                className="nav_text_white" style={{fontSize:'14px'}}> {this.state.forth}. Show FAQ</p>
                        </div>
                        :
                        <div className='col-12 col-sm-12  row justify-content-center'>

                          <div onClick={()=>this.setState({sf : false})}  className='Nav_buttons_white_v1' style={{width:'300px',marginTop:'33px'}}>    
                              <p 
                                  className="nav_text_black" style={{fontSize:'14px'}}> {this.state.forth}. Show FAQ</p>
                          </div>

                          <div className="container" style={{ marginTop: "40px" }}>
                    {sections.questions ? (
                      sections.questions.map((value, index) => {
                        return (
                          <div class style={{ textAlign: "start" }}>
                            <Collapsible
                              trigger={
                                <div className='row question mt-2' style={{background:'#2d2c2c'}}>
                                  <div className='col-10 col-sm-10 col-md-10'>
                                <p style={{color:'white'}}>{value.questionText}</p>
                                </div>
                                <div className='col-2 col-2 col-md-2'>
                                <img 
                                  src={require('../../logo/down-arrow.svg')} 
                                  alt='Down Arrow'
                                  style={{height:'20px', width:'20px', opacity:'.7'}} 
                                  />  
                                </div>
                              </div>
                              }
                            >
                              <p className="answer mt-4">
                            {/* {ReactHtmlParser(this.state.data.body.replace(/<img/g , `<img onclick="zoomImage(this.src)" class ='img-fluid' `))}  */}
                                {ReactHtmlParser(value.answerHtml.replace(/<p/g,'<p style=color:white;'))}
                              </p>
                            </Collapsible>
                          </div>
                        );
                      })
                    ) : (
                      <CircularProgress color='secondary' style={{marginTop:'30px'}} />
                    )}
                  </div>
  </div>

                        } 
                        </div>
                        


                        {/* 6th */}
                        
                        <div className='col-12 col-sm-12 row justify-content-center'>
                        {
                          this.state.fifthPoint === false? '' : 
                          this.state.srs === false ? 
                        
                        <div onClick={()=>this.setState({srs:true})} className='Nav_buttons_black_v1' style={{width:'300px',marginTop:'33px'}}>    
                            <p 
                                className="nav_text_white" style={{fontSize:'14px'}}>{
                                  this.state.mode === 'recent' ? 
                                  `${this.state.fifth}. Show Recent Submissions` :
                                  `${this.state.fifth}. Most Voted Submissions`
                                } </p>
                        </div>
                        :
                        <div className='col-12 col-sm-12  row justify-content-center'>

                          <div onClick={()=>this.setState({srs : false})}  className='Nav_buttons_white_v1' style={{width:'300px',marginTop:'33px'}}>    
                              <p 
                                  className="nav_text_black" style={{fontSize:'14px'}}> {
                                    this.state.mode === 'recent' ? 
                                    `${this.state.fifth}. Show Recent Submissions` :
                                    `${this.state.fifth}. Most Voted Submissions`
                                  }</p>
                          </div>

                      
                  
                  </div>

                        } 
                        </div>
                        

                      </div>


                      {this.state.srs === false ?'' :
                      
                      
                      data.length ? (
                        <div
                          style={{
                            justifyContent: "center",
                            marginTop: "40px",
                            marginLeft: "-14px",
                            marginRight: "-14px"
                          }}
                        >
                          <div
                            className="container"
                            style={{ marginBottom: "20px", marginTop: "-10px" }}
                          >
                            <div className="row justify-content-center">
                              <p
                                className="ju_name_white"
                                onClick={e => this.handle_menu(e)}
                                style={{ cursor: "pointer" }}
                              >
                                {this.state.mode === "recent"
                                  ? "Recent Photos"
                                  : "Most Voted Photos"}
                              </p>
                              <img
                                src={require("../../logo/down-arrow.svg")}
                                alt="more"
                                style={{
                                  opacity: "0.7",
                                  height: "11px",
                                  width: "11px",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  marginTop: "13px"
                                }}
                                onClick={e => this.handle_menu(e)}
                              />
    
                              <Menu
                                style={{ marginTop: "40px" }}
                                id="simple-menu"
                                anchorEl={this.state.anchor}
                                keepMounted
                                open={Boolean(this.state.anchor)}
                                onClose={e => this.closeMenu(e)}
                              >
                                <MenuItem onClick={e => this.getSubmissions()}>
                                  <p style={{color:'#000'}} className="ju_name_white">Recent Photos</p>
                                </MenuItem>
    
                                <MenuItem
                                  onClick={e => this.getMostVotedSubmissions()}
                                >
                                  <p style={{color:'#000'}} className="ju_name_white">Most Voted Photos</p>
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
    
                          <StackGrid
                            columnWidth={
                              window.innerWidth > 767
                                ? (window.innerWidth * 20) / 100
                                : (window.innerWidth * 45) / 100
                            }
                          >
                            {data.map((value, i) => {
                              dim.push({
                                width: (window.innerWidth * 50) / 100,
                                height:
                                  (value.imageProperties[0].originalHeight *
                                    ((window.innerWidth * 50) / 100)) /
                                  value.imageProperties[0].originalWidth
                              });
    
                              return (
                                <div id="outer-box">
                                  <div id="inner-box" className="container">
                                    {!ids.has(value.id) ? (
                                      <div
                                        className="row vote_button"
                                        onClick={e =>
                                          this.vote(e, value.id, true, i)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "15px",
                                          left: "30px",
                                          cursor: "pointer"
                                        }}
                                      >
                                        <img
                                          id="like"
                                          src={require("../../logo/like.svg")}
                                          alt="liked"
                                          style={{
                                            marginTop: "07px",
                                            height: "18px"
                                          }}
                                        />
                                        <p id="like-post">
                                        {
                                           value.shortMissionObject ? 
                                           value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                                 'Like' : 'Vote'
                                                         :
                                                     'Like' 
                                                   }
                                        </p>
                                      </div>
                                    ) : (
                                      <div
                                        className="row vote_button2"
                                        onClick={e =>
                                          this.vote(e, value.id, false, i)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "15px",
                                          left: "30px",
                                          cursor: "pointer"
                                        }}
                                      >
                                        <img
                                          id="like"
                                          src={require("../../logo/liked.svg")}
                                          alt="liked"
                                          style={{
                                            marginTop: "07px",
                                            height: "18px"
                                          }}
                                        />
                                        <p id="liked-post">
                                        {
                                           value.shortMissionObject ? 
                                           value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                                 'Liked' : 'Voted'
                                                         :
                                                     'Liked' 
                                                   }
                                        </p>
                                      </div>
                                    )}
                                  </div>
    
                                  {!ids.has(value.id) ? (
                                    <Img
                                      className="img-raised"
                                      alt="Post"
                                      onDragStart={e => this.onDragPhoto(e)}
                                      onDrag={e => this.onDragPhoto(e)}
                                      onContextMenu={e => this.clickRight(e)}
                                      src={[
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0].originalUrl
                                      ]}
                                      loader={
                                        <img
                                          alt="post"
                                          src={
                                            "https://placehold.it/" +
                                            `${parseInt(
                                              (window.innerWidth * 33) / 100
                                            )}` +
                                            "x" +
                                            parseInt(
                                              (value.imageProperties[0]
                                                .originalHeight *
                                                ((window.innerWidth * 33) / 100)) /
                                                value.imageProperties[0]
                                                  .originalWidth
                                            ) +
                                            "/00000090/00000090"
                                          }
                                          style={{
                                            padding: "5px",
                                            borderRadius: "8px",
                                            background: `#00000000`,
                                            cursor: "pointer"
                                          }}
                                        />
                                      }
                                      decode={false}
                                      onLoad={e => this.imageLoading()}
                                      style={{
                                        padding: "5px",
                                        borderRadius: "8px",
                                        background: `#00000000`,
                                        cursor: "pointer"
                                      }}
                                      onClick={evt =>
                                        this.handleClicks(
                                          this.state.like,
                                          value.id,
                                          value.imageProperties[0].medium
                                            ? value.imageProperties[0].medium
                                            : value.imageProperties[0].originalUrl,
                                          value.slug,
                                          data,
                                          i
                                        )
                                      }
                                    />
                                  ) : (
                                    <>
                                      <div
                                        className="row vote_button3"
                                        onClick={e =>
                                          this.vote(e, value.id, false, i)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "15px",
                                          left: "30px",
                                          cursor: "pointer"
                                        }}
                                      >
                                        <img
                                          id="like"
                                          src={require("../../logo/liked.svg")}
                                          alt="liked"
                                          style={{
                                            marginTop: "07px",
                                            height: "18px"
                                          }}
                                        />
                                      </div>
    
                                      <img
                                        className="img-raised"
                                        onDragStart={e => this.onDragPhoto(e)}
                                        onDrag={e => this.onDragPhoto(e)}
                                        onContextMenu={e => this.clickRight(e)}
                                        src={
                                          value.imageProperties[0].medium
                                            ? value.imageProperties[0].medium
                                            : value.imageProperties[0].originalUrl
                                        }
                                        alt="Post"
                                        onLoad={e => this.imageLoading()}
                                        style={{
                                          padding: "5px",
                                          borderRadius: "8px",
                                          background: `#00000000`,
                                          cursor: "pointer"
                                        }}
                                        onClick={evt =>
                                          this.handleClicks(
                                            this.state.like,
                                            value.id,
                                            value.imageProperties[0].medium
                                              ? value.imageProperties[0].medium
                                              : value.imageProperties[0]
                                                  .originalUrl,
                                            value.slug,
                                            data,
                                            i
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </StackGrid>
    
                          <div  className='row' style={{ marginTop: "60px", justifyContent:'center' }}>
                            {
                              this.state.loading === false ? 

                        <div 
                        onClick={()=>this.loadMore()} 
                        className='Nav_buttons_black_v1' style={{marginTop:'33px',display:'flex'}}>    
                          <p 
                              className="nav_text_white"> Load more images</p>
                        </div>
              :
              <CircularProgress color='secondary'/>
      }
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      

                  {/* ------------------------------ */}

                  
                  <div
                    className="row"
                    style={{ marginTop: "40px", justifyContent: "center", borderTop:'1px solid #ffffff36' }}
                  >
                    {mission_data.sponsors ? (
                      mission_data.sponsors.map((value, i) => {
                      
                          return (
                            <>
                              {i === 0 ? (
                                <div
                                  className="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                  style={{
                                    justifyContent: "center",
                                    marginTop: "40px",
                                    marginBottom:'40px',
                                  }}
                                >
                                  <p id="issue1_desc" style={{opacity:'0.8'}}>#Partners</p>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-4">
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "black"
                                  }}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={value.website}
                                >
                                  <img
                                    alt="jury_image"
                                    className="juPh"
                                    id="ju_image_category"
                                    src={value.logo}
                                  />
                                  <p style={{opacity:'1', marginTop:'24px'}} className="ju_name_white">{value.name}</p>
                                  <p className="ju_desc_white">{value.type}</p>
                                </a>
                              </div>
                            </>
                          );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                
            
                  {/* ================================ */}

     

                  
                  <div style={{ marginTop: "80px" }}>
                    <img src={require("../../logo/chatw.svg")} alt="chat_logo" style={{height:'40px', width:'40px'}}/>
                    <p className="about_" style={{ marginTop: "20px",color:'white' }}>
                      {" "}
                      Question or problem?
                    </p>
                    <p className="about__" style={{color:'white'}}>
                      {" "}
                      Contact our team from<b> <a href='https://tawk.to/chat/5dcbea87d96992700fc73ae3/default'
                            target='_blank' rel="noopener noreferrer">
                                                here</a></b>
                    </p>
                    <p className="about__" style={{color:'white'}}> and we’ll reply within 24 hours.</p>
                  </div>
                  
   

          {
            this.state.theposition < 0.05 ? 
            ''
            :
            this.state.ok === false ? (
                    <CircularProgress color='secondary'/>
                  ) : parseInt(mission_data.currentTime) <
                    parseInt(mission_data.startTime) ? (
                    <div
                      className="container row"
                      style={{
                        width: "100%",
                        placeContent: "center",
                        marginTop: "48px",
                        marginLeft:'0px',
                        marginRight:'0px'
                      }}
                    >
                      <div
                        className="container butt_red"
                        style={{ margin: "0px" }}
                      >
                        <div className="container">
                          <p id="pre2">Coming Soon</p>
                        </div>
                      </div>
                    </div>
                  ) : parseInt(mission_data.currentTime) <
                    parseInt(mission_data.finaleTime) ? (
                    paid === false ? (
                      mission_data.isPaidFreeBoth === false ? (
                        <div
                          className="row "
                          onClick={e => this.openCheckout()}
                          style={{
                            width: "100%",
                            placeContent: "center",
                            marginTop: "48px",
                            marginLeft:'0px',
                            marginRight:'0px'
                          }}
                        >
                          <div className="container butt_red ">
                            {/* <a onClick={this.openCheckout}> */}

                            {JSON.parse(
                              localStorage.getItem("current_user")
                            ) ? (
                              <div className="container">
                                <div className="row justify-content-center"  style={{margin:'0px'}}>
                                  <p id="pre2"> Participate</p>
                                  {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
                                </div>
                                <p id="inr2">
                                  {mission_data.participationFeesText}
                                </p>
                              </div>
                            ) : (
                              <div className="container">
                                <div className="row justify-content-center"  style={{margin:'0px'}}>
                                  <p id="pre2">Login to Participate</p>
                                  {/* <img className='arrow2' src={require('../../logo/right_arrow.svg')} /> */}
                                </div>
                              </div>
                            )}

                            {/* </a> */}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="row "
                          onClick={e => this.participateDialog()}
                          style={{
                            width: "100%",
                            placeContent: "center",
                            marginTop: "48px",
                            marginLeft:'0px',
                            marginRight:'0px'
                          }}
                        >
                          <div className="container butt_red ">
                            {/* <a onClick={this.openCheckout}> */}
                            <div className="row justify-content-center"  style={{margin:'0px'}}>
                              <p id="pre2"> Participate</p>
                              {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
                              {/* <p id='inr2'>{mission_data.participationFeesText}</p> */}
                            </div>
                            {/* </a> */}
                          </div>
                        </div>
                      )
                    ) : // when already Paid & No more Subs Remaining

                    remaining_subs !== "0" ? (
                      <div
                        className="row "
                        onClick={e => this.goToUploadPage(e)}
                        style={{
                          width: "100%",
                          placeContent: "center",
                          marginTop: "48px",
                          marginLeft:'0px',
                          marginRight:'0px'
                        }}
                      >
                        <div className="container butt_red ">
                          <div className="container">
                            <div className="row justify-content-center"  style={{margin:'0px'}}>
                              <p id="pre2"> Upload</p>
                              {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
                            </div>
                            <p id="inr2">
                              {remaining_subs} Submissions Remaining
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : 
                    (
                      //  View Submissions
                
                      <div
                        className="row "
                        onClick={e => this.openCheckoutSecond()}
                        style={{
                          width: "100%",
                          placeContent: "center",
                          cursor: "pointer",
                          margin: "48px 0px 0px 0px"
                        }}
                      >
                        <div className="container butt_red">
                          <div className="row justify-content-center" style={{margin:'0px'}}>
                            <p id="pre2">Purchase Extra Uploads</p>
                            </div>
                            <div className="row justify-content-center">
                            <p id="inr2">
                            INR {mission_data.extraPerImageCost * mission_data.extraImageUploadLimitation} for {mission_data.extraImageUploadLimitation} Pics 
                            </p>
                            {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
                          </div>
                        </div>
                      </div>
                    ))
                
                   : parseInt(mission_data.currentTime) <
                    parseInt(mission_data.endTime) ? (
                    <div
                      className="row"
                      onClick={e => this.clickOnFinalist(false)}
                      style={{
                        width: "100%",
                        placeContent: "center",
                        marginTop: "48px",
                        marginLeft:'0px',
                        marginRight:'0px'
                      }}
                    >
                      <div className="container butt_red ">
                        <div className="row justify-content-center"  style={{margin:'0px'}}>
                          <p id="pre2"> Vote For Finalist</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="row "
                      onClick={e => this.clickOnWinner(false)}
                      style={{
                        width: "100%",
                        placeContent: "center",
                        marginTop: "48px",
                        marginLeft:'0px',
                        marginRight:'0px'
                      }}
                    >
                      <div className="container butt_red ">
                        <div className="row justify-content-center" style={{margin:'0px'}}>
                          <p id="pre2">Follow Winners</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : this.state.finalist === true ? (
                parseInt(mission_data.currentTime) <
                parseInt(mission_data.finaleTime) ? (
                  <>
                    <br></br>
                    <br></br>

                    <img
                      src={require("../../logo/medal.svg")}
                      alt="medal"
                      style={{ height: "102px", width: "102px" }}
                    />
                    <br></br>
                    <br></br>
                    <br></br>

                    <p className="finalist_text_white">
                      Finalist Will Be Announced In :
                    </p>

                    <div>
                      {this.addLeadingZeros(countDown.days) === "00" ? (
                        <></>
                      ) : (
                        <>
                          <p className="finale_time_white">
                            <strong>
                              {this.addLeadingZeros(countDown.days)}
                            </strong>
                          </p>

                          <p className="finale_text_white">
                            <span> Days </span>
                          </p>
                        </>
                      )}

                      {this.addLeadingZeros(countDown.hours) === "00" ? (
                        <></>
                      ) : (
                        <>
                          <p className="finale_time_white">
                            <strong>
                              {this.addLeadingZeros(countDown.hours)}
                            </strong>
                          </p>

                          <p className="finale_text_white">
                            <span> Hours </span>
                          </p>
                        </>
                      )}

                      {this.addLeadingZeros(countDown.min) === "00" ? (
                        <></>
                      ) : (
                        <>
                          <p className="finale_time_white">
                            <strong>
                              {this.addLeadingZeros(countDown.min)}
                            </strong>
                          </p>

                          <p className="finale_text_white">
                            <span> Minutes </span>
                          </p>
                        </>
                      )}

                      <p className="finale_time_white">
                        <strong>{this.addLeadingZeros(countDown.sec)}</strong>
                      </p>

                      <p className="finale_text_white">
                        <span> Seconds </span>
                      </p>
                    </div>

                    <br></br>
                    <p className="finale_time_white">
                      <strong>
                        {String(
                          new Date(parseInt(mission_data.finaleTime))
                        ).slice(0, 15)}
                      </strong>
                    </p>
                  </>
                ) : finalist ? finalist.length ? (
                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: "60px",
                      marginLeft: "-14px",
                      marginRight: "-14px"
                    }}
                  >
                    <div className="container">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                          marginTop: "51px",
                          border: "1px solid #b8a33b",
                          padding: "15px",
                          marginLeft: "10px",
                          marginRight: "10px"
                        }}
                      >
                        <div>
                          <p className="about_v1"  style={{textAlign:'left'}}>
                            {ReactHtmlParser(prizeText)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <p className="winners mt-4">Finalist</p>

                    {/* <Columns  queries={queries} dimensions={dim}>           */}
                    <StackGrid
                      columnWidth={
                        window.innerWidth > 767
                          ? (window.innerWidth * 20) / 100
                          : (window.innerWidth * 45) / 100
                      }
                    >
                      {finalist.map((value, i) => {
                        dim.push({
                          width: (window.innerWidth * 50) / 100,
                          height:
                            (value.imageProperties[0].originalHeight *
                              ((window.innerWidth * 50) / 100)) /
                            value.imageProperties[0].originalWidth
                        });

                        return (
                          <div id="outer-box">
                            <div id="inner-box" className="container">
                              {!ids.has(value.id) ? (
                                <div
                                  className="row vote_button"
                                  onClick={e => this.vote(e, value.id, true, i)}
                                  style={{
                                    position: "absolute",
                                    top: "15px",
                                    left: "30px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    id="like"
                                    src={require("../../logo/like.svg")}
                                    alt="liked"
                                    style={{
                                      marginTop: "07px",
                                      height: "18px"
                                    }}
                                  />
                                  <p id="like-post">
                                  {
                                       value.shortMissionObject ? 
                                       value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                             'Like' : 'Vote'
                                                     :
                                                 'Like' 
                                               }
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className="row vote_button2"
                                  onClick={e =>
                                    this.vote(e, value.id, false, i)
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "15px",
                                    left: "30px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    id="like"
                                    src={require("../../logo/liked.svg")}
                                    alt="liked"
                                    style={{
                                      marginTop: "07px",
                                      height: "18px"
                                    }}
                                  />
                                  <p id="liked-post">
                                  {
                                       value.shortMissionObject ? 
                                       value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                             'Liked' : 'Voted'
                                                     :
                                                 'Liked' 
                                               }
                                  </p>
                                </div>
                              )}
                            </div>

                            {!ids.has(value.id) ? (
                              <Img
                                className="img-raised"
                                alt="Post"
                                onDragStart={e => this.onDragPhoto(e)}
                                onDrag={e => this.onDragPhoto(e)}
                                onContextMenu={e => this.clickRight(e)}
                                src={[
                                  value.imageProperties[0].medium
                                    ? value.imageProperties[0].medium
                                    : value.imageProperties[0].originalUrl
                                ]}
                                loader={
                                  <img
                                    alt="medal"
                                    src={
                                      "https://placehold.it/" +
                                      `${parseInt(
                                        (window.innerWidth * 33) / 100
                                      )}` +
                                      "x" +
                                      parseInt(
                                        (value.imageProperties[0]
                                          .originalHeight *
                                          ((window.innerWidth * 33) / 100)) /
                                          value.imageProperties[0].originalWidth
                                      ) +
                                      "/00000090/00000090"
                                    }
                                    style={{
                                      padding: "5px",
                                      borderRadius: "8px",
                                      background: `#00000000`,
                                      cursor: "pointer"
                                    }}
                                  />
                                }
                                decode={false}
                                onLoad={e => this.imageLoading()}
                                style={{
                                  padding: "5px",
                                  borderRadius: "8px",
                                  background: `#00000000`,
                                  cursor: "pointer"
                                }}
                                onClick={evt =>
                                  this.handleClicks(
                                    this.state.like,
                                    value.id,
                                    value.imageProperties[0].medium
                                      ? value.imageProperties[0].medium
                                      : value.imageProperties[0].originalUrl,
                                    value.slug,
                                    finalist,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <>
                                <div
                                  className="row vote_button3"
                                  onClick={e =>
                                    this.vote(e, value.id, false, i)
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "15px",
                                    left: "30px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    id="like"
                                    src={require("../../logo/liked.svg")}
                                    alt="liked"
                                    style={{
                                      marginTop: "07px",
                                      height: "18px"
                                    }}
                                  />
                                </div>

                                <img
                                  className="img-raised"
                                  onDragStart={e => this.onDragPhoto(e)}
                                  onDrag={e => this.onDragPhoto(e)}
                                  onContextMenu={e => this.clickRight(e)}
                                  src={
                                    value.imageProperties[0].medium
                                      ? value.imageProperties[0].medium
                                      : value.imageProperties[0].originalUrl
                                  }
                                  alt="Post"
                                  onLoad={e => this.imageLoading()}
                                  style={{
                                    padding: "5px",
                                    borderRadius: "8px",
                                    background: `#00000000`,
                                    cursor: "pointer"
                                  }}
                                  onClick={evt =>
                                    this.handleClicks(
                                      this.state.like,
                                      value.id,
                                      value.imageProperties[0].medium
                                        ? value.imageProperties[0].medium
                                        : value.imageProperties[0].originalUrl,
                                      value.slug,
                                      finalist,
                                      i
                                    )
                                  }
                                />
                              </>
                            )}
                          </div>
                        );
                      })}
                    </StackGrid>
                  
                    <div className='row' style={{ marginTop: "60px", justifyContent:'center' }}>

                    {
                          this.state.loading === false ? 

                        <div 
                        onClick={()=>this.loadMore()} 
                        className='Nav_buttons_black_v1' style={{marginTop:'33px',display:'flex'}}>    
                          <p 
                              className="nav_text_white"> Load more images</p>
                      </div>
          
          :
          <CircularProgress color='secondary'/>
  }
  </div>
                  
                  
                  </div>
                ) : (
                  isFinalist === true ?
                  <CircularProgress color="secondary" />
                  :
                  <div style={{marginTop:'98px'}}>
                  <img
                     src={require("../../logo/medal.svg")}
                     alt="medal"
                     style={{ height: "102px", width: "102px", marginTop:'30px' }}
                   />

                   <p className="finalist_text" style={{color:'white', marginTop:'42px'}} >
                     We are selecting the finalist , result will be declared soon within 2 to 3 days
                   </p>
               </div>
                )
                :
                <div style={{marginTop:'98px'}}>
                   <img
                      src={require("../../logo/medal.svg")}
                      alt="medal"
                      style={{ height: "102px", width: "102px", marginTop:'30px' }}
                    />

                    <p className="finalist_text "  style={{color:'white', marginTop:'42px'}}>
                      We are selecting the finalist , result will be declared soon within 2 to 3 days
                    </p>
                </div>
              ) : (
                <>
                  {parseInt(mission_data.currentTime) <
                  parseInt(mission_data.endTime) ? (
                    <>
                      <br></br>
                      <br></br>

                      <img
                        src={require("../../logo/icon-awards.svg")}
                        alt="medal"
                        style={{ height: "102px", width: "102px" }}
                      />
                      <br></br>
                      <br></br>
                      <br></br>

                      <p className="finale_text_white">
                        Winners Will Be Announced In :
                      </p>

                      <div>
                        {this.addLeadingZeros(countDown.edays) === "00" ? (
                          <></>
                        ) : (
                          <>
                            <p className="finale_time_white">
                              <strong>
                                {this.addLeadingZeros(countDown.edays)}
                              </strong>
                            </p>

                            <p className="finale_text_white">
                              <span> Days </span>
                            </p>
                          </>
                        )}

                        {this.addLeadingZeros(countDown.ehours) === "00" ? (
                          <></>
                        ) : (
                          <>
                            <p className="finale_time_white">
                              <strong>
                                {this.addLeadingZeros(countDown.ehours)}
                              </strong>
                            </p>

                            <p className="finale_text_white">
                              <span> Hours </span>
                            </p>
                          </>
                        )}

                        {this.addLeadingZeros(countDown.emin) === "00" ? (
                          <></>
                        ) : (
                          <>
                            <p className="finale_time_white">
                              <strong>
                                {this.addLeadingZeros(countDown.emin)}
                              </strong>
                            </p>

                            <p className="finale_text_white">
                              <span> Minutes </span>
                            </p>
                          </>
                        )}

                        <p className="finale_time_white">
                          <strong>
                            {this.addLeadingZeros(countDown.esec)}
                          </strong>
                        </p>
                        <p className="finale_text_white">
                          <span> Seconds </span>
                        </p>
                      </div>

                      <br></br>
                      <p className="finale_time_white">
                        <strong>
                          {String(
                            new Date(parseInt(mission_data.endTime))
                          ).slice(0, 15)}
                        </strong>
                      </p>
                    </>
                  ) : winners ? winners.length ? (
                    <div
                      style={{
                        justifyContent: "center",
                        marginTop: "60px"
                      }}
                    >
                      {/* ------------------------------ */}
                      <div className="container-fluid">
                        <div
                           className="container"
                           style={{
                             justifyContent: "center",
                             marginTop: "51px",
                             border: "1px solid #b8a33b",
                             padding: "15px",
                             marginLeft: "10px",
                             marginRight: "10px"
                           }}
                        >
                          <div>
                            <p className="about_v1" style={{textAlign:'left'}}> 
                              {ReactHtmlParser(prizeText)}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* ------------------------------ */}
                      <div className="container">
                        <div className="row">
                          {winners.map((value, i) => {
                            return (
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                <p
                                  style={{ marginTop: "100px",color:'#ffffff00' }}
                                  className="winners"
                                >
                                  {i === 0
                                    ? "1st Winner"
                                    : i === 1
                                    ? "2nd Winner"
                                    : "3rd Winner"}
                                </p>
                                <img
                                  onClick={() =>
                                    this.goToProfile(value.shortUserObject.slug)
                                  }
                                  alt="user"
                                  height="80px"
                                  width="80px"
                                  style={{
                                    borderRadius: "180px",
                                    objectFit: "cover",
                                    marginTop: "30px",
                                    cursor: "pointer",
                                    border:'1px solid #ffffff50'
                                  }}
                                  src={value.shortUserObject.profilePicture}
                                  onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                  }}
                                />
                                <p
                                  style={{ cursor: "pointer" ,color:'white' }}
                                  onClick={() =>
                                    this.goToProfile(value.shortUserObject.slug)
                                  }
                                  className="winners_name mt-3"
                                >
                                  {value.shortUserObject.name}
                                </p>
                                <p
                                  style={{ cursor: "pointer" ,color:'white' }}
                                  onClick={() =>
                                    this.goToProfile(value.shortUserObject.slug)
                                  }
                                  className="votes"
                                >
                                  {value.likeCount} 
                                  {
                                       value.shortMissionObject ? 
                                       value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                             ' Likes' : ' Votes'
                                                     :
                                                 ' Likes' 
                                               }
                                </p>

                                <div style={{ marginTop: "40px" }}>
                                  {value.title.length === 0 ? (
                                    ""
                                  ) : (
                                    <p className="winner_title_desc" style={{color:'white'}}>
                                      <b>Title :</b> {value.title}
                                    </p>
                                  )}
                                  {value.description.length === 0 ? (
                                    ""
                                  ) : (
                                    <p className="winner_title_desc" style={{color:'white'}}>
                                      <b>Story Behind :</b> {value.description}
                                    </p>
                                  )}
                                </div>

                                <img
                                  style={{ marginTop: "8px" }}
                                  className="img-fluid"
                                  src={
                                    value.imageProperties[0].large
                                      ? value.imageProperties[0].large
                                      : value.imageProperties[0].originalUrl
                                  }
                                  alt="winner"
                                  onClick={evt =>
                                    this.handleClicks(
                                      this.state.like,
                                      value.id,
                                      value.imageProperties[0].medium
                                        ? value.imageProperties[0].medium
                                        : value.imageProperties[0].originalUrl,
                                      value.slug,
                                      winners,
                                      i
                                    )
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    isWinners === true ?
                    <CircularProgress color="secondary" />
                     :
                     <div style={{marginTop:'98px'}}>
                   <img
                      src={require("../../logo/icon-awards.svg")}
                      alt="medal"
                      style={{ height: "102px", width: "102px", marginTop:'30px' }}
                    />

                    <p className="finalist_text"  style={{color:'white', marginTop:'42px'}} >
                      We are selecting the winner , Result will be declared soon within 2 to 3 days
                    </p>
                </div>
                  )
                  :
                  <div style={{marginTop:'98px'}}>
                   <img
                      src={require("../../logo/icon-awards.svg")}
                      alt="medal"
                      style={{ height: "102px", width: "102px", marginTop:'30px' }}
                    />

                    <p className="finalist_text "  style={{color:'white', marginTop:'42px'}} >
                      We are selecting the winner , Result will be declared soon within 2 to 3 days
                    </p>
                </div>
                  
                  }
                </>
              )}
            </div>
            {/* Body part ends here */}

            {/* Footer here */}

            {JSON.parse(localStorage.getItem("current_user")) ? 
            JSON.parse(localStorage.getItem("current_user")).organization ? 
                JSON.parse(localStorage.getItem("current_user")).organization === '' ? 
             
            <div
              style={{ marginTop: "160px", position: "sticky", zIndex: "11" }}
            >
              <FooterWallmag />
            </div>
            :
            ''
            :
            <div
            style={{ marginTop: "160px", position: "sticky", zIndex: "11" }}
          >
            <FooterWallmag />
          </div>
          :
          <div
          style={{ marginTop: "160px", position: "sticky", zIndex: "11" }}
        >
          <FooterWallmag />
        </div>
  }
            {/* ---------------------  */}
          </div>
        :'') : (
          // for large scrrens (greater than 767)
          render === true ?
          <>
            {mission_data.title ? (
              <div style={{ background: "black" }}>
                {/* Header of the page */}
             
                <Header
                  url={mission_data.coverUrl}
                  heading={mission_data.hashTag}
                  sub_heading={mission_data.title}
                  logo={mission_data.headerLogo}
                  share = {true}
                  openDialog={this.openDialog}
                />

                {
                  JSON.parse(localStorage.getItem('current_user')) ? 
                  JSON.parse(localStorage.getItem('current_user')).isMobileVerified === true ? ''
                :
                  submissionsCount === '0' ? 
                  ''
                :
                <div className='verify-parent'>
                  <p                         
                    className="verify-mob-number"
                    onClick={() => this.setState({mob_verification_dialog : true })}>
                      Verify your phone number to get contest updates
                  </p>
              </div>
                :''  
              }
                {/* Header ends Here */}

                {/* Body Part Starts from here */}
                <div className="container-fluid" style={{ marginTop: "72px" }}>
                  <div className="row" style={{ justifyContent: "center" }}>
                    {this.state.about === true ? (

                        <div className='Nav_buttons_white'>    
                            <p 
                                className="nav_text_black"> Info</p>
                        </div>

                    ) : (

                        <div className='Nav_buttons_black' onClick={e => this.clickOnAbout(true)}>    
                          <p 
                            className="nav_text_white"> Info</p>
                    </div>

                    )}

                    {

                    this.state.finalist === true ? (
                        <div className='Nav_buttons_white' style={{ marginRight: "20px", marginLeft: "20px" }}
                        >    
                        <p 
                            className="nav_text_black"> Finalist</p>
                    </div>


                    ) : (

                        <div className='Nav_buttons_black' onClick={e => this.clickOnFinalist(true)}  style={{ marginRight: "20px", marginLeft: "20px" }}
                        >    
                        <p 
                            className="nav_text_white"> Finalist</p>
                    </div>
                    )
                    }
                    {

                    this.state.winner === true ? (

                        <div className='Nav_buttons_white'>    
                        <p 
                            className="nav_text_black"> Winner</p>
                    </div>
 
                    ) : (

                        <div className='Nav_buttons_black' onClick={e => this.clickOnWinner(true)}>    
                        <p 
                            
                            className="nav_text_white"> Winner</p>
                        </div>
                    )
                    }
                  </div>

                    {this.state.about === true ? (
                        <div
                        className="container-fluid"
                        style={{ position: "relative" }}
                        >
                       
                      <div className="container">
                        {/* ------------------------------ */}

                        {
                          (this.addLeadingZeros(countDown.days) === '01' || this.addLeadingZeros(countDown.days) === '00') 
                            &&
                           parseInt(mission_data.finaleTime) > parseInt(mission_data.currentTime)
                        ?
                        <div
                          className="row"
                          style={{
                            justifyContent: "center",
                            marginTop: "51px",
                            // border: "1px solid #ff6464",
                            padding: "15px",
                            background:'#ff646410'
                          }}
                        >

                    <div className='row' style={{display:'flex'}}>
                      <p className="finale_time_white mr-2" style={{color:'#d23d3d',fontSize:'16px', margin:'auto'}}>
                          Ending In {' '}
                      
                  {this.addLeadingZeros(countDown.days) === "00" ? (
                    <></>
                  ) : (
                    <>
                          {this.addLeadingZeros(countDown.days)}
                        <span> Days </span>
                    </>
                  )}

                  {this.addLeadingZeros(countDown.hours) === "00" ? (
                    <></>
                  ) : (
                    <>
                          {this.addLeadingZeros(countDown.hours)}
                        <span> Hours </span>
                    </>
                  )}

                  {this.addLeadingZeros(countDown.min) === "00" ? (
                    <></>
                  ) : (
                    <>
                          {this.addLeadingZeros(countDown.min)}
                          <span> Minutes </span>
                    </>
                  )}

                      {this.addLeadingZeros(countDown.sec)}
                    <span> Seconds </span>
                  </p>
                </div>

                {
                                      JSON.parse(localStorage.getItem('current_user')) ?
                                          paid === false ? 
                                          
                                          <p 
                                            className="finale_text_white " 
                                            onClick={e => this.openCheckout()}
                                            style={{cursor:'pointer',color:'#d23d3d',fontSize:'16px'}}>
                                              
                                                ( <u>Participate</u> )
                                          </p>
                                          
                                          :
                                          remaining_subs !== '0' ?
                                          <p 
                                            className="finale_text_white "
                                            onClick={e => this.goToUploadPage(e)} 
                                            style={{cursor:'pointer',color:'#d23d3d',fontSize:'16px'}}>
                                              
                                                ( <u>Upload Now</u> )
                                          </p>
                                          :
                                          '' 
                                      :
                                      <p 
                                        className="finale_text_white "
                                        onClick={e => this.openCheckout()} 
                                        style={{cursor:'pointer',color:'#d23d3d',fontSize:'16px'}}>
                                          
                                            ( <u>Participate</u> )
                                      </p>
                                    }
                          

                          </div>
                        :
                        ''
                            }


                        {mission_data.prizeText ? 
                               mission_data.prizeText.length < 15 ? <></> : 
                   
                   <div
                          className="row"
                          style={{
                            justifyContent: "center",
                            marginTop: "51px",
                            border: "1px solid #b8a33b",
                            padding: "45px"
                          }}
                        >
                          <div style={{ marginLeft: "40px" }}>
                            <p className="about_v1" style={{textAlign:'left'}}>
                            {ReactHtmlParser(prizeText)}
                              {/* {ReactHtmlParser(mission_data.prizeText)} */}
                            </p>
                          </div>
                        </div>
                        
   :
   <></>                     
                        
  }
   {mission_data.coverMovie === "" ? (
                          <></>
                        ) : (
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "90px",
                              position : 'relative'
                            }}
                          >

                            {
                              this.state.videoOverlay ? 
                              <VideoPlayer 
                                thumbnail={mission_data.videoThumbnail} 
                                closePayment = {this.closePaymentDialog}
                                openPayment = {this.openPaymentDialog}
                                coverMovie = {mission_data.coverMovie}
                                videoOverlay={this.state.videoOverlay}/>

                              :

                              <VideoPlayer 
                              thumbnail ={mission_data.videoThumbnail} 
                              closePayment = {this.closePaymentDialog}
                              openPayment = {this.openPaymentDialog}
                              coverMovie = {mission_data.coverMovie}
                              videoOverlay={this.state.videoOverlay}/>

                            }
                           
                          
                           

                           
                          
                          </div>
                        )}

{/* ------------------------------ */}




                        <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "101px",
                              display:'flex'
                            }}
                          >
                            <p className="quotation">
                              {mission_data.quotation}
                            </p>
                          </div>

{/* -------------------------------------------------------- */}
 
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop:'60px',
                              display:'flex'
                            }}
                          >
                            <p className="about_v1" style={{textAlign:'center', padding:'0px 80px'}}>
                              {ReactHtmlParser(description)}
                            </p>
                          </div> 


{/* ----------------------------------------------------------- */}

{/*  */}

          <div className='row justify-content-center' style={{marginTop:'28px'}}>

            {/* 1st */}
            
              <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                        {
                          this.state.firstPoint === false ? '' : 
                          this.state.wtu === false ? 
                        
                        <div 
                          onClick={()=>this.setState({wtu : true})} 
                          className='Nav_buttons_black_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                            <p 
                                className="nav_text_white"> {this.state.first}. What to upload?</p>
                        </div>
                        :
                        <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                        <div onClick={()=>this.setState({wtu : false})}  className='Nav_buttons_white_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                            <p 
                                  className="nav_text_black" >  {this.state.first}. What to upload?</p>
                          </div>

                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "61px"
                            }}
                          >
                            <p className="about_v1">
                              {ReactHtmlParser(whatToUpload)}
                            </p>
                          </div>
                        </div>


                        } 
                        </div>
            

              {/* 2nd */}

              
              <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                {
                  this.state.secondPoint === false ? '' : 
                  this.state.watt === false ? 
                  <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>

                <div onClick={()=>this.setState({watt:true})} className='Nav_buttons_black_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                    <p 
                        className="nav_text_white">  {this.state.second}. What are the timelines?</p>
                </div>

                   <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      marginTop: "80px",
                      display:'none'
                    }}
                    >
                    <div>
                      {mission_data.timelines ? (
                        mission_data.timelines[0].title === "" ? (
                          ""
                        ) : (
                            <MissionTime clr='white' data={mission_data} />
                        )
                      ) : (
                        <CircularProgress color='secondary' style={{marginTop:'20px'}}/>

                      )}
                    </div>
                  </div>
                
                </div>
                :
                <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                  <div onClick={()=>this.setState({watt : false})}  className='Nav_buttons_white_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                      <p 
                          className="nav_text_black" >   {this.state.second}. What are the timelines?</p>
                  </div>

                
                {/* ------------------ */}

                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                    marginTop: "80px"
                  }}
                >
                  <div>
                    
                    {mission_data.timelines ? (
                      mission_data.timelines[0].title === "" ? (
                        ""
                      ) : ( <MissionTime clr='white' data={mission_data} />
                      )
                    ) : (
                      <CircularProgress color='secondary' style={{marginTop:'20px'}}/>

                    )}
                  </div>
                </div>

                

                {/* ---------------------------- */}
                </div>
                } 
                </div>
              

              {/* 3rd */}
              
              <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                        {
                          this.state.thirdPoint === false ? '' : 
                          this.state.mtj === false ? 
                        
                        <div onClick={e=>this.setState({mtj:true})} className='Nav_buttons_black_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                            <p 
                                className="nav_text_white"> {this.state.third}. Meet the juries

                                </p>
                        </div>
                        :
                        <div className='col-md-12 col-lg-12 col-xl-12 '>
                          <div className='row justify-content-center'>
                          <div onClick={()=>this.setState({mtj : false})}  className='Nav_buttons_white_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                              <p 
                                  className="nav_text_black">  {this.state.third}. Meet the juries

                                  </p>
                          </div>
                          </div>

                          <div
                          className="row"
                          style={{
                            justifyContent: "center"
                          }}
                          >
                          {mission_data.juries ? (
                            mission_data.juries.map((value, i) => {
                              if (value.userSlug === "") {
                                return ''
                              } else {
                                return (
                                  <>
                                    {/* --------------------------------- */}

                                    {i === 0 ? (
                                      <div
                                        className="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                        style={{
                                          justifyContent: "center",
                                          marginTop: "40px"
                                        }}
                                      >
                                        <p id="issue1_desc"></p>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {/* ------------------------------ */}
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-4">
                                      <a
                                        href={value.redirectLink}
                                        
                                        style={{
                                          textDecoration: "none",
                                          color: "black"
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          className="juPh"
                                          id="ju_image_category_ju"
                                          src={value.user.photoUrl}
                                          alt="#juriesFashiongraphy1"
                                        />
                                      </a>

                                      <a
                                        href={value.redirectLink}
                                        style={{
                                          textDecoration: "none",
                                          color: "black"
                                        }}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        <p style={{opacity:'1', marginTop:'24px'}} className="ju_name_white">
                                          {value.user.displayName}
                                        </p>
                                      </a>

                                      <a
                                        href={value.redirectLink}
                                        style={{
                                          textDecoration: "none",
                                          color: "black"
                                        }}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        <p className="ju_desc_white">{value.bio}</p>
                                      </a>
                                    </div>
                                  </>
                                );
                              }
                            })
                          ) : (
                            <></>
                          )}
                          </div>
                        </div>

                        } 
                        </div>
              
              {/* 4th */}
              
              {/* <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                        {
                          this.state.srb === false ? 
                        
                        <div onClick={()=>this.showRanking()} className='Nav_buttons_black_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                            <p 
                                className="nav_text_white">4. Show Ranking Board</p>
                        </div>
                        :

                        <div onClick={()=>this.setState({srb : false})}  className='Nav_buttons_white_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                            
                            
                             <p 
                                className="nav_text_black" >4. Show Ranking Board</p>
                        </div>
                        } 
                        </div> */}

              {/* 5th */}

              
                <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                        {
                          this.state.forthPoint === false ? '' : 
                          this.state.sf === false ? 
                        
                        <div onClick={()=>this.setState({sf:true})} className='Nav_buttons_black_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                            <p 
                                className="nav_text_white">  {this.state.forth}. Show FAQ</p>
                        </div>
                        :
                        <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>

                          <div onClick={()=>this.setState({sf : false})}  className='Nav_buttons_white_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                              <p 
                                  className="nav_text_black">  {this.state.forth}. Show FAQ</p>
                          </div>

                          <div className="container" style={{ marginTop: "60px" }}>
                        {sections.questions ? (
                          sections.questions.map((value, index) => {
                            return (
                              <div class style={{ textAlign: "start" }}>
                                <Collapsible
                                  trigger={
                                    <div className='row question mt-2 ' style={{background:'#2d2c2c'}}>
                                      <div className='col-11 col-sm-11 col-md-11'>
                                        <p style={{color:'white',margin:'0px',padding:'5px 0px'}}>{value.questionText}</p>
                                      </div>
                                   
                                   <div className='col-1 col-sm-1 col-md-1'>
                                    <img 
                                      src={require('../../logo/down-arrow.svg')} 
                                      alt='Down Arrow'
                                      style={{height:'20px', width:'20px', opacity:'.7',margin:'0px',textAlign:'end',verticalAlign:'-webkit-baseline-middle'}} 
                                      />  
                                </div>
                                
                              </div>
                                  }
                                >
                                  <p style={{marginTop:'20px'}} className="answer">
                                  {ReactHtmlParser(value.answerHtml.replace(/<p/g,'<p style=color:white;'))}
                                  </p>
                                </Collapsible>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        </div>
                        </div>

                        } 
                        </div>
              
              {/* 6th */}
              
                <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>
                        {
                         this.state.fifthPoint === false ? '' : 
                          this.state.srs === false ? 
                        
                        <div onClick={()=>this.setState({srs:true})} className='Nav_buttons_black_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                            <p 
                                className="nav_text_white">{
                                  this.state.mode === 'recent' ? 
                                  `${this.state.fifth}. Show Recent Submissions` :
                                  `${this.state.fifth}. Most Voted Submissions`
                                }</p>
                        </div>
                        :
                        <div className='col-md-12 col-lg-12 col-xl-12 row justify-content-center'>

                          <div onClick={()=>this.setState({srs : false})}  className='Nav_buttons_white_v1' style={{width:'500px',marginTop:'33px',display:'flex'}}>    
                              <p 
                                  className="nav_text_black" > {
                                    this.state.mode === 'recent' ? 
                                    `${this.state.fifth}. Show Recent Submissions` :
                                    `${this.state.fifth}. Most Voted Submissions`
                                  }</p>
                          </div>

                        </div>

                        } 
                        </div>
                 
             </div>
{/*  */}
     
     </div>


{/* Recent Submissions -- Starts Here */}
     {
        this.state.srs === false ? '' :

                                                  <div
                                                    className="container-fluid"
                                                    style={{ marginTop: "80px" }}
                                                  >
                                                {data.length ? (
                                                  <div style={{ justifyContent: "center" }}>
                                                    {/* <p className="Candid2019">Submissions</p> */}
                                                    <div
                                                      className="row justify-content-center"
                                                      style={{ marginBottom: "30px" }}
                                                    >
                                                      <p
                                                        className="ju_name_white"
                                                        style={{ fontSize: "21px", cursor: "pointer" }}
                                                        onClick={e => this.handle_menu(e)}
                                                      >
                                                        {this.state.mode === "recent"
                                                          ? "Recent Photos"
                                                          : "Most Voted Photos"}
                                                      </p>
                                                      <img
                                                        src={require("../../logo/down-arrow.svg")}
                                                        alt="more"
                                                        style={{
                                                          opacity: "0.7",
                                                          height: "13px",
                                                          width: "13px",
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                          marginTop: "16px"
                                                        }}
                                                        onClick={e => this.handle_menu(e)}
                                                      />

                                                      <Menu
                                                        style={{ marginTop: "40px" }}
                                                        id="simple-menu"
                                                        anchorEl={this.state.anchor}
                                                        keepMounted
                                                        open={Boolean(this.state.anchor)}
                                                        onClose={e => this.closeMenu(e)}
                                                      >
                                                        <MenuItem onClick={e => this.getSubmissions()}>
                                                          <p>Recent Photos</p>
                                                        </MenuItem>

                                                        <MenuItem
                                                          onClick={e => this.getMostVotedSubmissions()}
                                                        >
                                                          <p>Most Voted Photos</p>
                                                        </MenuItem>
                                                      </Menu>
                                                    </div>

                                                    {/* <Columns  queries={queries} dimensions={dim}>           */}

                                                    <StackGrid
                                                      columnWidth={
                                                        window.innerWidth > 767
                                                          ? (window.innerWidth * 20) / 100
                                                          : (window.innerWidth * 45) / 100
                                                      }
                                                    >
                                                      {data.map((value, i) => {
                                                        dim.push({
                                                          width: (window.innerWidth * 33) / 100,
                                                          height:
                                                            (value.imageProperties[0].originalHeight *
                                                              ((window.innerWidth * 33) / 100)) /
                                                            value.imageProperties[0].originalWidth
                                                        });

                                                        return (
                                                          <div id="outer-box">
                                                            <div id="inner-box" className="container">
                                                              {!ids.has(value.id) ? (
                                                                <div
                                                                  className="row vote_button"
                                                                  onClick={e =>
                                                                    this.vote(e, value.id, true, i)
                                                                  }
                                                                  style={{
                                                                    position: "absolute",
                                                                    top: "15px",
                                                                    left: "30px",
                                                                    cursor: "pointer"
                                                                  }}
                                                                >
                                                                  <img
                                                                    id="like"
                                                                    src={require("../../logo/like.svg")}
                                                                    alt="liked"
                                                                    style={{
                                                                      marginTop: "07px",
                                                                      height: "18px"
                                                                    }}
                                                                  />
                                                                  <p id="like-post">
                                                                  {
                                                              value.shortMissionObject ? 
                                                              value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                                                    'Like' : 'Vote'
                                                                            :
                                                                        'Like' 
                                                                      }
                                                                  </p>
                                                                </div>
                                                              ) : (
                                                                <div
                                                                  className="row vote_button2"
                                                                  onClick={e =>
                                                                    this.vote(e, value.id, false, i)
                                                                  }
                                                                  style={{
                                                                    position: "absolute",
                                                                    top: "15px",
                                                                    left: "30px",
                                                                    cursor: "pointer"
                                                                  }}
                                                                >
                                                                  <img
                                                                    id="like"
                                                                    src={require("../../logo/liked.svg")}
                                                                    alt="liked"
                                                                    style={{
                                                                      marginTop: "07px",
                                                                      height: "18px"
                                                                    }}
                                                                  />
                                                                  <p id="liked-post">
                                                                  {
                                                              value.shortMissionObject ? 
                                                              value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                                                    'Liked' : 'Voted'
                                                                            :
                                                                        'Liked' 
                                                                      }
                                                                  </p>
                                                                </div>
                                                              )}
                                                            </div>

                                                            {!ids.has(value.id) ? (
                                                              <Img
                                                                className="img-raised"
                                                                alt="Post"
                                                                onDragStart={e => this.onDragPhoto(e)}
                                                                onDrag={e => this.onDragPhoto(e)}
                                                                onContextMenu={e => this.clickRight(e)}
                                                                src={[
                                                                  value.imageProperties[0].medium
                                                                    ? value.imageProperties[0].medium
                                                                    : value.imageProperties[0]
                                                                        .originalUrl
                                                                ]}
                                                                loader={
                                                                  <img
                                                                    alt="medal"
                                                                    src={
                                                                      "https://placehold.it/" +
                                                                      `${parseInt(
                                                                        (window.innerWidth * 33) / 100
                                                                      )}` +
                                                                      "x" +
                                                                      parseInt(
                                                                        (value.imageProperties[0]
                                                                          .originalHeight *
                                                                          ((window.innerWidth * 33) /
                                                                            100)) /
                                                                          value.imageProperties[0]
                                                                            .originalWidth
                                                                      ) +
                                                                      "/00000090/00000090"
                                                                    }
                                                                    style={{
                                                                      padding: "5px",
                                                                      borderRadius: "10px",
                                                                      background: `#00000000`,
                                                                      cursor: "pointer"
                                                                    }}
                                                                  />
                                                                }
                                                                decode={false}
                                                                onLoad={e => this.imageLoading()}
                                                                style={{
                                                                  padding: "5px",
                                                                  borderRadius: "10px",
                                                                  background: `#00000000`,
                                                                  cursor: "pointer"
                                                                }}
                                                                onClick={evt =>
                                                                  this.handleClicks(
                                                                    this.state.like,
                                                                    value.id,
                                                                    value.imageProperties[0].medium
                                                                      ? value.imageProperties[0].medium
                                                                      : value.imageProperties[0]
                                                                          .originalUrl,
                                                                    value.slug,
                                                                    data,
                                                                    i
                                                                  )
                                                                }
                                                              />
                                                            ) : (
                                                              <>
                                                                <div
                                                                  className="row vote_button3"
                                                                  onClick={e =>
                                                                    this.vote(e, value.id, false, i)
                                                                  }
                                                                  style={{
                                                                    position: "absolute",
                                                                    top: "15px",
                                                                    left: "30px",
                                                                    cursor: "pointer"
                                                                  }}
                                                                >
                                                                  <img
                                                                    id="like"
                                                                    src={require("../../logo/liked.svg")}
                                                                    alt="liked"
                                                                    style={{
                                                                      marginTop: "07px",
                                                                      height: "18px"
                                                                    }}
                                                                  />
                                                                </div>

                                                                <img
                                                                  className="img-raised"
                                                                  onDragStart={e => this.onDragPhoto(e)}
                                                                  onDrag={e => this.onDragPhoto(e)}
                                                                  onContextMenu={e =>
                                                                    this.clickRight(e)
                                                                  }
                                                                  src={
                                                                    value.imageProperties[0].medium
                                                                      ? value.imageProperties[0].medium
                                                                      : value.imageProperties[0]
                                                                          .originalUrl
                                                                  }
                                                                  alt="Post"
                                                                  onLoad={e => this.imageLoading()}
                                                                  style={{
                                                                    padding: "5px",
                                                                    borderRadius: "10px",
                                                                    background: `#00000000`,
                                                                    cursor: "pointer"
                                                                  }}
                                                                  onClick={evt =>
                                                                    this.handleClicks(
                                                                      this.state.like,
                                                                      value.id,
                                                                      value.imageProperties[0].medium
                                                                        ? value.imageProperties[0]
                                                                            .medium
                                                                        : value.imageProperties[0]
                                                                            .originalUrl,
                                                                      value.slug,
                                                                      data,
                                                                      i
                                                                    )
                                                                  }
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                        );
                                                      })}
                                                    </StackGrid>

                                                    <div className='row' style={{ marginTop: "60px", justifyContent:'center' }}>
                                                      {
                                                        this.state.loading === false ? 
                                                      
                                                      <div 
                                                      onClick={()=>this.loadMore()} 
                                                      className='Nav_buttons_black_v1' style={{marginTop:'33px',display:'flex'}}>    
                                                        <p 
                                                            className="nav_text_white"> Load more images</p>
                                                    </div>
                                                      
                                                      
                                                      :
                                                      <CircularProgress color='secondary' />
                                                }
                                                    </div>
                                                  </div>
                                                ) : (
                                                  // <CircularProgress color="primary" />
                                                  <></>
                                                )}
                                                </div>
                                                
    }

{/* Recent Submissions -- Ends Here */}


{/* Sponsors -- Starts Here */}


    <div className="container-fluid" style={{ marginTop: "72px",borderTop:'1px solid #ffffff36' }}>
            <div className="row" style={{ justifyContent: "center" }}>
              <div
                          className="row"
                          style={{
                            marginTop: "40px",
                            justifyContent: "center"
                          }}
                        >
                          {mission_data.sponsors ? (
                            mission_data.sponsors.map((value, i) =>(
                                <>
                                  {i === 0 ? (
                                    <div
                                      className="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                      style={{
                                        justifyContent: "center",
                                        marginTop: "80px",
                                        marginBottom:'42px',
                                      }}
                                    >
                                      <p id="issue1_desc" style={{opacity:'0.8'}}>#Partners</p>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-4">
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "black"
                                      }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={value.website}
                                    >
                                      <img
                                        alt="juries"
                                        className="juPh"
                                        id="ju_image_category"
                                        src={value.logo}
                                      />
                                      <p style={{marginTop:'28px', opacity:'1'}} className="ju_name_white">{value.name}</p>
                                      <p className="ju_desc_white">{value.type}</p>
                                    </a>
                                  </div>
                                </>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        </div>
            </div>
        </div>                      

{/* Sponsors -- Ends Here */}                      

                  
                      {/* ==================================== */}

                      
               
                      <div style={{ marginTop: "140px" }}>
                        <img
                          src={require("../../logo/chatw.svg")}
                          alt="chat_logo"
                          style={{height:'40px', width:'40px',opacity:'0.9'}}
                        />
                        <p className="about__" style={{ marginTop: "20px" , color:'white' }}>
                          {" "}
                          Question or problem?
                        </p>

                        <p className="about__" style={{color:'white' }}>
                          {" "}
                          Contact our team from<b> <a href='https://tawk.to/chat/5dcbea87d96992700fc73ae3/default'
                            target='_blank' rel="noopener noreferrer">
                                                here</a></b>
                        </p>
                        <p className="about__" style={{color:'white'}}>
                          {" "}
                          and we’ll reply within 24 hours.
                        </p>
                      </div>
                            
                      {/* --------------------------------- */}
                      {/* <div
                        className="row"
                        style={{ justifyContent: "center", marginTop: "40px" }}
                      >
                        <div className="vl" style={{border: '1px solid #ffffff'}}></div>
                      </div>

                      <div
                        className="row"
                        style={{ justifyContent: "center", marginTop: "40px" }}
                      >
                        <img
                          className="winning_image"
                          src="https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg"
                          alt="winning_image"
                        />
                      </div> */}
{
  this.state.theposition < 0.09 ? 
  ''
  :

  <div className="container">
  {this.state.ok === false ? (
    <CircularProgress color='secondary'/>
  ) : parseInt(mission_data.currentTime) <
    parseInt(mission_data.startTime) ? (
    <div
      className="row "
      style={{
        width: "100%",
        placeContent: "center",
        margin: "48px 0px 0px 0px",
        cursor: "pointer",
      }}
    >
      <div className="container butt_red" style={{margin:'0px'}}>
        <div className="container">
          <p id="pre2">Coming Soon</p>
        </div>
      </div>
    </div>
  ) : parseInt(mission_data.currentTime) <
    parseInt(mission_data.finaleTime) ? (
    paid === false ? (
      mission_data.isPaidFreeBoth === false ? (
        <div
          className="row"
          onClick={e => this.openCheckout()}
          style={{
            width: "100%",
            placeContent: "center",
            margin: "48px 0px 0px 0px",
            cursor: "pointer"
          }}
        >
          <div className="container butt_red">
            {/* <a onClick={this.openCheckout}> */}

            {JSON.parse(
              localStorage.getItem("current_user")
            ) ? (
              <div className="container">
                <div className="row justify-content-center" style={{margin:'0px'}}>
                  <p id="pre2"> Participate</p>
                  {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
                </div>
                <p id="inr2">
                  {mission_data.participationFeesText}
                </p>
              </div>
            ) : (
              <div
                className="container"
                style={{ cursor: "pointer" }}
              >
                <div className="row justify-content-center">
                  <p id="pre2">Login to Participate</p>
                  {/* <img  className='arrow2' src={require('../../logo/right_arrow.svg')} /> */}
                </div>
              </div>
            )}
            {/* </a> */}
          </div>
        </div>
      ) : (
        <div
          className="row "
          onClick={e => this.openCheckout()}
          style={{
            width: "100%",
            placeContent: "center",
            cursor: "pointer",
            margin: "48px 0px 0px 0px"
          }}
        >
          <div className="container butt_red">
            {/* <a onClick={this.openCheckout}> */}
            <div className="row justify-content-center" style={{margin:'0px'}}>
              <p id="pre2"> Participate</p>
              {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
              {/* <p id='inr2'>{mission_data.participationFeesText}</p> */}
            </div>
            {/* </a> */}
          </div>
        </div>
      )
    ) : // when already Paid & No more Subs Remaining

    remaining_subs !== "0" ? (
      <div
        className="row  "
        onClick={e => this.goToUploadPage(e)}
        style={{
          width: "100%",
          placeContent: "center",
          cursor: "pointer",
          margin: "48px 0px 0px 0px"
        }}
      >
        <div className="container butt_red ">
          <div className="container">
            <div className="row justify-content-center" style={{margin:'0px'}}>
              <p id="pre2"> Upload</p>
              {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
            </div>
            <p id="inr2">
              {remaining_subs} Submissions Remaining
            </p>
          </div>
        </div>
      </div>
    ) : (
      //  View Submissions

      <div
        className="row "
        onClick={e => this.openCheckoutSecond()}
        style={{
          width: "100%",
          placeContent: "center",
          cursor: "pointer",
          margin: "48px 0px 0px 0px"
        }}
      >
        <div className="container butt_red">
          <div className="row justify-content-center" style={{margin:'0px'}}>
            <p id="pre2">Purchase Extra Uploads</p>
            </div>
            <div className="row justify-content-center">
            <p id="inr2">
            INR {mission_data.extraPerImageCost * mission_data.extraImageUploadLimitation} for {mission_data.extraImageUploadLimitation} Pics 
            </p>
            {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
          </div>
        </div>
      </div>
    )
  ) : parseInt(mission_data.currentTime) <
    parseInt(mission_data.endTime) ? (
    <div
      className="row "
      onClick={e => this.clickOnFinalist(false)}
      style={{
        width: "100%",
        placeContent: "center",
        cursor: "pointer",
        margin: "48px 0px 0px 0px"
      }}
    >
      <div className="container butt_red ">
        <div className="row justify-content-center" style={{margin: '0px'}}>
          <p id="pre2"> Vote For Finalist</p>
          {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
        </div>
      </div>
    </div>
  ) : (
    <div
      className="row "
      onClick={e => this.clickOnWinner(false)}
      style={{
        width: "100%",
        placeContent: "center",
        cursor: "pointer",
        margin: "48px 0px 0px 0px"
      }}
    >
      <div className="container butt_red ">
        <div className="row justify-content-center">
          <p id="pre2">Follow Winners</p>
          {/* <img className='arrow' src={require('../../logo/right_arrow.svg')} /> */}
        </div>
      </div>
    </div>
  )}
</div>

}
                    </div>
                  ) : this.state.finalist === true ? (
                    parseInt(mission_data.currentTime) <
                    parseInt(mission_data.finaleTime) ? (
                      <>
                        <br></br>
                        <br></br>

                        <img
                          src={require("../../logo/medal.svg")}
                          alt="medal"
                          style={{ height: "102px", width: "102px" }}
                        />
                        <br></br>
                        <br></br>
                        <br></br>

                        <p className="finale_text_white">
                          Finalist Will Be Announced In :
                        </p>

                        <div>
                          {this.addLeadingZeros(countDown.days) === "00" ? (
                            <></>
                          ) : (
                            <>
                              <p className="finale_time_white">
                                <strong>
                                  {this.addLeadingZeros(countDown.days)}
                                </strong>
                              </p>

                              <p className="finale_text_white">
                                <span> Days </span>
                              </p>
                            </>
                          )}

                          {this.addLeadingZeros(countDown.hours) === "00" ? (
                            <></>
                          ) : (
                            <>
                              <p className="finale_time_white">
                                <strong>
                                  {this.addLeadingZeros(countDown.hours)}
                                </strong>
                              </p>

                              <p className="finale_text_white">
                                <span> Hours </span>
                              </p>
                            </>
                          )}

                          {this.addLeadingZeros(countDown.min) === "00" ? (
                            <></>
                          ) : (
                            <>
                              <p className="finale_time_white">
                                <strong>
                                  {this.addLeadingZeros(countDown.min)}
                                </strong>
                              </p>

                              <p className="finale_text_white">
                                <span> Minutes </span>
                              </p>
                            </>
                          )}

                          <p className="finale_time_white">
                            <strong>
                              {this.addLeadingZeros(countDown.sec)}
                            </strong>
                          </p>

                          <p className="finale_text_white">
                            <span> Seconds </span>
                          </p>
                        </div>

                        <br></br>
                        <p className="finale_time_white">
                          <strong>
                            {String(
                              new Date(parseInt(mission_data.finaleTime))
                            ).slice(0, 15)}
                          </strong>
                        </p>
                      </>
                    ) : 
                    finalist ? finalist.length ? 
                    (
                      <div
                        style={{ justifyContent: "center", marginTop: "60px" }}
                      >
                        {/* ------------------------------ */}
                        <div className="container">
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "51px",
                              border: "1px solid #b8a33b",
                              padding: "45px"
                            }}
                          >
                            <div style={{ marginLeft: "40px" }}>
                              <p className="about_v1" style={{textAlign:'left'}}>
                                {ReactHtmlParser(prizeText)}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* ------------------------------ */}

                        <p
                          style={{ marginTop: "50px", marginBottom: "30px" }}
                          className="winners"
                        >
                          Finalist
                        </p>

                        <StackGrid
                          columnWidth={
                            window.innerWidth > 767
                              ? (window.innerWidth * 20) / 100
                              : (window.innerWidth * 45) / 100
                          }
                        >
                          {finalist.map((value, i) => {
                            dim.push({
                              width: (window.innerWidth * 33) / 100,
                              height:
                                (value.imageProperties[0].originalHeight *
                                  ((window.innerWidth * 33) / 100)) /
                                value.imageProperties[0].originalWidth
                            });

                            return (
                              <div id="outer-box">
                                <div id="inner-box" className="container">
                                  {!ids.has(value.id) ? (
                                    <div
                                      className="row vote_button"
                                      onClick={e =>
                                        this.vote(e, value.id, true, i)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../logo/like.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                      <p id="like-post">
                                      {
                                       value.shortMissionObject ? 
                                       value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                             'Like' : 'Vote'
                                                     :
                                                 'Like' 
                                               }
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="row vote_button2"
                                      onClick={e =>
                                        this.vote(e, value.id, false, i)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../logo/liked.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                      <p id="liked-post">
                                      {
                                       value.shortMissionObject ? 
                                       value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                             'Liked' : 'Voted'
                                                     :
                                                 'Liked' 
                                               }
                                      </p>
                                    </div>
                                  )}
                                </div>

                                {!ids.has(value.id) ? (
                                  <Img
                                    className="img-raised"
                                    alt="Post"
                                    onDragStart={e => this.onDragPhoto(e)}
                                    onDrag={e => this.onDragPhoto(e)}
                                    onContextMenu={e => this.clickRight(e)}
                                    src={[
                                      value.imageProperties[0].medium
                                        ? value.imageProperties[0].medium
                                        : value.imageProperties[0].originalUrl
                                    ]}
                                    loader={
                                      <img
                                        alt="post"
                                        src={
                                          "https://placehold.it/" +
                                          `${parseInt(
                                            (window.innerWidth * 33) / 100
                                          )}` +
                                          "x" +
                                          parseInt(
                                            (value.imageProperties[0]
                                              .originalHeight *
                                              ((window.innerWidth * 33) /
                                                100)) /
                                              value.imageProperties[0]
                                                .originalWidth
                                          ) +
                                          "/00000090/00000090"
                                        }
                                        style={{
                                          padding: "5px",
                                          borderRadius: "10px",
                                          background: `#00000000`,
                                          cursor: "pointer"
                                        }}
                                      />
                                    }
                                    decode={false}
                                    onLoad={e => this.imageLoading()}
                                    style={{
                                      padding: "5px",
                                      borderRadius: "10px",
                                      background: `#00000000`,
                                      cursor: "pointer"
                                    }}
                                    onClick={evt =>
                                      this.handleClicks(
                                        this.state.like,
                                        value.id,
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0]
                                              .originalUrl,
                                        value.slug,
                                        finalist,
                                        i
                                      )
                                    }
                                  />
                                ) : (
                                  <>
                                    <div
                                      className="row vote_button3"
                                      onClick={e =>
                                        this.vote(e, value.id, false, i)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../logo/liked.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                    </div>

                                    <img
                                      className="img-raised"
                                      onDragStart={e => this.onDragPhoto(e)}
                                      onDrag={e => this.onDragPhoto(e)}
                                      onContextMenu={e => this.clickRight(e)}
                                      src={
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0].originalUrl
                                      }
                                      alt="Post"
                                      onLoad={e => this.imageLoading()}
                                      style={{
                                        padding: "5px",
                                        borderRadius: "10px",
                                        background: `#00000000`,
                                        cursor: "pointer"
                                      }}
                                      onClick={evt =>
                                        this.handleClicks(
                                          this.state.like,
                                          value.id,
                                          value.imageProperties[0].medium
                                            ? value.imageProperties[0].medium
                                            : value.imageProperties[0]
                                                .originalUrl,
                                          value.slug,
                                          finalist,
                                          i
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </StackGrid>

                        <div  className='row' style={{ marginTop: "60px", justifyContent:'center' }}>
                              {
                                this.state.loading === false ? 
                                finalistCount > 15 ? 

                              <div 
                              onClick={()=>this.loadMoreFinalist()} 
                              className='Nav_buttons_black_v1' style={{marginTop:'33px',display:'flex'}}>    
                                <p 
                                    className="nav_text_white"> Load more images</p>
                            </div>
                            :
                            ''
                              :
                              <CircularProgress color='secondary' />
              }
                            </div>
                      
                      </div>
                    ) : (
                      
                      isFinalist === true ?
                        <CircularProgress color="secondary" />
                        :
                        <div style={{marginTop:'98px'}}>
                        <img
                            src={require("../../logo/medal.svg")}
                            alt="medal"
                            style={{ height: "102px", width: "102px" , marginTop:'20px' }}
                          />
                      <p className="finalist_text"  style={{color:'white', marginTop:'42px'}} >
                        We are selecting the finalist , Result will be declared soon within 2 to 3 days
                      </p>

                      </div>
                    ):
                    <div style={{marginTop:'98px'}}>
                      <img
                          src={require("../../logo/medal.svg")}
                          alt="medal"
                          style={{ height: "102px", width: "102px" , marginTop:'20px' }}
                        />
                    <p className="finalist_text"  style={{color:'white', marginTop:'42px'}} >
                      We are selecting the finalist , Result will be declared soon within 2 to 3 days
                    </p>

                    </div>

                  ) : (
                    <>
                      {parseInt(mission_data.currentTime) <
                      parseInt(mission_data.endTime) ? (
                        <>
                          <br></br>
                          <br></br>

                          <img
                            src={require("../../logo/icon-awards.svg")}
                            alt="medal"
                            style={{ height: "102px", width: "102px" }}
                          />
                          <br></br>
                          <br></br>
                          <br></br>

                          <p className="finalist_text_white">
                            Winners Will Be Announced In :
                          </p>

                          <div>
                            {this.addLeadingZeros(countDown.edays) === "00" ? (
                              <></>
                            ) : (
                              <>
                                <p className="finale_time_white">
                                  <strong>
                                    {this.addLeadingZeros(countDown.edays)}
                                  </strong>
                                </p>

                                <p className="finale_text_white">
                                  <span> Days </span>
                                </p>
                              </>
                            )}

                            {this.addLeadingZeros(countDown.ehours) === "00" ? (
                              <></>
                            ) : (
                              <>
                                <p className="finale_time_white">
                                  <strong>
                                    {this.addLeadingZeros(countDown.ehours)}
                                  </strong>
                                </p>

                                <p className="finale_text_white">
                                  <span> Hours </span>
                                </p>
                              </>
                            )}

                            {this.addLeadingZeros(countDown.emin) === "00" ? (
                              <></>
                            ) : (
                              <>
                                <p className="finale_time_white">
                                  <strong>
                                    {this.addLeadingZeros(countDown.emin)}
                                  </strong>
                                </p>

                                <p className="finale_text_white">
                                  <span> Minutes </span>
                                </p>
                              </>
                            )}

                            <p className="finale_time_white">
                              <strong>
                                {this.addLeadingZeros(countDown.esec)}
                              </strong>
                            </p>
                            <p className="finale_text_white">
                              <span> Seconds </span>
                            </p>
                          </div>

                          <br></br>
                          <p className="finale_time_white">
                            <strong>
                              {String(
                                new Date(parseInt(mission_data.endTime))
                              ).slice(0, 15)}
                            </strong>
                          </p>
                        </>
                      ) : 
                      winners ? winners.length ? (
                        <div
                          style={{
                            justifyContent: "center",
                            marginTop: "60px"
                          }}
                        >
                          {/* ------------------------------ */}
                          <div className="container">
                            <div
                              className="row"
                              style={{
                                justifyContent: "center",
                                marginTop: "51px",
                                border: "1px solid #b8a33b",
                                padding: "45px"
                              }}
                            >
                              <div style={{ marginLeft: "40px" }}>
                                <p className="about_v1" style={{textAlign:'left'}}>
                                  {ReactHtmlParser(prizeText)}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* ------------------------------ */}
                          <div className="container mt-4">
                            <div className="row">
                              {winners.map((value, i) => {
                                return (
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                    <p
                                      style={{ marginTop: "100px" }}
                                      className="winners"
                                    >
                                      {i === 0
                                        ? "1st Winner"
                                        : i === 1
                                        ? "2nd Winner"
                                        : "3rd Winner"}
                                    </p>

                                    <div className="row">
                                      <img
                                        onClick={() =>
                                          this.goToProfile(
                                            value.shortUserObject.slug
                                          )
                                        }
                                        alt="user"
                                        height="80px"
                                        width="80px"
                                        style={{
                                          borderRadius: "180px",
                                          objectFit: "cover",
                                          marginTop: "30px",
                                          cursor: "pointer",
                                          border:'1px solid #ffffff50'
                                        }}
                                        src={value.shortUserObject.profilePicture}
                                        onError={(e) => {
                                          e.target.onerror = null
                                          e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                        }}
                                      />
                                      <div
                                        style={{
                                          marginTop: "25px",
                                          marginLeft: "15px"
                                        }}
                                      >
                                        <p
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.goToProfile(
                                              value.shortUserObject.slug
                                            )
                                          }
                                          className="winners_name mt-2" style={{color:'#fff',cursor:'pointer'}}
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <p
                                          style={{ cursor: "pointer", color : '#fff' }}
                                          onClick={() =>
                                            this.goToProfile(
                                              value.shortUserObject.slug
                                            )
                                          }
                                          className="votes"
                                        >
                                          {value.likeCount} 
                                          {
                                       value.shortMissionObject ? 
                                       value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                             ' Likes' : ' Votes'
                                                     :
                                                 ' Likes' 
                                               }
                                        </p>
                                      </div>
                                    </div>
                                    <div style={{ marginTop: "40px" }}>
                                      {value.title.length === 0 ? (
                                        ""
                                      ) : (
                                        <p className="winner_title_desc" style={{color:'#fff'}}>
                                          <b>Title :</b> {value.title}
                                        </p>
                                      )}
                                      {value.description.length === 0 ? (
                                        ""
                                      ) : (
                                        <p className="winner_title_desc" style={{color : '#fff'}}>
                                          <b>Story Behind :</b>{" "}
                                          {value.description}
                                        </p>
                                      )}
                                    </div>
                                    <img
                                      style={{
                                        marginTop: "20px",
                                        cursor: "pointer"
                                      }}
                                      className="img-fluid"
                                      src={
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0].originalUrl
                                      }
                                      alt="winner"
                                      onClick={evt =>
                                        this.handleClicks(
                                          this.state.like,
                                          value.id,
                                          value.imageProperties[0].medium
                                            ? value.imageProperties[0].medium
                                            : value.imageProperties[0]
                                                .originalUrl,
                                          value.slug,
                                          winners,
                                          i
                                        )
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        isWinners === true ?
                        <CircularProgress color="secondary" style={{marginTop:'40px'}}/>
                        :
                         <div style={{marginTop:'98px'}}>
                        <img
                            src={require("../../logo/icon-awards.svg")}
                            alt="medal"
                            style={{ height: "102px", width: "102px" , marginTop:'20px' }}
                          />
                      <p className="finalist_text"  style={{color:'white', marginTop:'42px'}}>
                        We are selecting the winners , Result will be declared soon within 2 to 3 days
                      </p>
  
                      </div>
                      )
                        :
                        <div style={{marginTop:'98px'}}>
                        <img
                            src={require("../../logo/icon-awards.svg")}
                            alt="medal"
                            style={{ height: "102px", width: "102px" , marginTop:'20px' }}
                          />
                      <p className="finalist_text"  style={{color:'white', marginTop:'42px'}} >
                        We are selecting the winners , result will be declared soon within 2 to 3 days
                      </p>
  
                      </div>
                    }
                    </>
                  )}
                </div>
                {/* Body part ends here */}

                {/* Footer here */}
                {JSON.parse(localStorage.getItem("current_user")) ? 
            JSON.parse(localStorage.getItem("current_user")).organization ? 
                JSON.parse(localStorage.getItem("current_user")).organization === '' ? 
               
                <div
                  style={{
                    marginTop: "160px",
                    position: "sticky",
                    zIndex: "11"
                  }}
                >
                  <FooterWallmag />
                </div>
                :
                ''
                :
                <div
                style={{
                  marginTop: "160px",
                  position: "sticky",
                  zIndex: "11"
                }}
              >
                <FooterWallmag />
              </div>
              :
              <div
              style={{
                marginTop: "160px",
                position: "sticky",
                zIndex: "11"
              }}
            >
              <FooterWallmag />
            </div>
  }
                {/* ---------------------  */}
              </div>
            ) : (
              <></>
            )}
          </>
       :'' )
     
      
      }

        {/* Dialog for Payment Mode */}

        <Dialog
          open={this.state.payment_dialog}
          onClose={this.closeDialog}
          disableBackdropClick={true}
        >
          <DialogTitle>
            <p className="title_dial">{mission_data.hashTag}</p>
          </DialogTitle>

          <DialogContent>
            <p className="title_desc">Payment has been successful</p>
          </DialogContent>

          <ThemeProvider theme={theme}>
            <Button onClick={e => this.refreshPage()}>ok</Button>
          </ThemeProvider>
        </Dialog>

        {/* Dialog for submission details */}

        {window.outerWidth < 768 ? (
          <Dialog
            className="dial"
            open={this.state.dialogOpen}
            onClose={this.handleClosePost}
            fullScreen
            PaperProps={{
              style: {
                backgroundColor: "black",
                boxShadow: "none"
              }
            }}
          >
            <div
              className="row"
              style={{
                justifyContent: "flex-end",
                position: "sticky",
                top: "10px"
              }}
            >
              <img
                alt="close"
                style={{
                  height: "20px",
                  width: "20px",
                  marginTop: "10px",
                  marginRight: "40px",
                  cursor: "pointer"
                }}
                src={require("../../logo/close.svg")}
                onClick={this.handleClose}
              />
            </div>
            <div style={{ background: "black" }}>
              <PostViewer
                props={this.props}
                url={this.state.post_url}
                id={post_id}
                position={this.state.pos}
                postData={this.state.postData}
              />
            </div>
          </Dialog>
        ) : (
          <Dialog
            className="dial"
            open={this.state.dialogOpen}
            onClose={this.handleClose}
            fullScreen
            PaperProps={{
              style: {
                backgroundColor: "#ffffff12",
                boxShadow: "none"
              }
            }}
          >
            <div
              className="row"
              style={{
                justifyContent: "flex-end",
                position: "sticky",
                top: "10px"
              }}
            >
              <img
                style={{
                  height: "20px",
                  width: "20px",
                  marginTop: "10px",
                  marginRight: "40px",
                  cursor: "pointer"
                }}
                src={require("../../logo/close.svg")}
                onClick={this.handleClose}
                alt='Close'
              />
            </div>
            <div
              style={{
                background: "black",
                marginLeft: `${window.outerWidth <1200 ? '50px':'200px'}`,
                marginRight: `${window.outerWidth <1200 ? '50px':'200px'}`,
              }}
            >
              <PostViewer
                props={this.props}
                url={this.state.post_url}
                id={post_id}
                position={this.state.pos}
                postData={this.state.postData}
              />
            </div>
          </Dialog>
        )}


      {/* LG - Dialog For Mobile verification */}
      <Dialog
        open={this.state.mob_verification_dialog}
        close={this.closePaymentDialog}
        onBackdropClick
      >
        
          <MobileVerification closeDialog={this.closePaymentDialog}/>

      </Dialog>


      {/* SM - Dialog For Mobile verification */}
      <Dialog
        open={this.state.mob_verification_dialog_mob}
        close={this.closePaymentDialog}
        fullScreen
        onBackdropClick
      >
          <MobileVerification closeDialog={this.closePaymentDialog}/>

      </Dialog>
      


        {/* Dialog for Payment or upload */}

        <Dialog
          open={this.state.pay_dialogOpen}
          close={this.closePaymentDialog}
        >
          <img
            alt="cancel"
            src={require("../../logo/cancel.svg")}
            style={{ cursor: "pointer", alignSelf: "flex-end", margin: "10px" }}
            onClick={this.closePaymentDialog}
          />

          {window.innerWidth > 767 ? (
            <>
              <div
                className="container payment_button"
                style={{
                  width: "100%",
                  marginTop: "48px",
                  marginBottom: "10px"
                }}
              >
                <div className="container butt_red">
                  <a onClick={this.openCheckout}>
                    <div className="container">
                      <p id="pre2"> Participate</p>
                      <p
                        id="inr2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "300",
                          opacity: "1"
                        }}
                      >
                        {mission_data.participationFeesText}
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              <div className="row" style={{ alignSelf: "center" }}>
                <img
                  alt="award_logo"
                  src={require("../../logo/award.png")}
                  style={{ height: "30px", width: "30px", marginTop: "7px" }}
                />
                <p
                  id="pre2"
                  style={{
                    marginLeft: "10px",
                    fontSize: "20px",
                    marginTop: "10px",
                    fontWeight: "300",
                    opacity: "1"
                  }}
                >
                  Win Prizes
                </p>
              </div>

              {/* ------------------------------------------------------------------------------------- */}

              <div className="container sep">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                    <hr className="hr2" style={{ color: "black" }}></hr>
                  </div>

                  <p className="or" style={{ opacity: 1, marginTop: "6px" }}>
                    OR
                  </p>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                    <hr className="hr2" style={{ color: "black" }}></hr>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                </div>
              </div>

              {/* ------------------------------------------------------------------------------------- */}

              <div
                className="container payment_button"
                style={{
                  width: "100%",
                  marginTop: "48px",
                  paddingRight: "150px",
                  paddingLeft: "150px"
                }}
              >
                <div
                  className="container butt "
                  onClick={e => this.participateFree(e)}
                >
                  <div className="container">
                    <p id="pre2"> Particapate</p>
                    <p
                      id="inr2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "300",
                        opacity: "1"
                      }}
                    >
                      FREE
                    </p>
                  </div>
                </div>
              </div>

              <p
                id="inr2"
                style={{
                  alignSelf: "center",
                  fontSize: "20px",
                  marginTop: "20px",
                  fontWeight: "300",
                  opacity: "1"
                }}
              >
                You can win but no
              </p>

              <p
                id="inr2"
                style={{
                  alignSelf: "center",
                  fontSize: "20px",
                  marginTop: "-10px",
                  fontWeight: "300",
                  opacity: "1"
                }}
              >
                prizes will be rewarded for free participation
              </p>
            </>
          ) : (
            <>
              <div
                className="container payment_button"
                style={{
                  width: "100%",
                  marginTop: "28px",
                  marginBottom: "10px"
                }}
              >
                <div className="container butt_red">
                  <a onClick={this.openCheckout}>
                    <div className="container">
                      <p id="pre2"> Participate</p>
                      <p
                        id="inr2"
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          opacity: "1"
                        }}
                      >
                        {mission_data.participationFeesText}
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              <div
                className="row"
                style={{ alignSelf: "center", marginBottom: "20px" }}
              >
                <img
                  alt="award_icon"
                  src={require("../../logo/award.png")}
                  style={{ height: "30px", width: "30px" }}
                />
                <p
                  id="inr2"
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    marginTop: "5px",
                    fontWeight: "300",
                    opacity: "1"
                  }}
                >
                  Win Prizes
                </p>
              </div>

              {/* ------------------------------------------------------------------------------------- */}

              <div className="container sep">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                    <hr className="hr3" style={{ color: "black" }}></hr>
                  </div>

                  <p className="or" style={{ opacity: 1, marginTop: "8px" }}>
                    OR
                  </p>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                    <hr className="hr3" style={{ color: "black" }}></hr>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                </div>
              </div>

              {/* ------------------------------------------------------------------------------------- */}

              <div
                className="container payment_button"
                style={{ width: "100%", marginTop: "28px" }}
              >
                <div
                  className="container butt "
                  onClick={e => this.participateFree(e)}
                >
                  <div className="container">
                    <p id="pre2"> Particapate</p>
                    <p
                      id="inr2"
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        opacity: "1"
                      }}
                    >
                      FREE
                    </p>
                  </div>
                </div>
              </div>

              <p
                id="inr2"
                style={{
                  alignSelf: "center",
                  fontSize: "16px",
                  marginTop: "20px",
                  fontWeight: "300",
                  opacity: "1"
                }}
              >
                You can win but no
              </p>

              <p
                id="inr2"
                style={{
                  alignSelf: "center",
                  fontSize: "16px",
                  marginTop: "-10px",
                  fontWeight: "300",
                  opacity: "1"
                }}
              >
                prizes will be rewarded for free participation
              </p>
            </>
          )}
        </Dialog>

        <Dialog
          open={this.state.verify_user_dialog}
          onClose={this.closePaymentDialog}
          disableBackdropClick
          >

            <DialogTitle>
            <p className="verifyTitle" >Please verify your email address </p>
            </DialogTitle>

            <DialogContent>
            <p className="verifyContent">
            In order to perform this action you need to verify your email address 
                         <b className="verifyEmail"> {JSON.parse(localStorage.getItem("current_user")) ? 
               JSON.parse(localStorage.getItem("current_user")).email : ''} </b> from the verification email sent to you.
            </p>
          </DialogContent>

          <DialogActions>
            <p className='okButton' onClick={this.closePaymentDialog}>
              Okay
            </p>
          </DialogActions>


        </Dialog>  


        <Dialog
          open={this.state.rankingBoard_mob}
          onClose={this.closeRanking}
          disableBackdropClick
          fullScreen
        >


          <div className='row otp-parent'>
            <p className='v-m-n' style={{width: '260px'}}>
              Participate to view ranking board
            </p>

            <img
              alt="cancel"
              src={require("../../logo/cancel.svg")}
              className='cross-icon'
              onClick={this.closeRanking}
            />
          </div>

          <p className='V-m-n-d'>
            Ranking board shows image ranking based upon juries and public votes.
            It only visible to participants. Upload your shots to view ranking board.
          </p>

          <div className='row send-otp-parent' >
            <div className='row send-OTP-btn' style={{width:'150px', height:'38px'}}>
              <p className='send-otp'>Upload Photos</p>
            </div>
          </div>
 
        </Dialog>    



        <Dialog
          open={this.state.rankingBoard}
          onClose={this.closeRanking}
          disableBackdropClick
        >

          <DialogTitle>
            <div className='row otp-parent'>
                <p className='v-m-n'>
                  Participate to view ranking board
                </p>

                <img
                alt="cancel"
                src={require("../../logo/cancel.svg")}
                className='cross-icon-v1'
                onClick={this.closeRanking}
            />

            </div>
            </DialogTitle>

          <DialogContent>
          <p className='V-m-n-d'>
              Ranking board shows image ranking based upon juries and public votes.
              It only visible to participants. Upload your shots to view ranking board.
            </p>
          </DialogContent>

          <DialogActions>
            <div
              style={{ outline: "none", margin:'0px 10px 16px 0px' }}
              className="learnmorebtn_dial"

            >
              {" "}
              <p className='learnmore_text_dial'>Upload Photos</p>
            </div>
          </DialogActions>                  
          
        </Dialog>   

{
  window.innerWidth < 768 ?

        <Dialog
          open={this.state.invite_friends_dialog}
          onClose = {() => this.closeDialogInvite()}
          fullScreen
          disableBackdropClick
        >
            <Invite props={this.state.props}  title={mission_data.hashTag}  closeFunc = {this.closeDialog}/>
        </Dialog>
  :
        <Dialog
          open={this.state.invite_friends_dialog}
          onClose = {() => this.closeDialogInvite()}
          fullWidth
          disableBackdropClick
        >
            <Invite props={this.state.props} title={mission_data.hashTag}  closeFunc = {this.closeDialog}/>
        </Dialog> 
  }
      </>
    );
  }
}

export default ContestDetails;
