import React, { Component } from 'react';
// import Iframe from 'react-iframe';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../../../node_modules/froala-design-blocks/dist/css/froala_blocks.css';
import './Testimonial.css'
import StarRatings from 'react-star-ratings';

class Testimonial extends Component {
  render() {
    return <section className="fdb-block bi" >
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-md-10 col-lg-8 col-xl-7">
          <h1 className='reviews'>Customer Reviews</h1>
          <p className="mainDesc">check what others are saying</p>
        </div>
      </div>
  
      <div className="row mt-5 align-items-center justify-content-center">
        <div className="col-md-8 col-lg-4">
          <div className="fdb-box">
            <div className="row no-gutters align-items-center">
              <div className="col-3">
                <img alt="imawge" className="img-fluid rounded" src="./imgs/people/cust1.jpg" />
              </div>
              <div className="col-8 ml-auto">
                <p>
                  <strong className='name_'>Bhavishya Garg</strong><br/>
                  <StarRatings
          rating={5}
          starRatedColor="#ffc966"
          numberOfStars={5}
          name='rating'
          starDimension	='15px'
          starSpacing='4px'
        />
                  {/* <em>Co-founder at Company</em> */}
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <p className="lead">
                  very unique concept for a news, magzine, reading app. Awesome wallpaper feature. Its a wow. High five from my side.
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="col-md-8 col-lg-4 mt-4 mt-lg-0">
          <div className="fdb-box">
            <div className="row no-gutters align-items-center">
              <div className="col-3">
                <img alt="imwage" className="img-fluid rounded" src="./imgs/people/cust2.jpg" />
              </div>
              <div className="col-8 ml-auto">
                <p>
                  <strong className='name_'>Shubhra Singh</strong><br/>
                  <StarRatings
          rating={5}
          starRatedColor="#ffc966"
          numberOfStars={5}
          name='rating'
          starDimension	='15px'
          starSpacing='4px'
        />
                  {/* <em></em> */}
                </p>
              </div>
            </div>
            <div className="row mt-4">  
              <div className="col-12">
                <p className="lead">
                  Very cool concept. The execution is also good. Smooth and no glitches seen yet.
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="col-md-8 col-lg-4 mt-4 mt-lg-0">
          <div className="fdb-box">
            <div className="row no-gutters align-items-center">
              <div className="col-3">
                <img alt="imawge" className="img-fluid rounded" src="./imgs/people/cust3.jpg" />
              </div>
              <div className="col-8 ml-auto">
                <p>
                  <strong className='name_'>Devraj Aragariya</strong><br/>
                  <StarRatings
          rating={5}
          starRatedColor="#ffc966"
          numberOfStars={5}
          name='rating'
          starDimension	='15px'
          starSpacing='3px'
        />
                  {/* <em>Co-founder at Company</em> */}
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <p className="lead">
                  Something beautiful that we need!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>

  }
}

export default Testimonial
