import React, { Component } from 'react';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../../../node_modules/froala-design-blocks/dist/css/froala_blocks.css';
import './Footer.css'

class Footer extends Component {
  render() {
    return <footer className="fdb-block footer">
  <div className="container-fluid">
    <div className="row text-center">
      <div className="col">
        <h3 className='cmpny_name'>WallMag</h3>
        <p className='add1'>6th floor<br/>Bhamashah Techno Hub, Jaipur, Rajasthan 302033</p>
        
        <p className='cntct'>team@https://wallmag-2.appspot.com/_ah/api</p>
      </div>
    </div>

    <div className="row mt-4">
      <div className="col text-center">
        <p className="lead">
        {/* WallMag Social */}

{/* Instagram */}
          <a href="https://www.instagram.com/wallmag.photography/" className="mx-2">
            <img alt='Instagram' height='25px' src='../../imgs/social_icons/insta.svg' />
            </a>
{/* facebook */}
          <a href="https://www.facebook.com/https://wallmag-2.appspot.com/_ah/api/" className="mx-2">
            <img alt='Facebook' height='25px' src='../../imgs/social_icons/fb.svg' />
            </a>
{/* Linkedin  */}
          <a href="https://www.linkedin.com/company/wallmag" className="mx-2">
            <img alt='Linkedin' height='25px' src='../../imgs/social_icons/lnk.svg' />
            </a>
{/* Medium */}
          <a href="https://medium.com/wallmag" className="mx-2">
            <img alt='medium' height='25px' src='../../imgs/social_icons/md.svg' />
            </a>
{/* Twitter */}
          <a href="https://twitter.com/wallmag_io" className="mx-2">
            <img alt='Twitter' height='25px' src='../../imgs/social_icons/tw.svg' />
            </a>
{/* Pinterest */}
          <a href="https://in.pinterest.com/wallmag.photography/" className="mx-2">
            <img alt="Pinterest" height='25px' src='../../imgs/social_icons/pin.svg' />
            </a>
{/* tumblr */}
          <a href="https://wallmag-app.tumblr.com/" className="mx-2">
            <img alt='Tumblr' height='25px' src='../../imgs/social_icons/tmb.svg' />
            </a>


        </p>
      </div>
    </div>
  </div>
</footer>

  }
}

export default Footer