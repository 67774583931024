import React from 'react'
import '../Signup/Signup.css'
import { TextField } from '@material-ui/core';
import Header from '../../../IssuePages/Header/Header'
import MobHeader from '../../../IssuePages/Header/Mob_Header'
class SetPassword extends React.Component {

    state ={
        pass : '',
        c_pass : '',
    }
    

                // On value change 
                change = (e)=>{
                    this.setState({
                      [e.target.id] : e.target.value,
                      open : false
                    })
                  }
    

    render(){
        return(
            <div  style={{backgroundColor:'white'}}> 
            {window.innerWidth < 768 ? 
                                    <MobHeader
                                    url=''
                                        heading='Wedding Photography Awards 2020'
                                        sub_heading='Searching for the best in you'
                                    />
                            :
                                        <Header
                                        url=''
                                        heading='Wedding Photography Awards 2020'
                                        sub_heading='Searching for the best in you'
                                        />
                        }    

                     <div className='container-fluid' style={{backgroundColor:'white'}}> 
                    

           

    
            
                <div className='row' style={{marginTop:'66px'}}>
                    <div className='signup-block'>
                        <p className='Sign-Up'>
                            Set Password
                        </p>

                        <p className='Apply-to-WallMag'>
                        Apply to WallMag Photography Awards 2020 <br></br>by Submitting your application
                        </p>

                        <p className='Line-item-selected'>
                            LAST FEW DAYS TO GO
                        </p>

                        <div className='Rectangle-1758'>
                            
                        
                        <TextField
                            className='tfs'
                            variant="outlined"
                            margin="dense"
                            id="pass"
                            type="Name"
                            placeholder="Password"
                            style={{marginTop:'38px', color:'red'}}
                            value={this.state.pass}
                            onChange={e => this.change(e)}
                        />
                        <br></br>

                        <TextField
                            className='tfs'
                            variant="outlined"
                            margin="dense"
                            id="c_pass"
                            type="Name"
                            placeholder="Confirm Password"
                            style={{marginTop:'38px'}}
                            value={this.state.c_pass}
                            onChange={e => this.change(e)}
                        />

                       
                        <br></br>
                        
                        <div className='Rectangle-806'>
                            <p className='submit'>
                                Submit
                            </p>
                        </div>

                        <p className='Line-item-selected' style={{marginTop:'23px'}}>
                            LAST FEW DAYS TO GO
                        </p>

                        </div>
                   
                    </div>
                </div>

            

            </div>
            </div>

        )
    }
};

export default SetPassword;