import React from 'react'
import '../../../Styles/PostViewer.css'
import {FacebookIcon,  TwitterIcon,  WhatsappIcon,  LinkedinIcon,FacebookMessengerIcon} from 'react-share';
import {FacebookShareButton,FacebookMessengerShareButton, LinkedinShareButton,  TwitterShareButton, WhatsappShareButton} from 'react-share';
import axios from 'axios';
import { add_comment, get_user_by_slug, follow_user, is_like, get_post_by_slug, get_comments, get_post_likes, view_post, report_post, delete_post, get_public_votes, get_jury_votes, jury_like } from '../../common/AppConstants';
import { Dialog, DialogTitle, AppBar, DialogContent, DialogActions, Button, createMuiTheme, CircularProgress } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import cogoToast from 'cogo-toast';
import MobHeaderNoImg from './../../Header/Mob_HeaderNoImg'
import HeaderNoImg from './../../Header/Header_NoImg'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { ThemeProvider } from '@material-ui/styles';
import firebase from '../../Login/firebaseConfig'
import ReactGA from 'react-ga';
import { loadProgressBar } from 'axios-progress-bar'
import Helmet from 'react-helmet'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactImageFallback from 'react-image-fallback';
import FooterWallmag from '../../Footer/Footer';
import JuryVote from '../../MyDialogs/JuryVote';

const theme = createMuiTheme({
    typography: {
      // Use the system font.
      fontFamily:
        '-apple-system,system-ui,BlinkMacSystemFont,' +
        'Montserrat',
    },
  })
const formatter = buildFormatter(frenchStrings)
    
var metatags_names = [];
var metatags_props = [];
var load = false
var short = []
var followingIds = new Set()
var ids = new Set()
var reported = new Set()
var user_comments =[]
var likes = []
var likesCount = 0
var data = []
var image_url_m = ''
var image_url_s = ''
var user_id = ''
var other_user_profile_picture = ''
var other_user_name = ''
var postId = ''
var likeCount = 0
var nextPageToken = ''
var likeslength = 15
var publicNPT = ''
var juryNPT = ''
var votesOf =''

export default class PostViewer extends React.Component{
    
    constructor(props){
        super(props)
        followingIds = new Set(JSON.parse(localStorage.getItem('followingIds')))    
        ids = new Set(JSON.parse(localStorage.getItem('likedPostIds'))) 
        reported = new Set(JSON.parse(localStorage.getItem('reportedPostIds')))
        this.state = {
                desc : '',
                otherUserData : '',
                slug : `${this.props.match ? this.props.match.params.postId : this.props.url}`,
                dialog : false,
                dialog_mob:false,
                post_data : '',
                dialogPost : false,
                anchor : null,
                openDelete : false,
                progress : false,
                metaAvailable : false,
                verify_user_dialog : false,
                vote_score_dialog : false,
                vote_score_dialog_mob : false,
                share_this_image : false,
                props : this.props.props,
                url : this.props.url,
                id : this.props.id,
                position : this.props.position,
                postData : this.props.postData,
                post_full : true,
                more : true,
                pageLoad : false,
                props : this.props.props ? this.props.props : this.props,
                post_id : '',
                loading : false

        }

        likeCount = this.props.postData === undefined ? '' : this.props.postData[this.props.position].likeCount

        ReactGA.initialize('UA-117934873-2', {
            gaOptions: {
              userId: JSON.parse(localStorage.getItem('current_user')) ? JSON.parse(localStorage.getItem('current_user')).displayName+'_'+JSON.parse(localStorage.getItem('current_user')).id : 'Guest_User' 
            }
          });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentDidMount(){
        // window.scrollTo(0,0)

    // arrow key func -- 2nd
    window.onkeydown = this.keyPressed;
    
    firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})
           this.getPostData()
    }

    juryLike = (comment, likeCount, postId) => {
        this.setState({loading : true,jury_vote:false})
        axios.put(jury_like+`?comment=${comment}&likeCount=${likeCount}&postId=${postId}`)
        .then(res => {
            // this.setState({jury_vote:false})
            ids.add(postId)
            localStorage.setItem('likedPostIds' ,JSON.stringify( Array.from(ids)))

            this.getComments(postId)
            this.getLikes()
            this.setState({loading:false})
            this.forceUpdate()
        })
        .catch(error=>{
        ids.delete(postId)
        localStorage.setItem('likedPostIds' ,JSON.stringify( Array.from(ids)))

        })
        this.forceUpdate()
    }

    // arrow key func -- 1st
  keyPressed = e => {

    if(e.keyCode===37){
      this.imageSliderLeft(e)
    }else if(e.keyCode === 39){
      this.imageSliderRight(e)
    }
  };

    // get Post Data
    getPostData = () =>{

    if(this.state.postData === undefined){
        this.setState({ post_full : true })
        this.fullPagePost()
    }else{
        this.setState({ post_full : false})
        this.postDialog()
    }

}

// Carousel Post View
    postDialog = () => {
        if(JSON.parse(localStorage.getItem('current_user'))){
            // when user logged in
            
                    image_url_m = this.state.postData[this.state.position].imageProperties[0].medium ? this.state.postData[this.state.position].imageProperties[0].medium : this.state.postData[this.state.position].imageProperties[0].originalUrl 
                    image_url_s = this.state.postData[this.state.position].imageProperties[0].medium ? this.state.postData[this.state.position].imageProperties[0].medium : this.state.postData[this.state.position].imageProperties[0].originalUrl 
                    user_id = this.state.postData[this.state.position].shortUserObject.id
                    other_user_profile_picture = this.state.postData[this.state.position].shortUserObject.profilePicture
                    other_user_name = this.state.postData[this.state.position].shortUserObject.name
                    short = this.state.postData[this.state.position].shortUserObject
                    data = this.state.postData[this.state.position]
                    likeCount = this.state.postData[this.state.position].likeCount
                    metatags_names = Object.entries(this.state.postData[this.state.position].metaTags.names);
                    metatags_props = Object.entries(this.state.postData[this.state.position].metaTags.properties);
        
                    this.setState({
                        metaAvailable : true,
                        pageLoad : true
                    })
                    localStorage.setItem(
                        'data_url' , JSON.stringify(this.state.postData[this.state.position])
                    )
                   
                    this.getLikes()    
                    this.getComments(this.state.postData[this.state.position].id)
                    this.viewPost(this.state.postData[this.state.position].id)
                    this.forceUpdate()
        
            
        }else{
            // when user logged out
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+new Date().getTime()+'_GpyTJy7zgV5qaGaBH1VF'
            }
            
            axios.get(get_post_by_slug+'?slug='+this.state.slug,{
                headers 
            }).then(res=>{
                image_url_m = res.data.imageProperties[0].medium ? res.data.imageProperties[0].medium : res.data.imageProperties[0].originalUrl 
                image_url_s = res.data.imageProperties[0].medium ? res.data.imageProperties[0].medium : res.data.imageProperties[0].originalUrl 
                user_id = res.data.shortUserObject.id
                other_user_profile_picture = res.data.shortUserObject.profilePicture
                other_user_name = res.data.shortUserObject.name
                short = res.data.shortUserObject
                data = res.data
                
                metatags_names = Object.entries(res.data.metaTags.names);
                metatags_props = Object.entries(res.data.metaTags.properties);
                this.setState({
                    metaAvailable : true,
                    pageLoad : true
                })
                localStorage.setItem(
                    'data_url' , JSON.stringify(res.data)
                )
                this.getComments(res.data.id)
                this.viewPost(res.data.id)
                this.forceUpdate()
            }).catch(error=>{
                if(error.response){
                    if (error.response.status === 404) {
                        window.location.assign('/page-not-found')
                    }
                }
            })
        }
    }

// Post View - Full page 
    fullPagePost = () => {
                axios.get(get_post_by_slug,{
                    params :{
                        slug : this.state.slug
                    }
                }).then(res=>{
                    image_url_m = res.data.imageProperties[0].large ? res.data.imageProperties[0].large : res.data.imageProperties[0].originalUrl 
                    image_url_s = res.data.imageProperties[0].medium ? res.data.imageProperties[0].medium : res.data.imageProperties[0].originalUrl 
                    user_id = res.data.shortUserObject.id
                    other_user_profile_picture = res.data.shortUserObject.profilePicture
                    other_user_name = res.data.shortUserObject.name
                    short = res.data.shortUserObject
                    data = res.data
                    likeCount = res.data.likeCount
                    metatags_names = Object.entries(res.data.metaTags.names);
                    metatags_props = Object.entries(res.data.metaTags.properties);
        
                    this.setState({
                        metaAvailable : true,
                        pageLoad : true,
                    })
                    localStorage.setItem(
                        'data_url' , JSON.stringify(res.data)
                    )
                    this.getComments(res.data.id)
                    this.viewPost(res.data.id)
                    this.forceUpdate()
            }).catch(error=>{
                if(error.response){
                    if (error.response.status === 404) {
                        window.location.assign('/page-not-found')
                    }
            }})
       
    }

//click on facebook
    clickOnFB = () => {
        ReactGA.event({
            category: 'Share after uploading image',
            action:'Share on Social Sites',
        })
    }

//click on copy to clipboard
    clickOnCopyToClip = () => {
        ReactGA.event({
            category: 'Share after uploading image',
            action:'Copy from clipboard',
        })
    }

// Mission Page
    goToMission=()=>{
        this.props.history.push('/missions/'+data.shortMissionObject.slug)
    }

// on Image Load
    imageLoaded = (e) => {
        e.preventDefault()        
        load = true
        this.forceUpdate()
    }

// Rightclick On an Image
    clickRight=(e)=>{
        e.preventDefault()
    }  

// Drag a photo
    onDragPhoto = (e) => {
        e.preventDefault()
    }


    componentWillUnmount = () => {
        image_url_m = ''
        image_url_s = ''
        user_id = ''
        short = []
        data = []
        user_id = ''
        other_user_profile_picture = ''
        other_user_name = ''
        load = false
    }



//Image Slider Left
  imageSliderLeft = e => {
    if (this.state.position === 0) {
    } else {
      window.history.replaceState(
        "", "", "/post/" + this.state.postData[this.state.position - 1].slug
      );

    //   likeCount = this.state.postData[this.state.position - 1].likeCount
      data = this.state.postData[this.state.position-1]
      short = this.state.postData[this.state.position-1].shortUserObject
      this.getComments(this.state.postData[this.state.position - 1].id);
      this.setState({
        position: this.state.position - 1
      });
      this.forceUpdate();
      this.getLikes()
      this.forceUpdate();

    }
  };

  //   Image Slider Right
  imageSliderRight = e => {
      
    if (this.state.position === this.state.postData.length - 1) {
    } else {
      window.history.replaceState(
        "", "", "/post/" + this.state.postData[this.state.position + 1].slug
      );

     
    //   likeCount = this.state.postData[this.state.position + 1].likeCount
      data = this.state.postData[this.state.position+1]
      short = this.state.postData[this.state.position+1].shortUserObject

      this.getComments(this.state.postData[this.state.position + 1].id);
      this.setState({
        position: this.state.position + 1
      });
      this.forceUpdate();
      this.getLikes()
      this.forceUpdate();

    }
  };

    // Edit Post
        editPost =()=>{
            this.props.history.push('/edit-post/'+this.state.slug)
        }
    
    // Delete Post
        deletePost=(e)=>{
            e.preventDefault()
            axios.delete(delete_post+this.props.id)
                 .then(res=>{
                    
                    cogoToast.success('Your Post Has been Removed')
                        this.setState({
                            openDelete:false
                        })
                        this.closeDialog()
                        window.location.assign(localStorage.getItem('loc'))
                 })
                 .catch(error=>{})
        }

    // Report Comment
        reportComment = (e, isReport, id) =>{
            e.preventDefault()
            axios.put(report_post,{
                    isReport : isReport ,
                    postId : id,
            })
            .then(res=>{
                if(isReport===true){
                    reported.add(this.state.comment_id)
                    cogoToast.success('Your Have Reported this Comment')
                }else{
                    reported.delete(this.state.comment_id)
                    cogoToast.success('Your Have UnReported this Comment')
                }
                    localStorage.setItem('reportedPostIds' ,JSON.stringify( Array.from(reported)))
                    this.forceUpdate()
                    this.setState({
                        openDelete:false,
                })
            })
            .catch(error=>{
                this.setState({
                    openDelete:false
                })
            })
        }

    // Delete Comment
        deleteComment =(e) =>{
            axios.delete(delete_post+this.state.comment_id)
            .then(res=>{
                cogoToast.success('Your Comment Has been Removed')
                this.setState({
                    openDelete:false
                })
                this.getComments(localStorage.post_id)
            })
            .catch(error=>{})
    }

    // Report Post
    reportPost=(e,isReport)=>{
        e.preventDefault()
        this.setState({
            openDelete:false,
            anchor:false,
            
        })
        axios.put(report_post,{
                isReport : isReport ,
                postId : data.id,
        })
        .then(res=>{

            if(isReport === true){
                reported.add(data.id)
                cogoToast.info('Your Have Reported this Post')
            }else{
                reported.delete(data.id)
                cogoToast.info('Your Have UnReported this Post')
            }
            localStorage.setItem('reportedPostIds' ,JSON.stringify( Array.from(reported)))
            this.forceUpdate()
           
        })
        .catch(error=>{
            this.setState({
                openDelete:false
            })
            // if(error.response.status===401){
            //         cogoToast.info('Please Login First')
            //         localStorage.setItem('loc',window.location.href)
            //          this.props.history.push('/login')
            //     }
        })
    }

    // Delete Post
        deletePost=(e)=>{
            e.preventDefault()
            axios.delete(delete_post+data.id)
                .then(res=>{
                    cogoToast.success('Your Post Has been Removed')
                    this.setState({
                        openDelete:false
                    })
                    window.location.assign(localStorage.getItem('loc'))
             })
             .catch(error=>{})
        }

    // Menu
        handle_menu =(event)=>{
        this.setState({
            anchor : event.currentTarget
        })
        }
 
    // Menu Close
        handleClose=(e)=>{
            e.preventDefault()
            this.setState({
              anchor : null
            })
          }

    // share Post 
        clickOnShare = () => {
            ReactGA.event({
                category: 'Share from profile',
                action:'Share on Social Sites',
            })
        }

    // view a post
        viewPost=(id)=>{
        if(JSON.parse(localStorage.getItem('current_user'))){
            // when user logged in
            axios.put(view_post+'?postId='+id)
            .then(res=>{})
        }else{
            // when user logged out
                var headers = {
                    'Authorization' : 'Bearer '+new Date().getTime()+'_GpyTJy7zgV5qaGaBH1VF'
                }
                axios.put(view_post+'?postId='+id, {headers})
                .then(res=>{})
        }}

//  Close Jury Dialog
    closeJuryDialog = () => {
        this.setState({
            jury_vote : false
        })
    }

// on Change value
    change = (e)=>{
        this.setState({
          [e.target.name] : e.target.value,
          open : false
        })
      }

      sendCode=()=>{
        firebase.auth().currentUser.sendEmailVerification()
        cogoToast.success('Verification Code ha been Send !!!')
      }

    // add new comment
        addNewComment = (post_id) => {
            if(JSON.parse(localStorage.getItem('current_user'))){

            // when user logged in
            if(this.state.desc===''){
                // when comment box is empty
                cogoToast.info('comment box is empty')

            }else{
                this.setState({
                    progress : true
                })
                this.forceUpdate()
                axios.post(add_comment+'?postId='+post_id,
                {
                    'title' : this.state.desc
                }).then(res=>{
                    this.setState({
                        desc:'',
                        progress : false
                    })
                    document.getElementById('textarea_desc').value = ''
                    this.getComments(post_id)
                }).catch(error=>{})
            }

        }else{

            // when useer logged out
            window.scrollTo(0,0)
            localStorage.setItem('loc',window.location.href)
            this.state.props.history.push('/login')
        }
    }

    // get Comments
        getComments = (id) =>{
        
            postId = id
            user_comments = []

        if((JSON.parse(localStorage.getItem('current_user')))){
            // when user logged in
            axios.get(get_comments+'?parentPostId='+id+'&limit=20').then(
                res=>{
                    if(res.data.items){
                        user_comments = res.data.items
                        this.setState({
                            cursor : res.data.nextPageToken,
                            hasMore : true,
                        })
                    }else{
                        if(user_comments.length===1){
                            user_comments = []
                        }
                        this.setState({
                            hasMore : false
                        })
                    }
                    this.forceUpdate()
                }
            ).catch(error=>{})

        }else{
            // when user logged out
            this.setState({postID : id})
            var headers = {
                'Authorization' : 'Bearer '+new Date().getTime()+'_GpyTJy7zgV5qaGaBH1VF'
            }
            axios.get(get_comments+'?parentPostId='+id+'&limit=20',{
                headers
            }).then(
                res=>{
                    if(res.data.items){
                        user_comments = res.data.items
                        
                        this.setState({
                            cursor : res.data.nextPageToken,
                            hasMore : true
                        })
                    }else{
                        this.setState({
                            hasMore : false
                        })
                    }
                    
                }
            ).catch(error=>{})
        }}

    // load more comments
    loadMoreComments = () =>{
        if((JSON.parse(localStorage.getItem('current_user')))){
            // when user logged in
            axios.get(get_comments+'?parentPostId='+postId+'&cursor='+this.state.cursor+'&limit=10').then(
                res=>{
                    if(res.data.items){
                        user_comments = user_comments.concat(res.data.items)
                        this.setState({
                            cursor : res.data.nextPageToken,
                            hasMore : true
                        })
                    }else{
                        this.setState({
                            hasMore : false
                        })
                        cogoToast.warn('No More Comments')
                    }
                this.forceUpdate()
                }
            ).catch(error=>{})
        }else{
            // when user logged out
            var headers = {
                'Authorization' : 'Bearer '+new Date().getTime()+'_GpyTJy7zgV5qaGaBH1VF'
            }
            axios.get(get_comments+'?parentPostId='+postId+'&cursor='+this.state.cursor+'&limit=10',{
                headers
            }).then(
                res=>{
                    if(res.data.items){
                        user_comments = user_comments.concat(res.data.items)
                        this.setState({
                            cursor : res.data.nextPageToken,
                            hasMore : true
                        })
                    }else{
                        this.setState({
                            hasMore : false
                        })
                        cogoToast.warn('No More Comments')
                    }
                    this.forceUpdate()
                }
            ).catch(error=>{})
        }}

    // like comments
        likeComment = (e,comment_id, like)=>{
            e.preventDefault()
            if((JSON.parse(localStorage.getItem('current_user')))){
                // when user logged in
                const params ={
                    'postId':comment_id,
                    'isLike' : like
                }
                axios.put(is_like, 
                    params)
                .then(response=>{
                    if(like===true){
                        ids.add(comment_id)
                    }else{
                        ids.delete(comment_id)
                    }
        
                    localStorage.setItem('likedPostIds' ,JSON.stringify( Array.from(ids)))

                    if(JSON.parse(localStorage.getItem('post_data'))){
                        if(JSON.parse(localStorage.getItem('post_data')).slug === this.state.slug){
                            this.getComments(localStorage.post_id)
                        }else{
                            this.getComments(data.id)
                        }
                        
                    }else{
                        this.getComments(data.id)
                    }
        
                }).catch(error =>{})

            }else{
            // when user logged out
            window.scrollTo(0,0)
            localStorage.setItem('loc',window.location.href)
            this.state.props.history.push('/login')
        }}
    
    // vote
    vote = (e , post_id, like) => {
            e.preventDefault()

            if(JSON.parse(localStorage.getItem('current_user'))){
                if(JSON.parse(localStorage.getItem('current_user')).badge==='0' || like === false){
                
                
                firebase.auth().onAuthStateChanged((user)=>{
                    if(user.emailVerified === false){
                        var actionCodeSettings = {
                            url: 'https://wallmag.io/',
                        };
                        firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
                            .then(function() {
                            // Verification email sent.
                            })
                            .catch(function(error) {
                            // Error occurred. Inspect error.code.
                            });
                            this.setState({
                                verify_user_dialog : true
                            })
                  }else{
                        localStorage.setItem("user_ver", 'true');
        
            // when user logged in
                    
                    if(like===true){
                        ids.add(post_id)
                    }else{
                        ids.delete(post_id)
                    }
                    
                    localStorage.setItem('likedPostIds' ,JSON.stringify( Array.from(ids)))
                    this.forceUpdate()

                    const params ={
                        'postId':post_id,
                        'isLike' : like
                    }

                    axios.put(is_like, params).then(response=>{
                        likeCount = response.data.likeCount
                        this.getComments(post_id)
                        this.getPostBySlug()
                    }).catch(error =>{
                        if(like===true){
                            ids.delete(post_id)
                        }else{
                            ids.add(post_id)
                        }
                        localStorage.setItem('likedPostIds' ,JSON.stringify( Array.from(ids)))
                        this.forceUpdate()
       })
    }})
           }else{
                this.setState({
                    jury_vote : true,
                    post_id : post_id,
                })
           }
            }else{
                // when user logged out
                window.scrollTo(0,0)
                localStorage.setItem('loc',window.location.href)
                this.state.props.history.push('/login')
        }
    }

//like count
    getPostBySlug = ()=>{

        axios.get(get_post_by_slug,{
            params :{
                slug : window.location.pathname.replace('/post/','')
            }
        }).then(res=>{
            if(JSON.parse(localStorage.getItem('post_data'))){
                if(JSON.parse(localStorage.getItem('post_data')).slug === window.location.pathname.replace('/post/','')){
                    localStorage.setItem('post_data', JSON.stringify(res.data))   
                }else{
                    data = res.data
                    
                }
                            
            }else{
                data = res.data
            }
            
            this.forceUpdate()
        }).catch(error=>{})
    }


    //like count
    getLikes = ()=>{

        axios.get(get_post_by_slug,{
            params :{
                slug : window.location.pathname.replace('/post/','')
            }
        }).then(res=>{
                likeCount = res.data.likeCount
                this.forceUpdate()
            }).catch(error=>{})
    }


    // follow user
        followUser = (e, userId,isFollow)=>{
            e.preventDefault()

            axios.put(follow_user, {
                isFollow : isFollow,
                userId : userId
            }).then(response =>{
                
                if(isFollow===true){
                    followingIds.add(userId)
                }else{
                    followingIds.delete(userId)
                }
                this.forceUpdate()
                localStorage.setItem('followingIds' ,JSON.stringify( Array.from(followingIds)))
                
                axios.get(get_user_by_slug,{
                    params :{
                        slug : (JSON.parse(localStorage.getItem('other_user')).slug)
                    }
                }).then(res=>{
                    localStorage.setItem('other_user',JSON.stringify(res.data))
                    this.forceUpdate()
                    }).catch(error=>{})
            }).catch(error=>{})
        }

    // open LikeUsersList (dialog)
        openLikeUsersList = (e, post_id, dialog,lc)=>{
            e.preventDefault()
            this.setState({more : true})
            likesCount = lc
            likes = []
            nextPageToken =''

            votesOf = 'post'
            if(JSON.parse(localStorage.getItem('current_user'))){
                // when user logged in

                  if(dialog === true){
                    this.setState({
                        dialog:true
                    })
                }else{
                    this.setState({
                        dialog_mob:true
                    })
                }

                axios.get(get_post_likes+'?limit=15&nextPageToken='+nextPageToken+'&postId='+post_id)
            .then(res=>{

                // likesCount = res.data.likesCount
                likes = res.data.items
                nextPageToken = res.data.nextPageToken
              
                this.getPostBySlug()
            })
            .catch(error=> {
                console.log(error)
            })

            }else{
            // when user logged out
                window.scrollTo(0,0)
                localStorage.setItem('loc',window.location.href)
                window.location.assign('/login')
                // this.props.history.push('/login')
           }}

//More Likes
    loadMoreLikes = (e, post_id) => {

            var likesList ;
            

            if(votesOf === 'post'){
                likesList = get_post_likes
            }
            if(votesOf === 'public'){
                likesList = get_public_votes
            }
            if(votesOf === 'jury'){
                likesList = get_jury_votes
            }
        
            axios.get(likesList+'?postId='+post_id+'&limit=15&nextPageToken='+nextPageToken)
            .then(res=>{
              
                likes = likes.concat(res.data.items)
                if (likes.length === res.data.itemCount) {
                    this.setState({ more: false });
                    return;
                  }
                nextPageToken = res.data.nextPageToken

                this.forceUpdate()
            })
            .catch(error=> {
                this.setState({ more: false });
            })

    }

// open Public Votes (dialog)
    publicVotes = (e, post_id,dialog)=>{

        e.preventDefault()

        this.setState({more : true})
        likesCount = 0
        likes = []
        nextPageToken = ''
        
       votesOf = 'public'
        if(JSON.parse(localStorage.getItem('current_user'))){

            if(dialog === true){
                this.setState({
                    dialog:true
                })
            }else{
                this.setState({
                    dialog_mob:true
                })
            }

            // when user logged in
            axios.get(get_public_votes+'?postId='+post_id+'&limit=20&nextPageToken='+nextPageToken)
        .then(res=>{
            likes = res.data.items
            likesCount = res.data.itemCount
            nextPageToken = res.data.nextPageToken 

            this.getPostBySlug()
        })

        }else{
            
            // when user logged out

            // localStorage.setItem('redirect', '/post/'+this.state.slug)
            window.scrollTo(0,0)
            localStorage.setItem('loc',window.location.href)
            this.state.props.history.push('/login')
        }
    }

// open LikeUsersList (dialog)
    juryVotes = (e, post_id, dialog)=>{

        e.preventDefault()
        this.setState({more : true})
        likesCount = 0
        likes = []
        votesOf = 'jury'
        nextPageToken =''

        if(JSON.parse(localStorage.getItem('current_user'))){

            // when user logged in

            if(dialog === true){
                this.setState({
                    dialog:true
                })
            }else{
                this.setState({
                    dialog_mob:true
                })
            }

            axios.get(get_jury_votes+'?postId='+post_id+'&limit=10')
        .then(res=>{

            likes = res.data.items
            likesCount = res.data.itemCount
            nextPageToken = res.data.nextPageToken 


            this.getPostBySlug()
        })

        }else{
        
        // when user logged out

        // localStorage.setItem('redirect', '/post/'+this.state.slug)
        window.scrollTo(0,0)
        localStorage.setItem('loc',window.location.href)
        this.state.props.history.push('/login')
    }
        
    }

    // Redirect to Profile page 
        goToProfile = (from ,userSlug) =>{
            if(userSlug === undefined){}
            else{
                localStorage.setItem('other_user_slug',userSlug)

                if(this.state.post_full){
                    this.props.history.push('/'+userSlug)
                }else{
                    this.props.props.history.push('/'+userSlug)

                }
            }
        }

    // close Dialog
    closeDialog=()=>{
        this.setState({
            dialog:false,
            dialog_mob:false,
            openDelete:false,
            verify_user_dialog : false,
            vote_score_dialog : false,
            vote_score_dialog_mob : false,
            share_this_image : false
        })
    }

    render(){
      
        return(
            <>
            {
                this.state.metaAvailable === false ? '' :
 
                  
                <Helmet>
                {metatags_names.map((res) => {
                  return (
                    <meta name={res[0]} content={res[1]} />
                  );
                })}
                
                {
                    metatags_names.map((res)=>{
                        return res[0] === "title" ? (
                            <title>{res[1]}</title>
                          ) : ''
                    })
                }
    
                {metatags_props.map((res) => {
                  return <meta property={res[0]} content={res[1]} />;
                })}
              </Helmet>
    }
         
    { 
        window.outerWidth <768 ? 

        !this.state.pageLoad ? 
        <div style={{position:'relative', height:`${window.innerHeight}px`}}>
        <CircularProgress style={{position:'absolute', top:'50%',left:'47%'}} />
        </div>
        :
    <div style={{height:`${window.innerHeight}px`, background:'black'}}>
                    
        {
            this.state.post_full === true ?
            <MobHeaderNoImg />
            :
            ''
        }



    <div className='container-fluid' style={{background:'black'}}>
    {
                            this.state.loading ?
                            <CircularProgress color='secondary' />
                            :''
                        }
        
        <div className='row'>
                    

    {/* user Image , Name , follow  */}
    <div className='col-11 col-sm-11 user-profile' style={{textAlign:'start'}}>
            <div  className='row' style={{marginTop:`${ this.state.post_full === true ? `40px` : ``}`, marginLeft:'2px'}}>
                        <img 
                            src={this.state.post_full ? other_user_profile_picture :                           
                            this.state.postData[this.state.position]
                            .shortUserObject.profilePicture}
                            alt="User"
                            onClick={e=>this.goToProfile(1,short.slug)}
                            style={{height:'40px', width:'40px',border:'1px solid #ffffff50',
                                    borderRadius:'60px', objectFit:'cover'}}
                        />

                    <div>


<div>

{
    short.badge === '0' ? 
        <>
            <p className='user-name2' style={{cursor:'pointer'}} onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
        </>
        :
            short.badge === '1' ? 
        <>
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}} onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/1.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div>
        </>
        :
            short.badge === '2' ? 
        <>
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}} onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/2.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div> 
        </>
        :
        short.badge === '3' ? 
        <>       
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}} onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/3.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div>
        </>
        :
        <>
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}} onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/4.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div>
        </>     
    }
</div>

        {/* User Profile Created on --> */}
            <p className='post-date' onClick={e=>this.goToProfile(1,short.slug)} style={{marginLeft:'10px', marginTop:'-10px'}}>
                <TimeAgo date={parseInt(data.dateCreated)} formatter={formatter} />
            </p>
        </div>    
    </div>
</div>

                     {/* --------------------------------------------------------- */}
            
        <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 user-more'   >
            <div className='row'  style={{justifyContent:'flex-end'}}>

            <img src={require('../../../logo/more.svg')}
                     alt='more'
                     className='more_opt'
                     style={{marginTop:`${ this.state.post_full === true ? `12px` : `-27px`}`}}
                     onClick ={e=>this.handle_menu(e)}
                     />   

        <Menu
            style={{marginTop:'40px'}}
            id="simple-menu"
            anchorEl={this.state.anchor}
            keepMounted
            open={Boolean(this.state.anchor)}
            onClose={e=>this.handleClose(e)}
        >

            {
                JSON.parse(localStorage.getItem('current_user')) ? 
                JSON.parse(localStorage.getItem('current_user')).id === user_id ?

                <>
                    <MenuItem  onClick={e=>this.editPost()}>
                        <p> Edit </p>
                    </MenuItem>
                    
                    <MenuItem  onClick={e=>this.setState({openDelete : true, action : 'delete', anchor:null})}>
                        <p> Delete </p>
                    </MenuItem>
                </>

                :
                    !reported.has(data.id)?                         
                            
                    <MenuItem  onClick={e=>this.setState({openDelete:true, action:'report'})}>
                        <p>Report</p>
                    </MenuItem>
                    :

                    <MenuItem  onClick={e=>this.reportPost(e,false)}>
                        <p>UnReport</p>
                    </MenuItem>
                :
                    <MenuItem  onClick={e=>this.reportPost(e, true)}>
                        <p> Report </p>
                    </MenuItem>
            }    
        </Menu>

                                                                        
            </div>                                                            
        </div>
                            {/* --------------------------------------------------------------------- */}
                           
                {
                    this.state.post_full == true
                    ? 
                    
                    <div className='container' style={{marginTop:'30px'}} >
                    <img
                      onLoad={e=>this.imageLoaded(e)}
                      onDragStart={e=>this.onDragPhoto(e)}
                      onDrag={e=>this.onDragPhoto(e)}
                      onContextMenu={e=>this.clickRight(e)}  
                      style={{maxHeight:`${window.innerHeight-260}px`}} 
                      src={image_url_s}
                      alt='Post' />
            </div>

                    : 
                    <>
                        <img 
                            className='prev-icon'
                            alt='next'
                            src={require('../../../logo/lft.svg')} 
                            onClick={e => this.imageSliderLeft(e)}/>    

                        <img 
                            className='next-icon'
                            alt='next'
                            src={require('../../../logo/rgt.svg')} 
                            onClick={e => this.imageSliderRight(e)}/>

                <div className='container' style={{marginTop:'30px', textAlign:'center'}} >
                      <img
                        onLoad={e=>this.imageLoaded(e)}
                        onDragStart={e=>this.onDragPhoto(e)}
                        onDrag={e=>this.onDragPhoto(e)}
                        onContextMenu={e=>this.clickRight(e)}  
                        style={{maxHeight:`${window.innerHeight-260}px`}} 
                        src={ this.state.postData[this.state.position]
                            .imageProperties[0].small
                            ? this.state.postData[this.state.position]
                                .imageProperties[0].small
                            : this.state.postData[this.state.position]
                                .imageProperties[0].originalUrl
                        }
                        alt='Post' />
              </div>
                    </>
                }
     


              {
                data.specialText ? 
                    <div className='container-fluid' style={{margin:'5px'}}>
                        <div className='row justify-content-center'>
                            <div className='mt-4' style={{border:'1px solid #707070', borderRadius:'60px'}}>
                                <div 
                                    className='row justify-content-center ml-3 mr-4' 
                                    onClick={this.goToMission} 
                                    style={{cursor:'pointer', display:'flex'}}>
                                    
                                    {data.specialImage ? 
                                        <img 
                                            style={{height:'30px', width:'30px',padding:'5px',margin:'auto',flex:'.12'}}
                                            src={data.specialImage}
                                            alt='contestLogo'
                                        /> : ''}
                
                                    <p className='winner' style={{flex:'1'}}>
                                        {data.specialText}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            :
            ''
    }

{/* No. of Comments & votes  */}

{    load === false ? <></>
        :
        <div className='col-12 col-sm-12 '  style={{marginTop:'10px'}}>
            <div className='row' style={{marginLeft:'30px', justifyContent:'start'}}>
                <div  className='row'>
                    <div className='row'>
                        <img 
                            src={require('../../../logo/comments.svg')} 
                            alt="Comments"
                            style={{height:'16px', width:'16px', marginTop:'15px'}}
                          />
                            {
                            user_comments ? 
                                <p id='comments'>{data.commentCount}</p>
                              :
                                <p id='comments'>0</p>
                            }

                            <img src={require('../../../logo/likes.svg')} 
                                alt="Likes"
                                onClick={e=>this.openLikeUsersList(e, data.id,false,likeCount)}
                                style={{cursor:'pointer'}}
                            />
                            <p
                                style={{cursor:'pointer'}} 
                                onClick={e=>this.openLikeUsersList(e, data.id,false,likeCount)}
                                id='likes'>{likeCount}</p>
                              
                            {
                                ids.has(data.id) ?
                                                              
                                        <div className='row vote_button5' 
                                            onClick={e=>this.vote(e, data.id, false)}
                                        >
                  
                                            <img    
                                                id='like1' src={require('../../../logo/liked.svg')} alt='liked'
                                                style={{marginTop:'07px', height:'18px'}}
                                            />
                                                <p id='liked-post2' >{
                                                    data.shortMissionObject ? 
                                                        data.shortMissionObject.currentTime > data.shortMissionObject.finaleTime ? 
                                                        'Liked' : 'Voted'
                                                        :
                                                        'Liked' 
                                                }</p>
                                            </div> 
                                        :

                                        <div 
                                            className='row vote_button4' 
                                            onClick={e=>this.vote(e, data.id, true)}>

                                                <img    
                                                    id='like1' src={require('../../../logo/like.svg')} alt='liked'
                                                    style={{marginTop:'07px', height:'18px'}}
                                                />
                                                <p id='liked-post1' >{
                                                    data.shortMissionObject ? 
                                                        data.shortMissionObject.currentTime > data.shortMissionObject.finaleTime ? 
                                                        'Like' : 'Vote'
                                                        :
                                                        'Like' 
                                                    }</p>
                                        </div> 

                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>


    {
              data.voteScore === 0 ? '' : 

      <div className='Rectangle-1999'  onClick={()=>{this.setState({vote_score_dialog_mob : true})}}>
        <div className='row' style={{ margin: "5px 10px 0px 10px"}}>
          <div>
            <img className='grp-icon' src={require('../../../logo/Group1342.svg')} alt='Group Icon' />
          </div>
       
          <div>
            <p className='votescore'>Vote Score - {parseFloat(data.voteScore).toFixed(3)}</p>
            <p className='votescore_desc'>Learn More</p>
          </div>
    
        </div>
    </div>
}


{/* ----------------------------sec-B-------------------------------------------------------------- */}

{ load === false ? 
                                  <></>
                                  
                                  :
            <div style={{textAlign:'start', marginTop:'20px'}}>
                  <p id='title-post'>
                      {data.title}
                  </p>

                  <p id='desc-post'>
                  {data.description}
                  </p>


    {/* social-share */}

     <div onClick={e=>this.clickOnShare()}  className='row' style={{marginLeft:'2px', marginTop:'30px'}}>
            
        <WhatsappShareButton  style={{outline:'none', cursor:'pointer', margin:'0px'}} className='sc' title='' url={window.location}>
            <WhatsappIcon round size='30px' />
        </WhatsappShareButton>

        <FacebookShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={window.location}>
            <FacebookIcon round size='30px'/>
        </FacebookShareButton> 

        <FacebookMessengerShareButton appId='421685814838229' className='sc'  url={window.location}>
            <FacebookMessengerIcon round size='30px' />
        </FacebookMessengerShareButton>
        
        <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={window.location}>
            <LinkedinIcon round size='30px' />
        </LinkedinShareButton>
            
        <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={window.location} title=''>
            <TwitterIcon round size='30px' />
        </TwitterShareButton>
    </div> 


</div>
}


{/* User Comments */}


{
    load === false ? 
    <></>

    :
                    user_comments? 
                    user_comments.map((value,index)=>{

                    return(

                        <>
                        <div className='row'>
                        <div 
                        className='col-10 col-sm-10 col-md-10 col-lg-6 col-xl-7' 
                        style={{textAlign:'start'}}>

                            <div className='row' style={{marginTop:'40px', marginLeft:'0px'}}>
                                    <img 
                                    src={value.shortUserObject.profilePicture} 
                                    onError={(e) => {
                                        e.target.onerror = null
                                        e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                      }}
                                    alt="Profile"
                                    onClick={() => this.goToProfile(1,value.shortUserObject.slug)}
                                    style={{height:'40px', width:'40px',border:'1px solid #ffffff50', 
                                    borderRadius:'60px', objectFit:'cover', cursor:'pointer'}}
                                />

                                    <div style={{marginTop:'2px'}}>
                                    <div>
    {value.shortUserObject.badge === "0" ? (
    <>
    <p
    className="user-name2"
    style={{ cursor: "pointer" }}
    onClick={e =>
    this.goToProfile(
    1,
    value.shortUserObject.slug
    )
    }
    >
    {value.shortUserObject.name}
    </p>
    </>
    ) : value.shortUserObject.badge === "1" ? (
    <>
    <div className="row">
    <p
    className="user-name"
    style={{ cursor: "pointer" }}
    onClick={e =>
    this.goToProfile(
    1,
    value.shortUserObject.slug
    )
    }
    >
    {value.shortUserObject.name}
    </p>
    <img
    src={require("../../../logo/1.png")}
    alt='Logo'
    style={{
    height: "28px",
    width: "20px",
    marginTop: "-5px",
    marginLeft: "5px"
    }}
    />
    </div>
    </>
    ) : value.shortUserObject.badge === "2" ? (
    <>
    <div className="row">
    <p
    className="user-name"
    style={{ cursor: "pointer" }}
    onClick={e =>
    this.goToProfile(
    1,
    value.shortUserObject.slug
    )
    }
    >
    {value.shortUserObject.name}
    </p>
    <img
    src={require("../../../logo/1.png")}
    alt='Logo'
    style={{
    height: "28px",
    width: "20px",
    marginTop: "-5px",
    marginLeft: "5px"
    }}
    />
    </div>
    </>
    ) : value.shortUserObject.badge === "3" ? (
    <>
    <div className="row">
    <p
    className="user-name"
    style={{ cursor: "pointer" }}
    onClick={e =>
    this.goToProfile(
    1,
    value.shortUserObject.slug
    )
    }
    >
    {value.shortUserObject.name}
    </p>
    <img
    src={require("../../../logo/3.png")}
    alt='Logo'
    style={{
    height: "28px",
    width: "20px",
    marginTop: "-5px",
    marginLeft: "5px"
    }}
    />
    </div>
    </>
    ) : (
    <>
    <div className="row">
    <p
    className="user-name"
    style={{ cursor: "pointer" }}
    onClick={e =>
    this.goToProfile(
    1,
    value.shortUserObject.slug
    )
    }
    >
    {value.shortUserObject.name}
    </p>
    <img
    src={require("../../../logo/4.png")}
    alt='Logo'
    style={{
    height: "28px",
    width: "20px",
    marginTop: "-5px",
    marginLeft: "5px"
    }}
    />
    </div>
    </>
    )}
    </div>

                                        <p className='post-date' style={{marginLeft:'15px', marginTop:'-10px'}}>
                                            <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                            {
                                                    value.shortUserObject.badge === '0' ? '' :
                                                    ` | Jury's Comment`
                                                } 
                                        </p>
                                        
                                    </div>    

                                
                                <div className='container'>
                                    <p className='user-comm'>
                                        {value.title}
                                    </p>
                                </div>


                            </div>
                            
                        </div>
                        </div>

                                                        {/* ///////////////////////////////////////////////////////////////////////// */}

    {
    !(ids.has(value.id)) ?
    <div className='row' style={{marginLeft:'55px'}}>
        <img 
            src={require('../../../logo/like.svg')} 
            alt="likes"
            style={{height:'20px', width:'20px', cursor:'pointer'}}
            onClick={e=>this.likeComment(e,value.id,true)}
        />
        {
            value.likeCount === '1' ?
                <p  className='small_text' onClick={e=>this.openLikeUsersList(e, value.id, false,value.likeCount)}>{value.likeCount} like</p>
            : value.likeCount === '0' ? '' : 
                <p  className='small_text' onClick={e=>this.openLikeUsersList(e, value.id, false,value.likeCount)}>{value.likeCount} likes</p>
        }
            
            { !JSON.parse(localStorage.getItem('current_user')) ? '' :
        (JSON.parse(localStorage.getItem('current_user')).id) === value.shortUserObject.id ?

        <img 
        onClick={e=>{this.setState({openDelete : true , action : 'c_delete' , comment_id : value.id})}}
        className='comment_more_opt' 
        src={require('../../../logo/comment_delete.svg')} 
        alt='comment_delete' />

        :
            reported.has(value.id) ? 
        <p 
        onClick={e=>{this.reportComment(e, false, value.id)}}
        className='reported'> UnReport</p>

        :

        <p 
        onClick={e=>{this.setState({openDelete : true , action : 'c_report',is_reported : true , comment_id : value.id})}}
        className='reported'> Report</p>
    }

    </div>

    :

    <div className='row' style={{marginLeft:'55px'}}>
    <img 
        src={require('../../../logo/liked.svg')} 
        alt="liked"
        style={{height:'20px', width:'20px', cursor:'pointer'}}
        onClick={e=>this.likeComment(e,value.id,false)}
    />
                                                            {
            value.likeCount === '1' ?
                <p  className='small_text' onClick={e=>this.openLikeUsersList(e, value.id, false,value.likeCount)}>{value.likeCount} like</p>
                : value.likeCount === '0' ? '' : 
                <p  className='small_text' onClick={e=>this.openLikeUsersList(e, value.id, false,value.likeCount)}>{value.likeCount} likes</p>
        }

    {
    (JSON.parse(localStorage.getItem('current_user')).id) === value.shortUserObject.id ?

    <img 
    onClick={e=>{this.setState({openDelete : true , action : 'c_delete' , comment_id : value.id})}}
    className='comment_more_opt' 
    src={require('../../../logo/comment_delete.svg')} 
    alt='comment_delete' />

    :
    reported.has(value.id) ? 
    <p 
    onClick={e=>{this.reportComment(e, false, value.id)}}
    className='reported'> UnReport</p>

    :

    <p 
    onClick={e=>{this.setState({openDelete : true , action : 'c_report',is_reported : true , comment_id : value.id})}}
    className='reported'> Report</p>
    }
    </div>
                                                        }


    {/* ////////////////////////////////////////////////////////////////////// */}

    </>
                    )
        })
                    
                    :<></>
                }

   {/*----- Load More Comments ------*/}

{
    load === false ? 
    <></>
    :
    this.state.hasMore === true ? 
        <div className='container-fluid'> 
            <p
                onClick={e=>this.loadMoreComments(e)} 
                className='load_more'>
                Load Older Comments</p>
        </div>
    :
    <></>
}

{
    load === false ? <></>
    :
    this.state.progress === true ?
        <CircularProgress />
    :
    <div className='row'>
        <div style={{textAlign:'start', background:'#000', borderRadius:'6px', margin:'30px 0px 0px 30px'}}>
            <div className='row' style={{marginTop:'15px'}}>
                <img 
                    src={(JSON.parse(localStorage.getItem('current_user'))) ? 
                    (JSON.parse(localStorage.getItem('current_user'))).photoUrl :
                    'https://firebasestorage.googleapis.com/v0/b/wallmag-2.appspot.com/o/user.svg?alt=media&token=a0aaff3b-37a1-42d0-a4bc-8e52bfd2d597'
                }
                    alt="User"
                    style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover',border:'1px solid #ffffff50'}}
                />
            <div style={{marginTop:'2px'}}>
                        
                        
                <textarea name='desc' id='textarea_desc'  placeholder='Write Something Nice' cols="30" rows="4"  
                            style={{ marginLeft:'10px', border:'none',outline:'none', borderRadius:'6px',padding:'5px',fontSize:'12px'}}
                            onChange={e => this.change(e)}
                            >
                </textarea>
            </div>    
        </div>
    </div>

        <div style={{ height:'40px', width:'40px', marginLeft:'20px',marginTop:'37px',cursor:'pointer'}} onClick={e=>this.addNewComment(data.id)}>
        <img src={require('../../../logo/send-msg.svg')} alt='send-msg' height='30' width='30' style={{marginTop:'10px'}}/>
            {/* <img src={require('../../../logo/arrow.svg')} alt="Arrow" height='20' width='20' style={{marginTop:'10px'}} /> */}
        </div>
    </div>
    }

{/* --------------------------------sec-B--------------------------------------- */}

    {load === false ? <></> :
            this.state.post_full === true ? 
            <div>
            <FooterWallmag />
        </div>
        :
        ''
    }
    </div>
    </div>


        :

        
        !this.state.pageLoad ? 
        <div style={{position:'relative', height:`${window.innerHeight}px`}}>
           <CircularProgress style={{position:'absolute', top:'50%',left:'47%'}} />
        </div>
        :

        <div style={{height:`${window.innerHeight}px`, background:'black',marginTop:`${
            this.state.post_full === true ? `-80px` : ``
        }`}}>

            {
                this.state.post_full === true ?
                <HeaderNoImg />
                :
                ''
            }
            
            <div className='container-fluid' style={{background:'black'}}>
            {
                                    this.state.loading ?
                                    <CircularProgress color='secondary' />
                                    :''
                                }
     
                <div className='row'>
                    {/* user Image , Name , follow  */}
                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6' style={{textAlign:'start'}}>
                            <div  className='row' style={{marginTop:'40px', marginLeft:'32px'}}>
                                        <img 
                                            src={this.state.post_full ? other_user_profile_picture :                           
                                                this.state.postData[this.state.position]
                                                .shortUserObject.profilePicture}
                                            alt="User"
                                            onClick={e=>this.goToProfile(1,short.slug)}
                                            style={{height:'52px', width:'52px', border:'1px solid #ffffff50',
                                                    borderRadius:'60px', objectFit:'cover'}}
                                        />
                                    <div style={{marginTop:'3px'}}>

                                <div>

{
    short.badge === '0' ? 
        <>
            <p className='user-name2' style={{cursor:'pointer'}}  onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
        </>
        :
            short.badge === '1' ? 
        <>
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}}  onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/1.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div>
     
        </>
        :
            short.badge === '2' ? 
        <>
       
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}}  onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/2.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div>
        </>
        :
            short.badge === '3' ? 
        <>
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}}  onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/3.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div>
        </>
        :
        <>
            <div className='row'>
                <p className='user-name' style={{cursor:'pointer'}}  onClick={e=>this.goToProfile(1,short.slug)}>{short.name}</p>
                <img src={require('../../../logo/4.png')} alt="Logo" style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>
            </div>
        </>
    }

</div>

                                        <p onClick={e=>this.goToProfile(1,short.slug)} className='post-date' style={{marginLeft:'15px', marginTop:'-10px'}}>
                                            <TimeAgo date={parseInt(data.dateCreated)} formatter={formatter} />
                                        </p>
                                    </div>          
                            </div>

                    </div>

          {/* No. of Comments & votes  */}

              <div className='col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5'  style={{marginTop:'40px'}}>
                  <div className='row' style={{marginTop:'10px', marginRight:'10px', justifyContent:'flex-end'}}>

                      <div  className='row'>
                         
                               
                          <div className='row'>

                          <img 
                            src={require('../../../logo/comments.svg')} 
                            alt="Comments"
                            style={{height:'16px', width:'16px', marginTop:'15px'}}
                          />
                          {
                              user_comments ? 
                              <p id='comments'>{data.commentCount}</p>
                              :
                              <p id='comments'>0</p>
                          }
                              


                                                              <img 
                                                                  src={require('../../../logo/likes.svg')} 
                                                                  alt="Likes"
                                                                  onClick={e=>this.openLikeUsersList(e, data.id,true,likeCount)}
                                                                  style={{cursor:'pointer'}}
                                                              />
                                                              <p 
                                                              style={{cursor:'pointer'}}
                                                              onClick={e=>this.openLikeUsersList(e, data.id,true,likeCount)}
                                                              id='likes'>{likeCount}</p>
                              
                        {
                            ids.has(data.id) ?
                        
                                    <div className='row vote_button5' 
                                        onClick={e=>this.vote(e, data.id, false)}
                                    >

                                        <img    
                                            id='like1' src={require('../../../logo/liked.svg')} alt='liked'
                                            style={{marginTop:'07px', height:'18px'}}
                                        />
                                                                              
                                                    <p id='liked-post2' >{
                                                        data.shortMissionObject ? 
                                                            data.shortMissionObject.currentTime > data.shortMissionObject.finaleTime ? 
                                                            'Liked' : 'Voted'
                                                            :
                                                            'Liked' 
                                                        }</p>
                                                       
                                                                  </div> 

                                                          :

                                                                  <div className='row vote_button4' 
                                                                          onClick={e=>this.vote(e, data.id, true)}>
              
                                                                          <img    
                                                                              id='like1' src={require('../../../logo/like.svg')} alt='liked'
                                                                              style={{marginTop:'07px', height:'18px'}}
                                                                          />

                                                                          <p id='liked-post1' >{
                                                                            data.shortMissionObject ? 
                                                                                data.shortMissionObject.currentTime > data.shortMissionObject.finaleTime ? 
                                                                                'Like' : 'Vote'
                                                                                :
                                                                                'Like' 
                                                                            }</p>

                      
                                                                  </div> 

                                                              }
                          </div>
                              
                      </div>

                  </div>
              </div>


{/* ----------------------------------------------- */}

<div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'   style={{marginTop:'40px'}}>
            <div className='row'  style={{justifyContent:'flex-end'}}>

            <img src={require('../../../logo/more.svg')}
                     alt='more'
                     className='more_opt'
                     onClick ={e=>this.handle_menu(e)}
                     />   

            <Menu
                style={{marginTop:'40px'}}
                id="simple-menu"
                anchorEl={this.state.anchor}
                keepMounted
                open={Boolean(this.state.anchor)}
                onClose={e=>this.handleClose(e)}
            >
                {
                    JSON.parse(localStorage.getItem('current_user')) ? 
                    JSON.parse(localStorage.getItem('current_user')).id === user_id ?
                    <>
                        <MenuItem  onClick={e=>this.editPost()}>
                            <p>Edit</p>
                        </MenuItem>
                        <MenuItem  onClick={e=>this.setState({openDelete : true, action : 'delete',anchor:null})}>
                            <p>Delete</p>
                        </MenuItem>
                    </>
                    :
                        !reported.has(data.id)?                         
                        
                        <MenuItem  onClick={e=>this.setState({openDelete:true,action:'report'})}>

                            <p>Report</p>
                        </MenuItem>
                        :

                        <MenuItem  onClick={e=>this.reportPost(e,false)}>
                        <p>UnReport</p>
                    </MenuItem>

                    :
                        <MenuItem  onClick={e=>this.reportPost(e,true)}>
                            <p>Report</p>
                        </MenuItem>
                }                  
              </Menu>                                                              
            </div>                                                            
        </div>

        {
            this.state.post_full === true ? 
            
            <div className='container' style={{marginTop:'60px', textAlign : 'center'}} >
            <img 
                onLoad={e=>this.imageLoaded(e)}
                onDragStart={e=>this.onDragPhoto(e)}
                onDrag={e=>this.onDragPhoto(e)}
                onContextMenu={e=>this.clickRight(e)} 
                style={{maxHeight:`${window.innerHeight-260}px`}} 
                src={image_url_m} 
                alt="Post"
            />
        </div>
            
            : 

            <div
            className="container"
            style={{ marginTop: "60px", textAlign: "center" }}
          >
             <img 
            className='prev-icon'
            alt='next'
            src={require('../../../logo/lft.svg')} 
            onClick={e => this.imageSliderLeft(e)}/>

            <img
              onLoad={e=>this.imageLoaded(e)}
              onDragStart={e => this.onDragPhoto(e)}
              onDrag={e => this.onDragPhoto(e)}
              onContextMenu={e => this.clickRight(e)}
              style={{ maxHeight: `${window.innerHeight - 260}px` }}
              src={
                this.state.postData[this.state.position]
                  .imageProperties[0].medium
                  ? this.state.postData[this.state.position]
                      .imageProperties[0].medium
                  : this.state.postData[this.state.position]
                      .imageProperties[0].originalUrl
              }
              alt='Post'
            />

<img 
            className='next-icon'
            alt='next'
            src={require('../../../logo/rgt.svg')} 
            onClick={e => this.imageSliderRight(e)}/>
          </div>

        }

{/* ----------------------------------------------- */}
    </div>
        {/* <div className='container' style={{marginTop:'60px', textAlign : 'center'}} >
            <img 
                onLoad={e=>this.imageLoaded(e)}
                onDragStart={e=>this.onDragPhoto(e)}
                onDrag={e=>this.onDragPhoto(e)}
                onContextMenu={e=>this.clickRight(e)} 
                style={{maxHeight:`${window.innerHeight-260}px`}} 
                src={image_url_m} 
                alt="Post"
            />
        </div> */}

              {
                data.specialText ? 
                    <div className='row justify-content-center'>
                        <div className='mt-4' style={{border:'1px solid #707070', borderRadius:'60px'}}>
                            <div 
                                className='row justify-content-center ml-3 mr-4' 
                                onClick={this.goToMission} 
                                style={{cursor:'pointer'}}>

                            {data.specialImage ? 
                                <img 
                                    style={{height:'45px', width:'45px',padding:'10px'}}
                                    src={data.specialImage}
                                    alt='contestLogo'
                                /> : ''}
                            
                            <p
                                className='winner'
                            >
                                {data.specialText}</p>
                        </div>
                    </div>
                </div>
            :
            ''
    }



{
    data.voteScore === 0 ? '' : 

    <div className='Rectangle-1999' onClick={()=>{this.setState({vote_score_dialog : true})}}>
        <div className='row' style={{ margin: "5px 10px 0px 10px"}}>
          <div>
            <img className='grp-icon' src={require('../../../logo/Group1342.svg')} alt='Group Icon' />
          </div>



          <div>
            <p className='votescore'>Vote Score - {parseFloat(data.voteScore).toFixed(3)}</p>
            <p className='votescore_desc'>Learn More</p>
          </div>
    
        </div>
    </div>
    }


{/* ----------------------------sec-B-------------------------------------------------------------- */}
{
    load === false ? 
        <></>
        :
            <div style={{textAlign:'start', marginTop:'50px', marginLeft:'40px'}}>
                  <p id='title-post'>
                    {data.title}
                </p>
                <p id='desc-post'>
                    {data.description}
                </p>

            {/* social-share */}
                 <div onClick={e=>this.clickOnShare()} style={{marginTop:'30px'}}  className='row '>
                    <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' title='' url={window.location}>
                        <WhatsappIcon round size='30px' />
                    </WhatsappShareButton>                      

                    <FacebookShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={window.location}>
                        <FacebookIcon round size='30px'/>
                    </FacebookShareButton> 

                    <FacebookMessengerShareButton style={{outline:'none', cursor:'pointer'}} appId='421685814838229' className='sc'  url={window.location}>
                        <FacebookMessengerIcon round size='30px'/>
                    </FacebookMessengerShareButton>
              
                    <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={window.location}>
                        <LinkedinIcon round size='30px' />
                    </LinkedinShareButton>
                  
                    <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={window.location} title=''>
                        <TwitterIcon round size='30px' />
                    </TwitterShareButton>
                </div> 
            </div>
    }
      {
                                                          load === false ? 
                                                          <></>
                                                          
                                                          :
                                                                                      user_comments? 
                                                                                      
                                                                                      user_comments.map((value,index)=>{
                                    
                                                                                      return(
                                    
                                                                                          <>
                                                                                          <div className='row'>
                                                                                          <div className='col-10 col-sm-10 col-md-10 col-lg-6 col-xl-7' style={{textAlign:'start'}}>
                                    
                                                                                              <div className='row' style={{marginTop:'40px', marginLeft:'32px'}}>
                                                                                                      <img 
                                                                                                      src={value.shortUserObject.profilePicture} 
                                                                                                      onError={(e) => {
                                                                                                        e.target.onerror = null
                                                                                                        e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                                                                                      }}
                                                                                                      alt="Profile"
                                                                                                      onClick={() => this.goToProfile(1,value.shortUserObject.slug)}
                                                                                                      style={{height:'40px', width:'40px',border:'1px solid #ffffff50' ,
                                                                                                                borderRadius:'60px', objectFit:'cover'}}
                                                                                                  />
                                    
                                                                                                      <div style={{marginTop:'2px'}}>

                                                                                                      <div>
                                  {value.shortUserObject.badge === "0" ? (
                                    <>
                                      <p
                                        className="user-name2"
                                        style={{ cursor: "pointer" }}
                                        onClick={e =>
                                          this.goToProfile(
                                            1,
                                            value.shortUserObject.slug
                                          )
                                        }
                                      >
                                        {value.shortUserObject.name}
                                      </p>
                                    </>
                                  ) : value.shortUserObject.badge === "1" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/1.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : value.shortUserObject.badge === "2" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/1.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : value.shortUserObject.badge === "3" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/3.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/4.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>

                                                                                                          <p className='post-date' style={{marginLeft:'15px', marginTop:'-10px'}}>
                                                                                                                <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                                                                                                {
                                                                                                                        value.shortUserObject.badge === '0' ? '' :
                                                                                                                            value.isJuryComment === true ? 
                                                                                                                            ` | ${value.juryCommentVote} jury's vote`
                                                                                                                        :
                                                                                                                            ` | Jury's Comment`
                                                                                                                    } 
                                                                                                          </p>
                                                                                                      </div>    
                                    
                                                                                                  
                                                                                                  <div className='container'>
                                                                                                      <p className='user-comm'>
                                                                                                          {value.title}
                                                                                                      </p>
                                                                                                  </div>
                                    
                                    
                                                                                              </div>
                                                                                              
                                                                                           </div>
                                                                                            </div>
                                    
                                                                                                                            {/* ///////////////////////////////////////////////////////////////////////// */}
                                                      
{
    !(ids.has(value.id)) ?
            <div className='row' style={{marginLeft:'84px'}}>
                <img 
                    src={require('../../../logo/like.svg')} 
                    alt="likes"
                    style={{height:'20px', width:'20px', cursor:'pointer'}}
                    onClick={e=>this.likeComment(e,value.id,true)}
                />
                {
                    value.likeCount === '1' ?
                        <p style={{cursor : 'pointer'}} className='small_text' onClick={e=>this.openLikeUsersList(e, value.id,true,value.likeCount)}>{value.likeCount} like</p>
                        : value.likeCount ==='0' ? '' :
                        <p style={{cursor : 'pointer'}}  className='small_text' onClick={e=>this.openLikeUsersList(e, value.id,true,value.likeCount)}>{value.likeCount} likes</p>
                }

                                                                            {
                                                                                JSON.parse(localStorage.getItem('current_user')) ? 
                                                                                (JSON.parse(localStorage.getItem('current_user')).id) === value.shortUserObject.id ?

                                                                                <img 
                                                                                onClick={e=>{this.setState({openDelete : true , action : 'c_delete' , comment_id : value.id})}}
                                                                                className='comment_more_opt' 
                                                                                src={require('../../../logo/comment_delete.svg')} 
                                                                                alt='comment_delete' />
                                                                            
                                                                                :
                                                                                    reported.has(value.id) ? 
                                                                                <p 
                                                                                onClick={e=>{this.reportComment(e, false, value.id)}}
                                                                                className='reported'> UnReport</p>

                                                                                :

                                                                                <p 
                                                                                onClick={e=>{this.setState({openDelete : true , action : 'c_report',is_reported : true , comment_id : value.id})}}
                                                                                className='reported'> Report</p>
                                                                                :''
                                                                            }
                    
            </div>

            :

            <div className='row' style={{marginLeft:'84px'}}>
            <img 
                src={require('../../../logo/liked.svg')} 
                alt="liked"
                style={{height:'20px', width:'20px', cursor:'pointer'}}
                onClick={e=>this.likeComment(e,value.id,false)}
            />
                                                                    {
                    value.likeCount === '1' ?
                        <p  className='small_text' onClick={e=>this.openLikeUsersList(e, value.id,true,value.likeCount)}>{value.likeCount} like</p>
                    : value.likeCount ==='0' ? '' :
                        <p  className='small_text' onClick={e=>this.openLikeUsersList(e, value.id, true,value.likeCount)}>{value.likeCount} likes</p>
                
                }

                
                                                                        {
                                                                            (JSON.parse(localStorage.getItem('current_user')).id) === value.shortUserObject.id ?

                                                                            <img 
                                                                            onClick={e=>{this.setState({openDelete : true , action : 'c_delete' , comment_id : value.id})}}
                                                                            className='comment_more_opt' 
                                                                            src={require('../../../logo/comment_delete.svg')} 
                                                                            alt='comment_delete' />
                                                                        
                                                                            :
                                                                                reported.has(value.id) ? 
                                                                            <p 
                                                                            onClick={e=>{this.reportComment(e, false, value.id)}}
                                                                            className='reported'> UnReport</p>

                                                                            :

                                                                            <p 
                                                                            onClick={e=>{this.setState({openDelete : true , action : 'c_report',is_reported : true , comment_id : value.id})}}
                                                                            className='reported'> Report</p>
                                                                        }

        </div>
    }
                                                  
                                                  
                                                  {/* ////////////////////////////////////////////////////////////////////// */}
                                    
                                                  </>
                                                                                      )
                                                                          })
                                                                                      
                                                                                      :<></>
                                                                                  }

   {/*----- Load More Comments ------*/}
{
    load === false ? <></>
    :
    this.state.hasMore === true ? 
        <div className='container-fluid'> 
            <p
                onClick={e=>this.loadMoreComments(e)} 
                className='load_more'>
                Load Older Comments</p>
        </div>
    :
    <></>
}

{
        load === false ? 
        <></>
        
        :
        this.state.progress === true ?

        <CircularProgress />
        :
          <div className='row'>
              <div style={{textAlign:'start', background:'#000', borderRadius:'6px', marginLeft:'62px'}}>
                  <div className='row' style={{marginTop:'30px'}}>
                    <img 
                        src={(JSON.parse(localStorage.getItem('current_user'))) ? 
                        (JSON.parse(localStorage.getItem('current_user'))).photoUrl :
                        'https://firebasestorage.googleapis.com/v0/b/wallmag-2.appspot.com/o/user.svg?alt=media&token=a0aaff3b-37a1-42d0-a4bc-8e52bfd2d597'
                    }
                        alt="User"
                        style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover',border:'1px solid #ffffff50'}}
                    />
            
                    <div style={{marginTop:'2px'}}>
                        <textarea name='desc' id='textarea_desc'  placeholder='Write Something Nice' cols="60" rows="5"  
                            style={{ marginLeft:'20px', border:'none',outline:'none', borderRadius:'6px',padding:'10px'}}
                            onChange={e => this.change(e)}
                            >
                        </textarea>
                    </div>    
                </div>
          </div>
          <div style={{height:'40px', width:'40px', marginLeft:'40px',marginTop:'20px',cursor:'pointer'}} onClick={e=>this.addNewComment(data.id)}>
          <img src={require('../../../logo/send-msg.svg')} alt='send-msg' height='40' width='40' style={{marginTop:'10px'}}/>

             {/* <img src={require('../../../logo/arrow.svg')} alt="Arrow" height='20' width='20' style={{marginTop:'10px'}} /> */}
          </div>

          </div>
    }

    {
            load === false ? <></>
        :
            this.state.post_full === true ? 
            <div style={{marginTop:'200px'}}>
                <FooterWallmag />
            </div>
            : ''
    }

        </div>
    </div>
    }

    
    {/* dialog for mobile */}

    <Dialog 
                
                open={this.state.dialog_mob} 
                onClose={()=>this.setState({dialog_mob : false})}
                disableBackdropClick={true}
                fullScreen>

                <DialogTitle >
                     <AppBar style={{background:'white', paddingRight:'20px', paddingLeft:'10px'}}>
                     <div className='row' style={{justifyContent:'space-between', marginTop:'10px'}}>
                            <p id='totalLikers'>{likesCount === 0 ? '' : likesCount} 
                            
                            {
                                                        data.shortMissionObject ? 
                                                            data.shortMissionObject.currentTime > data.shortMissionObject.finaleTime ? 
                                                            ' Likes' : ' Votes'
                                                            :
                                                            ' Likes' 
                                                        }
                            </p>
                   
                        <img 
                            src={require('../../../logo/cancel.svg')} 
                            alt='Cancel'
                            style={{marginTop:'-10px', cursor:'pointer'}} 
                            onClick={e=>this.setState({dialog_mob:false})}/>
                    </div>
                     </AppBar>
                    
                </DialogTitle>
                

                                {/* </DialogTitle> */}
                                <div id="scrollableDiv" style={{
                                    height: '1000px',
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>

            {
                likesCount <=20 ? 

                <List style={{ marginTop:'30px'}}>

                {
                    likes.length ? 
                        likes.map((value,indexNo)=>{
                            return(
                                <ListItem>
                            
                                <ListItemAvatar>
                                    {/* <img 
                                        src={value.photoUrl} 
                                        alt='user'
                                        style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover', marginTop:'-16px'}}
                                    /> */}

                                                <ReactImageFallback
                                                    src={value.photoUrl} 
                                                    fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                                                    style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover', marginTop:'-16px',border:'1px solid #ffffff50'}}
                                                    alt="cool image should be here"
                                                    className="my-image" />
                                </ListItemAvatar>
                                <div>
                                    {
                                        value.badge === '0' ? 
                                        <>
                                        <p id='nameOfLikersNoBadge' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                             :
                                             ''
                                                }</p>
                                        <p id='desig'>
                                            Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                        </p>
                                        </>
                                        :

                                        value.badge === '1' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                Jury's Vote
                                            </p>
                                        </>
                                      
                                        :

                                        value.badge === '2' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        :
                                        value.badge === '3' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName : ''
                                                }</p>
                                        <img src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        :
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   :''
                                                }</p>
                                        <img src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        


                                        
                                    }
                                   
                                </div>
                               
                                
                                {
                                    (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                                        
                                              <></> 
                                                     :

                                            followingIds.has(value.id) ? 

                                                <div className='following_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                      onClick={e=>this.followUser(e, value.id,false)}>
                                                    <p id='following_dialog_text'> Following </p>
                                                </div>
                                                :
                                                <div className='follow_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                onClick={e=>this.followUser(e, value.id,true)}>
                                                    <p id='foll_dialog_text'> Follow </p>
                                                </div> 
                                }

                                </ListItem>
                            )
                        })        
                            
                        :
                        <div style={{position:'relative'}}>
                        <CircularProgress style={{position:'absolute', left:'45%'}}/>
                    </div>    
                }
                
  </List>




                :
                <InfiniteScroll
                    dataLength={likes.length}
                    next={e => this.loadMoreLikes(e, data.id)}
                    // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                    // inverse={true} 
                    hasMore={this.state.more}
                    loader={<div className='container-fluid' style={{overflow : 'hidden', margin:'20px 0px'}}>
                                <div className='row' style={{position:'relative', left:'48%', overflow:'hidden'}}>
                                    <CircularProgress />
                                    {/* <p className='nameOfLikersNoBadge'>Loading...</p> */}
                                </div>
                            </div>}
                    scrollableTarget="scrollableDiv"
                >

                <List style={{ marginTop:'30px'}}>

                {
                    likes ? 
                        likes.map((value,indexNo)=>{
                            return(
                                <ListItem>
                            
                                <ListItemAvatar>
                                    {/* <img 
                                        src={value.photoUrl} 
                                        alt='user'
                                        style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover', marginTop:'-16px'}}
                                    /> */}

                                                <ReactImageFallback
                                                    src={value.photoUrl} 
                                                    fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                                                    style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover', marginTop:'-16px',border:'1px solid #ffffff50',border:'1px solid #ffffff50'}}
                                                    alt="cool image should be here"
                                                    className="my-image" />
                                </ListItemAvatar>
                                <div>
                                    {
                                        value.badge === '0' ? 
                                        <>
                                        <p id='nameOfLikersNoBadge' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                             :
                                             ''
                                                }</p>
                                        <p id='desig'>
                                            Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                        </p>
                                        </>
                                        :

                                        value.badge === '1' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                      
                                        :

                                        value.badge === '2' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        :
                                        value.badge === '3' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName : ''
                                                }</p>
                                        <img src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        :
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   :''
                                                }</p>
                                        <img src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        


                                        
                                    }
                                   
                                </div>
                               
                                
                                {
                                    (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                                        
                                              <></> 
                                                     :

                                            followingIds.has(value.id) ? 

                                                <div className='following_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                      onClick={e=>this.followUser(e, value.id,false)}>
                                                    <p id='following_dialog_text'> Following </p>
                                                </div>
                                                :
                                                <div className='follow_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                onClick={e=>this.followUser(e, value.id,true)}>
                                                    <p id='foll_dialog_text'> Follow </p>
                                                </div> 
                                }

                                </ListItem>
                            )
                        })        
                            
                        :
                        <></>
                }
                
  </List>

                </InfiniteScroll>

                }
                </div>
            </Dialog>

    {/* dialog for large screens (tab, laptop, pc) */}

    <Dialog
        open={this.state.dialog} 
        onClose={() => this.setState({dialog : false})}
        disableBackdropClick={true}>
            
                {/* <DialogTitle> */}
                    <div className='row usersListDialog' style={{justifyContent:'space-between'}}>
                            <p id='totalLikers'>{likesCount === 0 ? '' : likesCount} 
                            
                                {
                                    data.shortMissionObject ? 
                                        data.shortMissionObject.currentTime > data.shortMissionObject.finaleTime ? 
                                        ' Likes' : ' Votes'
                                        :
                                        ' Likes' 
                                }
                            </p>
                   
                        <img 
                            src={require('../../../logo/cancel.svg')} 
                            alt='Cancel'
                            style={{marginTop:'-10px', cursor:'pointer'}} 
                            onClick={e=>this.setState({dialog:false})}/>
                    
                    </div>
                    

                    
                {/* </DialogTitle> */}
                <div id="scrollableDiv" style={{
                        height: '500px',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>

                        {
                            likesCount <=20 ? 

                            <List style={{width:'500px'}}>

                            {
                                likes.length ? 
                                    likes.map((value,indexNo)=>{
                                        return(
                                            <ListItem>
                                        
                                            <ListItemAvatar>
                                                {/* <img 
                                                    src={value.photoUrl} 
                                                    alt='user'
                                                    style={{height:'52px', width:'52px', borderRadius:'60px', objectFit:'cover'}}
                                                /> */}

                                                <ReactImageFallback
                                                    src={value.photoUrl} 
                                                    fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                                                    style={{height:'52px', width:'52px', borderRadius:'60px', objectFit:'cover',border:'1px solid #ffffff50'}}
                                                    alt="cool image should be here"
                                                    className="my-image" />
                                                
                                            </ListItemAvatar>
                                            <div>
                                                {
                                                    value.badge === '0' ? 
                                                    <>
                                                    <p id='nameOfLikersNoBadge' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length   > 16 ? 
                                                         value.displayName.slice(0,16)+'...' 
                                                            :
                                                         value.displayName   
                                                         :
                                                         ''
                                                            }</p>
                                                    <p id='desig'>
                                                        Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                                    </p>
                                                    </>
                                                    :
            
                                                    value.badge === '1' ? 
                                                    <>
                                                   
                                                    <div className='row'>
                                                    <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                                         value.displayName.slice(0,16)+'...' 
                                                            :
                                                         value.displayName   : ''
                                                            }</p>
                                                    <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>
            
                                                    </div>
                                                    <p id='desig'>
                                                        Jury's Vote
                                                    </p>
                                                    </>
                                                    
                                                    :
            
                                                    value.badge === '2' ? 
                                                    <>
                                                   
                                                    <div className='row'>
                                                    <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                                         value.displayName.slice(0,16)+'...' 
                                                            :
                                                         value.displayName   : ''
                                                            }</p>
                                                    <img src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>
            
                                                    </div>
                                                    <p id='desig'>
                                                        Jury's Vote
                                                    </p>
                                                    </>
                                                
                                                    :
                                                    value.badge === '3' ? 
                                                    <>
                                                   
                                                    <div className='row'>
                                                    <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName? value.displayName.length > 16 ? 
                                                         value.displayName.slice(0,16)+'...' 
                                                            :
                                                         value.displayName   : ''
                                                            }</p>
                                                    <img src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>
            
                                                    </div>
                                                    <p id='desig'>
                                                        Jury's Vote
                                                    </p>
                                                    </>
                                                
                                                    :
                                                    <>
                                                   
                                                    <div className='row'>
                                                    <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                                         value.displayName.slice(0,16)+'...' 
                                                            :
                                                         value.displayName   : ''
                                                            }</p>
                                                    <img src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>
            
                                                    </div>
                                                    <p id='desig'>
                                                        Jury's Vote
                                                    </p>
                                                    </>
                                                
                                                    
            
            
                                                    
                                                }
                                               
                                            </div>
                                           
                                            
                                            {
                                                (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                                                    
                                                    <></> 
                                                        :
            
                                                        followingIds.has(value.id) ? 
            
                                                            <div className='following_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                                                  onClick={e=>this.followUser(e, value.id,false)}>
                                                                <p id='following_dialog_text'> Following </p>
                                                            </div>
                                                            :
                                                            <div className='follow_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                                            onClick={e=>this.followUser(e, value.id,true)}>
                                                                <p id='foll_dialog_text'> Follow </p>
                                                            </div> 
                                            }
            
                                            </ListItem>
                
                                        )
                                    })        
                                        
                                    :
                                    <div style={{position:'relative'}}>
                                        <CircularProgress style={{position:'absolute', left:'45%'}}/>
                                    </div>    
                            }
            
            
                            
                   
              </List>
                            



                            :

                <InfiniteScroll
                    dataLength={likes.length}
                    next={e => this.loadMoreLikes(e, data.id)}
                    // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                    // inverse={true} 
                    hasMore={this.state.more}
                    loader={<div className='container-fluid' style={{overflow : 'hidden', margin:'20px 0px'}}>
                                <div className='row' style={{position:'relative', left:'48%', overflow:'hidden'}}>
                                    <CircularProgress />
                                    {/* <p className='nameOfLikersNoBadge'>Loading...</p> */}
                                </div>
                            </div>}
                    scrollableTarget="scrollableDiv"
                >

                <List style={{width:'500px'}}>

                {
                    likes ? 
                        likes.map((value,indexNo)=>{
                            return(
                                <ListItem>
                            
                                <ListItemAvatar>
                                    {/* <img 
                                        src={value.photoUrl} 
                                        alt='user'
                                        style={{height:'52px', width:'52px', borderRadius:'60px', objectFit:'cover'}}
                                    /> */}

                                                <ReactImageFallback
                                                    src={value.photoUrl} 
                                                    fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                                                    style={{height:'52px', width:'52px', borderRadius:'60px', objectFit:'cover',border:'1px solid #ffffff50',border:'1px solid #ffffff50'}}
                                                    alt="cool image should be here"
                                                    className="my-image" />
                                </ListItemAvatar>
                                <div>
                                    {
                                        value.badge === '0' ? 
                                        <>
                                        <p id='nameOfLikersNoBadge' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length   > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                             :
                                             ''
                                                }</p>
                                        <p id='desig'>
                                            Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                        </p>
                                        </>
                                        :

                                        value.badge === '1' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                        
                                        :

                                        value.badge === '2' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        :
                                        value.badge === '3' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    
                                        :
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName ? value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   : ''
                                                }</p>
                                        <img src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                            Jury's Vote
                                        </p>
                                        </>
                                    }
                                   
                                </div>
                               
                                {
                                    (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                                        <></> 
                                            :
                                            followingIds.has(value.id) ? 
                                                <div className='following_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                                      onClick={e=>this.followUser(e, value.id,false)}>
                                                    <p id='following_dialog_text'> Following </p>
                                                </div>
                                                :
                                                <div className='follow_dialog' style={{marginLeft:'350px', cursor:'pointer'}} 
                                                onClick={e=>this.followUser(e, value.id,true)}>
                                                    <p id='foll_dialog_text'> Follow </p>
                                                </div> 
                                }
                                </ListItem>
                            )
                        })        
                        :
                        <></>
                }
      </List>
                </InfiniteScroll>
    }
                </div>
            </Dialog>

{/* Delete Dialog , Report Dialog*/}

    <Dialog
        open={this.state.openDelete}
        close={this.closeDialog}
    >

        <DialogTitle>
            <div style={{display:'flex'}}>
            { this.state.action === 'report' ? 

            <p className='title_dial'>
                Are you sure you want to report this Post?</p>  
                : 

                this.state.action === 'delete' ?

                <p  className='title_dial'>
                Are you sure you want to delete this Post? </p> 
                        
                        :

                this.state.action === 'c_delete' ?
                <p className='title_dial'>
                    Are you sure you want to delete this Comment?
                </p>
                            :
                                     
            <p className='title_dial'>
                    Are you sure you want to report this Comment?
            </p>

            }
                <img 
                    src={require('../../../logo/cancel.svg')} 
                    className='vote-cancel'
                    onClick={()=>this.closeDialog()}
                />

            </div>
        </DialogTitle>

        <DialogContent>
            { this.state.action === 'report' ? 
            
            <p  className='title_desc'>
                Reporting this post might remove this image or user permanently from WallMag</p>  
                
                : 
                this.state.action === 'delete' ? 
                
                <p className='title_desc'>Once a post is deleted it cannot be revert back</p> 

                :

                this.state.action === 'c_delete' ?
                <p  className='title_desc'>
                Once a comment is deleted it cannot be revert back</p>  

                :
                
                <p  className='title_desc'>
                Reporting this comment might remove this comment permanently from WallMag</p>  
            

            }


        </DialogContent>

        <DialogActions style={{marginRight:'10px'}}>

        <ThemeProvider theme={theme}>
            <Button className='diaog_action' style={{outline:'none', color:'#00000070'}} onClick={e=>this.closeDialog()}>Cancel</Button>
        </ThemeProvider>


{ this.state.action === 'report' ? 
        <ThemeProvider theme={theme}>
            <Button className='diaog_action' style={{outline:'none', color:'#FF0000'}} onClick={e=>this.reportPost(e, true)}>Report</Button>
        </ThemeProvider>            
                    :

    this.state.action === 'delete' ?
        <ThemeProvider theme={theme}>
            <Button className='diaog_action'  style={{outline:'none', color:'#FF0000'}} onClick={e=>this.deletePost(e)}>Delete</Button>
        </ThemeProvider>

                    :
    
    this.state.action === 'c_delete' ?
        <ThemeProvider theme={theme}>
            <Button className='diaog_action'  style={{outline:'none', color:'#FF0000'}} onClick={e=>this.deleteComment(e)}>Delete</Button>
        </ThemeProvider>


                    :
        
        <ThemeProvider theme={theme}>
            <Button className='diaog_action'  style={{outline:'none', color:'#FF0000'}} onClick={e=>this.reportComment(e,true, this.state.comment_id)}>Report</Button>
        </ThemeProvider>

                    
            }
                </DialogActions>
    </Dialog>

    <Dialog
          open={this.state.verify_user_dialog}
          onClose={this.closeDialog}
          disableBackdropClick
          >

            <DialogTitle>
            <p className="verifyTitle" >Please verify your email address </p>
            </DialogTitle>

            <DialogContent>
            <p className="verifyContent">
            In order to perform this action you need to verify your email address 
             <b className="verifyEmail"> {JSON.parse(localStorage.getItem("current_user")) ? 
               JSON.parse(localStorage.getItem("current_user")).email : ''} </b> from the verification email sent to you.
            </p>
          </DialogContent>

          <DialogActions>
            <p className='okButton' onClick={this.closeDialog}>
              Okay
            </p>
          </DialogActions>


        </Dialog>    

    {/* Vote Score  */}
    <Dialog
        open={this.state.vote_score_dialog}
        onClose={this.closeDialog}
        disableBackdropClick
        maxWidth = 'md'
        >
    <div className='share-main-div'>

        <div className='row' style={{margin:'0px 0px 0px 10px'}}>
            <img className='grp-icon' src={require('../../../logo/Group1348.svg')} alt='Group icon' />
            <p className='image-vote-score'>Image Vote Score - {parseFloat(data.voteScore).toFixed(3)}</p>
            <img className='vote-cancel' src={require('../../../logo/cancel.svg')} alt='cancel' onClick={this.closeDialog}/>

        </div>    

        <div className='row' style={{margin:'40px 0px 0px 15px'}}>
            <img className='pub-icon' src={require('../../../logo/audience.svg')} alt='Group icon' />
            <p 
                className='public-vote-score'
                onClick={e=>this.publicVotes(e,data.id,true,data.publicVote)}
            >
                Public Votes - {data.publicVote} Votes ({parseFloat(data.publicVoteScore).toFixed(3)})</p>
        </div>    

        <div className='row' style={{margin:'20px 0px 0px 15px'}}>
            <img className='pub-icon' src={require('../../../logo/star(1).svg')} alt='Group icon' />
            <p 
            className='public-vote-score'
            onClick={e=>this.juryVotes(e,data.id, true,data.juryVote)}
            >
                
                Jury Votes - {data.juryVote} Votes ({parseFloat(data.juryVoteScore).toFixed(3)})</p>
        </div>                   


        {/* <div className='row' style={{margin:'30px 0px 0px 15px'}}>
            <p onClick={()=>this.setState({share_this_image : true})} className='share-text'><u>Share this image to get more public votes</u></p>
        </div>   */}

        {/* {
                ids.has(data.id) ? 
            <div>
                <div
                style={{position:'relative'}}
                >
                    <img className='like-icon' src={  require('../../../logo/like.svg')} alt='Group icon' />
                </div>   

                <div
                className='row Rectangle-1481-voted' 
                style={{margin:'40px 0px 0px 15px'}}
                onClick={e => this.vote(e,data.id, false)}
                >
                    <p className='vote-this-image-voted'>Alrady Voted</p>
                </div>  
                </div>   

                :
                <div>
                <div
                style={{position:'relative'}}
                >
                    <img className='like-icon' src={  require('../../../logo/liked.svg')} alt='Group icon' />
                    

                    </div>
                <div
                className='row Rectangle-1481' 
                style={{margin:'40px 0px 0px 15px'}}
                onClick={e => this.vote(e,data.id, true)}
                >
                
                    <p className='vote-this-image'>Vote this image</p>
                </div>   
                </div>   

        }                  */}



        <div className='row' style={{margin:'30px 0px 0px 15px'}}>
        {/* <img className='pub-icon pub-icon-mob' src={require('../../../logo/Group1348.svg')} alt='Group icon' /> */}
            <p className='share-text st-2'><u>Learn more</u></p>
        </div>                
    </div>       

    </Dialog>  

    {/* Vote Score Mobile */}
    <Dialog
        open={this.state.vote_score_dialog_mob}
        onClose={this.closeDialog}
        disableBackdropClick
        fullScreen
        >
    <div className='share-main-div'>

        <img src={require('../../../logo/cancel.svg')} alt='cancel' style={{position:'absolute', top:'30px', right:'30px',cursor:'pointer'}} onClick={this.closeDialog}/>
        <div  style={{margin:'0px 0px 0px 10px', textAlign:'center'}}>
            <img className='grp-icon' src={require('../../../logo/Group1348.svg')} alt='Group icon' />
            <p className='image-vote-score'>Image Vote Score - {parseFloat(data.voteScore).toFixed(3)}</p>
        </div>    

        <div  style={{margin:'40px 0px 0px 15px', textAlign:'center'}}>
            <img className='pub-icon' src={require('../../../logo/audience.svg')} alt='Group icon' />
            <p 
                className='public-vote-score'
                onClick={e=>this.publicVotes(e,data.id,false,data.publicVote)}
            >
                Public Votes - {data.publicVote} Votes ({parseFloat(data.publicVoteScore).toFixed(3)})</p>
        </div>    

        <div  style={{margin:'20px 0px 0px 15px', textAlign:'center'}}>
            <img className='pub-icon' src={require('../../../logo/star(1).svg')} alt='Group icon' />
            <p 
                className='public-vote-score'
                onClick={e=>this.juryVotes(e,data.id, false,data.juryVote)}
                >
                Jury Votes - {data.juryVote} Votes ({parseFloat(data.juryVoteScore).toFixed(3)})</p>
        </div>                   

{/* 
        <div className='row' style={{margin:'30px 0px 0px 15px'}}  onClick={() => this.setState({share_this_image : true})}>
            <p className='share-text'><u>Share this image to get more public votes</u></p>
        </div>                    */}

        {/* {
                ids.has(data.id) ? 
            <div> 
    <div style={{position:'relative'}}>
            <img className='like-icon' src={require('../../../logo/like.svg')} alt='Group icon' />
        </div>      

        <div className='row Rectangle-1481-voted' style={{margin:'40px 0px 0px 15px'}} onClick={e => this.vote(e,data.id, false)}>
            <p className='vote-this-image-voted'>Already Voted</p>
        </div>      
        </div>      

        :
            <div>
        <div style={{position:'relative'}}
        >
                <img className='like-icon' src={require('../../../logo/liked.svg')} alt='Group icon' />
            </div>    

                <div className='row Rectangle-1481' style={{margin:'40px 0px 0px 15px'}} onClick={e => this.vote(e,data.id, true)}
        >
                <p className='vote-this-image'>Vote this image</p>
            </div>   
            </div>      
} */}

        <div className='row' style={{margin:'30px 0px 0px 15px'}}>
        {/* <img className='pub-icon pub-icon-mob' src={require('../../../logo/Group1348.svg')} alt='Group icon' /> */}
            <p className='share-text st-2'><u>Learn more </u></p>
        </div>                
    </div>       

    </Dialog>        
    
    {/* share Popup */}
        <Dialog 
            fullWidth
                open={this.state.share_this_image} 
                onClose={() => this.setState({share_this_image : false})}
                disableBackdropClick={true}>


            <DialogTitle>
                <div className='row image_uploaded' style={{justifyContent:'flex-end'}}>
                
                    <img 
                    className='cancel'
                    alt='cancel'
                        src={require('../../../logo/cancel.svg')} 
                        style={{ cursor:'pointer'}} 
                        onClick={() => this.setState({share_this_image : false})}/>

                </div>

            <div  style={{textAlignLast:'center'}}>
                <p id='share'><b>Share</b> your post to get <b>maximum votes</b></p>
                {/* */}
            </div>        
            </DialogTitle>     


            <div style={{alignSelf:'center'}}>
                <img className='img-fluid image_uploaded' alt='icon'  src={image_url_m} style={{ width: '400px' }} />
            </div>

            <div  style={{textAlignLast:'center', padding:'10px'}}>

                <p className='share_'>
                    Your 20% winning depends upon public & community votes
                </p>
            </div>

    {
        window.innerWidth > 900 ?
        <div onClick={e=>this.clickOnFB()} className='row icons_dad' style={{alignSelf:'center', padding:'10px', justifyContent:'center'}}>
        
        <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' title={data.title} url={'https://wallmag.io/post/'+data.slug}>
            <WhatsappIcon className='icons' round size='40px' />
        </WhatsappShareButton>

        <FacebookShareButton    style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={'https://wallmag.io/post/'+data.slug}>
                <FacebookIcon  className='icons' round size='40px'/>
        </FacebookShareButton> 

        <FacebookMessengerShareButton style={{outline:'none', cursor:'pointer'}} appId='421685814838229'  className='sc'  url={'https://wallmag.io/post/'+data.slug}>
            <FacebookMessengerIcon className='icons' round size='40px' />
        </FacebookMessengerShareButton>

        <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug}>
            <LinkedinIcon className='icons' round size='40px' />
        </LinkedinShareButton>
                                    
        <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug} title={data.title}>
            <TwitterIcon className='icons' round size='40px' />
        </TwitterShareButton>

        </div>


        :
    
    <div  onClick={e=>this.clickOnFB()} className='row icons_dad' style={{alignSelf:'center', padding:'10px', justifyContent:'center'}}>

        <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' title={data.title} url={'https://wallmag.io/post/'+data.slug}>
            <WhatsappIcon className='icons' round size='30px' />
        </WhatsappShareButton>

        <FacebookShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={'https://wallmag.io/post/'+data.slug}>
            <FacebookIcon className='icons' round size='30px'/>
        </FacebookShareButton> 

        <FacebookMessengerShareButton appId='421685814838229' className='sc'  url={'https://wallmag.io/post/'+data.slug}>
            <FacebookMessengerIcon round className='icons' size='30px' />
        </FacebookMessengerShareButton>

        <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug}>
            <LinkedinIcon className='icons' round size='30px' />
        </LinkedinShareButton>
        
        <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug} title={data.title}>
            <TwitterIcon className='icons' round size='30px' />
        </TwitterShareButton>
    </div>

}
            <div  onClick={e=>this.clickOnCopyToClip()}  style={{ marginBottom:'40px', textAlignLast:'center'}}>
            <CopyToClipboard text={'https://wallmag.io/post/'+data.slug}
                onCopy={() => cogoToast.info('Post link has been copied')}>
                <p className='share_' style={{cursor:'pointer'}}>
                                    <u>Copy share link</u>
                                </p>
                </CopyToClipboard>
                
            </div>

            </Dialog>

    {/* Jury Vote */}
    {
        window.innerWidth > 767
    ?
        <Dialog 
            fullWidth
            open={this.state.jury_vote} 
            onClose={() => this.closeJuryDialog()}
            disableBackdropClick={true}>
                
                <JuryVote badge={JSON.parse(localStorage.getItem('current_user')) ?
                                JSON.parse(localStorage.getItem('current_user')).badge : '0'} id={this.state.post_id} like={this.juryLike}  onClose={this.closeJuryDialog}/>
        
        </Dialog>
    :
        <Dialog 
            fullScreen
            open={this.state.jury_vote} 
            onClose={() => this.closeJuryDialog()}
            disableBackdropClick={true}>
                
                <JuryVote badge={JSON.parse(localStorage.getItem('current_user')) ?
                                JSON.parse(localStorage.getItem('current_user')).badge : '0'} id={this.state.post_id} like={this.juryLike} onClose={this.closeJuryDialog}/>
        
        </Dialog>
    }
            </>
        )
    }
}
