import React from 'react'
import '../../Styles/Upload.css'
import Button from '@material-ui/core/Button';
import {  Switch, DialogTitle, CircularProgress, createMuiTheme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import{ NavLink} from 'react-router-dom'
import {storage} from '../Login/firebaseConfig'
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import { upload_images, get_post_update, paid_remaining_subs, get_mission_by_Id } from '../common/AppConstants';
// import { get_image_tags } from '../common/AppConstants';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import cogoToast from 'cogo-toast'
import { Redirect } from 'react-router-dom'
import firebase from '../Login/firebaseConfig'
import ReactGA from 'react-ga';
import {FacebookIcon,  TwitterIcon,  WhatsappIcon,  LinkedinIcon, 
   FacebookMessengerShareButton, FacebookMessengerIcon} from 'react-share';
import {FacebookShareButton, LinkedinShareButton,  TwitterShareButton, WhatsappShareButton} from 'react-share';
   import {CopyToClipboard} from 'react-copy-to-clipboard';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import EXIF from "exif-js";


var progress = false
// var suggestions = [] 
// var suggId = []
// var mentionIds = new Set() // ID Of Mentions
// var mentionsArray = []
var height_img = 0
var width_img = 0
var size = 0
var percent = 0;
var data = []
var allow_uploads = false;

const theme = createMuiTheme({
    typography: {
      // Use the system font.
      fontFamily:
        // '-apple-system,system-ui,BlinkMacSystemFont,' 
        // +
        'Montserrat',
    },
  });


export default class Upload extends React.Component{

    constructor(props){
        super(props)

        window.scrollTo(0,0)

        this.state={
            image : '',
            im_url : '',
            title:'',
            story:'',
            checked : false,
            chips:[],
            progress:false,
            percent : 0,
            missionIds : this.props.match.params.id ? [this.props.match.params.id] : '',
            missionIds2 : this.props.match.params.id ? this.props.match.params.id : '',
            pictures: [],          // to store the pictures in base64 format.
            slideCount: 0 ,
            upload_popup : false
        }

        if(this.props.match.params.id){
            this.verifyForUpload(this.props.match.params.id);
        }else{
            allow_uploads = true;
        }

        ReactGA.initialize('UA-117934873-2', {
            gaOptions: {
              userId: JSON.parse(localStorage.getItem('current_user')) ? JSON.parse(localStorage.getItem('current_user')).displayName+'_'+JSON.parse(localStorage.getItem('current_user')).id : 'Guest_User' 
            }
          });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }


    componentDidMount(){
        
        // if(allow_uploads === false){
        //     this.sendToMissionPage();
        // }

        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})
            
    }


     dragOver = (e) => {
        e.preventDefault();
        this.setState({
            image : '',
            im_url : ''
        })
    }
    
     dragEnter = (e) => {
        e.preventDefault();

        this.setState({
            image : '',
            im_url : ''
        })
        this.forceUpdate()
    }
    
     dragLeave = (e) => {
        e.preventDefault();
        this.setState({
            image : '',
            im_url : ''
        })
    }

    getExif = () => {
        var file = document.getElementById("img1");
        EXIF.getData(file, function () {
          var exifData = EXIF.getAllTags(this);
          if (exifData) {
            console.log("exifData: ", JSON.stringify(exifData));
          } else {
            console.log("No EXIF data found in image '" + file + "'.");
          }
        });
      };
    
    
     fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files[0];

        if(e.dataTransfer.files[0]){
            if(e.dataTransfer.files[0].type == 'image/jpeg' || 
                e.dataTransfer.files[0].type == 'image/JPG' ||
                e.dataTransfer.files[0].type == 'image/png' 
                )
                {
                    if(e.dataTransfer.files[0].size <= 73400320){

                    let reader = new FileReader();
    
                    reader.onload = e =>{    
                      var img = new Image();
                      img.onload = function() {
                          height_img = img.height
                          width_img = img.width
                      };
                      img.src = reader.result; 
                      this.setState({image: e.target.result, image1 : e.target.result});
                      this.setState({im_url  : files , file_props_count : 0})
              
                      size = this.state.im_url.size
            
                    }
                    reader.readAsDataURL(e.dataTransfer.files[0]);
                }else{
                    cogoToast.warn('File limitation 70 MB (Image larger than 70MB is not allowed)')
                }
                }
            else{
                cogoToast.warn('Other files are not allowed to upload such as PDF etc.. (Only Image format is allowed)')
            }   
        }     


      }

        // Select Image
        pick_image = e =>{

            var fullPath = document.getElementById('raised-button-file').value;
           
            if (fullPath) {
                var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
                var filename = fullPath.substring(startIndex);
                if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                    filename = filename.substring(1);
                }
                    this.setState({
                        fileName : filename
                    })
            }
    
            if(e.target.files[0]){
                if(e.target.files[0].type == 'image/jpeg' ||  // Checking File Format
                    e.target.files[0].type == 'image/Jpg' || 
                    e.target.files[0].type == 'image/png' )
                    {

                        if(e.target.files[0].size <= 73400320){

                        const image = e.target.files[0]
                        let reader = new FileReader();
              
                        reader.onload = e =>{    
                          var img = new Image();
                          img.onload = function() {
                              height_img = img.height
                              width_img = img.width
                          };
                          img.src = reader.result; 
                          this.setState({image: e.target.result, image1 : e.target.result});
                          this.setState({im_url  : image , file_props_count : 0})
                  
                          size = this.state.im_url.size
              
                        }
                        reader.readAsDataURL(e.target.files[0]);
                    }else{
                        cogoToast.warn('File limitation 70 MB (Image larger than 70MB is not allowed)')
                    }
                        
                    }else{
                        cogoToast.warn('Other files are not allowed to upload such as PDF etc.. (Only Image format is allowed)')
                    }

            }
    
           }
    

    // Send To Mission Page
    sendToMissionPage = () => {
        axios.get(get_mission_by_Id + this.state.missionIds2)
        .then(res => {
            cogoToast.info('Please Wait For Result');
            window.location.assign('/missions/'+res.data.slug);
        })
        .catch(error => {})
    }


    // Handling Image Uploads
        verifyForUpload = (id) => {
            axios.get(paid_remaining_subs + id)
            .then(res => {
                if(res.data.submissionRemaining=='0'){
                    allow_uploads = false
                    this.sendToMissionPage();

                }else{
                    allow_uploads = true;
                    this.forceUpdate()
                }
            })
            .catch(error => {})
        }

    // Handling tags (Chips)
    onChange = chips => {
        this.setState({
           chips 
          });
      }

    // Progress Dialog
    closeDialog = () => {
        this.setState({
            progress : false
        })
        progress = false
    }
        onChange = chips => {
        this.setState({ chips });
  }

    // Checked
    handleChange = (e) =>{
        this.setState({
            checked : e.target.checked
        })
    }


    // On Change
    change = (e)=>{
        this.setState({
          [e.target.name] : e.target.value,
          open : false
        })
      }


    //   Upload
    upload = (e) =>{
        e.preventDefault()
        const {im_url} = this.state
        // for(let i=0 ; i<(this.state.chips).length;i++){
        //         for(let j=0 ; j<suggId.length ; j++){
        //             if (suggId[j].name == this.state.chips[i] ){
        //                 mentionIds.add(suggId[j].id)
        //                 mentionsArray = Array.from(mentionIds)
        //                 break
        //         }
        //     }
        // }
        
            const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.user
                  }

                  if(this.state.title === ''){
                      cogoToast.info("Please Mention Caption of the image")
                  }else{
                    this.setState({
                        percent : 0,
                        title:'',
                        story:'',
                        image: '',
                        im_url:''
                    })
                    progress = true
                  if(this.state.missionIds === ''){

                    axios.post(upload_images,
              
                        {
                            'description': this.state.story,
                            'title' : this.state.title,
                            // 'tagIds' : mentionsArray,
                            // 'isDeleted' : true,
                            'isInMarketplace' : this.state.checked,
                            // 'missionIds' : this.state.missionIds 
                        }, {
                            headers : headers
                        }
                        ).then(response => {
                            this.setState({
                                percent : 20,
                            })
    
                            this.firebaseImageUpload(response.data,im_url)
                            
                        }).catch(error =>{
                            cogoToast.error('this is an error! Try again')
                        })

                  }else{
                    axios.post(upload_images,
                        {
                            'description': this.state.story,
                            'title' : this.state.title,
                            // 'tagIds' : mentionsArray,
                            // 'isDeleted' : true,
                            'isInMarketplace' : this.state.checked,
                            'missionIds' : this.state.missionIds 
                        }, {
                            headers : headers
                        }
                        ).then(response => {
                            this.setState({
                                percent : 20,
                                title:'',
                                story:'',
                                image: '',
                                im_url:''
                            })
    
                            this.firebaseImageUpload(response.data,im_url)
                            
                        }).catch(error =>{
                            cogoToast.error('this is an error! Try again')
                        })
                  }
                }}


    // uploading image Firebase
        firebaseImageUpload = (post, im_url) => {   

            const uploadtask =  storage.ref('cover_images/post_'+post.id+'_post').put(im_url)
        uploadtask.on('state_changed' , 
        (snapshot) =>{
                percent = parseInt(snapshot.bytesTransferred /snapshot.totalBytes * 100)
                this.forceUpdate()
        },
          (error) =>{
            // Error function
            alert(error)
          } ,
           () =>{
            storage.ref('cover_images/').child("post_"+post.id+'_post').getDownloadURL().then(url =>{
                this.updatePost(post, url)
                progress = false
            })
        })
    }

    // updates Post
    updatePost = (postData, originalUrl) => {
        postData.imageProperties = [{
            name : 'post_'+postData.id+'_post',
            originalUrl : originalUrl,
            originalHeight : height_img,
            originalWidth : width_img,
            size : size/1024,
            type : 'io.wallmag.backend.FileUploadApi.Models.ImageProperty',
            
        }]
        postData.isDeleted = false

        axios.put(get_post_update+postData.id,
            postData
        ).then( res =>{
            data = res.data
            this.setState({
                percent : 100,
                title:'',
                story:'',
                image: '',
                im_url:''
            })
            cogoToast.success('Image has been successfully Uploaded')
            this.setState({
                upload_popup : true
            })
            this.resetAllVars()
            this.forceUpdate()
        }).catch(error => {})
    }

    closeUploadPopup = () => {
        ReactGA.event({
            category: 'cancel share',
            action:'Clicked on cancel icon',
        })

        this.setState({
            upload_popup : false
        })
          window.history.back()
    }

// after uploads an image (Reset all variables/properties)
resetAllVars = () =>{
    // suggestions = [] 
    // suggId = []
    // mentionIds = new Set() // ID Of Mentions
    // mentionsArray = []

    this.setState({
        chips:[]
    })
}




    // click on facebook
    clickOnFB = () => {
        ReactGA.event({
            category: 'Share after uploading image',
            action:'Share on Social Sites',
        })
    }

    // click on copy to clipboard
    clickOnCopyToClip = () => {
        ReactGA.event({
            category: 'Share after uploading image',
            action:'Copy from clipboard',
        })
    }

    render(){

        if(localStorage.user){


            return(
                allow_uploads === false ? 
                <div style={{marginTop:`${window.innerHeight/1.95}px`}}>
                    <CircularProgress />
                </div>
                // 
                : 
                <>
                
                <div 
                    className='container-fluid'
                    style={{overflow:'hidden'}}
                >
                    <div className='row'
                    >
                         
                            <div className='header col-6 col-sm-6 col-md--6 col-lg-6 col-xl-6'>
                                 <img 
                                 alt='wall_logo'
                                    onClick={e=>this.props.history.push('/')}
                                    style={{cursor:'pointer'}}
                                    height='45px' width='45px' 
                                    src={require('../../logo/wallmag_black.png')} />
                            </div>
                       
                           <div className='header2 col-6 col-sm-6 col-md--6 col-lg-6 col-xl-6'>
                           <Button variant="outlined" style={{outline:'none'}} onClick={e=>window.history.back()}>
                                Go Back
                            </Button>
                           </div>
                    </div>
                </div>
    {/* -------------------------Progress Bar----------------------------- */}
    
                
    
      
     
    
    
    
                { this.state.image === '' ? 
    
                
                 <div className='container' style={{marginTop:'160px'}}>
                     <div className='row'>
                        <div className='col-1 col-sm-1 col-xl-4 col-lg-4 col-md-3'></div>
    
                    
                        <div className='col-10 col-sm-10 col-xl-4 col-lg-4 col-md-6'>
                            <label htmlFor="raised-button-file">
                            <div 
                                className='imageView' 
                                style={{paddingRight:'30px', paddingLeft:'30px', cursor:'pointer' }}
                                onDragOver={e=>this.dragOver(e)}
                                onDragEnter={e=> this.dragEnter(e)}
                                onDragLeave={e => this.dragLeave(e)}
                                onDrop={e=>this.fileDrop(e)}
                            >
                       
                                           
                    <img 
                        src={require('../../logo/drop.svg')} 
                        alt='' height='50' width='60px' 
                        style={{marginTop:'40px', cursor:'pointer'}} 
                     
                    />
                    
                    <p className='upload'>Upload Your Picture Here</p>
                                           
                    </div>                                           
                                            
                    </label>
                                        <input
                                        accept="image/x-png,image/jpeg"
                                        id="raised-button-file"
                                        type="file"
                                        style={{display:'none'}}
                                        onChange={this.pick_image}
                                        />
                        </div>
    
                        <div className='col-1 col-sm-1 col-xl-4 col-lg-4 col-md-3'></div>
    
                     </div>
                 </div>  
                                      :
                                                        
                <div className='container-fluid '
                onDragOver={e=>this.dragOver(e)}
                onDragEnter={e=> this.dragEnter(e)}
                onDragLeave={e => this.dragLeave(e)}
                style={{paddingRight:'0px', paddingLeft:'15px'}}>
                                                       
                                                        <div style={{marginTop:'160px', paddingBottom:'0px'}}>
                                                         <label htmlFor="raised-button-file">
                                                             {
                                                         window.innerWidth < 768 ? 

                                                         <div >
                                                         
                                                             <img 
                                                             alt='attachment'
                                                                src={require('../../logo/attach.svg')} 
                                                                style={{height:'42px' , width:'42px', opacity:'.5'}}
                                                             
                                                             />
                                                                <p className='fileName'> {this.state.fileName}</p>
                                                            </div>
                                                        
                                                                    :

                                                        <img id='img1' className='uploading_image' src={this.state.image} alt='' />

                                                        }
                                                              
                                                              
                                                              
                                                           


                                                            </label>
                                                            <input
                                                                accept="image/x-png,image/jpeg"
                                                                id="raised-button-file"
                                                                type="file"
                                                                style={{display:'none'}}
                                                                onChange={this.pick_image}
                                                                />
                                                        </div>
                                                        </div>
                                                        }
    
    {/* ------------------------------------------------------ */}
            <div className='conatiner' style={{overflow:'hidden'}}>
            {
                this.state.image === '' ?
                <></>
                :
                <div className='row'>
                   <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
    
                <ThemeProvider theme={theme}>
                    <TextField
                        className='textFiled'
                        name="title"
                        type="search"
                        placeholder='Caption'
                        margin="normal"
                        variant="outlined"
                        onChange={e => this.change(e)}
                    />
                </ThemeProvider>    
            </div>
            
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
                <ThemeProvider theme={theme}>
                    <TextField
                        className='textFiled'
                        name="story"
                        type="search"
                        placeholder='Story Behind Photo (Optional)'
                        variant="outlined"
                        onChange={e => this.change(e)}
                    /> 
                </ThemeProvider>    
            </div>


{/* image tags */}
        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style={{marginTop:'12px'}}>
                
            <div className='row'>

                <div className='col-1 col-sm-1 col-md-2 col-lg-4 col-xl-4'></div>
                <div className='col-10 col-sm-10 col-md-8 col-lg-4 col-xl-4'>
                        <Chips
                            placeholder = 'Image Tags'
                            value={this.state.chips}
                            onChange={this.onChange}
                            fetchSuggestions = {(value) =>
                                axios.get(get_image_tags,{
                                    params : {
                                        text : value
                                    }
                                }).then(res =>{
                                    // //console.log(res)
                                    suggestions = []
                                
                                    if(res.data.tagDocuments==null){
            
                                    }else{
                                    for(let i=0 ; i<res.data.tagDocuments.length ; i++){
                                        
                                    suggestions.push(res.data.tagDocuments[i].name)
                                    suggId.push(res.data.tagDocuments[i])
                                    
                                }
                                }
                                
                                    return suggestions
            
                                })
                            }

                        />
                    </div>    

                </div>
             </div>
 */}

{/* image tags */}




{/* 
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
                <div className='container tags_div' style={{width:'100%', marginTop:'48px', marginBottom:'48px'}}>
                            <a onClick={this.upload}>
                                <div className="container tag_button">
                                        <p id='tags'>Tags</p>
                                </div>  
                            </a>
                        </div>
              </div>           */}
    
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
              <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.checked}
                                    onChange={e=> this.handleChange(e)}
                                    value="checkedB"
                                    color="primary"
                                />
                                }
                                  label="List my image on marketplace for sell"
                        />
                </div>
    
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
                                    <div className='row' style={{justifyContent:'center'}}>
                         
                                    
                                <NavLink style={{ textDecoration: 'none'}}  target='_blank' to='/mp'>
                                            <p className='We-will-never-send-you-junk-mail'>  Learn how WallMag marketplace works</p>
                                            </NavLink>
                            </div>
                </div>
            </div>
            }
            </div>
    
    
    {/* -------------------------------- */}
    
    
    
        {/* ------------------------------------ */}
       
    
                 {/* Upload */}
                 
             {/* {
                 progress === true ? 
                 <Progress percent={this.state.percent} status="success" /> : <> </>
             } */}

                    {
                        this.state.im_url === '' ? 
                        <></>

                        :

                        <div className='container payment_button' style={{width:'100%', marginTop:'48px', marginBottom:'48px'}}>
                        <a onClick={e=>this.upload(e)}>
                            <div className="container butt ">
                                    <p id='pre_upload'> Upload </p>
                            </div>  
                        </a>
                    </div>

                    }

{/* <p onClick={this.getExif}>
    exif data
</p> */}

{/* Progress Dialog For Image Upload */}

                    <Dialog 
                        open={progress} 
                        onClose={e => this.closeDialog()}
                        disableBackdropClick={true}>

                        <div 
                            style={{
                                paddingRight : '60px',
                                paddingLeft :'60px',
                                marginTop:'30px',
                                marginBottom:'30px'}}
                        >
                            <Progress type="circle" percent={percent}  />
                        </div>
    
                    </Dialog> 



                    {/* Upload Popup  */}
                    <Dialog 
                    fullWidth
                        open={this.state.upload_popup} 
                        onClose={e => this.closeUploadPopup()}
                        disableBackdropClick={true}>


                    <DialogTitle>
                        <div className='row image_uploaded' style={{justifyContent:'flex-end'}}>
                        
                            <img 
                                className='cancel'
                                alt='cancel'
                                src={require('../../logo/cancel.svg')} 
                                style={{ cursor:'pointer'}} 
                                onClick={e=>this.closeUploadPopup()}/>

                        </div>

                    <div  style={{textAlignLast:'center'}}>
                        <p id='upload_done'>Successfully Uploaded</p>
                        {/* */}
                    </div>        
                    </DialogTitle>     


                    <div style={{alignSelf:'center'}}>
                     <img className='img-fluid image_uploaded' alt='icon'  src={this.state.image1} style={{ width: '400px' }} />
                    </div>

                    <div  style={{textAlignLast:'center', padding:'10px'}}>
                        <p className='share'>
                            <b>Share</b> your post to get <b>maximum votes</b>
                        </p>
                    </div>

                    {
                        window.innerWidth > 900 ?
                        <div onClick={e=>this.clickOnFB()} className='row icons_dad' style={{alignSelf:'center', padding:'10px', justifyContent:'center'}}>

                       <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' title={data.title} url={'https://wallmag.io/post/'+data.slug}>
                            <WhatsappIcon className='icons' round size='40px' />
                        </WhatsappShareButton>

                        <FacebookShareButton     style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={'https://wallmag.io/post/'+data.slug}>
                                <FacebookIcon  className='icons' round size='40px'/>
                        </FacebookShareButton> 

                        <FacebookMessengerShareButton style={{outline:'none', cursor:'pointer'}} appId='421685814838229' className='sc'   url={'https://wallmag.io/post/'+data.slug}>
                            <FacebookMessengerIcon className='icons' round size='40px' />
                        </FacebookMessengerShareButton>
                                                  
                        <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug}>
                            <LinkedinIcon className='icons' round size='40px' />
                        </LinkedinShareButton>
                                                      
                        <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug} title={data.title}>
                            <TwitterIcon className='icons' round size='40px' />
                        </TwitterShareButton>

                        </div>
    

                        :
                    
                    <div  onClick={e=>this.clickOnFB()} className='row icons_dad' style={{alignSelf:'center', padding:'10px', justifyContent:'center'}}>

                  
                        <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' title={data.title} url={'https://wallmag.io/post/'+data.slug}>
                            <WhatsappIcon className='icons' round size='30px' />
                        </WhatsappShareButton>

                        <FacebookShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={'https://wallmag.io/post/'+data.slug}>
                            <FacebookIcon className='icons' round size='30px'/>
                        </FacebookShareButton>   

                        <FacebookMessengerShareButton style={{outline:'none', cursor:'pointer'}} appId='421685814838229' className='sc'  url={'https://wallmag.io/post/'+data.slug}>
                            <FacebookMessengerIcon className='icons' round size='30px' />
                        </FacebookMessengerShareButton>
                                
                        <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug}>
                            <LinkedinIcon className='icons' round size='30px' />
                        </LinkedinShareButton>
                    
                        <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug} title={data.title}>
                            <TwitterIcon className='icons' round size='30px' />
                        </TwitterShareButton>
                    </div>

        }


                    <div  onClick={e=>this.clickOnCopyToClip()}  style={{ marginBottom:'40px', textAlignLast:'center'}}>
                    <CopyToClipboard text={'https://wallmag.io/post/'+data.slug}
                        onCopy={() => cogoToast.info('Post link has been copied')}>
                        <p style={{cursor:'pointer'}}>
                                            <u>Copy share link</u>
                                        </p>
                        </CopyToClipboard>
                       
                    </div>

                    </Dialog>

                </>
            )
        }else{
            return <Redirect to='/login' />
        }
       
    }
}