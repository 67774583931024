import axios from 'axios'
import { Wallmag_All,wallmag_rem_post } from './AllWallmagPostsAction';
import {CATEGORY_DATA} from '../types'


export const Category_Post = (data, cat_ID)=>{


  return {
    type: CATEGORY_DATA,
    data,
    cat_ID,
   
  }
}
/////// this is middleware /////////
export const category_post  = (cat_ID) => {
    return (dispatch) => {
        return axios.get('https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getFeedsByEntityIdWall?entityId='+cat_ID+'&limit=9')
          .then(response => {
            dispatch(Category_Post (response.data,cat_ID));
            dispatch(Wallmag_All(response.data))
          })
          .catch(error => {
            // console.log(error.message);
          });
      };
  };

  export const rem_cat_post = (data,cat_ID) =>{
    return (dispatch) =>{
      dispatch(Category_Post(data, cat_ID))
      dispatch(wallmag_rem_post(data))
    }
  }