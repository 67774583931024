import React from 'react'
import MobHeader from './Header/Mob_Header';
import Header from './Header/Header';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar'
import firebase from '../Login/firebaseConfig'
import { issue_wall } from '../common/AppConstants';
import { createMuiTheme } from '@material-ui/core';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import FooterWallmag from './../Footer/Footer';

var data = [];
const theme = createMuiTheme({
    typography: {
      // Use the system font.
      fontFamily:
        // '-apple-system,system-ui,BlinkMacSystemFont,' 
        // +
        'Montserrat',
    },
  });

class Issue1 extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            openDialog : false, // for Billing
            name : '', 
            mobile:'',
            email:'',
            address:'',
            city:'',
            state:'',
            country:'',
            pin_code:'',
            amount:'',
            successDialog : false,
            failureDialog : false,
            razor_id : '',
            order_id : '',
            issue_name : '',
            url : this.props.match.params.issue
        }
        this.openCheckout = this.openCheckout.bind(this)
    }


    componentDidMount(){
        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})


            this.getIssue()

    //  1) browser back button event
    window.onkeydown = this.onBackButtonEvent;
    }


    getIssue=()=>{
        Axios.get(issue_wall+this.state.url)
        .then(res=>{
            data = res.data
            this.forceUpdate()
        })
    }

    onBackButtonEvent = e => {
        if(e.keyCode === 27){
        
            this.setState({
                successDialog: false,
                failureDialog : false,
                openDialog : false
            });
        }
        
      };


    // Checkout

    openCheckout () {

        if(this.state.name !=='' &&
        this.state.mobile !== '' && 
        this.state.email !=='' && 
        this.state.city !== '' && 
        this.state.address !== '' &&
        this.state.state !=='' && 
        this.state.country !== '' &&
        this.state.pin_code !== ''){

           let fullName = this.state.name
           let email = this.state.email
           let mobile = this.state.mobile
           let city = this.state.city
           let address = this.state.address
           let state = this.state.state
           let country = this.state.country
           let pinCode = this.state.pin_code

        let options = {
          "key": "rzp_live_NqUjiqMbRXHnd5",
          "amount": 160000, // 2000 paise = INR 20, amount in paisa
          "name": "WallMag",
          "description": "Wallmag Magzine 1 Year Subscription",
          "image": "https://i.imgur.com/3c07Kie.png",
          "handler":  (response)=>{
           response = response.razorpay_payment_id


                    this.setState({
                        openDialog : false,
                    })

// Progress Bar
            loadProgressBar()

// Billing Info (Api) 
            Axios.post('https://wallmag-2.appspot.com/_ah/api/printOrderApi/v1/printOrder', {
              
               'fullName': fullName,
               'mobile': mobile,
               'email': email,
               'city': city,
               'address': address,
               'state': state,
               'country': country,
               'pinCode': pinCode,
               'razorPaymentId': response,
               'issueName':"In Search of True Photography",
               'amount': 1600,
            
          }).then(res => {
                this.setState({
                    name : res.data.fullName,
                    mobile : res.data.mobile,
                    email : res.data.email,
                    city : res.data.city,
                    address : res.data.address,
                    state : res.data.state,
                    country : res.data.country,
                    pin_code : res.data.pinCode,
                    razor_id : res.data.razorPaymentId,
                    order_id : res.data.id,
                    issue_name : res.data.issueName,
                    successDialog : true
                })
        })
          
          },
          "modal": {
            "ondismiss": ()=>{
                this.setState({
                    failureDialog : true
                })
            },
        }
          ,
          "prefill": {
            "name": "",
            "email": ""
          },
          "notes": {
            "address": ""
          },
          "theme": {
            "color": "#000000"
          }
        };
        
        let rzp = new window.Razorpay(options);
        rzp.open()
       
        
        }else{
            alert('please fill all the info first')
        }
      }

 

    //  Handle Dialog Close
        closeDialog = () => {
            this.setState({
                openDialog : false,
                successDialog : false,
                failureDialog : false
            })
        }

    
    // Open the Billing Dialog
        billingDialog = () => {
            this.setState({
                openDialog : true
            })
        }


        // Payment Mode
        paymentMode = () => {
            //console.log(this.state);
        } 

        // On value change 
        change = (e)=>{
            this.setState({
              [e.target.id] : e.target.value,
              open : false
            })
          }
          
        //   error 
        errorShow =()=>{
            alert('fill All the info first')
        }

        

    render(){
        return(
            data.id ? 
            <>
            
               {/* {window.scrollTo(0,0)} */}
     

            {/* Header for Mobile Devices */}
               <div className='mob_top_get_featured'>
                 <MobHeader 
                    url={data.imageMain}   
                    logo='true'
                    clicked='yes' 
                    heading={data.titleMain}
                    sub_heading={data.subtitleMain}
                    
                    />
            </div>

            
            
            {/* Header for desktops  */}
                <div className='web_top_get_featured'>
                <Header 
                    id='issue1_header'
                    logo = 'true'
                    url={data.imageMain}   
                    clicked='yes' 
                    heading={data.titleMain}
                    sub_heading={data.subtitleMain}
                    />
            </div>

{/* ----------------Header Ends Here ----------------- */}


{/*--------------- Body Part--------------  */}

            <div className='container'>
                
                <p id='vol1'> {data.titleOne}</p>

                <p className='topic_desc' style={{marginTop:'34px'}}>
                    {data.textOne}
                   </p>        

              


                {/* Visuals Beyond Imagination */}

                <div className='row desktop' style={{marginTop:'140px'}}>
                    
                    <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
                        <img
                            className='img-fluid'
                            src={data.imageOne}
                            alt='Visuals Beyond Imagination'
                            />
                    </div>




                <div className='col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8'>
                    <div className='container-fluid'>
                        <p className='topics'>{data.titleTwo}</p>
                        <p className='topic_desc' style={{paddingRight:'30px'}}>
                       {data.textTwo}
                        </p>
                        
                    </div>
                </div>

                </div>
            






            {/* Mobile */}

            <div className='row mobile' style={{marginTop:'140px'}}>
                    
                    <div className='container'>
                    <p className='topics'>
                        {data.titleTwo}
                    </p>

                    <img
                            className='img-fluid'
                            src={require('../../logo/imagination.jpg')}
                            alt='Visuals Beyond Imagination'
                            style={{marginTop:'26px'}}
                            />

                    <p className='topic_desc' style={{marginTop:'50px'}}>
                        {
                            data.textTwo
                        }
                    </p>      

                    </div>


                </div>
            


           

            </div>

                <div
                    className='container-fluid desktop'
                    style={{
                    backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                    transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                    , url(${require('../../logo/quality.jpg')})`,
                        height:'504px' , width:'100%' , backgroundSize:'cover' , 
                        backgroundPosition:'center center' , 
                        backgroundRepeat : 'no-repeat' , marginTop:'120px'
                }} >

                    <div className='row'>
                    <div className='col-lg-3 col-xl-3'></div>
                        <div className=' col-md-6 col-lg-3 col-xl-3 ql_div' style={{height:'504px',marginTop:'147.5px'}}>
            <p className='ql'>{data.titleThree}</p>
                                {/* <p className='ql'>Matters</p>
                                <p className='ql'>To us</p> */}
                        </div>



                        <div className='col-md-6 col-lg-5 col-xl-4' style={{height:'504px',marginTop:'140px'}}>
                            <p className='ql_desc'>
                                {data.textThree}
                            </p>
                        </div>
                        <div className='col-lg-1 col-xl-1'></div>



                    </div>

                </div>



{/*----------------- Mobile -----------------*/}

<div
                className='container-fluid mobile'
                style={{
                 backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                 transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                 , url(${require('../../logo/quality.jpg')})`,
                    height:'322px' , width:'100%' , backgroundSize:'cover' , 
                    backgroundPosition:'center center' , 
                    backgroundRepeat : 'no-repeat' , marginTop:'120px'
            }} >

                            <p className='ql'>
                                {data.titleThree}
                            </p>
                            



                        <p className='ql_desc'>
                            {data.textThree}

                         </p>
                 
            </div>

            <div>
        <p className='buy'>{data.offerText}</p>
            </div>

            <hr style={{ height:'52px', width:'1px', border:'solid .2px', opacity:'.5',  marginTop:'48px'}} />


            <a onClick={() => this.billingDialog()}>
            <div className='container payment_button' style={{width:'100%', marginTop:'48px'}}>
                <div className="container butt ">
                    <div className="container">
        <p id='pre_'>{data.buttonText}</p>
        <p id='inr'>{data.buttonPriceText}</p>
                        </div>
                    </div>  
                </div>
            </a>

            <div>
        <p className='subs'>{data.buttonBelowText}</p>
            </div>
            <div>
                <FooterWallmag />
            </div>
            

{/* --------------------Body Ends Here------------------------------- */}



{/*----------------------- Billing Information (Dialog for large Screens)--------------------- */}

                <Dialog id='large_dialog'  open={this.state.openDialog} onClose={this.closeDialog} disableBackdropClick={true}>

                <img    
                        className='cancel_'
                        src={require('../../logo/cancel.svg')} 
                        alt='Cancel'
                        onClick={e=>this.setState({openDialog:false})}
                        />
                   
                    <DialogContent>
                    <p className='title_bill'>Billing Information</p>
                    <p className='title_bill_desc'>
                        Issue Title: In Search of True Photography
                    </p>

                    <ThemeProvider theme={theme}>
                        <TextField
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            id="name"
                            type="Name"
                            placeholder="Full Name"
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>


                    <ThemeProvider theme={theme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            id="mobile"
                            type="Name"
                            placeholder="Mobile"
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            id="email"
                            type="Name"
                            placeholder="Email"
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            id="address"
                            type="Name"
                            placeholder='Address'
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            id="city"
                            type="Name"
                            placeholder='City'
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            id="state"
                            type="Name"
                            placeholder='State'
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            id="country"
                            type="Name"
                            placeholder='Country'
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            id="pin_code"
                            type="Name"
                            placeholder='Pin Code'
                            fullWidth
                            onChange={e => this.change(e)}
                        />
                    </ThemeProvider>
                   
                
                

                <div className='container payment_button' style={{width:'100%', marginTop:'48px', marginBottom:'48px'}}>
                    <a onClick={this.openCheckout}>
                        <div className="container butt ">
                                <p id='pre_'> Pre-Order Now </p>
                                <p id='inr'>INR 1600 Including GST</p>
                        </div>  
                    </a>
                </div>
                    



                              

                    </DialogContent>
                   
                 

                </Dialog>


{/* ------------------------------------------------- */}




            {/* Billing Information (Dialog for small Devices)  */}

                        <Dialog 
                                id='small_dialog'
                                open={this.state.openDialog} 
                                onClose={this.closeDialog}
                                fullScreen={true}
                                >
                <img    
                        className='cancel_'
                        alt='Cancel'
                        src={require('../../logo/cancel.svg')} 
                        onClick={e=>this.setState({openDialog:false})}
                        />
                   
                    <DialogContent>
                        <p className='title_bill'>Billing Information</p>
                        <p className='title_bill_desc'>
                            Issue Title: In Search of True Photography
                        </p>
    
                        <ThemeProvider theme={theme}>
                            <TextField
                                autoFocus
                                variant="outlined"
                                margin="dense"
                                id="name"
                                type="Name"
                                placeholder="Full Name"
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>    

                        <ThemeProvider theme={theme}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="mobile"
                                type="Name"
                                placeholder="Mobile"
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>

                        <ThemeProvider theme={theme}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="email"
                                type="Name"
                                placeholder="Email"
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>  

                        <ThemeProvider theme={theme}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="address"
                                type="Name"
                                placeholder='Address'
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>

                        <ThemeProvider theme={theme}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="city"
                                type="Name"
                                placeholder='City'
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>


                        <ThemeProvider theme={theme}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="state"
                                type="Name"
                                placeholder='State'
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>

                        <ThemeProvider theme={theme}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="country"
                                type="Name"
                                placeholder='Country'
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>

                        <ThemeProvider theme={theme}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="pin_code"
                                type="Name"
                                placeholder='Pin Code'
                                fullWidth
                                onChange={e => this.change(e)}
                            />
                        </ThemeProvider>

                    <div className='container payment_button' style={{width:'100%', marginTop:'48px', marginBottom:'48px'}}>
                            <a onClick={this.openCheckout}>
                                <div className="container butt ">
                                        <p id='pre_'> Pre-Order Now </p>
                                        <p id='inr'>INR 1600 Including GST</p>
                                </div>  
                            </a>
                        </div>
                              

                    </DialogContent>
                   
                 

                </Dialog>

{/* ....................................................................... */}

{/* ----------Bill Receipt Dialog---------------- */}

                         <Dialog 
                                open={this.state.successDialog} 
                                onClose={this.closeDialog}
                                disableBackdropClick={true}>
                               
                               <img    
                                    className='cancel_'
                                    alt='Cancel'
                                    src={require('../../logo/cancel.svg')} 
                                    onClick={e=>this.setState({successDialog:false})}
                                    />
                               
                            <DialogContent>

                                    <div className='container'>
                                        <div className = 'row'>
                                             <p className='title_bill'>Order placed</p>
                                             <img    
                                                style={{
                                                    height : '22px',
                                                    marginTop : '34px',
                                                    marginLeft:'10px'
                                                }}
                                                className='img-fluid'
                                                src={require('../../logo/ordered.svg')}
                                                alt='order_placed'
                                                />   
                                                </div>
                                    </div>

                                        <p className='bill_desc'>
                                        <b> Issue Title :</b> {this.state.issue_name}
                                        </p>


                                        <p className='bill_desc'>
                                            <b>Invoice No : </b> {this.state.order_id} 
                                        </p>       
                                                
                                        <p className='bill_desc'>
                                            <b>Dispatch Date :</b> 1st oct, 2019
                                        </p>

                                        <p className='bill_desc'>
                                            <b>Contact us : </b> team@wallmag.io
                                        </p>



                            </DialogContent>
                         </Dialog>




{/* ------Failure Dialog ------------------- */}

<Dialog 
                                open={this.state.failureDialog} 
                                onClose={this.closeDialog}
                                disableBackdropClick={true}>
                               
                               <img    
                                    className='cancel_'
                                    alt='Cancel'
                                    src={require('../../logo/cancel.svg')} 
                                    onClick={e=>this.setState({failureDialog:false})}
                                    />
                               
                            <DialogContent>

                                    <div className='container'>
                                        <div className = 'row'>
                                             <p className='title_bill'>Payment Canceled</p>

                                                </div>
                                    </div>





                            </DialogContent>
                         </Dialog>

            </>
        :
        <></>                                    
        
        )
    }
}

export default Issue1