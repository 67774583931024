// wallmag-staging.appspot.com
// wallmag-2.appspot.com

// export const base_url = 'https://wallmag-staging.appspot.com/_ah/api/'

export let base_url = ''

const func = () => {
    if(window.location.href.indexOf("wallmag.io") > -1) {
        base_url = 'https://wallmag-2.appspot.com/_ah/api/'
    }
    else {
        base_url = 'https://millennial-reader.appspot.com/_ah/api/'
    }
}
func();

export const feeds = 'https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getFeedsByEntityId?entityId=wallpapers&limit=20' // feeds (for submissions)

export const login_api = base_url+'userApi/v1/login' // user Login

export const upload_images = base_url+'postApi/v1/post' // upload image
export const update_user = base_url+'userApi/v1/user/' // update user info

export const get_my_post = base_url+'postApi/v1/getMyPosts' // to get own photo posts

export const is_like = base_url+'postApi/v1/likeV2' // post like
export const jury_like = base_url+'postApi/v1/juryLike'

export const startup_call = base_url+'userApi/v1/startUpCall' // for likedPostIds , followerIds ......
export const startUp_call_web = base_url+'userApi/v1/startUpCallWeb' // HomePage

export const get_user_by_slug = base_url+'userApi/v1/getUserBySlug' // get other user info 
export const follow_user = base_url+'userApi/v1/followUser' // follow other users
export const get_other_user_post = base_url+'postApi/v1/getOtherUserPosts' // other user photo posts
export const get_image_tags = base_url+'searchApi/v1/searchTags' // image Tags

export const get_post_update = base_url+'postApi/v1/post/' // updates post

export const add_comment = base_url+'postApi/v1/addComment' // add new comment
export const get_comments = base_url+'postApi/v1/listByParentPostId' // get comments

export const get_post_by_slug = base_url+'postApi/v1/getBySlug' // get post by slug
// export const get_post_likes = base_url+'postApi/v1/getPostLikes' // get post likes
export const get_post_likes = base_url+'postApi/v1/getPostLikesV1' // get post likes

export const get_followers = base_url+'userApi/v1/getUserFollowers' // get follower list
export const get_followings = base_url+'userApi/v1/getUserFollowing' // get following list

export const view_post = base_url+'postApi/v1/view' // view a post

// FAQ
export const get_faq_section = base_url+'faqSectionApi/v1/faqSection/' // get Faq Sections 
export const get_faq_topics = base_url+'faqTopicApi/v1/faqTopic/' // get Faq Topics     
export const get_SectionList_byTopic = base_url+'faqSectionApi/v1/listByFaqTopic?id=' // get sections by topic id
export const get_topicBySlug = base_url+'faqTopicApi/v1/getTopicBySlug?slug=' // get topic by slug

// Missions
export const get_missions = base_url+'missionApi/v1/mission/' // get All Missions
export const get_mission_by_slug = base_url+'missionApi/v1/getMissionBySlug?slug=' // get Mission By Slug
export const get_mission_by_Id = base_url+'missionApi/v1/mission/' // get Mission By ID
export const get_all_active_missions = base_url+'missionApi/v1/getAllActiveMissions' // get all active missions

export const paid_remaining_subs = base_url+'missionApi/v1/getMissionValidityV1?missionId='; // get remaining submissions(status)
export const payment_mode_missions = base_url+'printOrderApi/v1/insertMissionPayment?missionId='; // payment(mission)
export const purchase_extra_uploads = base_url+'printOrderApi/v1/insertMissionExtraUploadPayment?missionId='; // Pay For Extra Image Uploads

export const get_submissions = base_url+'missionApi/v1/listSubmissions?' // get User submissions
export const get_mostVotedSubmissions = base_url+'missionApi/v1/listSubmissionsByVote?' // get most voted submissions
// User Post Delete
export const delete_post = base_url+'postApi/v1/post/'

// Report Post
export const report_post = base_url+'postApi/v1/report' 

// Finalist Posts
export const get_finalist = base_url+'missionApi/v1/getFinalistPostV1'

// Winner Posts
export const get_winners = base_url+'missionApi/v1/getWinnerPost'

// Inspiration Posts
export const get_inspirations = base_url+'missionApi/v1/getInspirationPost'

// Team-Api
export const team_wallmag = base_url+'teamApi/v1/printOrder'

// issue Api
export const issue_wall = base_url+'teamApi/v1/getIssueByUrl?url='

// Contests Api
export const get_all_contests = base_url+'missionApi/v1/listAllContests'

// get Public likes
export const get_public_votes = base_url+'postApi/v1/getPublicLikesV1'

// get Jury likes
export const get_jury_votes = base_url+'postApi/v1/getJuryLikesV1'

// Send Otp To User
export const send_otp_to_user = base_url+'userApi/v1/sendOtp'
// ?countryCode=%2B91&mobileNumber=7597202177&otp=1234

// Verify OTP
export const verify_otp = base_url+'userApi/v1/verifyMobile'
// ?isVerified=true&mobileNumber=7597202177

// T&C
export const getTc = base_url+'staticLoadApi/v1/staticLoad/5204888466227200'

// Privacy
export const getWallPrivacy = base_url+'staticLoadApi/v1/staticLoad/5714659476766720'

// App version
export const getLatestVersion = base_url+'userApi/v1/startUpCallWebV1?appVersion=';

export const listAllByOrderWeb = base_url+'bannerApi/v1/listAllByOrderWeb';