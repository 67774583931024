import React from "react";
import "../../Styles/Footer.css";
import { NavLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Axios from "axios";
import { currentVerion } from "../../App";
import { listAllByOrderWeb } from "../common/AppConstants";

class FooterWallmag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
    };
  }

  componentDidMount() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.user,
    };

    Axios.get(
      listAllByOrderWeb,
      {
        headers: headers,
      }
    ).then((res) => {
      this.setState({
        data: res.data,
      });
    });
  }

  // Redirect Page
  goToPage = (url) => {
    window.open(url, "_blank");
  };

  render() {
    return (
      <div
        className="container-fluid foot_div"
        style={{ borderTop: "1px solid #ffffff36" }}
      >
        <Carousel
          useKeyboardArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          showArrows={true}
          showThumbs={false}
          showIndicators={false}
          interval={7000}
        >
          {this.state.data.items
            ? this.state.data.items.map((res) => {
                return (
                  <div
                    style={{
                      cursor: "pointer",
                      width: `${window.innerWidth}px`,
                    }}
                    onClick={(e) => this.goToPage(res.webRedirectUrl)}
                  >
                    {window.innerWidth < 992 ? (
                      <img
                        className="img-fluid"
                        src={res.imageMobileUrl}
                        alt="webImage"
                        style={{ padding: "0px" }}
                      />
                    ) : window.innerWidth < 1440 ? (
                      <img
                        className="img-fluid"
                        src={res.imageWebUrl}
                        alt="webImage"
                        style={{
                          paddingTop: "50px",
                          paddingBottom: "50px",
                        }}
                      />
                    ) : (
                      <img
                        className="img-fluid"
                        src={res.imageWebUrl}
                        alt="webImage"
                        style={{
                          paddingTop: "50px",
                          paddingBottom: "50px",
                          paddingLeft: `${(window.innerWidth * 15) / 100}px`,
                          paddingRight: `${(window.innerWidth * 15) / 100}px`,
                        }}
                      />
                    )}
                  </div>
                );
              })
            : ""}
        </Carousel>
        <img
          id="logo"
          width=""
          src={require("../../logo/wallmag_white.png")}
          alt="Logo"
        />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 wallmag">
            WallMag
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 wallmag_sub_title">
            Where Photos Meet Stories
          </div>
          <div className="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 "></div>
          <div className="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 ">
            <p className="wallmag_desc">
              A community platform to empower photographers of every kind
              through learning, improving and growing resources so our
              photographers can do more and achieve more.
            </p>
          </div>
          <div className="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 mt-2 "></div>
          <div className="foot_button">
            <p className="jmff">Join for Free</p>
            <img className="ahead" src={require("../../logo/ahead.png")} />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 div_link">
            <div className="row  justify-content-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-2"></div>
                  <div className="col-4 col-sm-4 col-xl-3 col-lg-3 col-md-3">
                    <NavLink
                      style={{ textDecoration: "none", cursor: "default" }}
                    >
                      <p id="links"> The Company </p>
                    </NavLink>
                    <a target="_blank" href="/about-us" className="sub_links">
                      About Us
                    </a>
                    <br />
                    <a
                      target="_blank"
                      href="/team-wallmag"
                      className="sub_links"
                    >
                      The Team
                    </a>
                    <br />
                    <a
                      target="_blank"
                      href="/download-app"
                      className="sub_links"
                    >
                      Our App
                    </a>
                    <br />
                    <a target="_blank" href="/faq" className="sub_links">
                     FAQ
                    </a>
                  </div>
                  <div className="col-4 col-sm-4 col-xl-2 col-lg-2 col-md-2">
                    <NavLink
                      style={{ textDecoration: "none", cursor: "default" }}
                    >
                      <p id="links"> Contact us </p>
                    </NavLink>
                    <a
                      href="https://tawk.to/chat/5dcbea87d96992700fc73ae3/default"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="sub_links"
                    >
                      {" "}
                      Get in Touch
                    </a>
                    {/* <a href="/advertise" className="sub_links">
                      {" "}
                      Advertise
                    </a> */}
                    <br />
                    <a
                      href="https://angel.co/company/wallmag/jobs"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="sub_links"
                    >
                      {" "}
                      Careers
                    </a>
                  </div>
                  <div className="col-4 col-sm-4 col-xl-3 col-lg-3 col-md-3">
                    <NavLink
                      style={{ textDecoration: "none", cursor: "default" }}
                    >
                      <p id="links"> Legal </p>
                    </NavLink>
                    <a
                      target="_blank"
                      href="/privacy-policy"
                      className="sub_links"
                    >
                      Privacy Policy
                    </a>
                    <br/>
                    <a target="_blank" href="/terms-of-use" className="sub_links">
                      Terms of Use
                    </a>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="insta_button"
            onClick={() =>
              window.open("https://www.instagram.com/wallmag.photography/", "_blank")
            }
          >
            <a href="" target="_blank" rel="noopener noreferrer"></a>
            <img
              className="insta_share"
              src={require("../../logo/insta.svg")}
              alt="Instagram Logo"
            />
            <p className="waaoi">We are active on Instagram</p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justify-content-center"
            style={{ marginTop: "-40px" }}
          >
            {/* facebook */}
            <a
              href="https://www.facebook.com/wallmag.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                id="sc_links"
                src={require("../../logo/fb.svg")}
                alt="Facebook Logo"
              />
            </a>

            {/* LinkedIn */}
            <a
              href="https://www.linkedin.com/company/wallmag"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                id="sc_links"
                src={require("../../logo/lnk.svg")}
                alt="Linkedin Logo"
              />
            </a>

            {/* twitter */}
            <a
              href="https://twitter.com/wallmag_io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                id="sc_links"
                src={require("../../logo/tw.svg")}
                alt="Twitter Logo"
              />
            </a>
          </div>

          <div
            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style={{ marginTop: "40px" }}
          >
            <p
              className="sub_links"
              style={{ fontSize: "14px", color: "white" }}
            >
              &copy; WallMag Global PVT LTD 2020
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p
              className="sub_links"
              style={{ fontSize: "14px", color: "white" }}
            >
              Version {currentVerion}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default FooterWallmag;
