import React, { Component } from "react";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "./MissionsV1.css"
import firebase from "../Login/firebaseConfig";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "100px",
    position: "relative",
  },
  cardRoot: {
    padding: theme.spacing(0),
    marginLeft: "auto",
    marginRight: "auto",
    margin: "0 auto",
    float: "none",
    maxWidth: 345,
    // width: "339px",
    height: "350px",
    border: "1px solid #c7b96dd0",
    backgroundColor: "black",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    transition: "0.3s",
    color: "white",
    ['@media (max-width:377px)']: { // eslint-disable-line no-useless-computed-key
      height: "400px"
    }
  },
  media: {
    height: 140,
    padding: 0,
    margin: 0,
  },
});

var missions = [];

class CardComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    missions = this.props.awardsData
    this.state = {
      load: false,
    };
    
    this.forceUpdate()
  }
  

  componentDidMount() {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        localStorage.setItem("user", user.ra);
      }
    });

  }

componentWillReceiveProps(props){
  missions = props.awardsData 
}
  render() {
    console.log(missions.items)
    const { classes } = this.props;
    return (
      <div className={classes.root}>
       <Grid container spacing={8} alignItems="center" justify="center">
       {missions.items ? (
         missions.items.map((value, index) => {
            return (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Card className={this.props.classes.cardRoot}>
                  <CardActionArea className={this.props.classes.hoverEffect}>
                    <div className="missions-img-container">
                      <CardMedia
                        className={this.props.classes.media}
                        image={value.coverUrl}
                        alt={value.hashTag}
                        style={{
                          width: "100%",
                          opacity: 0.5,
                        }}
                        title="Contemplative Reptile"
                      />
                      <div class="missions-img-centered">
                        <div className="awards-1 glacialindifference-regular-normal-white-12px">
                          Awards
                        </div>
                        <div className="text-4 montserrat-bold-white-18px">
                          {value.hashTag}
                        </div>
                      </div>
                    </div>
                    <CardContent style={{ textAlign: "left" }}>
                      <button className="missions-card-button">
                              {/* Starting */}
                              {value.currentTime < value.startTime ? (
                                <p className="missions-card-button-text">
                                  {/* Starting : {" "} */}
                                  Starting in {Math.ceil((value.startTime - value.currentTime)/(24 * 60 * 60 * 1000))} days
                                </p>
                              ) :  //  Started (vote for winners)
                              value.currentTime < value.endTime ? (
                                <p className="missions-card-button-text">
                                  Closing in {Math.ceil((value.endTime - value.currentTime)/(24 * 60 * 60 * 1000))} days

                                </p>
                              ) : (
                                // Award has been finished
                                <p className="missions-card-button-text">
                                Finished
                                </p>
                              )} 
                      </button>
                      <p className="missions-card-text glacialindifference-regular-normal-white-14px">
                        <MonetizationOnOutlinedIcon
                          style={{
                            height: "0.7em",
                            width: "0.7em",
                            marginRight: "10px",
                          }}
                          className="money-icon"
                        />
                          {value.participationFees === "0"?
                      "Free entry":
                      "Paid entry"
                      
                    }
                      </p>
                      <img
                        style={{ height: "1em", width: "1em", float: "left" }}
                        className="money-icon"
                        src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6077b65554d0489b8747539e/img/path-83203-1@1x.png"
                      />
                      <p
                        className="missions-card-text glacialindifference-regular-normal-white-14px"
                        style={{ marginBottom: "58px" }}
                      >
                        {value.shortPrizeDescription}
                      </p>

                      <hr className="missions-hr" />
                      
                      {value.currentTime < value.startTime ? (
                          <div
                          
                            style={{
                              height: "40px",
                              background: "#000000",
                              margin: "10px",
                              borderRadius: "3px",
                              cursor: "pointer"
                            }}
                          >
                            <div className="row justify-content-center">
                             <p                                                           
                                className="missions-card-text-bottom glacialindifference-regular-normal-white-14px"
                              >
                                LEARN MORE
                              </p>
                            </div>
                          </div>
                        ) :
                        
                        value.currentTime < value.finaleTime ? 
                        (
                          <div
                            style={{
                              height: "40px",
                              background: "#000000",
                              margin: "10px",
                              borderRadius: "3px",
                              cursor: "pointer"
                            }}
                          >
                            <div className="row justify-content-center">
                              <p                                                            
                                className="missions-card-text-bottom glacialindifference-regular-normal-white-14px"
                              >
                                PARTICIPATE
                              </p>
                            </div>
                          </div>
                        ):
                        value.currentTime < value.endTime ? 
                        (
                          <div
                            style={{
                              height: "40px",
                              background: "#000000",
                              margin: "10px",
                              borderRadius: "3px",
                              cursor: "pointer"
                            }}
                          >
                            <div className="row justify-content-center">
                              <p                              
                                className="missions-card-text-bottom glacialindifference-regular-normal-white-14px">
                              VOTE FOR FINALISTS
                              </p>
                            </div>
                          </div>
                        )
                      :
                      (
                        <div
                          style={{
                            height: "40px",
                            background: "#000000",
                            margin: "10px",
                            borderRadius: "3px",
                            cursor: "pointer"
                          }}
                        >
                          <div className="row justify-content-center">
                            <p   
                              className="missions-card-text-bottom glacialindifference-regular-normal-white-14px"
                            >
                              VIEW RESULTS 
                            </p>
                          </div>
                        </div>
                      )                     
                      }                      
                      <p className="missions-card-text-bottom glacialindifference-regular-normal-white-14px">
                        PARTICIPATE
                      </p>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })
       ):''}
        </Grid> 
      </div>
    );
  }
}
export default withStyles(styles)(CardComponent);
