import { RELATED_FEEDS } from './../types'
import axios from 'axios'
import { Wallmag_All,wallmag_rem_post } from './AllWallmagPostsAction';


const RelatedFeedsApi = 'https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getRelatedFeeds?feedId=';

export const Related_Feeds = (data)=>{
  return {
    type: RELATED_FEEDS,
    data,
  }
}

export const related_feeds  = (id) => {
    return (dispatch) => {
        return axios.get(RelatedFeedsApi+id )
          .then(response => {
            if(response.data.items){
              dispatch(Related_Feeds (response.data));
              dispatch(Wallmag_All(response.data))
            }else{
 
            }
            
          })
          .catch(error => {
            // console.log(error.message);
          });
      };
  };

  export const related_rem_post = (data) =>{
    return (dispatch) =>{
    //   dispatch(Related_Feeds(data))
      dispatch(wallmag_rem_post(data))
    }
  }