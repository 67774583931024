import React from "react";
import '../../Styles/Chips.css'
// import { Chip } from "@material-ui/core";

export const Chips = props => {
  let color = "#"+props.color;
 
    
  let style = {
    backgroundColor: color
  };
  return (
    <div>
      
      <div
        className={`category_name ${props.styles_for_title ? "selected_id" : "post_title"}`}
        style={style}
      >
        <span className="category_name"> {props.title}</span>
      </div>
    </div>
  );
};
