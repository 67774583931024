import React, { useEffect } from 'react';
// import logo from './logo.svg';
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.css';
import Home from './components/Home/Home';
import Story from './components/Story/Story';
import configureStore from "./Store/configureStore";
import history from './history';
import CategoryPost from './components/Category/CategoryPost';
import About_Us from './components/Other_Components/About_Us';
import AboutUs from './components/AboutUsV1/AboutUs'
import Get_Featured from './components/Other_Components/Get_Featured';
import Header from './components/Wallmag/components/Header';
import Certification from './components/Certification/Certification';
import Privacy from './components/Other_Components/Privacy';
import Terms from './components/Other_Components/Terms';
import Login from './components/Login/Login';
import Issue1 from './components/IssuePages/Issue1';
import ProfilePage from './components/User/Profile/ProfilePage';
import Upload from './components/upload/Upload';
import EditProfile from './components/User/EditProfile';
import PostViewer from './components/User/PostViewer/PostViewer';
import HomeScreen from './components/Awards/HomeScreen';
import Missions from './components/Awards/Missions';
import AwardDetails from './components/Awards/AwardDetails';
import MarketPlace from './components/Awards/MarketPlace';
import Faq from './components/FAQ/Faq';
import FaqSection from './components/FAQ/FaqSection';
import MissionDetails from './components/Awards/MissionDetails';
import EditPost from './components/Edit-Post/EditPost';
import NotFound from './components/NotFound/NotFound';
import BookPhotoshoots from './components/ForBrands/BookPhotoshoots';
import Team from './components/Team/Team';
import MissionPageMob from './components/Awards/MissionPageMob';
import ForgottenPassword from './components/Login/ForgottenPassword';
import ReactGA from "react-ga";
import FooterWallmag from './components/Footer/Footer';
import Signup from './components/User/User/Signup/Signup';
import SetPassword from './components/User/User/SetPassword/SetPassword';
import { getLatestVersion, update_user } from './components/common/AppConstants';
import axios from 'axios';
import ContestDetails from './components/Awards/ContestDetails';
import Ranking from './components/Ranking/Ranking';
import Axios from 'axios';
import ContestPage from './components/Awards/ContestPage';
import MissionsV1 from './components/MissionsV1/MissionsV1';
import Advertise from './components/Advertise/Advertise';

 export const currentVerion = 1.000014;

const store = configureStore();


function App() {
  
  useEffect(async () => {
    await Axios.get(getLatestVersion+currentVerion)
      .then(res => {
        if(res.data.latestVersion === currentVerion){
        }else{
          alert('old version')
          window.location.reload(true)
        }
      })
    },[])

  

  if(window.location.pathname==='/edit-profile'){
    // Edit Profile Page
  }
  else{
    if(JSON.parse(localStorage.getItem("current_user"))){ // After Login
      if(JSON.parse(localStorage.getItem("current_user")).displayName === undefined){ 
        // When User hasn't Updated his/her name
        window.location.assign('/edit-profile') // Redirect To Edit Profie Page 
    }}}


  ReactGA.initialize("UA-117934873-2", {
    gaOptions: {
      userId: JSON.parse(localStorage.getItem("current_user"))
        ? JSON.parse(localStorage.getItem("current_user")).displayName +
          "_" +
          JSON.parse(localStorage.getItem("current_user")).id
        : ""
    }
  });

  ReactGA.set({ userId :  JSON.parse(localStorage.getItem("current_user"))
  ? JSON.parse(localStorage.getItem("current_user")).displayName +
    "_" +
    JSON.parse(localStorage.getItem("current_user")).id
  : "" })

  ReactGA.pageview(history.location.pathname)

  
  return (
    <div className="App">
       <Provider store={store}>
         <BrowserRouter history={history}>
            <Switch >            
              <Route exact path="/" component={Home} />
              <Route path="/story/:seoUrl" component={Story} />
              <Route path="/category/:categoryName" component={CategoryPost} />
              <Route exact path='/about-us' component={AboutUs} />
              <Route exact path='/advertise' component={Advertise} />
              <Route exact path='/get-featured' component={Get_Featured} />
              <Route exact path='/download-app' component={Header} />
              <Route exact path='/verify' component={Certification} />
              <Route exact path='/privacy-policy' component={Privacy} />
              <Route exact path='/terms-of-use' component={Terms} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/forgottenpassword' component={ForgottenPassword} />
              <Route exact path='/photobook/:issue' component={Issue1} />
              <Route exact path='/upload' component={Upload} />
              <Route exact path='/upload/:id' component={Upload} />
              <Route exact path='/edit-post/:slug' component={EditPost} />
              <Route exact path='/team-wallmag' component={Team} />
              <Route exact path='/footer' component={FooterWallmag} />

              <Route exact path='/signup-awards' component={Signup} />
              <Route exact path='/set' component={SetPassword} />

              <Route exact path='/book-photoshoot' component={BookPhotoshoots} />

              <Route exact path='/edit-profile' component={EditProfile} />
              <Route exact path='/awards' component={HomeScreen} />
              <Route exact path='/awards/:category' component={AwardDetails} />
              <Route exact path='/contests' component={Missions} />
              {/* <Route exact path='/missions' component={MissionsV1} /> */}
              <Route exact path='/missions' component={ContestPage} />
              {/* <Route exact path='/missions' component={Missions} /> */}
              {/* <Route exact path='/missions/:missionTag' component={MissionDetails} />
              <Route exact path='/missions/:missionTag/ranking' component={Ranking} />
              <Route exact path='/missions/:missionTag/:type' component={MissionDetails} /> */}

              <Route exact path='/missions/:missionTag' component={ContestDetails} />
              <Route exact path='/missions/:missionTag/:type' component={ContestDetails} />


              <Route exact path='/page-not-found' component={NotFound} />
              <Route exact path='/sell-images' component={MarketPlace} />
              <Route exact path='/faq' component={Faq} />
              <Route exact path='/faq/:faqSection' component={FaqSection} />
              <Route exact path='/post/:postId' component={PostViewer} />
              <Route exact path='/mission-mob/:slug' component={MissionPageMob} />
              <Route path='/:userId' component={ProfilePage} />

            </Switch>
          </BrowserRouter>
        </Provider>
    </div>
  );
}

export default App;
