import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavLink from "react-router-dom/NavLink";
import "../../Styles/Header.css";
import "../../../node_modules/axios-progress-bar/dist/nprogress.css";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import firebase from "../Login/firebaseConfig";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theposition: 0,
      heading: this.props.heading,
      sub_heading: this.props.sub_heading,
      clicked: this.props.clicked,
      url: this.props.url ? this.props.url : "",
      userName: this.props.user,
      anchor: null,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      heading: props.heading,
      sub_heading: props.sub_heading,
    });
    this.forceUpdate();
  }

  componentDidMount() {
    // //console.log(this.state.userName);
    if (this.props.url === undefined) {
      this.setState({
        url: "",
      });
    } else {
      this.setState({
        url: this.props.url,
      });
    }
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  // Menu
  handle_menu = (event) => {
    this.setState({
      anchor: event.currentTarget,
    });
  };

  // Menu Close
  handleClose = (e) => {
    e.preventDefault();

    this.setState({
      anchor: null,
    });
  };

  // logout
  logOut = (e) => {
    e.preventDefault();

    firebase.auth().signOut();
    localStorage.clear();
    this.setState({
      anchor: null,
    });
  };

  saveLoc = () => {
    localStorage.setItem("loc", window.location.href);
  };

  // Listening Scroll Position

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    this.setState({
      theposition: scrolled,
    });
  };

  render() {
    return (
      <div className='head_div'
      style ={this.state.url==='' ? 
      {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
      transparent),linear-gradient( to bottom , rgba(0,0,0,1), transparent)
      , url(${require('../../logo/home_top.jpg')})`,
                                   height:'400px' , width:'100%' , backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat' }

       :
       {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                                transparent),linear-gradient( to bottom , rgba(0,0,0,.8), transparent)
                                , url(${this.state.url})`,
                                   height:'500px' , width:'100%', backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat'  ,
}} 

>
     
        <CssBaseline />

        {this.state.theposition < 0.000001 ? (
          //  if clause
          <AppBar style={{ background: "transparent", boxShadow: "none" }}>
            <Toolbar>
              <NavLink to="/">
                <img
                  alt="wall_logo"
                  id="wall_logo"
                  src={require("../../logo/wallmag_white.png")}
                />
              </NavLink>
              <div
                className="container-fluid"
                style={{ alignSelf: "baseline" }}
              >
                <div className="row justify-content-end">
                  <div className="row mt-3">
                    {/*---------------User------------- */}
                    {localStorage.current_user ? (
                      <>
                        <img
                          alt="logo"
                          height="30px"
                          width="30px"
                          src={
                            JSON.parse(localStorage.getItem("current_user"))
                              .photoUrl
                          }
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                          }}
                          style={{
                            borderRadius: "80px",
                            cursor: "pointer",
                            objectFit: "cover",
                            border: "1px solid #ffffff50",
                          }}
                          onClick={(e) => this.handle_menu(e)}
                        />

                        <Menu
                          style={{ marginTop: "40px" }}
                          id="simple-menu"
                          anchorEl={this.state.anchor}
                          keepMounted
                          open={Boolean(this.state.anchor)}
                          onClose={(e) => this.handleClose(e)}
                        >
                          <NavLink
                            to={`user/${
                              JSON.parse(localStorage.getItem("current_user"))
                                .slug
                            }`}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>My Profile</MenuItem>
                          </NavLink>

                          <NavLink
                            to={"/edit-profile"}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>Edit Profile</MenuItem>
                          </NavLink>

                          <hr></hr>
                          <MenuItem onClick={(e) => this.logOut(e)}>
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <NavLink
                        style={{ textDecoration: "none" }}
                        onClick={this.saveLoc}
                        to="/login"
                      >
                        <p
                          style={{ color: "#fff" }}
                          color="default"
                          className="links"
                        >
                          {" "}
                          Login{" "}
                        </p>
                      </NavLink>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          // else clause
          <AppBar
            style={{
              background: "black",
              borderBottom: "1px solid #ffffff36",
              zIndex: "1",
            }}
          >
            <Toolbar>
              <NavLink to="/">
                <img
                  alt="wall_logo"
                  id="wall_logo2"
                  src={require("../../logo/wallmag_white.png")}
                />
              </NavLink>
              <div
                className="container-fluid "
                style={{ alignSelf: "baseline" }}
              >
                <div className="row justify-content-end ">
                  <div className="row mt-3">
                    {/*---------------User------------- */}
                    {localStorage.current_user ? (
                      <>
                        <img
                          alt="icon"
                          height="30px"
                          width="30px"
                          src={
                            JSON.parse(localStorage.getItem("current_user"))
                              .photoUrl
                          }
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                          }}
                          style={{
                            borderRadius: "80px",
                            cursor: "pointer",
                            objectFit: "cover",
                            border: "1px solid #ffffff50",
                          }}
                          onClick={(e) => this.handle_menu(e)}
                        />

                        <Menu
                          style={{ marginTop: "40px" }}
                          id="simple-menu"
                          anchorEl={this.state.anchor}
                          keepMounted
                          open={Boolean(this.state.anchor)}
                          onClose={(e) => this.handleClose(e)}
                        >
                          <NavLink
                            to={`user/${
                              JSON.parse(localStorage.getItem("current_user"))
                                .slug
                            }`}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>My Profile</MenuItem>
                          </NavLink>

                          <NavLink
                            to={"/edit-profile"}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem>Edit Profile</MenuItem>
                          </NavLink>

                          <hr></hr>
                          <MenuItem onClick={(e) => this.logOut(e)}>
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <NavLink
                        style={{ textDecoration: "none" }}
                        onClick={this.saveLoc}
                        to="/login"
                      >
                        <p
                          style={{ color: "#fff" }}
                          color="default"
                          className="links2"
                        >
                          {" "}
                          Login{" "}
                        </p>
                      </NavLink>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        )}
        <Toolbar />

        <div className="container desc_div_">
          {/* Heading of The Header  */}
          <div className="row " style={{ marginTop: "40px" }}>
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="row justify-content-center ">
                <p id="desc"> {this.state.heading} </p>
              </div>
            </div>

            {/* Sub_Heading of The Header */}
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="row justify-content-center ">
                <p id="desc3"> {this.state.sub_heading} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
