import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavLink from 'react-router-dom/NavLink'
import '../../Styles/Header.css'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import firebase from "../Login/firebaseConfig";


class  Header extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            theposition: 0,
            heading : this.props.heading ,
            sub_heading : this.props.sub_heading,
            clicked : this.props.clicked,
            anchor : null , 

        }
        
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }

      saveLoc=()=>{
        localStorage.setItem('loc',window.location.href)
      }

            // close dialog
            closeDialog = ()=>{
              this.setState({
                followers_dialog : false,
                following_dialog : false
              })
            }
      
      
            // Menu
            handle_menu =(event)=>{
              this.setState({
                anchor : event.currentTarget
              })
            }
      
            // Menu Close
            handleClose=(e)=>{
      
              e.preventDefault()
      
              this.setState({
                anchor : null
              })
            }
      
            // logout
            logOut = (e) =>{
              e.preventDefault()
      
              firebase.auth().signOut()
              localStorage.clear()
              
              this.setState({
                anchor : null
              })
              
            }


// Listening Scroll Position

      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
        this.setState({
          theposition: scrolled,
        })
      }


    render(){
    


  return (
    
    <div className='head_div' >
    
      <CssBaseline />
    
     

        {this.state.theposition <0.000001
                 ?
         //  if clause
            <AppBar  style={{ background: 'transparent', boxShadow: 'none'}}>
             <Toolbar>
               <NavLink to='/'>
                <img id='wall_logo' src={require('../../logo/wallmag_white.png')}  alt='Logo' />
               </NavLink> 
                  <div className='container-fluid'  style={{alignSelf:'baseline'}}>
                    <div className='row justify-content-end'>
                    <div className='row mt-3'>
                    {
                              localStorage.user ? 
                              <>
                              <img  
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  alt='Logo'
                                  style ={{borderRadius:'600px' , cursor:'pointer', objectFit:'cover',border:'1px solid #ffffff50'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  />

                                  <Menu
                                  style={{marginTop:'40px'}}
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  onClose={e=>this.handleClose(e)}
                                >
                                  

                                  <NavLink to={`/${JSON.parse(localStorage.getItem('current_user')).slug}`}  style={{textDecoration:'none'}}>
                                  <MenuItem>
                                    My Profile

                                  </MenuItem>
                                  </NavLink>



                                  <NavLink to={'/edit-profile'}  style={{textDecoration:'none'}}> 
                                  <MenuItem>
                                  
                                        Edit Profile
                                    
                                  </MenuItem>
                                  </NavLink>

                                  <hr></hr>
                                  <MenuItem onClick={e=>this.logOut(e)}>Logout</MenuItem>
                                  </Menu>
                                  </>
                              :
                              <NavLink style={{ textDecoration: 'none' }}  onClick={this.saveLoc}  to='/login' >
                                  <p style={{color:'#fff'}} color='default' className='links'> Login </p>
                             </NavLink>
                         }

                             
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    </div>
               </Toolbar>
             </AppBar>
         
                :

          // else clause
             <AppBar   style={{background:'black', borderBottom:'1px solid #ffffff36'}} >
                <Toolbar>
                <NavLink to='/'>
                 <img id='wall_logo2' src={require('../../logo/wallmag_white.png')}  alt='Logo' />
                </NavLink>
                 <div className='container-fluid '>
                    <div className='row justify-content-end'>
                    <div className='row mt-3'>
                    {
                              localStorage.user ? 
                              <>
                              <img  
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  alt='Logo'
                                  style ={{borderRadius:'600px' , cursor:'pointer', objectFit:'cover',border:'1px solid #ffffff50'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  />

                                  <Menu
                                  style={{marginTop:'40px'}}
                                  id="simple-menu"
                                  anchorEl={this.state.anchor}
                                  keepMounted
                                  open={Boolean(this.state.anchor)}
                                  onClose={e=>this.handleClose(e)}
                                >
                                  

                                  <NavLink to={`/${JSON.parse(localStorage.getItem('current_user')).slug}`}  style={{textDecoration:'none'}}>
                                  <MenuItem>
                                    
                                    My Profile
                                    
                                  </MenuItem>
                                  </NavLink>



                                  <NavLink to={'/edit-profile'}  style={{textDecoration:'none'}}> 
                                  <MenuItem>
                                        Edit Profile

                                  </MenuItem>
                                  </NavLink>


                                  <hr></hr>
                                  <MenuItem onClick={e=>this.logOut(e)}>Logout</MenuItem>
                                  </Menu>
                                  </>
                              :
                              <NavLink style={{ textDecoration: 'none' }}  onClick={this.saveLoc}  to='/login' >
                                  <p style={{color:'#fff'}} color='default' className='links2'> Login </p>
                             </NavLink>
                         } 
                      
                        
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        
                    </div>
                    </div>

               </Toolbar>
        </AppBar>
          }
      <Toolbar />

              <div className='container desc_div'  style={{alignSelf:'baseline'}}>

                {/* Heading of The Header  */}
                <div className='row '>   
                  <div className='col-md-12 col-lg-12 col-xl-12'>
                      <div className='row justify-content-center '>
                          <p id='desc'> {this.state.heading} </p>
                      </div>
                  </div>
                  
                  {/* Sub_Heading of The Header */}
                  <div className='col-md-12 col-lg-12 col-xl-12'>
                      <div className='row justify-content-center '>
                        <p id='desc2'> {this.state.sub_heading} </p> 
                      </div>
                  </div>
                  
                  </div>
                </div>
              </div>
  );
        }
}

export default Header