import {CATEGORY} from './../types'
// import axios from 'axios'

// const apiUrlForCat = 'https://wallmag-2.appspot.com/_ah/api/categoryApi/v1/getCategoriesV2';

export const Story_Category = (data)=>{
  return {
    type: CATEGORY,
    data,
  }
}
/////// this is middleware /////////
export const story_category  = (data) => {
    return (dispatch) => {
      dispatch(Story_Category(data))


        // return axios.get(apiUrlForCat)
        //   .then(response => {
        //     dispatch(Story_Category (response.data));
        //   })
        //   .catch(error => {
        //     console.log(error.message);
        //   });
      };
  };