import React from 'react'
import { Button, TextField, FormControlLabel, Switch, createMuiTheme } from '@material-ui/core'
import{ NavLink} from 'react-router-dom'
import axios from 'axios'
import { get_post_by_slug, get_post_update } from '../common/AppConstants'
import cogoToast from 'cogo-toast'
import firebase from '../Login/firebaseConfig'
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider'

var data = []
var checked = false

const theme = createMuiTheme({
    typography: {
      // Use the system font.
      fontFamily:
        // '-apple-system,system-ui,BlinkMacSystemFont,' 
        // +
        'Montserrat',
    },
  })


class EditPost extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            slug : this.props.match.params.slug,
            title : '',
            story : '',
        }
    }

    componentDidMount(){

        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})

     
        axios.get(get_post_by_slug+"?slug="+this.state.slug)
        .then(res=>{
            console.log(res)
            this.setState({
                title : res.data.title,
                story : res.data.description,
                checked : res.data.isInMarketplace
            })
            checked = res.data.isInMarketplace
            data = res.data
            this.forceUpdate()
        })
        .catch(error=>{

        })
    }






    update=(e)=>{

        e.preventDefault()

        data.title = this.state.title
        data.description = this.state.story
        data.isInMarketplace = this.state.checked

        axios.put(get_post_update+data.id,
            data)
            .then(res=>{
            
                cogoToast.success('Your post has been updated')
                window.history.back()
            })
            .catch(error=>{
                console.log(error);
            })
    }










        // Checked
        handleChange = (e) =>{
            console.log(e.target.checked)
            this.setState({
                checked : e.target.checked
            })
            checked = e.target.checked
        }


        // On Change
        change = (e)=>{
            this.setState({
              [e.target.name] : e.target.value,
              open : false
            })
          }



    render(){
        return(
            <>
            {
                data.slug ? 
                <>
              <div className='container-fluid' >
                    <div className='row'>
                         
                            <div className='header col-6 col-sm-6 col-md--6 col-lg-6 col-xl-6'>
                                 <img 
                                    onClick={e=>this.props.history.push('/')}
                                    alt='Logo'
                                    style={{cursor:'pointer'}}
                                    height='45px' width='45px' 
                                    src={require('../../logo/wallmag_black.png')} />
                            </div>
                       
                           <div className='header2 col-6 col-sm-6 col-md--6 col-lg-6 col-xl-6'>
                           <Button variant="outlined" style={{outline:'none'}} onClick={e=>window.history.back()}>
                                Go Back
                            </Button>
                           </div>
                    </div>
                </div>
  




                <div className='conatiner' style={{overflow:'hidden'}}>
      
                <div className='row'>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
                    <img 
                        className='uploading_image' 
                        src={data.imageProperties[0].medium ? data.imageProperties[0].medium : data.imageProperties[0].originalUrl} 
                        alt='Logo' 
                        />
                </div>    


                   <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
    
                   <ThemeProvider theme={theme}>

                <TextField
                    className='textFiled'
                    name="title"
                    type="search"
                    placeholder='Caption'
                    margin="normal"
                    variant="outlined"
                    multiline
                    value={this.state.title}
                    onChange={e => this.change(e)}
            />
            </ThemeProvider>
            </div>
            
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
            <ThemeProvider theme={theme}>

                <TextField
                    className='textFiled'
                    name="story"
                    type="search"
                    placeholder='Story Behind Photo'
                    margin="normal"
                    variant="outlined"
                    multiline
                    value={this.state.story}
                    onChange={e => this.change(e)}
                /> 
                </ThemeProvider>
            </div>


    
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
              <FormControlLabel
                            control={
                                <Switch
                                    checked={checked}
                                    onChange={e=> this.handleChange(e)}
                                    value="checkedB"
                                    color="primary"
                                />
                                }
                                  label="List my image on marketplace for sell"
                        />
                </div>
    
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >
                                    <div className='row' style={{justifyContent:'center'}}>
                         
                                    
                                <NavLink style={{ textDecoration: 'none'}}  target='_blank' to='/mp'>
                                            <p className='We-will-never-send-you-junk-mail'>  Learn how WallMag marketplace works</p>
                                            </NavLink>
                            </div>
                </div>
            </div>
            </div>

            <div className='container payment_button' style={{width:'100%', marginTop:'48px', marginBottom:'48px'}}>
                        <a onClick={e=>this.update(e)}>
                            <div className="container butt ">
                                    <p id='pre'> Update Post </p>
                            </div>  
                        </a>
                    </div>
 </>
                                    :

                                    <>
                                    
                                    </>
                            }
            </>
        )
    }
}

export default EditPost