import {PRODUCT_API } from './../types';
// const isEmpty = null;
const initialState = {
    home_top_data: {},
    home_top_load: false
}
export default function HomeReducer(state = initialState, action) {
 switch(action.type){
    case PRODUCT_API : 
        return {...state, home_top_data: action.data , home_top_load: true};
     default : return state
 }    
}