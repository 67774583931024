import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import NavLink from 'react-router-dom/NavLink'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import '../../../Styles/ProfilePageHeaderMob.css'
import firebase from "../../Login/firebaseConfig";
import { loadProgressBar } from 'axios-progress-bar'
// import { Redirect } from 'react-router-dom'
import { get_followings, follow_user, get_followers } from '../../common/AppConstants';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { Dialog, DialogTitle } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ReactImageFallback from 'react-image-fallback';


const formatter = buildFormatter(frenchStrings)

var followers = []
var followings = []
var followingIds = new Set()

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});



export default class ProfilePageHeaderMob extends React.Component{

  constructor(props){
    super(props)
      this.state ={
        top: false,
        left: false,
        bottom: false,
        right: false,
        theposition: 0,
        heading : this.props.heading,
        sub_heading : this.props.sub_heading,
        clicked : this.props.clicked,
        url : '' , 
        userName : this.props.user,
        anchor : null , 
        userData : this.props.data,
        followers_dialog:false,
        following_dialog:false,
        // facebookUrl : this.props.data.facebookUrl ? this.props.data.facebookUrl : '',
        // instagramUrl :  this.props.data.instagramUrl ? this.props.data.instagramUrl : '',
        facebookUrl:'',
        instagramUrl:''

      }
  }
    
  componentWillReceiveProps(props){
    loadProgressBar()
    
    followingIds = new Set(JSON.parse(localStorage.getItem('followingIds')))
    
    this.forceUpdate()

    this.setState({
      userData : this.props.data
    })

  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)

    if(this.props.url===undefined){
      this.setState({
        url : ''
  })
    }else{
      this.setState({
          url : this.props.url
    })
  }
    
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }


  
                    // Redirect to Profile page 
                    goToProfile = (from ,userSlug) =>{
                        
                      // //console.log(this.props)
                      localStorage.setItem('other_user_slug',userSlug)
                      // this.props.props.history.push('/'+userSlug)
                  
                      window.location.assign('/'+userSlug)
                   
              }

  
      // go to voting-system article
      goToInfo=()=>{
        window.location.assign('/story/voting-system')
        
      }

      saveLoc=()=>{
        localStorage.setItem('loc',window.location.href)
      }

  followUser = (e, userId,isFollow)=>{
    
    e.preventDefault()
    axios.put(follow_user, {
        isFollow : isFollow,
        userId : userId
    }).then(response =>{
        
        if(isFollow===true){
            followingIds.add(userId)
        }else{
            followingIds.delete(userId)
        }
        
        localStorage.setItem('followingIds' ,JSON.stringify( Array.from(followingIds)))
        this.forceUpdate()

    }).catch(error=>{
        // //console.log(error)
    })
    
}
  

// get followings 
getFollowings = (user_id) => {

  
  axios.put(get_followings+'?userId='+user_id)
  .then(res=>{
    
    followings = res.data.items
    this.setState({
      following_dialog:true
    })
    this.forceUpdate()
  })
}

  // get Followers
  getFollowers = (user_id) => {

      axios.put(get_followers+'?userId='+user_id)
      .then(res=>{
          followers = res.data.items
          this.setState({
            followers_dialog:true
          })
          this.forceUpdate()
      })
  }



  // No followers
  Nofollowers=()=>{
    cogoToast.info("you don't have any followers !")
  }



  // No followings
  noFollowings=()=>{
    cogoToast.info("You are not following anyone !")
  }











  // close dialog
  closeDialog = ()=>{
    this.setState({
      followers_dialog : false,
      following_dialog : false
    })
  }


  
      // logout
      logOut = (e) =>{
        e.preventDefault()

        firebase.auth().signOut()
        localStorage.clear()
        
        setTimeout(this.goToLogin, 100)

        
        // window.location.assign('/')

        this.setState({
          anchor : null
        })
        
      }

      goToLogin=()=>{
        window.location.assign('/')
      }

// Listening Scroll Position

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
    this.setState({
      theposition: scrolled,
    })
  }



 render(){

    
  if(localStorage.current_user){

  }else{
  }



  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ ...this.state, [side]: open });
  };

  

  const fullList = side => (
    <div
      className={useStyles.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >   
     {
                              localStorage.current_user ? 
                              <>
                              {/* <img  
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  style ={{borderRadius:'80px' , cursor:'pointer', objectFit:'cover'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  /> */}

<NavLink style={{ textDecoration: 'none' }} to='/' >

                              <div className='container'> 
                                <div className='row justify-content-center'>
                                        <p color='default' className='wall_web_links'> 
                                        WallMag Home
                                        </p>
                                </div>
                              </div>
                              </NavLink>



                              <NavLink style={{ textDecoration: 'none' }} to='/edit-profile' >

                                  <div className='container'> 
                                <div className='row justify-content-center'>
                                        <p color='default' className='wall_web_links'> Edit-profile </p>
                                </div>
                              </div>
                              </NavLink>


                                
                                
                              <NavLink style={{ textDecoration: 'none' }} onClick={e=>this.logOut(e)} to='/login' >
                                  <div className='container'> 
                                <div className='row justify-content-center'>
                                        <p color='default' className='wall_web_links'> Logout </p>
                                </div>
                              </div>
                              </NavLink>


                                  </>
                              :
                              <NavLink style={{ textDecoration: 'none' }}   onClick={this.saveLoc}  to='/login' >
                              <div className='container'> 
                                <div className='row justify-content-center'>
                                        <p color='default' className='wall_web_links'> Login </p>
                              </div>
                             </div>
                            </NavLink>

                         }
           
    </div>
  );

  return (
    
    <div className='head_div'
     style ={this.state.url==='' ? 
        {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                              transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                              , url(${require('../../../logo/bg11.jpg')})`,
                                 height:'600px' , width:window.outerWidth , backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat' }

         :
         {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                              transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                              , url(${this.state.url})`,
                                 height:'600px' , width:window.outerWidth, backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat'  ,
}} 

>
   
   {this.state.theposition <0.11111
                 ?

            <AppBar style={{ background: 'transparent', boxShadow: 'none'}}>
            <Toolbar>
              <div className='container-fluid justify-content-start'>
              <div className='row justify-content-start'>
                  <NavLink to='/'>
                  
                      <img  src={require('../../../logo/wallmag_white.png')} alt='Logo' height='20px'/>
                  </NavLink>    
              </div>
              </div>

            <div className='justify-content-end'>
              <MenuIcon  variant='permanent' color='inherit' onClick={toggleDrawer('top', true)} />

                  <Drawer anchor="top" open={this.state.top} onClose={toggleDrawer('top', false)}>
                  {fullList('top')}
                  </Drawer>
                  </div>
            </Toolbar>
            </AppBar>  


    :
    
            <AppBar  style={{background:'black', borderBottom:'1px solid #ffffff36'}} >
            <Toolbar>
              <div className='container-fluid justify-content-start'>
              <div className='row justify-content-start'>
                <NavLink to='/'>
                
                    <img  src={require('../../../logo/wallmag_white.png')} alt='Logo' height='20px'/>
                </NavLink>    
          </div>
            </div>

            <div className='justify-content-end'>
              <MenuIcon  variant='permanent' color='inherit' onClick={toggleDrawer('top', true)} />

                  <Drawer anchor="top" open={this.state.top} onClose={toggleDrawer('top', false)}>
                  {fullList('top')}
                  </Drawer>
                </div>
            </Toolbar>
        </AppBar>  

    }


<div className='container ' > 

{/* Image of The User  */}
<div className='row '>   
<div className='col-md-12 col-lg-12 col-xl-12'>
<div className='row justify-content-center '>

{
    this.state.userData === '' ?
    <img src={(JSON.parse(localStorage.getItem('current_user')).photoUrl)} 
          alt='user' 
          height='80px' 
          width='80px'
          style={{borderRadius:'180px', objectFit:'cover', marginTop:'60px',border:'1px solid #ffffff50'}}
     />
     :
     <img src={this.state.userData.photoUrl} 
        alt='user' 
        height='80px' 
        width='80px'
        style={{borderRadius:'180px', objectFit:'cover', marginTop:'60px',border:'1px solid #ffffff50'}}
     />

}
</div>
</div> 

{/* Heading of The Header  */}

<div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'40px'}}>
<div className='row justify-content-center '>
  {
    this.state.userData === '' ?
    <>
      <p id='user_name' > {(JSON.parse(localStorage.getItem('current_user')).displayName)} </p> 
      {
              JSON.parse(localStorage.getItem('current_user')).badge === '0' ?
              <></>

              :
              JSON.parse(localStorage.getItem('current_user')).badge === '1' ?
              <img onClick={e=>this.goToInfo()} src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
  

                            :
              JSON.parse(localStorage.getItem('current_user')).badge === '2' ?
              <img onClick={e=>this.goToInfo()} src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>
  

                            :
              JSON.parse(localStorage.getItem('current_user')).badge === '3' ?
              <img onClick={e=>this.goToInfo()} src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>


                            :
              <img onClick={e=>this.goToInfo()} src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>

      }
    </>


    :

    <>
    <p id='user_name' > {(this.state.userData.displayName)} </p> 
    {
            this.state.userData.badge === '0' ?
            <></>

            :
            this.state.userData.badge === '1' ?
            <img onClick={e=>this.goToInfo()} src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>


                          :
            this.state.userData.badge === '2' ?
            <img onClick={e=>this.goToInfo()} src={require('../../../logo/2.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>


                          :
            this.state.userData.badge === '3' ?
            <img onClick={e=>this.goToInfo()} src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>


                          :
            <img onClick={e=>this.goToInfo()} src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginLeft:'5px', cursor:'pointer'}}/>

    }
  </>
  }
    
</div>
</div> 

{/* Sub_Heading of The Header */}
<div className='col-md-12 col-lg-12 col-xl-12'>
<div className='row justify-content-center '>

{/* {
this.state.userData === '' ?

       JSON.parse(localStorage.getItem('current_user')).facebookUrl === '' ?
          <></>
            :
            <a href={(JSON.parse(localStorage.getItem('current_user')).facebookUrl).indexOf('https://')=== 0 ?
            JSON.parse(localStorage.getItem('current_user')).facebookUrl 
            :
            'https://'+JSON.parse(localStorage.getItem('current_user')).facebookUrl
          } 
      
         target='_blank'>
        
          <img src={require('../../../logo/fb.svg')} alt='fb_logo' style={{height:'24px', width:'24px', opacity:'0.8', cursor:'pointer'}} />
        </a>

        :

        this.state.facebookUrl ===''  ?
        <></>
          :
          <a href={(this.state.facebookUrl).indexOf('https://') === 0 ?
                                        this.state.facebookUrl  
                                        :
                                        'https://'+this.state.facebookUrl
                                      }
              target='_blank'>

        <img src={require('../../../logo/fb.svg')} alt='fb_logo' style={{height:'24px', width:'24px', opacity:'0.8', cursor:'pointer'}} />
      </a>

}

{
this.state.userData === '' ?
 
    (JSON.parse(localStorage.getItem('current_user')).instagramUrl==='' ) ?

      <></> :
      
      <a href={ (JSON.parse(localStorage.getItem('current_user')).instagramUrl).indexOf('https://') === 0 ?
      JSON.parse(localStorage.getItem('current_user')).instagramUrl
      :
      'https://'+JSON.parse(localStorage.getItem('current_user')).instagramUrl} 
target='_target'>
      <img src={require('../../../logo/insta.svg')} alt='insta_logo' style={{height:'24px', width:'24px', marginLeft:'12px', opacity:'0.8', cursor:'pointer'}} />
      </a>

      :

      this.state.instagramUrl===''  ?

      <></> :
      
      <a href={ (this.state.instagramUrl).indexOf('https://') === 0 ?
      this.state.instagramUrl
      :
     'https://'+this.state.instagramUrl} target='_target'>
      <img src={require('../../../logo/insta.svg')} alt='insta_logo' style={{height:'24px', width:'24px', marginLeft:'12px', opacity:'0.8', cursor:'pointer'}} />
      </a>
}
 */}

{/* <p id='who_are_you'> Photographer </p>  */}
</div>
</div>


{/* Details of The User */}
<div className='col-md-12 col-lg-12 col-xl-12' style={{marginTop:'40px'}}>
<div className='row justify-content-center '>
<div className='col-md-3 col-lg-3 col-xl-3'></div>

{/* ------------------------------------ */}

    <div className='col-md-6 col-lg-6 col-xl-6'>
        <div className='row'>


        <div className='col-md-4 col-lg-4 col-xl-4'>

          {
            this.state.userData === '' ?
            <>
              <p className='counts'> {(new Set(JSON.parse(localStorage.getItem('myPostIds')))).size} </p> 
              <p className='cfb'>Photographs</p> 
            </>
            :
            <>
            {
              this.state.userData.userAnalytics.myPostIds ?

              <>
                <p className='counts'>{this.state.userData.userAnalytics.myPostIds.length} </p> 
                <p className='cfb'>Photographs</p> 
              </>
              :
              <>
                <p className='counts'>0</p> 
                <p className='cfb'>Photographs</p>
              </>

            }
              
            </>
          }

        </div>

        <div className='col-md-4 col-lg-4 col-xl-4'>

          {
            this.state.userData === '' ?
              <>
                {(new Set(JSON.parse(localStorage.getItem('followersIds')))).size===0 ? 
                
                   <>                               
                        <p className='counts' style={{cursor:'pointer'}} onClick={e=>this.Nofollowers()}>
                          {(new Set(JSON.parse(localStorage.getItem('followersIds')))).size} 
                        </p> 
                        <p className='cfb'> Followers </p> 
                        </>
                                      :
              <>
                          <p className='counts' style={{cursor:'pointer'}} onClick={e=>this.getFollowers(JSON.parse(localStorage.getItem('current_user')).id)}>
                          {(new Set(JSON.parse(localStorage.getItem('followersIds')))).size} 
                       </p> 
                       <p className='cfb'> Followers </p> 
              </>
            } 

              </>
              :
              <>
              {
                this.state.userData.userAnalytics.followersIds ?
                <>
                  <p className='counts' style={{cursor:'pointer'}}  onClick={e=>this.getFollowers(this.state.userData.id)}> 
                      {this.state.userData.userAnalytics.followersIds.length} 
                  </p>
                   
                  <p className='cfb'> Followers </p> 
                </>
                :
                <>
                  <p className='counts'  onClick={e=>this.Nofollowers()}> 0 </p> 
                  <p className='cfb'> Followers </p> 
                </>
              }

             </>
          }

        </div>
        
        <div className='col-md-4 col-lg-4 col-xl-4'>

          {
            this.state.userData === '' ?
            <>
            {(new Set(JSON.parse(localStorage.getItem('followingIds')))).size===0 ?
          
                  <>                                   
                <p 
                  className='counts'  
                  style={{cursor:'pointer'}} 
                  onClick={e=>this.noFollowings()}> 
                              
                              {(new Set(JSON.parse(localStorage.getItem('followingIds')))).size} 
                 </p> 
                 
                <p className='cfb'> Following </p> </>

                  :
                  <>                                    
                 <p 
                  className='counts'  
                  style={{cursor:'pointer'}} 
                  onClick={e=>this.getFollowings(JSON.parse(localStorage.getItem('current_user')).id)}> 
                              
                              {(new Set(JSON.parse(localStorage.getItem('followingIds')))).size} 
                 </p> 
                 
                <p className='cfb'> Following </p> </>
          
          
          }

            </>
            :
            <>
            {
              this.state.userData.userAnalytics.followingIds ? 
              <>
                <p className='counts' style={{cursor:'pointer'}}  
                onClick={e=>this.getFollowings(this.state.userData.id)}
                > 
                  {this.state.userData.userAnalytics.followingIds.length} 
                </p> 


                <p className='cfb'> Following </p> 
              </>
              :
              <>
                <p className='counts'   style={{cursor:'pointer'}} 
                                        onClick={e=>this.noFollowings()}>
                  0
                </p> 
                <p className='cfb'> Following </p> 
              </>
            }

            </>
          }
           
        </div>

        {/* cfb --> comments faourites bookmarks */}
        </div>
    </div>

    {/* --------------------------------------- */}


    <div className='col-md-3 col-lg-3 col-xl-3'></div>
</div>
</div>

</div>
</div> 

    {/* dialog for follower List (Mob Screens)*/}
    <Dialog 
                
                open={this.state.followers_dialog} 
                onClose={this.closeDialog}
                disableBackdropClick={true}
                fullScreen>
                    
            
            
                <DialogTitle >
                     <AppBar style={{background:'white', paddingRight:'20px', paddingLeft:'10px'}}>
                     <div className='row' style={{justifyContent:'space-between', marginTop:'10px'}}>
                            <p id='totalLikers'>{followers.length} followers</p>
                   
                        <img 
                            src={require('../../../logo/cancel.svg')} 
                            alt='Cancel'
                            style={{marginTop:'-10px', cursor:'pointer'}} 
                            onClick={e=>this.setState({followers_dialog:false})}/>
                    </div>
                     </AppBar>
                    

                    

                    
                </DialogTitle>
                
                

                <List style={{width:'280px', marginTop:'30px'}}>

                {
                    followers ? 
                        followers.map((value,indexNo)=>{
                            return(
                                <ListItem>
                            
                                <ListItemAvatar>
                                    {/* <img 
                                        src={value.photoUrl} 
                                        alt='user'
                                        style={{height:'30px', width:'30px', borderRadius:'60px', objectFit:'cover', marginTop:'-16px'}}
                                    /> */}

                      <ReactImageFallback
                        src={value.photoUrl} 
                        fallbackImage="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                        style={{height:'30px', width:'30px', borderRadius:'60px', objectFit:'cover', marginTop:'-16px',border:'1px solid #ffffff50'}}
                        alt="cool image should be here"
                        className="my-image" />


                                </ListItemAvatar>
                                <div>
                                    {
                                        value.badge === '0' ? 
                                        <>
                                        <p id='nameOfLikersNoBadge' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <p id='desig'>
                                            Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                        </p>
                                        </>
                                        :

                                        value.badge === '1' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 5 Votes
                                            </p>
                                        </>
                                        
                                        :

                                        value.badge === '2' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 10 Votes
                                            </p>
                                        </>
                                    
                                        :
                                        value.badge === '3' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 20 Votes
                                            </p>
                                        </>
                                    
                                        :
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 100 Votes
                                            </p>
                                        </>
                                    
                                        


                                        
                                    }
                                   
                                </div>
                                
                                
                                {
                                    (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                                        
                                              <></> 
                                                     :

                                          new Set(JSON.parse(localStorage.getItem('followingIds'))).has(value.id) ? 

                                                <div className='following_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                      onClick={e=>this.followUser(e, value.id,false)}>
                                                    <p id='following_dialog_text'> Following </p>
                                                </div>
                                                :
                                                <div className='follow_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                onClick={e=>this.followUser(e, value.id,true)}>
                                                    <p id='foll_dialog_text'> Follow </p>
                                                </div> 
                                }

                                </ListItem>
    
                            )
                        })        
                            
                        :
                        <></>
                }
                
  </List>


            </Dialog>


{/* dialog for follower List (Mob Screens)*/}





{/* dialog for following List (Mob Screens )*/}
        <Dialog 
                
                open={this.state.following_dialog} 
                onClose={this.closeDialog}
                disableBackdropClick={true}
                fullScreen>
                    
            
            
                <DialogTitle >
                     <AppBar style={{background:'white', paddingRight:'20px', paddingLeft:'10px'}}>
                     <div className='row' style={{justifyContent:'space-between', marginTop:'10px'}}>
                            <p id='totalLikers'>{followings.length} Followings</p>
                   
                        <img 
                            src={require('../../../logo/cancel.svg')} 
                            alt='Cancel'
                            style={{marginTop:'-10px', cursor:'pointer'}} 
                            onClick={e=>this.setState({following_dialog:false})}/>
                    </div>
                     </AppBar>
                    

                    

                    
                </DialogTitle>
                
                

                <List style={{width:'280px', marginTop:'30px'}}>

                {
                    followings ? 
                        followings.map((value,indexNo)=>{
                            return(
                                <ListItem>
                            
                                <ListItemAvatar>
                                    <img 
                                        src={value.photoUrl} 
                                        alt='user'
                                        style={{height:'30px', width:'30px', borderRadius:'60px', objectFit:'cover', marginTop:'-16px',border:'1px solid #ffffff50'}}
                                    />
                                </ListItemAvatar>
                                <div>
                                    {
                                        value.badge === '0' ? 
                                        <>
                                        <p id='nameOfLikersNoBadge' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <p id='desig'>
                                            Joined <TimeAgo date={parseInt(value.dateCreated)} formatter={formatter} />
                                        </p>
                                        </>
                                        :

                                        value.badge === '1' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 5 Votes
                                            </p>
                                        </>
                                        
                                        :

                                        value.badge === '2' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/1.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 10 Votes
                                            </p>
                                        </>
                                    
                                        :
                                        value.badge === '3' ? 
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/3.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 20 Votes
                                            </p>
                                        </>
                                    
                                        :
                                        <>
                                       
                                        <div className='row'>
                                        <p id='nameOfLikers' onClick={e=>this.goToProfile(1,value.slug)}>{value.displayName.length > 16 ? 
                                             value.displayName.slice(0,16)+'...' 
                                                :
                                             value.displayName   
                                                }</p>
                                        <img src={require('../../../logo/4.png')} alt='Logo' style={{height:'28px', width:'20px', marginTop:'-5px', marginLeft:'5px'}}/>

                                        </div>
                                        <p id='desig'>
                                                1 Vote = 100 Votes
                                            </p>
                                        </>
                                    
                                        


                                        
                                    }
                                   
                                </div>
                                
                                
                                {
                                    (JSON.parse(localStorage.getItem('current_user'))).id === value.id ?
                                        
                                              <></> 
                                                     :

                                          new Set(JSON.parse(localStorage.getItem('followingIds'))).has(value.id) ? 

                                                <div className='following_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                      onClick={e=>this.followUser(e, value.id,false)}>
                                                    <p id='following_dialog_text'> Following </p>
                                                </div>
                                                :
                                                <div className='follow_dialog' style={{marginLeft:'120px', cursor:'pointer'}} 
                                                onClick={e=>this.followUser(e, value.id,true)}>
                                                    <p id='foll_dialog_text'> Follow </p>
                                                </div> 
                                }

                                </ListItem>
    
                            )
                        })        
                            
                        :
                        <></>
                }
                
  </List>


            </Dialog>

{/* dialog for following List (Mob Screens)*/}

   
  </div>

    );
  }
}

       
     
 