import React, { Component } from 'react';
// import Iframe from 'react-iframe';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../../../node_modules/froala-design-blocks/dist/css/froala_blocks.css';
// import Features from './Features';
import './DownloadApp.css'

export default class DownloadApp extends Component {
  render() {
    return <section className="bg">
  <div className="container-fluid main">
    <div className="row align-items-center download">
     
      <div className="container  col-md-5 col-lg-6  m-auto pb-5 pb-md-0">
        <div className='row mainImageDiv'>
          <div className='col-md-1 col-lg-5 '></div>
          
          <div>
        <img alt="imuage" className="img-fluid rounded-0 mainImage" src="../../imgs/wallFirst.png" />
          </div>
     
      </div>
      </div>

{/* Large Screen */}
      <div id='largeScreens' className="container col-md-7 col-lg-6 pb-5 pb-md-0 ">
        <div className='row'>
          <div className='logo'>
          <img alt="imauge" className="fdb-icon icon" src="./imgs/wm.png" />
          </div>
          
          <div className=''>
            <div className='wall_main'>
                  <h1 className='wall'>WallMag</h1>
                  </div>
                  <div>
        <p className="desc">Where photos meet stories</p>
        </div>
        </div>
        </div>
      
        
        <p className="mt-4 app_download">
          <a  href="https://play.google.com/store/apps/details?id=io.wallmag.android&hl=en_IN">
            <img className="gp" src='../../imgs/gp.png' alt='https://wallmag-2.appspot.com/_ah/api'/>
            </a>
          <img className="appst" src='../../imgs/appstore.png' alt='https://wallmag-2.appspot.com/_ah/api'/>
        </p>
      </div>
  
  
{/* Mobile Screen */}
      <div id='smallScreens' className="container col-12  col-md-7 pb-5 pb-md-0">
       
        <div className='row'>
          <div className='container logo col-12'>
            <div className='row justify-content-center'>
          <img alt="imuage" className="fdb-icon icon" src="./imgs/wm.png" />
          </div>
          </div>
          
          <div className='container col-12'>
            <div className='row justify-content-center'>
                  <h1 className='wall'>WallMag</h1>
                  </div>
                  <div>
        </div>
        </div>
        </div>
        <div id='small' className='container'>
          <div className='row justify-content-center'>
          <p  className="desc">Where photos meet stories </p>
          </div>
        </div>
        
        
        <div className='container'>
          <div className='row justify-content-center'>
        <p className="mt-4">
          <a  href="https://play.google.com/store/apps/details?id=io.wallmag.android&hl=en_IN">
            <img className="gp" src='../../imgs/gp.png' alt='https://wallmag-2.appspot.com/_ah/api'/></a>
          <img className="appst" src='../../imgs/appstore.png' alt='https://wallmag-2.appspot.com/_ah/api'/>
        </p>
        </div>
        </div>

      </div>
    </div>
 


    
  </div>

</section>

  }
}


