import React, { Component } from "react";
import withFirebaseAuth from "react-auth-firebase";
import firebase from "./firebaseConfig";
import Card from "@material-ui/core/Card";
import "../../Styles/Login.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import NavLink from "react-router-dom/NavLink";
import axios from "axios";
import { loadProgressBar } from "axios-progress-bar";
import { login_api, startup_call } from "../common/AppConstants";
import cogoToast from "cogo-toast";
import ReactGA from "react-ga";
// import ForgottenPassword from "./ForgottenPassword";
import { Dialog, CircularProgress, createMuiTheme } from "@material-ui/core";
import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";


const theme = createMuiTheme({
  typography: {
    // Use the system font.
    fontFamily:
      // '-apple-system,system-ui,BlinkMacSystemFont,' 
      // +
      'Montserrat',
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);

    this.state = {
      email: "",
      password: "",
      isSignedIn: false,
      signInWithEmail: this.props.signInWithEmail,
      signUpWithEmail: this.props.signUpWithEmail,
      signInWithGoogle: this.props.signInWithGoogle,
      signInWithFacebook: this.props.signInWithFacebook,
      googleAccessToken: this.props.googleAccessToken,
      facebookAccessToken: this.props.facebookAccessToken,
      user: this.props.user,
      error: this.props.user,
      signOut: this.props.signOut,
      new_user: false,
      dialogOpen: false,
      loading : false
    };
    ReactGA.initialize("UA-117934873-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    // //console.log(this.props)
  }



  componentDidMount = () => {
    
    window.scrollTo(0, 0);
    

   
      this.setState({
        loading : true
      })

    
    firebase.auth().onAuthStateChanged((user) => {
  
    
      this.setState({ isSignedIn: !!user, user: user });

      // if(user){
      //   if(user.emailVerified === false){
      //   firebase.auth().currentUser.sendEmailVerification()
      //   cogoToast.success('Verification Code has been sent to Your E-Mail')
      // }else{
      // }
    // }


      

      if (this.state.isSignedIn) {

        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.ra,
        };
     
       


        // Google
        if (user.providerData[0]["providerId"] === "google.com") {
          
          loadProgressBar();
 
        
          axios
            .post(login_api, {
              displayName: user.displayName,
              email: user.email,
              id: user.uid,
              photoUrl: user.photoURL,
              googleProfile: {
                id: user.providerData[0]["uid"],
              },
            })
            .then((res) => {
             
              this.startUpApi(headers);
              this.interceptor(user.ra);

              localStorage.setItem("current_user", JSON.stringify(res.data));
              localStorage.setItem("user", this.state.user.ra);
              localStorage.setItem("user_ver", this.state.user.emailVerified);
              localStorage.setItem("name", res.data.displayName);
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("image", res.data.photoUrl);

              if (
                JSON.parse(localStorage.getItem("current_user")).contactNo ===
                ""
              ) {
                this.props.history.replace("/edit-profile");
              } 
              // else if (
              //   !JSON.parse(localStorage.getItem("current_user")).contactNo
              // ) {
              //   this.props.history.replace("/edit-profile");
              // }
               else {
                window.location.assign(localStorage.getItem('loc'))
              }
              
          this.setState({
            loading : false
          })
            });
        } else if (user.providerData[0]["providerId"] === "facebook.com") {
          loadProgressBar();
          axios
            .post(login_api, {
              displayName: user.name,
              email: user.email,
              id: user.uid,
              photoUrl: user.photoURL,
              facebookProfile: {
                id: user.providerData[0]["uid"],
              },
            })
            .then((res) => {
              this.startUpApi(headers);
              this.interceptor(user.ra);

              localStorage.setItem("current_user", JSON.stringify(res.data));
              localStorage.setItem("user", this.state.user.ra);

              localStorage.setItem("user_ver", this.state.user.emailVerified);
              localStorage.setItem("name", res.data.displayName);
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("image", res.data.photoUrl);

              if (
                JSON.parse(localStorage.getItem("current_user")).contactNo ===
                ""
              ) {
                this.props.history.replace("/edit-profile");
              }
              //  else if (
              //   !JSON.parse(localStorage.getItem("current_user")).contactNo
              // ) {
              //   this.props.history.replace("/edit-profile");
              // } 
              else {
                window.location.assign(localStorage.getItem('loc'))
              }
              this.setState({
                loading : false
              })
              // }
            });
        } else if (user.providerData[0]["providerId"] === "password") {
          loadProgressBar();
          axios
            .post(login_api, {
              displayName: user.displayName,
              email: user.email,
              id: user.uid,
              photoUrl: user.photoURL,
            })
            .then((res) => {
              this.startUpApi(headers);
              this.interceptor(user.ra);

             

              localStorage.setItem("current_user", JSON.stringify(res.data));

              localStorage.setItem("user", this.state.user.ra);
              // localStorage.setItem(
              //   "name",
              //   this.state.email.slice(0, this.state.email.indexOf("@"))
              // );
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("user_ver", this.state.user.emailVerified);

              localStorage.setItem("image", res.data.photoUrl);

              if (
                JSON.parse(localStorage.getItem("current_user")).contactNo ===
                ""
              ) {
                this.props.history.replace("/edit-profile");
              } 
              // else if (
              //   !JSON.parse(localStorage.getItem("current_user")).contactNo
              // ) {
              //   this.props.history.replace("/edit-profile");
              // }
               else {
                window.location.assign(localStorage.getItem('loc'))
              }
            });
        }
      }else{
        this.setState({
          loading : false
        })
      }
    });
  // }
   

  };

  PasswordLink = () => {
    firebase.auth().sendPasswordResetEmail(this.state.email)
    cogoToast.success('Reset link sent to Email id')
    this.setState({
      dialogOpen:false
    })
  }

  ForgottenPassword = () => {
    // this.props.history.replace("/forgottenPassword");

    // firebase.auth().sendPasswordResetEmail('ankitintchar@gmail.com')

    this.setState({
      dialogOpen: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  startUpApi = (headers) => {
    axios
      .get(startup_call, {
        headers: headers,
      })
      .then((response) => {
        localStorage.setItem(
          "likedPostIds",
          JSON.stringify(response.data.likedPostIds)
        );
        localStorage.setItem(
          "myPostIds",
          JSON.stringify(response.data.myPostIds)
        );
        localStorage.setItem(
          "followingIds",
          JSON.stringify(response.data.followingIds)
        );
        localStorage.setItem(
          "followersIds",
          JSON.stringify(response.data.followersIds)
        );
        localStorage.setItem(
          "bookmarkedPostIds",
          JSON.stringify(response.data.bookmarkedPostIds)
        );
        localStorage.setItem(
          "reportedPostIds",
          JSON.stringify(response.data.reportedPostIds)
        );
      })
      .catch((error) => {
        // //console.log(error);
      });
  };

  interceptor = (acT) => {
    axios.interceptors.request.use(
      (config) => {
        const token = "Bearer " + acT;
        config.headers.Authorization = token;

        return config;
      },
      (error) => {
        // handle the error
        return Promise.reject(error);
      }
    );
  };

  // Sign In / Sign Up
  sign_up = (e) => {
    e.preventDefault();

    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email.trim(), this.state.password)
      .then((res) => {
        this.setState({
          new_user: false,
        });

        
        // localStorage.setItem("current_user", JSON.stringify(res.data));

        // localStorage.setItem("user", this.state.user.ra);
        // localStorage.setItem(
        //   "name",
        //   this.state.email.slice(0, this.state.email.indexOf("@"))
        // );
        // localStorage.setItem("id", res.data.id);
        // localStorage.setItem("user_ver", this.state.user.emailVerified);

        // localStorage.setItem("image", res.data.photoUrl);
      })
      .catch((err) => {
        // //console.log(err);
        if (err.code === "auth/user-not-found") {
          this.newUser();
          // cogoToast.error('User Not Found , Try different Login Method or Check username/password')
        } else {
          cogoToast.error(err.message);
        }
      });
  };

  // new User
  newUser = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        this.state.email.trim(),
        this.state.password
      )
      .then((response) => {
        // signUp user
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + response.user.ra,
        };

        loadProgressBar();

        axios
          .post(login_api, {
            displayName: this.state.email.slice(
              0,
              this.state.email.indexOf("@")
            ),
            email: response.user.email,
            id: response.user.uid,
            photoUrl:
              "https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf",
          })
          .then((res) => {
            localStorage.setItem("current_user", JSON.stringify(res.data));

            localStorage.setItem("user", response.user.ra);
            localStorage.setItem(
              "name",
              this.state.email.slice(0, this.state.email.indexOf("@"))
            );
            localStorage.setItem("id", res.data.id);
            localStorage.setItem("image", res.data.photoUrl);
            this.startUpApi(headers);
            this.interceptor(response.user.ra);
          });

        this.setState({
          new_user: true,
        });
        // window.location.assign("/edit-profile");

      })
      .catch((error) => {
        cogoToast.error(error.message);
      });
  };

  // On value change
  change = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      open: false,
    });
  };

    //   clickOnInsta=()=>{
    //   axios.get(`https://api.instagram.com/oauth/authorize
    //   ?client_id=554899731880284
    //   &redirect_uri=https:/wallmag.io/
    //   &scope=user_profile,user_media
    //   &response_type=code`).then(res=>{
    //     console.log(res)
    //   }).catch(err=>{
    //     console.log(err)
    //   })
    // }

  render() {
    // //console.log(this.state.isSignedIn);

    if (this.state.user) {
      localStorage.setItem("image", this.state.user.photoURL);

      // When signUp --> Redirects to User Profile_Edit_Page

      if (this.state.new_user === true) {
        // if (localStorage.redirect) {
        //   var red_ = localStorage.redirect;
        //   localStorage.removeItem("redirect");
        //   this.props.history.push(red_);
        // }
        // this.props.history.replace("/edit-profile");

        // return  <Redirect to='/edit-profile' />;
      } else {
        
        // return  <Redirect to='/' />;
      }
    } else {
      //  // //console.log(this.state.user,'false')
    }
    return (
      <>
        {window.innerWidth > 576 ? (
               
                this.state.loading === true ? 
                <div style={{textAlign:'center'}} className="wrapper">
                  <div style={{position:'absolute', top:'50%',left:'50%'}} className="button">
                    <CircularProgress />
                  </div>
            </div>
                :
                               
          <div
            className="container-fluid"
            //  style={{background:'white'}}
            // style={{backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
            // transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
            // , url(${require('../../logo/wa.jpeg')})`,
            style={{
              backgroundImage: `linear-gradient( to top , rgba(0,0,0,.5),
                                               transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                                               , url(${require("../../logo/login3.jpg")})`,
              // height : window.outerHeight,
              width: window.innerWidth,
              paddingBottom: "200px",
            }}
          >
            <div style={{ height: "50px" }}></div>
            {/* login  */}

            <div className="container login_div">
              <Card>
                <div>
             
                  {/* <button onClick={this.clickOnInsta} > Insta </button> */}
                  <img
                    alt="wallmag_logo"
                    height="50px"
                    width="50px"
                    src={require("../../logo/wallmag_black.png")}
                    style={{ marginTop: "50px" }}
                  />

                  {!localStorage.getItem("redirect") ? (
                    <p className="login">Continue </p>
                  ) : (
                    <p className="login">You need to login first </p>
                  )}

                  <p className="to-get-access-to-WallMag">
                    to get access to WallMag
                  </p>

                  {/*------Facebook----------*/}
                  <div className="container">
                    <div className="row fb_button_row">
                      <div>
                        <div
                          className="row fb_div"
                          onClick={this.state.signInWithFacebook}
                        >
                          <p className="fb_text">WITH FACEBOOK</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*------Google----------*/}
                  <div className="container">
                    <div className="row g_button_row">
                      <div>
                        <div
                          className="row g_div"
                          onClick={this.state.signInWithGoogle}
                        >
                          <p className="g_text">WITH GOOGLE</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* --------Separator------------ */}

                  <div className="container sep">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                        <hr></hr>
                      </div>
                      <p className="or">OR</p>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                        <hr></hr>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                    </div>
                  </div>

                  {/* ---------E-mail-------------- */}

                <ThemeProvider theme={theme}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="email"
                    type="Name"
                    placeholder="E-mail"
                    onChange={(e) => this.change(e)}
                  />
                </ThemeProvider>  
                  <br></br>

                  {/* ---------Password-------------- */}

                  <ThemeProvider theme={theme}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      id="password"
                      type="password"
                      placeholder="Password"
                      onChange={(e) => this.change(e)}
                    />
                  </ThemeProvider>
                  {/* --------Login------------ */}
                  <br></br>
                  <Button
                    id="log_button"
                    variant="contained"
                    onClick={(e) => this.sign_up(e)}
                  >
                    Login / Signup
                  </Button>

                  <br></br>
                  <br></br>
                  <p
                    className="login"
                    style={{ fontSize: "14px" , cursor:'pointer'}}
                    onClick={this.ForgottenPassword}
                  >
                    Forget Password?
                  </p>

                  <br></br>

                  {/*----- We will never send you junk mail----------------  */}
                  <div className="container">
                    <div className="row">
                      <div className="row  col-md-2 col-lg-3 col-xl-2"></div>

                      <div className="row col-md-7 col-lg-7 col-xl-10 asdsadsadsad">
                        <img
                          src={require("../../logo/junk.svg")}
                          alt="info"
                          style={{ marginTop: "8px", marginRight: "5px" }}
                        />
                        <p className="We-will-never-send-you-junk-mail">
                          {" "}
                          We will never send you junk mail
                        </p>
                      </div>
                      <div className="row  col-md-1 col-lg-1 col-xl-2"></div>
                    </div>
                  </div>

                  {/* --------------------By signing up you agree--------------- */}
                  <br></br>
                  <p className="by-signing-up-you-agree-our">
                    By signing up you agree our
                  </p>

                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/terms-of-use"
                  >
                    <p className="terms-and-condition"> Terms and Conditions</p>
                  </NavLink>
                </div>
              </Card>
            </div>

            {/* Login */}
            {/* <div style={{height:'150px'}}></div> */}
          </div>
        ) : (
          // mob devices
   
          this.state.loading === true ? 
                <div style={{textAlign:'center'}} className="wrapper">
                  <div style={{position:'absolute', top:'50%',left:'45%'}} className="button">
                    <CircularProgress />
                  </div>
            </div>
                :
          <div
            style={{
              backgroundImage: `linear-gradient( to top , rgba(0,0,0,.5),
           transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
           , url(${require("../../logo/login3.jpg")})`,
              // height : window.innerHeight,
              width: window.innerWidth,
            }}
          >
            <div style={{ height: "20px" }}></div>

            <Card style={{ margin: "20px" }}>
              <img
                height="30px"
                width="30px"
                src={require("../../logo/wallmag_black.png")}
                alt="WallMag_logo"
                style={{ marginTop: "30px" }}
              />
              <p className="login">Continue </p>
              <p className="to-get-access-to-WallMag">
                to get access to WallMag
              </p>

              {/*------Facebook----------*/}
              <div className="container">
                <div className="row fb_button_row">
                  <div>
                    <div
                      className="row fb_div"
                      onClick={this.state.signInWithFacebook}
                    >
                      <p className="fb_text">WITH FACEBOOK</p>
                    </div>
                  </div>
                </div>
              </div>

              {/*------Google----------*/}
              <div className="container">
                <div className="row g_button_row">
                  <div>
                    <div
                      className="row g_div"
                      onClick={this.state.signInWithGoogle}
                    >
                      <p className="g_text">WITH GOOGLE</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* --------Separator------------ */}

              <div className="container sep">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                    <hr></hr>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2">
                    <p className="or">OR</p>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-3">
                    <hr></hr>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2"></div>
                </div>
              </div>

              {/* ---------E-mail-------------- */}

              <ThemeProvider theme={theme}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="email"
                  type="Name"
                  placeholder="E-mail"
                  onChange={(e) => this.change(e)}
                />
              </ThemeProvider>

              <br></br>
              {/* ---------Password-------------- */}

              <ThemeProvider theme={theme}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="password"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => this.change(e)}
                />
              </ThemeProvider>

              <br></br>
              {/* --------Login------------ */}
              <Button
                id="log_button"
                variant="contained"
                onClick={(e) => this.sign_up(e)}
              >
                Login / Signup
              </Button>
              <br></br>
                  <br></br>
                  <p
                    className="login"
                    style={{ fontSize: "14px" , cursor:'pointer'}}
                    onClick={this.ForgottenPassword}
                  >
                    Forget Password?
                  </p>


              <br></br>

              {/*----- We will never send you junk mail----------------  */}

              <div className="container-fluid">
                <p className="We-will-never-send-you-junk-mail">
                  We will never send you junk mail
                </p>
              </div>

              {/* --------------------By signing up you agree--------------- */}
              <br></br>
              <p className="by-signing-up-you-agree-our">
                By signing up you agree our
              </p>

              <NavLink style={{ textDecoration: "none" }} to="/terms-of-use">
                <p className="terms-and-condition"> Terms and Conditions</p>
              </NavLink>
            </Card>
            <div style={{ height: "150px" }}></div>
          </div>
        )}

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleCloseDialog}
          fullWidth
          disableBackdropClick
        >
          <div className="container">
            <div
              className="row justify-content-center"
              style={{ position: "relative" }}
            >
              {
                window.innerWidth > 576 ? 
              <>
              <img
                src={require("../../logo/cancel.svg")}
                onClick={this.handleCloseDialog}
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "20px",
                  cursor: "pointer",
                  height : '30px',
                  width : '30px'
                }}
                alt="cancel"
              />
              <p className="login">Reset Password</p>

              <div
                className="row justify-content-center"
                style={{ width: "100%" }}
              >

                <ThemeProvider theme={theme}>
                  <TextField
                    variant="outlined"
                    // margin="dense"
                    // style={{ width: "400px" }}
                    id="email"
                    type="Name"
                    placeholder="E-mail"
                    onChange={(e) => this.change(e)}
                  />
                </ThemeProvider>

              </div>
              <br></br>
              <br></br>
              <br></br>

              <div>
                <Button
                  id="log_button"
                  variant="contained"
                  onClick={(e) => this.PasswordLink()}
                  style={{marginBottom:'20px'}}
                  >
                  Reset
                </Button>
              </div>
              <br></br>
              <br></br>
              <br></br>
</>
                  :


                  <>
                  <img
                    src={require("../../logo/cancel.svg")}
                    onClick={this.handleCloseDialog}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      cursor: "pointer",
                      height : '30px',
                      width : '30px'
                    }}
                alt="cancel"
              />
              <p className="login">Reset Password</p>

              <div
                className="row justify-content-center"
                // style={{ width: "100%" }}
              >

                <ThemeProvider theme={theme}>
                  <TextField
                    variant="outlined"
                    // margin="dense"
                    style={{ width: "200px" }}
                    id="email"
                    type="Name"
                    placeholder="E-mail"
                    onChange={(e) => this.change(e)}
                  />
                </ThemeProvider>

              </div>
              <br></br>
              <br></br>
              <br></br>

              <div>
                <Button
                  id="log_button"
                  variant="contained"
                  onClick={(e) => this.PasswordLink()}
                  style={{marginBottom:'20px', height:'50px'}}
                >
                  Reset
                </Button>
              </div>
              <br></br>
              <br></br>
              <br></br>
</>

  }
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

const authConfig = {
  email: {
    verifyOnSignup: false,
    saveUserInDatabase: true,
  },
  google: {
    // scopes: ["admin.datatransfer", "contacts.readonly"], // optional
    // customParams: {
    //   login_hint: "user@example.com"
    // },
     redirect: true,
    returnAccessToken: true,
    // scopes: [], // array
    saveUserInDatabase: true,
  },
  facebook: {
    // scopes: ["admin.datatransfer", "contacts.readonly"], // optional
    // customParams: {
    //   login_hint: "user@example.com"
    // },
    redirect: true, // default is popup: true, redirect: true,
    returnAccessToken: true,
    saveUserInDatabase: true,
  },
  github: {
    // redirect: true,
    returnAccessToken: true,
    saveUserInDatabase: true,
  },

  twitter: {
    // redirect: true,
    returnAccessToken: true,
    returnSecret: true,
    saveUserInDatabase: true,
  },
};

export default withFirebaseAuth(Login, firebase, authConfig);
