/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Arrows from "./Arrows";
import "./../../../Styles/Styles.css";
import {home_top} from "./../../../Store/action/HomeAction";
import compose from "compose";
import {connect} from "react-redux";
import {story_category } from "../../../Store/action/StoryAction";
import { Chips } from "../../chips/Chips"
import NavLink from 'react-router-dom/NavLink'


const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: " 100%",
    height: "100%",
    overflow: "hidden",
    // height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  icon: {
    color: "white"
  },
  icon2: {
    color: "rgba(255, 255, 255, 0.54)"
  }
});

class HomeTop extends React.Component {
  state = {
    data: {},
    load: false,
    currentslide: 1,
    lastslide: 3,
    initialprev: 0,
    index: 1,
    displayRightArrow: true,
    displayLeftArrow: false,
    width: window.innerWidth
  };

  componentDidMount() {
    if(this.props.home_top_load ){
    }else{
      this.props.home_top()
    }
    if(this.props.load ){
      
    }else{
      this.props.story_category();  
    }
  
   
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
 
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  clickHandler = direction => {
    if (direction === "left") {
      this.slider.slickPrev();
    } else if (direction === "right") {
      this.slider.slickNext();
    }
  };
  setArrowDisplay = currentSlide => {
    const cityList = this.props.home_top_data.items;
    const displayLeftArrow = currentSlide !== 0;
    const displayRightArrow = currentSlide !== cityList - this.slidesToShow;
    this.setState({
      displayRightArrow: displayRightArrow,
      displayLeftArrow: displayLeftArrow
    });
  };
  render() {

    



    // const sameid = [];
    this.state.width > 1400
      ? (this.slidesToShow = 4)
      : this.state.width < 1400 && this.state.width > 1130
      ? (this.slidesToShow = 4)
      : this.state.width < 1130 && this.state.width > 800
      ? (this.slidesToShow = 3)
      : this.state.width < 800 && this.state.width > 500
      ? (this.slidesToShow = 2)
      : this.state.width < 500
      ? (this.slidesToShow = 1)
      : "";
    var settings = {
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: this.slidesToShow,
      afterChange: currentSlide => this.setArrowDisplay(currentSlide),
      arrows: false,
      //nextArrow: null ,//this.state.index < 4 &&  window.innerWidth > 1024 ? <SampleNextArrow classs="disabledprevnext"/> :  this.state.currentslide  === this.state.index ?   <SampleNextArrow classs="disabledprevnext"/> : <SampleNextArrow classs="gallery gallery3"/>,
      // prevArrow:  null ,//this.state.initialprev === 0 ?  <SamplePrevArrow classs="disabledprevnext"/> :  <SamplePrevArrow classs="gallery gallery3"/>,
      // afterChange: currentSlide => this.setArrowDisplay(currentSlide),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: this.slidesToShow,
            arrows: false,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: this.slidesToShow,
            slidesToScroll: this.slidesToShow
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: this.slidesToShow,
            slidesToScroll: this.slidesToShow
          }
        }
      ]
    };
    return (
      //  <Grid container>
      <div style={{ position: "relative" ,marginTop:'61px'}}>
        {this.slidesToShow !== 4
          ? this.props.home_top_data &&
          this.props.home_top_data &&
            this.state.displayLeftArrow && (
              <Arrows
                styleClassName={`${
                  this.props.data.data &&
                  this.state.displayLeftArrow > this.slidesToShow
                    ? this.state.displayRightArrow
                      ? "gallery gallery3"
                      : "disabledprevnext"
                    : "disabledprevnext"
                }`}
                direction="left"
                clickHandler={this.clickHandler}
                left={true}
              />
            )
          : ""}
        {this.slidesToShow !== 4 && this.state.displayRightArrow ? (
          <Arrows
            styleClassName={` ${
              this.state.displayLeftArrow
                ? "gallery gallery3"
                : "disabledprevnext"
            }`}
            direction="right"
            right={true}
            clickHandler={this.clickHandler}
          />
        ) : (
          ""
        )}


            <div className='container'>
              {/* <div className='row justify-content-center'> */}
                {/* <p id='featuredstories'>Featured Photo Stories</p> */}
              {/* </div> */}
            </div>
           
            <div className='container home_top_div'>
              <div className='row justify-content-center'>
                <p id='featuredstories'>Featured Photo Stories</p>
              </div>
            </div>
            
            <hr className='hr'/> 
        
        {this.props.home_top_load 
          ? this.props.home_top_data && (
              <Slider
                {...settings}
                ref={c => (this.slider = c)}
                style={{ overflow: "hidden" }}
              >
                
                
                {this.props.home_top_data.items.map(val => {
                  return (
                    <div className='container top_div'>
                      <div className='row justify-content-center'>
                      
                      <div className="imagess">

                      <div className='chips_top'>  
                        {this.props.load &&
                          this.props.story_category_data.items.map(vale =>
                            val.linkedEntityIds.map((valle, i) =>
                              vale.id === valle ? (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 14,
                                    marginLeft:'14px',
                                  }}
                                >
                                  <Chips  title={vale.name} color={vale.color}/>{" "} 
                                  {/* <BlogTitle title={vale.name} />{" "} */}
                                </div>
                              ) : (
                                ""
                              )
                            )
                          )}
                      </div>

                    <NavLink to={`story/${val.seoUrl}`}>
                        <div
                          style={{
                            backgroundImage: `linear-gradient( to top , rgba(0,0,0,1),rgba(0,0,0,0), transparent), url(${
                              val.fileProperty.publicUrl
                            })`,
                            backgroundSize: "cover"
                          }}
                          className="topimages"
                        >
                          <p className="toptag">
                            <span> </span>{" "}
                          </p>
                          <p className="h2s">
                            <span>{/*<BlogTitle title={val.title}/>*/}</span>
                          </p>

                          <div className="forbackground" />
                        </div>
                    </NavLink>

                        <div className="toppost_bottoms_text">
                          <p className="post_topic">{val.title}</p>
                        </div>
                            
                      </div>
                     </div>
                    </div>
                  );
                })}
              </Slider>
            )
          : ""}

        {/* //  </Grid> */}
      </div>
    );
  }
}
HomeTop.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    home_top_data: state.home.home_top_data,
    home_top_load: state.home.home_top_load,
    story_category_data: state.story_cat.story_category,
    load : state.story_cat.load
    // data: state.product.product,
    // load: state.product.Load
  };
};
export default compose(withStyles(styles) , connect(mapStateToProps,{home_top, story_category}))(HomeTop);
