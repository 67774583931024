import React from 'react'
import Header from '../Header/Header';
import MobHeader from '../Header/Mob_Header'
import FooterWallmag from '../Footer/Footer';
import Axios from 'axios';
import { getWallPrivacy } from '../common/AppConstants';
import firebase from "../Login/firebaseConfig";
import ReactHtmlParser from "react-html-parser";
import { CircularProgress } from '@material-ui/core';

var data =[]
// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-117934873-2');
// ReactGA.pageview(window.location.pathname + window.location.search);

export default class Privacy extends React.Component{


  componentDidMount(){
      this.getPrivacy()
  }

  
  tokenUpdate=()=>{
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          localStorage.setItem("user", user.ra);
        }
      });
    }
  
  // T&C

  getPrivacy = () => {
      this.tokenUpdate()
      
      Axios.get(getWallPrivacy)
      .then(res=>{
          data = res.data
          this.forceUpdate()
      })
      .catch(error=>{

      })
  }
  
  render(){
  return(
      <div style={{ background:'white'}}>
      {window.scrollTo(0,0)}

          <div className='mob_top_about'>
            <MobHeader url=''  heading='Privacy of Use' sub_heading='Welcome to WallMag! We want to empower your creativity.'/>
          </div>
          
          <div className='web_top_about'>
          <Header url='../../logo/topimg.jpg'   heading='Privacy of Use' sub_heading='Welcome to WallMag! We want to empower your creativity.'/>
          </div>

     
          {
          data.html?
          <>
          <div className='container-fluid' style={{padding:'60px', overflow:'hidden'}}>
            <div className='container'>
              <p style={{textAlign:'left'}}>
                { ReactHtmlParser(data.html)}
              </p>
            </div>
          </div>


      <div id='footer_content'>
          <FooterWallmag />
      </div>
      </>
      
      :
      
      <div  style={{display:'flex', marginTop:'60px'}}>
          <CircularProgress color='secondary' style={{margin:'auto'}}/>
      </div>
    }
      </div>
  )
  }
}
