import React from 'react'
import './Signup.css'
import { TextField } from '@material-ui/core';
import Header from '../../../IssuePages/Header/Header'
import MobHeader from '../../../IssuePages/Header/Mob_Header'

class Signup extends React.Component {

    state ={
        email : '',
        name : '',
        contact : ''
    }
    

                // On value change 
                change = (e)=>{
                    this.setState({
                      [e.target.id] : e.target.value,
                      open : false
                    })
                  }
    

    render(){
        return(
            <div  style={{backgroundColor:'white'}}>
                      {window.innerWidth < 768 ? 
                        
                        <MobHeader
                            url=''
                            heading='Wedding Photography Awards 2020'
                            sub_heading='Searching for the best in you'
                        />
                :
                            <Header
                                url=''
                                heading='Wedding Photography Awards 2020'
                                sub_heading='Searching for the best in you'
                                />
            }        
           <div className='container-fluid'  style={{backgroundColor:'white'}}> 
            {/* <div className='row'>
                <img
                    className='wall-logo-black'
                    src={require('../../../../logo/wallmag_black.png')} 
                    alt='Wall-Logo'
                    />
            </div>         */}

               
                <div className='row' style={{marginTop:'66px'}}>
                    <div className='signup-block'>
                        <p className='Sign-Up'>
                            Sign Up
                        </p>

                        <p className='Apply-to-WallMag'>
                        Apply to WallMag Photography Awards 2020 <br></br>by Submitting your application
                        </p>

                        <p className='Line-item-selected'>
                            LAST FEW DAYS TO GO
                        </p>

                        <div className='Rectangle-1758'>
                            
                        
                        <TextField
                            className='tfs'
                            variant="outlined"
                            margin="dense"
                            id="name"
                            type="Name"
                            placeholder="Full Name"
                            style={{marginTop:'38px', color:'red'}}
                            value={this.state.name}
                            onChange={e => this.change(e)}
                        />
                        <br></br>

                        <TextField
                            className='tfs'
                            variant="outlined"
                            margin="dense"
                            id="email"
                            type="Name"
                            placeholder="Email Address"
                            style={{marginTop:'38px'}}
                            value={this.state.email}
                            onChange={e => this.change(e)}
                        />

                        <br></br>

                        <TextField
                            className='tfs'
                            variant="outlined"
                            margin="dense"
                            id="contact"
                            type="Name"
                            placeholder="Mobile No."
                            style={{marginTop:'38px'}}
                            value={this.state.contact}
                            onChange={e => this.change(e)}
                        />

                        <br></br>
                        
                        <div className='Rectangle-806'>
                            <p className='submit'>
                                Submit
                            </p>
                        </div>

                        <p className='Line-item-selected' style={{marginTop:'23px'}}>
                            LAST FEW DAYS TO GO
                        </p>

                        </div>
                   
                   
                        <img
                         src={require('../../../../logo/play-button.svg')}
                         alt="Wall-Logo"
                         style={{
                             height : '86px',
                             width : '86px',
                             marginTop:'40px'
                         }}
                         />

                        <p className='mt-2'>Watch Trailer</p> 
                        <p className='mt-2  National-Level-Regonition'>🏆 National Level Regonition</p> 
                        <p className='mt-2  National-Level-Regonition'>🏆 Applicable for Indian Photographers Only</p> 

                        <p className='mt-4 Line-item-selected'>Already a User? Sign In Now</p> 

                    </div>
                </div>

            

            </div>
</div>
        )
    }
};

export default Signup;