import React from "react";
import "../../../Styles/PostViewerDial.css";
import {FacebookIcon, TwitterIcon, WhatsappIcon, LinkedinIcon} from "react-share";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton,  WhatsappShareButton} from "react-share";
import axios from "axios";
import {add_comment, get_user_by_slug, follow_user, is_like, get_post_by_slug, get_comments, get_post_likes, view_post, delete_post,
        report_post, get_jury_votes, get_public_votes} from "../../common/AppConstants";
import { Dialog, DialogTitle, AppBar, CircularProgress, DialogContent, DialogActions, Button, createMuiTheme} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import TimeAgo from "react-timeago";
import frenchStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import cogoToast from "cogo-toast";
import { loadProgressBar } from "axios-progress-bar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { ThemeProvider } from "@material-ui/styles";
import firebase from "../../Login/firebaseConfig";
import ReactGA from "react-ga";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { TrafficRounded } from "@material-ui/icons";


const formatter = buildFormatter(frenchStrings);

var followingIds = new Set();
var ids = new Set();
var reported = new Set();
var user_comments = [];
var likes = [];
var data = [];

var likeCount = 0
const theme = createMuiTheme({
  typography: {
    fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,Montserrat"
  }
});

export default class PostViewerDial extends React.Component {
  constructor(props) {
    super(props);

    followingIds = new Set(JSON.parse(localStorage.getItem("followingIds")));
    ids = new Set(JSON.parse(localStorage.getItem("likedPostIds")));
    reported = new Set(JSON.parse(localStorage.getItem("reportedPostIds")));

    this.state = {
      desc: "",
      otherUserData: "",
      slug: `${
        this.props.match ? this.props.match.params.postId : this.props.url
      }`,
      dialog: false,
      dialog_mob: false,
      post_data: "",
      dialogPost: false,
      progress: false,
      anchor: null,
      openDelete: false,
      postData: this.props.postData,
      position: this.props.position,
      comments:true,
      verify_user_dialog : false,
      vote_score_dialog : false,
      vote_score_dialog_mob : false,
      share_this_image : false
    };

    likeCount = this.props.postData[this.props.position].likeCount
    
    ReactGA.initialize("UA-117934873-2", {
      gaOptions: {
        userId: JSON.parse(localStorage.getItem("current_user"))
          ? JSON.parse(localStorage.getItem("current_user")).displayName +
            "_" +
            JSON.parse(localStorage.getItem("current_user")).id
          : "Guest_User"
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  // arrow key func -- 1st
  keyPressed = e => {

    if(e.keyCode===37){
      this.imageSliderLeft(e)
    }else if(e.keyCode === 39){
      this.imageSliderRight(e)
    }
  };


     // click on facebook
     clickOnFB = () => {
      ReactGA.event({
          category: 'Share after uploading image',
          action:'Share on Social Sites',
      })
  }

    // click on copy to clipboard
    clickOnCopyToClip = () => {
      ReactGA.event({
          category: 'Share after uploading image',
          action:'Copy from clipboard',
      })
  }


  componentDidMount() {

    // arrow key func -- 2nd
    window.onkeydown = this.keyPressed;
    localStorage.setItem('loc',window.location.href)

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        localStorage.setItem("user", user.ra);
      }
    });

    // window.scroll(0,0)

    if (JSON.parse(localStorage.getItem("post_data"))) {
      if (
        JSON.parse(localStorage.getItem("post_data")).slug !== this.state.slug
      ) {
        this.getPostData();
      } else {
        this.getComments(localStorage.post_id);
        this.viewPost(localStorage.post_id);
      }
    } else {
      this.getPostData();
    }
  }

  componentWillUnmount() {
    user_comments = [];
  }



     // Mission Page
     goToMission=()=>{
      this.props.props.history.replace(`/missions/${this.state.postData[this.state.position].shortMissionObject.slug}`)
  }

  // Login First
  loginFirst = e => {
    localStorage.setItem('loc',window.location.href)

    ////console.log(this.props);
    this.props.props.history.replace("/login");
  };

  // Right Click on an Image
  clickRight = e => {
    e.preventDefault();
  };

  // Drag a photo
  onDragPhoto = e => {
    e.preventDefault();
  };

  // Report Post
  reportPost = e => {
    e.preventDefault();

    axios
      .put(report_post, {
        isReport: true,
        postId: this.props.id
      })
      .then(res => {
        cogoToast.success("Your Have Reported this Post");
        this.setState({
          openDelete: false,
          anchor: false
        });
      })
      .catch(error => {
        this.setState({
          openDelete: false
        });
      });
  };

  // Edit Post
  editPost = () => {
    this.props.props.history.replace("/edit-post/" + this.props.url);
  };

  // Delete Post
  deletePost = e => {
    e.preventDefault();

    axios
      .delete(delete_post + this.props.id)
      .then(res => {
        window.history.back();

        cogoToast.success("Your Post Has been Removed");

        this.setState({
          openDelete: false
        });
        

        this.forceUpdate();
      })
      .catch(error => {});
  };

  // share Post
  clickOnShare = () => {
    ReactGA.event({
      category: "Share from profile",
      action: "Share on Social Sites"
    });
  };

  // Report Comment
  reportComment = (e, isReport, id) => {
    e.preventDefault();

    axios
      .put(report_post, {
        isReport: isReport,
        postId: id
      })
      .then(res => {
        if (isReport === true) {
          reported.add(this.state.comment_id);
          cogoToast.success("Your Have Reported this Comment");
        } else {
          reported.delete(this.state.comment_id);
          cogoToast.success("Your Have UnReported this Comment");
        }
        localStorage.setItem(
          "reportedPostIds",
          JSON.stringify(Array.from(reported))
        );
        this.forceUpdate();

        this.setState({
          openDelete: false
        });
      })
      .catch(error => {
        this.setState({
          openDelete: false
        });
      });
  };

  // Delete Comment
  deleteComment = e => {
    axios
      .delete(delete_post + this.state.comment_id)
      .then(res => {
        cogoToast.success("Your Comment Has been Removed");

        this.setState({
          openDelete: false
        });

        this.getComments(localStorage.post_id);
      })
      .catch(error => {});
  };

  // Menu
  handle_menu = event => {
    this.setState({
      anchor: event.currentTarget
    });
  };

  // Menu Close
  handleClose = e => {
    e.preventDefault();

    this.setState({
      anchor: null
    });
  };

  // view a post
  viewPost = id => {
    if (JSON.parse(localStorage.getItem("current_user"))) {
      // when user logged in

      axios.put(view_post + "?postId=" + id).then(res => {
      });
    } else {}
  };

  // get Post Data
  getPostData = () => {
    if (JSON.parse(localStorage.getItem("current_user"))) {
      // when user logged in

      axios
        .get(get_post_by_slug, {
          params: {
            slug: this.state.slug
          }
        })
        .then(res => {
          data = res.data;
          localStorage.setItem("data_url", JSON.stringify(res.data));
          this.getComments(res.data.id);
          this.viewPost(res.data.id);

          this.forceUpdate();
        })
        .catch(error => {});
    } else {
      // when user logged out
      var headers = {
        Authorization:
          "Bearer " + new Date().getTime() + "_GpyTJy7zgV5qaGaBH1VF"
      };

      axios
        .get(get_post_by_slug + "?slug=" + this.state.slug, {
          headers
        })
        .then(res => {
          data = res.data;
          localStorage.setItem("data_url", JSON.stringify(res.data));
          this.getComments(res.data.id);
          this.viewPost(res.data.id);
          this.forceUpdate();
        })
        .catch(error => {});
    }
  };

  // on Change value
  change = e => {
    this.setState({
      [e.target.name]: e.target.value,
      open: false
    });
  };

  //   Image Slider Left
  imageSliderLeft = e => {
    if (this.state.position === 0) {
    } else {
      window.history.replaceState(
        "", "", "/post/" + this.state.postData[this.state.position - 1].slug
      );

      likeCount = this.state.postData[this.state.position - 1].likeCount
      this.getComments(this.state.postData[this.state.position - 1].id);
      this.setState({
        position: this.state.position - 1
      });
      this.forceUpdate();
    }
  };

  //   Image Slider Right
  imageSliderRight = e => {
    if (this.state.position === this.state.postData.length - 1) {
    } else {
      window.history.replaceState(
        "", "", "/post/" + this.state.postData[this.state.position + 1].slug
      );

      likeCount = this.state.postData[this.state.position + 1].likeCount
      this.getComments(this.state.postData[this.state.position + 1].id);
      this.setState({
        position: this.state.position + 1
      });
      this.forceUpdate();
    }
  };

  sendCode=()=>{
    firebase.auth().currentUser.sendEmailVerification()
    cogoToast.success('Verification Code ha been Send !!!')
  }

  // add new comment
  addNewComment = post_id => {
    if (JSON.parse(localStorage.getItem("current_user"))) {
      
      this.setState({
        progress: true
      });
      // when user logged in

      if (this.state.desc === "") {
        // when comment box is empty

        cogoToast.info("comment box is empty");
      } else {
        loadProgressBar();
        axios
          .post(add_comment + "?postId=" + post_id, {
            title: this.state.desc
          })
          .then(res => {
            this.setState({
              desc: "",
              progress: false
            });

            document.getElementById("textarea_desc").value = "";
            this.getComments(post_id);
          })
          .catch(error => {});
      }
   
    } else {
      // when useer logged out

      cogoToast.info("You need to login First");
    }
  };

  // get Comments
  getComments = id => {
    if (JSON.parse(localStorage.getItem("current_user"))) {
      // when user logged in
      axios
        .get(get_comments + "?parentPostId=" + id + "&limit=20")
        .then(res => {
          if (res.data.items) {
            user_comments = res.data.items;
            this.setState({
              cursor: res.data.nextPageToken,
              hasMore: true,
              comments:true
            });
          } else {
            if (user_comments.length === 1) {
              user_comments = [];
            }
            this.setState({
              hasMore: false,
              comments:false
            });
          }

          this.forceUpdate();
        })
        .catch(error => {});
    } else {
      // when user logged out

      var headers = {
        Authorization:
          "Bearer " + new Date().getTime() + "_GpyTJy7zgV5qaGaBH1VF"
      };
      axios
        .get(get_comments + "?parentPostId=" + id, {
          headers
        })
        .then(res => {
         
          if (res.data.items) {
            user_comments = res.data.items;
            
            this.setState({
              cursor: res.data.nextPageToken,
              hasMore: true,
              comments:true
            });
          } else {
            this.setState({
              hasMore: false,
              comments:false
            });
          }

          this.forceUpdate();
        })
        .catch(error => {});
    }
  };

  // load more comments
  loadMoreComments = () => {
    
    if (JSON.parse(localStorage.getItem("current_user"))) {
      // when user logged in
      axios
        .get(
          get_comments +
            "?parentPostId=" +
            this.state.postData[this.state.position].id +
            "&cursor=" +
            this.state.cursor +
            "&limit=10"
        )
        .then(res => {
          if (res.data.items) {
            user_comments = user_comments.concat(res.data.items);

            this.setState({
              cursor: res.data.nextPageToken,
              hasMore: true
            });
          } else {
            this.setState({
              hasMore: false
            });
            cogoToast.warn("No More Comments");
          }

          this.forceUpdate();
        })
        .catch(error => {});
    } else {
      // when user logged out

      var headers = {
        Authorization:
          "Bearer " + new Date().getTime() + "_GpyTJy7zgV5qaGaBH1VF"
      };
      axios
        .get(
          get_comments +
            "?parentPostId=" +
            this.state.postData[this.state.position].id +
            "&cursor=" +
            this.state.cursor +
            "&limit=10",
          {
            headers
          }
        )
        .then(res => {
          if (res.data.items) {
            user_comments = user_comments.concat(res.data.items);

            this.setState({
              cursor: res.data.nextPageToken,
              hasMore: true
            });
          } else {
            this.setState({
              hasMore: false
            });
            cogoToast.warn("No More Comments");
          }

          this.forceUpdate();
        })
        .catch(error => {});
    }
  };

  // like comments
  likeComment = (e, comment_id, like) => {
    e.preventDefault();

    if (JSON.parse(localStorage.getItem("current_user"))) {
      // when user logged in

      const params = {
        postId: comment_id,
        isLike: like
      };
      axios
        .put(is_like, params)
        .then(response => {
          if (like === true) {
            ids.add(comment_id);
          } else {
            ids.delete(comment_id);
          }

          localStorage.setItem("likedPostIds", JSON.stringify(Array.from(ids)));

          if (JSON.parse(localStorage.getItem("post_data"))) {
            if (
              JSON.parse(localStorage.getItem("post_data")).slug ===
              this.state.slug
            ) {
              this.getComments(localStorage.post_id);
            } else {
              this.getComments(data.id);
            }
          } else {
            this.getComments(data.id);
          }
        })
        .catch(error => {});
    } else {
      // when user logged out
      this.loginFirst(e);
    }
  };

  // vote
  vote =  (e, post_id, like) => {
    e.preventDefault();

    if (JSON.parse(localStorage.getItem("current_user"))) {
      // when user logged in

      firebase.auth().onAuthStateChanged((user)=>{

          if(user.emailVerified === false){

            var actionCodeSettings = {
              url: 'https://wallmag.io/',
            };
            firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
              .then(function() {
                // Verification email sent.
              })
              .catch(function(error) {
                // Error occurred. Inspect error.code.
              });
          
            this.setState({
              verify_user_dialog : true
            })

         }
         else{
            localStorage.setItem("user_ver", 'true');
    
          if (like === true) {
            ids.add(post_id);
          } else {
            ids.delete(post_id);
          }
    
          localStorage.setItem("likedPostIds", JSON.stringify(Array.from(ids)));
          this.forceUpdate();
    
          const params = {
            postId: post_id,
            isLike: like
          };
    
          axios
            .put(is_like, params)
            .then(response => {
              likeCount = response.data.likeCount
              this.getPostBySlug();
            })
            .catch(error => {
              if (like === true) {
                ids.delete(post_id);
              } else {
                ids.post(post_id);
              }
    
              localStorage.setItem("likedPostIds", JSON.stringify(Array.from(ids)));
              this.forceUpdate();
            });
         }
       })

     
    } else {
      // when user logged out
      this.loginFirst(e);
    }
  };

  //    like count
  getPostBySlug = () => {
    axios
      .get(get_post_by_slug, {
        params: {
          slug: this.state.slug
        }
      })
      .then(res => {
        if (JSON.parse(localStorage.getItem("post_data"))) {
          if (
            JSON.parse(localStorage.getItem("post_data")).slug ===
            this.state.slug
          ) {
            localStorage.setItem("post_data", JSON.stringify(res.data));
          } else {
            data = res.data;
          }
        } else {
          data = res.data;
        }

        this.forceUpdate();
      })
      .catch(error => {
        // ////console.log(error)
      });
  };

  // follow user
  followUser = (e, userId, isFollow) => {
    e.preventDefault();

    axios
      .put(follow_user, {
        isFollow: isFollow,
        userId: userId
      })
      .then(response => {
        if (isFollow === true) {
          followingIds.add(userId);
          this.forceUpdate()
        } else {
          followingIds.delete(userId);
          this.forceUpdate()
        }
        this.forceUpdate();
        localStorage.setItem(
          "followingIds",
          JSON.stringify(Array.from(followingIds))
        );

        axios
          .get(get_user_by_slug, {
            params: {
              slug: JSON.parse(localStorage.getItem("other_user")).slug
            }
          })
          .then(res => {
            localStorage.setItem("other_user", JSON.stringify(res.data));

            this.forceUpdate();
          })
          .catch(error => {});
      })
      .catch(error => {});
  };

  // open openLikeUsersList (dialog)
  openLikeUsersList = (e, post_id, dialog) => {
    e.preventDefault();

    if (JSON.parse(localStorage.getItem("current_user"))) {
      // when user logged in
      axios.put(get_post_likes + "?postId=" + post_id).then(res => {
        // ////console.log(res)
        likes = res.data.items;
        if(dialog === true){
          this.setState({
              dialog:true
          })
      }else{
          this.setState({
              dialog_mob:true
          })
      }
        this.getPostBySlug();
      });
    } else {
      // when user logged out

      this.loginFirst(e);
      // localStorage.setItem('redirect', '/post/'+this.state.slug)
      // window.location.assign('/login')
    }
  };

  // open LikeUsersList (dialog)
  publicVotes = (e, post_id, dialog)=>{

    e.preventDefault()

    if(JSON.parse(localStorage.getItem('current_user'))){

        // when user logged in
        axios.put(get_public_votes+'?postId='+post_id)
    .then(res=>{
        // // //console.log(res)
        likes = res.data.items

        if(dialog === true){
          this.setState({
              dialog:true
          })
      }else{
          this.setState({
              dialog_mob:true
          })
      }
        this.getPostBySlug()
    })

    }else{
        
    // when user logged out
    this.loginFirst(e);
    }
    
}

// open LikeUsersList (dialog)
juryVotes = (e, post_id, dialog)=>{

    e.preventDefault()

    if(JSON.parse(localStorage.getItem('current_user'))){

        // when user logged in
        axios.put(get_jury_votes+'?postId='+post_id)
    .then(res=>{
        // // //console.log(res)
        likes = res.data.items
        if(dialog === true){
          this.setState({
              dialog:true
          })
      }else{
          this.setState({
              dialog_mob:true
          })
      }
        this.getPostBySlug()
    })

    }else{
        
    // when user logged out

    this.loginFirst(e);
    }
    
}


          
          
  // Redirect to Profile page
  goToProfile = (from, userSlug) => {
    if(userSlug === undefined){
    }else{
        localStorage.setItem('other_user_slug',userSlug)
        window.open('/'+userSlug)
    }
      
   
  };

  // close Dialog
  closeDialog = () => {
    this.setState({
      dialog: false,
      dialog_mob: false,
      openDelete: false,
      verify_user_dialog : false,
      vote_score_dialog : false,
      vote_score_dialog_mob : false,
    });
  };

  render() {
  
    return (
      <>
        {//   When user logged out

          window.innerWidth < 768 ? (
            <div
              style={{ height: `${window.innerHeight}px`, background: "black" }}
            >
              <div className="container-fluid" style={{ background: "black" }}>
                <div className="row">

                  {/* user Image , Name , follow  */}
                  <div className="col-11 col-sm-11" style={{ textAlign: "start" }}>
                    <div className="row" style={{ marginTop: "40px", marginLeft: "2px" }}>
                      <img
                        src={
                          this.state.postData
                            ? this.state.postData[this.state.position]
                                .shortUserObject.profilePicture
                            : ""
                        }
                        alt='Profile' style={{height: "40px",width: "40px", borderRadius: "60px", objectFit: "cover" }}/>

                      <div>
                     

                        <div>
                          {this.state.postData ? (
                            this.state.postData[this.state.position]
                              .shortUserObject.badge === "0" ? (
                              <>
                                <p
                                  className="user-name2"
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this.goToProfile(
                                      1,
                                      this.state.postData[this.state.position]
                                        .shortUserObject.slug
                                    )
                                  }
                                >
                                  {
                                    this.state.postData[this.state.position]
                                      .shortUserObject.name
                                  }
                                </p>
                              </>
                            ) : this.state.postData[this.state.position]
                                .shortUserObject.badge === "1" ? (
                              <>
                                <div className="row">
                                  <p
                                    className="user-name"
                                    style={{ cursor: "pointer" }}
                                    onClick={e =>
                                      this.goToProfile(
                                        1,
                                        this.state.postData[this.state.position]
                                          .shortUserObject.slug
                                      )
                                    }
                                  >
                                    {
                                      this.state.postData[this.state.position]
                                        .shortUserObject.name
                                    }
                                  </p>
                                  <img
                                    src={require("../../../logo/1.png")}
                                    alt='Logo'
                                    style={{
                                      height: "28px",
                                      width: "20px",
                                      marginTop: "-5px",
                                      marginLeft: "5px"
                                    }}
                                  />
                                </div>
                              </>
                            ) : this.state.postData[this.state.position]
                                .shortUserObject.badge === "2" ? (
                              <>
                                <div className="row">
                                  <p
                                    className="user-name"
                                    style={{ cursor: "pointer" }}
                                    onClick={e =>
                                      this.goToProfile(
                                        1,
                                        this.state.postData[this.state.position]
                                          .shortUserObject.slug
                                      )
                                    }
                                  >
                                    {
                                      this.state.postData[this.state.position]
                                        .shortUserObject.name
                                    }
                                  </p>
                                  <img
                                    src={require("../../../logo/1.png")}
                                    alt='Logo'
                                    style={{
                                      height: "28px",
                                      width: "20px",
                                      marginTop: "-5px",
                                      marginLeft: "5px"
                                    }}
                                  />
                                </div>
                              </>
                            ) : this.state.postData[this.state.position]
                                .shortUserObject.badge === "3" ? (
                              <>
                                <div className="row">
                                  <p
                                    className="user-name"
                                    style={{ cursor: "pointer" }}
                                    onClick={e =>
                                      this.goToProfile(
                                        1,
                                        this.state.postData[this.state.position]
                                          .shortUserObject.slug
                                      )
                                    }
                                  >
                                    {
                                      this.state.postData[this.state.position]
                                        .shortUserObject.name
                                    }
                                  </p>
                                  <img
                                    src={require("../../../logo/3.png")}
                                    alt='Logo'
                                    style={{
                                      height: "28px",
                                      width: "20px",
                                      marginTop: "-5px",
                                      marginLeft: "5px"
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <p
                                    className="user-name"
                                    style={{ cursor: "pointer" }}
                                    onClick={e =>
                                      this.goToProfile(
                                        1,
                                        this.state.postData[this.state.position]
                                          .shortUserObject.slug
                                      )
                                    }
                                  >
                                    {
                                      this.state.postData[this.state.position]
                                        .shortUserObject.name
                                    }
                                  </p>
                                  <img
                                    src={require("../../../logo/4.png")}
                                    alt='Logo'
                                    style={{
                                      height: "28px",
                                      width: "20px",
                                      marginTop: "-5px",
                                      marginLeft: "5px"
                                    }}
                                  />
                                </div>
                              </>
                            )
                          ) : (
                            ""
                          )}
                        </div>

                        <p
                          className="post-date"
                          style={{ marginLeft: "10px", marginTop: "-10px" }}
                        >
                          <TimeAgo date={parseInt(  this.state.postData
                            ? this.state.postData[this.state.position]
                                .dateCreated
                            : "")} formatter={formatter} />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* --------------------------------------------------------- */}
                  <div
                    className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="row" style={{ justifyContent: "flex-end" }}>
                      <img
                        src={require("../../../logo/more.svg")}
                        alt="more"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "20px",
                          marginTop: "20px",
                          cursor: "pointer"
                        }}
                        onClick={e => this.handle_menu(e)}
                      />

                      <Menu
                        style={{ marginTop: "40px" }}
                        id="simple-menu"
                        anchorEl={this.state.anchor}
                        keepMounted
                        open={Boolean(this.state.anchor)}
                        onClose={e => this.handleClose(e)}
                      >
                       {
                            JSON.parse(localStorage.getItem("current_user")) ?
                            JSON.parse(localStorage.getItem("current_user"))
                            .id ===  this.state.postData[this.state.position]
                            .shortUserObject.id ?
                            <>
                            <MenuItem onClick={e => this.editPost()}>
                          <p>Edit</p>
                        </MenuItem>

                        <MenuItem
                          onClick={e =>
                            this.setState({
                              openDelete: true,
                              action: "delete"
                            })
                          }
                        >
                          <p>Delete</p>
                        </MenuItem>
                            </>

                            :
                            <MenuItem  onClick={e=>this.reportPost}>
                                <p> Report </p>
                            </MenuItem>
                        :
                            <MenuItem  onClick={e=>this.reportPost(e)}>
                                <p> Report </p>
                            </MenuItem>
                          }
                      </Menu>
                    </div>
                  </div>
                  {/* --------------------------------------------------------- */}
                  
                  <div
                    className="container"
                    style={{ marginTop: "30px", textAlign: "center" }}
                  >
                     
                    <img
                      onDragStart={e => this.onDragPhoto(e)}
                      onDrag={e => this.onDragPhoto(e)}
                      onContextMenu={e => this.clickRight(e)}
                      style={{ maxHeight: `${window.innerHeight - 260}px` }}
                      src={
                        this.state.postData[this.state.position]
                          .imageProperties[0].medium
                          ? this.state.postData[this.state.position]
                              .imageProperties[0].medium
                          : this.state.postData[this.state.position]
                              .imageProperties[0].originalUrl
                      }
                      alt='Post'
                    />
                     
                  </div>


                  {
                                       this.props.postData[this.state.position].specialText ? 
                                       <div className='container-fluid' style={{margin:'5px'}}>
                                        <div className='row justify-content-center'>
                                        <div className='mt-4' style={{border:'1px solid #707070', borderRadius:'60px'}}>
                                        <div 
                                            className='row justify-content-center ml-3 mr-4' 
                                            onClick={this.goToMission} 
                                            style={{cursor:'pointer'}}>
                                        {this.props.postData[this.state.position].specialImage ? 
                                        <img 
                                            style={{height:'30px', width:'30px',padding:'5px',marginTop:'5px'}}
                                            src={this.props.postData[this.state.position].specialImage}
                                            alt='contestLogo'
                                        /> : ''}
                                        
                                        <p
                                            className='winner'
                                        >
                                            {this.props.postData[this.state.position].specialText}</p>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    :
                                    ''
    }


                  <img 
                    className='prev-icon'
                    alt='next'
                    src={require('../../../logo/lft.svg')} 
                    onClick={e => this.imageSliderLeft(e)}/>

                <img 
                  className='next-icon'
                  alt='next'
                  src={require('../../../logo/rgt.svg')} 
                  onClick={e => this.imageSliderRight(e)}/>
                  {/* ------------------------------------------------------------------- */}
                  {/* No. of Comments & votes  */}
                  <div
                    className="col-12 col-sm-12 "
                    style={{ marginTop: "10px" }}>
                      
                    <div
                      className="row"
                      style={{ marginLeft: "30px", justifyContent: "start" }}
                    >
                      <div className="row">
                        <div className="row">
                          <img
                            src={require("../../../logo/comments.svg")}
                            alt='Comment Logo'
                            style={{
                              height: "16px",
                              width: "16px",
                              marginTop: "15px"
                            }}
                          />
                          {user_comments ? (
                            <p id="comments">
                              {
                                this.state.postData[this.state.position].commentCount
                              }
                            </p>
                          ) : (
                            <p id="comments">0</p>
                          )}

                          <img
                            src={require("../../../logo/likes.svg")}
                            alt='Like Logo'
                            onClick={e =>
                              this.openLikeUsersList(
                                e,
                                localStorage.post_id,
                                false
                              )
                            }
                            style={{ cursor: "pointer", marginTop: "10px" }}
                          />
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={e =>
                              this.openLikeUsersList(
                                e,
                                localStorage.post_id,
                                false
                              )
                            }
                            id="likes"
                          >
                            {likeCount}
                          </p>

                          <div>
                            {ids.has(localStorage.post_id) ? (
                              <div
                                className="row vote_button5"
                                onClick={e =>
                                  this.vote(e, localStorage.post_id, false)
                                }
                              >
                                <img
                                  id="like1"
                                  src={require("../../../logo/liked.svg")}
                                  alt="liked"
                                  style={{ marginTop: "07px", height: "18px" }}
                                />
                                <p id="vote-post2">
                                {
                                       this.props.postData[this.state.position].shortMissionObject ? 
                                       this.props.postData[this.state.position].shortMissionObject.currentTime > this.props.postData[this.state.position].shortMissionObject.finaleTime ? 
                                             'Liked' : 'Voted'
                                                     :
                                                 'Liked' 
                                               }
                                </p>
                              </div>
                            ) : (
                              <div
                                className="row vote_button4"
                                onClick={e =>
                                  this.vote(e, localStorage.post_id, true)
                                }
                              >
                                <img
                                  id="like1"
                                  src={require("../../../logo/like.svg")}
                                  alt="liked"
                                  style={{ marginTop: "07px", height: "18px" }}
                                />
                                <p id="vote-post1">
                                {
                                       this.props.postData[this.state.position].shortMissionObject ? 
                                       this.props.postData[this.state.position].shortMissionObject.currentTime > this.props.postData[this.state.position].shortMissionObject.finaleTime ? 
                                             'Like' : 'Vote'
                                                     :
                                                 'Like' 
                                               }
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* vote Score */}

                {
                  this.props.postData[this.state.position].likeCount === '0' ? '' :
                
                <div className='Rectangle-1999' onClick={()=>{this.setState({vote_score_dialog_mob : true})}}>
        <div className='row' style={{ margin: "5px 10px 0px 10px"}}>
          <div>
            <img className='grp-icon' src={require('../../../logo/Group1342.svg')} alt='Group Icon' />
          </div>
          
          <div>
            <p className='votescore'>Vote Score - {parseFloat(this.props.postData[this.state.position].voteScore).toFixed(3)}</p>
            <p className='votescore_desc'>Learn More</p>
          </div>
        </div>
    </div>
  }
                {/* ----------------------------sec-B-------------------------------------------------------------- */}

                <div style={{ textAlign: "start", marginTop: "20px" }}>
                  <p id="title-post">
                    {this.state.postData[this.state.position].title}
                  </p>

                  <p id="desc-post">
                    {this.state.postData[this.state.position].description}
                  </p>

                  {/* social-share */}
                  <div
                    onClick={e => this.clickOnShare()}
                    className="row"
                    style={{ marginLeft: "2px" }}
                  >

                  <WhatsappShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      title=""
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                    >
                      <WhatsappIcon round size="30px" />
                    </WhatsappShareButton>


                    <FacebookShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      quote=""
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                    >
                      <FacebookIcon round size="30px" />
                    </FacebookShareButton>



                    <LinkedinShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                    >
                      <LinkedinIcon round size="30px" />
                    </LinkedinShareButton>

                   
                 

                    <TwitterShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                      title=""
                    >
                      <TwitterIcon round size="30px" />
                    </TwitterShareButton>
                  </div>
                </div>

                { this.state.comments === true ? (
                  user_comments.map((value, index) => {
                    return (
                      <>
                        <div className="row">
                          <div
                            className="col-10 col-sm-10 col-md-10 col-lg-6 col-xl-7"
                            style={{ textAlign: "start" }}
                          >
                            <div
                              className="row"
                              style={{ marginTop: "40px", marginLeft: "0px" }}
                            >
                              <img
                                src={value.shortUserObject.profilePicture}
                                onError={(e) => {
                                  e.target.onerror = null
                                  e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                }}
                                alt='Profile'
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "60px",
                                  objectFit: "cover"
                                }}
                              />

                              <div style={{ marginTop: "2px" }}>
                                

                                <div>
                                  {value.shortUserObject.badge === "0" ? (
                                    <>
                                      <p
                                        className="user-name2"
                                        style={{ cursor: "pointer" }}
                                        onClick={e =>
                                          this.goToProfile(
                                            1,
                                            value.shortUserObject.slug
                                          )
                                        }
                                      >
                                        {value.shortUserObject.name}
                                      </p>
                                    </>
                                  ) : value.shortUserObject.badge === "1" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/1.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : value.shortUserObject.badge === "2" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/1.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : value.shortUserObject.badge === "3" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/3.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/4.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>

                                <p
                                  className="post-date"
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "-10px"
                                  }}
                                >
                                  <TimeAgo
                                    date={parseInt(value.dateCreated)}
                                    formatter={formatter}
                                  />
                                   {
                                    value.shortUserObject.badge === 0 ? '' :
                                    ` | Jury's Comment`
                                  } 
                                </p>
                              </div>

                              <div className="container">
                                <p className="user-comm">{value.title}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{ marginLeft: "55px" }}>
                          {" "}
                          {/* ///////////////////////////////////////////////////////////////////////// */}
                          {!ids.has(value.id) ? (
                            <>
                              <img
                                src={require("../../../logo/like.svg")}
                                alt='Like Logo'
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer"
                                }}
                                onClick={e =>
                                  this.likeComment(e, value.id, true)
                                }
                              />
                              {value.likeCount === "1" ? (
                                <p className="small_text">
                                  {value.likeCount} like
                                </p>
                              ) : (
                                <p className="small_text">
                                  {value.likeCount} likes
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <img
                                src={require("../../../logo/liked.svg")}
                                alt='Liked Logo'
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer"
                                }}
                                onClick={e =>
                                  this.likeComment(e, value.id, false)
                                }
                              />
                              {value.likeCount === "1" ? (
                                <p
                                  className="small_text"
                                  onClick={e =>
                                    this.openLikeUsersList_mob(e, value.id)
                                  }
                                >
                                  {value.likeCount} like
                                </p>
                              ) : (
                                <p
                                  className="small_text"
                                  onClick={e =>
                                    this.openLikeUsersList_mob(e, value.id)
                                  }
                                >
                                  {value.likeCount} likes
                                </p>
                              )}
                            </>
                          )}

{JSON.parse(localStorage.getItem("current_user")).id ===
                        value.shortUserObject.id ? (
                          <img
                            onClick={e => {
                              this.setState({
                                openDelete: true,
                                action: "c_delete",
                                comment_id: value.id
                              });
                            }}
                            className="comment_more_opt"
                            src={require("../../../logo/comment_delete.svg")}
                            alt="comment_delete"
                          />
                        ) : reported.has(value.id) ? (
                          <p
                            onClick={e => {
                              this.reportComment(e, false, value.id);
                            }}
                            className="reported"
                          >
                            {" "}
                            UnReport
                          </p>
                        ) : (
                          <p
                            onClick={e => {
                              this.setState({
                                openDelete: true,
                                action: "c_report",
                                is_reported: true,
                                comment_id: value.id
                              });
                            }}
                            className="reported"
                          >
                            {" "}
                            Report
                          </p>
                        )}
                        </div>

                        {/* ////////////////////////////////////////////////////////////////////// */}
                      </>
                    );
                  })
                ) : (
                  <></>
                )}

                {/*----- Load More Comments ------*/}
                {this.state.hasMore === true ? (
                  <div className="container-fluid">
                    <p
                      onClick={e => this.loadMoreComments(e)}
                      className="load_more"
                    >
                      Load Older Comments
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {/* ----------------------------------------------------------------------------------- */}

                <div className="row">
                  {this.state.progress === true ? (
                    <div
                      className="row"
                      style={{
                        marginLeft: `${(window.innerWidth * 20) / 100}px`
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          textAlign: "start",
                          background: "#000",
                          borderRadius: "6px",
                          marginLeft: "30px"
                        }}
                      >
                        <div
                          className="row"
                          style={{ marginTop: "15px", marginBottom: "40px" }}
                        >
                          {/* <img 
                                                        src={(JSON.parse(localStorage.getItem('current_user'))).profilePicture} 
                                                        style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover'}}
                                                        /> */}
                          <div style={{ marginTop: "2px" }}>
                            <textarea
                              name="desc"
                              id="textarea_desc"
                              placeholder="Write Something Nice"
                              cols="30"
                              rows="4"
                              style={{
                                marginLeft: "10px",
                                border: "none",
                                outline: "none",
                                borderRadius: "6px",
                                padding: "5px",
                                fontSize: "12px"
                              }}
                              onChange={e => this.change(e)}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          borderRadius: "60px",
                          background: "white",
                          height: "40px",
                          width: "40px",
                          marginLeft: "20px",
                          marginTop: "20px",
                          cursor: "pointer"
                        }}
                        onClick={e => this.addNewComment(localStorage.post_id)}
                      >
                        <img
                          src={require("../../../logo/arrow.svg")}
                          alt='Arrow Logo'
                          height="20"
                          width="20"
                          style={{ marginTop: "10px", marginLeft: "10px" }}
                        />
                      </div>
                    </>
                  )}
                </div>

                {/* ///////////////////////////////////////////////////////////////////////// */}

                {/* --------------------------------sec-B--------------------------------------- */}
              </div>
            </div>
          ) : (
            // Large devices

            <div
              style={{ height: `${window.innerHeight}px`, background: "black" }}
            >
              <div className="container-fluid" style={{ background: "black" }}>
                <div className="row">
                  {/* user Image , Name , follow  */}
                  <div
                    className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                    style={{ textAlign: "start" }}
                  >
                    <div
                      className="row"
                      style={{ marginTop: "40px", marginLeft: "32px" }}
                    >
                      <img
                        src={
                          this.state.postData[this.state.position]
                            .shortUserObject.profilePicture
                        }
                        alt='Profile'
                        style={{
                          height: "52px",
                          width: "52px",
                          borderRadius: "60px",
                          objectFit: "cover"
                        }}
                      />

                      <div style={{ marginTop: "3px" }}>
                        
                        <div>
                          {this.state.postData[this.state.position]
                            .shortUserObject.badge === "0" ? (
                            <>
                              <p
                                className="user-name2"
                                style={{ cursor: "pointer" }}
                                onClick={e =>
                                  this.goToProfile(
                                    1,
                                    this.state.postData[this.state.position]
                                      .shortUserObject.slug
                                  )
                                }
                              >
                                {
                                  this.state.postData[this.state.position]
                                    .shortUserObject.name
                                }
                              </p>
                            </>
                          ) : this.state.postData[this.state.position]
                              .shortUserObject.badge === "1" ? (
                            <>
                              <div className="row">
                                <p
                                  className="user-name"
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this.goToProfile(
                                      1,
                                      this.state.postData[this.state.position]
                                        .shortUserObject.slug
                                    )
                                  }
                                >
                                  {
                                    this.state.postData[this.state.position]
                                      .shortUserObject.name
                                  }
                                </p>
                                <img
                                  src={require("../../../logo/1.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginTop: "-5px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                            </>
                          ) : this.state.postData[this.state.position]
                              .shortUserObject.badge === "2" ? (
                            <>
                              <div className="row">
                                <p
                                  className="user-name"
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this.goToProfile(
                                      1,
                                      this.state.postData[this.state.position]
                                        .shortUserObject.slug
                                    )
                                  }
                                >
                                  {
                                    this.state.postData[this.state.position]
                                      .shortUserObject.name
                                  }
                                </p>
                                <img
                                  src={require("../../../logo/1.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginTop: "-5px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                            </>
                          ) : this.state.postData[this.state.position]
                              .shortUserObject.badge === "3" ? (
                            <>
                              <div className="row">
                                <p
                                  className="user-name"
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this.goToProfile(
                                      1,
                                      this.state.postData[this.state.position]
                                        .shortUserObject.slug
                                    )
                                  }
                                >
                                  {
                                    this.state.postData[this.state.position]
                                      .shortUserObject.name
                                  }
                                </p>
                                <img
                                  src={require("../../../logo/3.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginTop: "-5px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row">
                                <p
                                  className="user-name"
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this.goToProfile(
                                      1,
                                      this.state.postData[this.state.position]
                                        .shortUserObject.slug
                                    )
                                  }
                                >
                                  {
                                    this.state.postData[this.state.position]
                                      .shortUserObject.name
                                  }
                                </p>
                                <img
                                  src={require("../../../logo/4.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginTop: "-5px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <p
                          className="post-date"
                          style={{ marginLeft: "15px", marginTop: "-10px" }}
                        >
                           <TimeAgo date={parseInt(  this.state.postData
                            ? this.state.postData[this.state.position]
                                .dateCreated
                            : "")} formatter={formatter} />
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* No. of Comments & votes  */}

                  <div
                    className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                    style={{ marginTop: "40px" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                        marginRight: "10px",
                        justifyContent: "flex-end"
                      }}
                    >
                      <div className="row">
                        <div className="row">
                          <img
                            src={require("../../../logo/comments.svg")}
                            alt='Comments'
                            style={{
                              height: "16px",
                              width: "16px",
                              marginTop: "15px"
                            }}
                          />
                          {user_comments ? (
                            <p id="comments">
                              {
                                this.state.postData[this.state.position]
                                  .commentCount
                              }
                            </p>
                          ) : (
                            <p id="comments">0</p>
                          )}

                          <img
                            src={require("../../../logo/likes.svg")}
                            alt='Likes'
                            onClick={e =>
                              this.openLikeUsersList(e, localStorage.post_id, true)
                            }
                            style={{ cursor: "pointer", marginTop:"10px" }}
                          />
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={e =>
                              this.openLikeUsersList(e, localStorage.post_id, true)
                            }
                            id="likes"
                          >
                            {likeCount}
                          </p>

                          {ids.has(localStorage.post_id) ? (
                            <div
                              className="row vote_button5"
                              onClick={e =>
                                this.vote(e, localStorage.post_id, false)
                              }
                            >
                              <img
                                id="like1"
                                src={require("../../../logo/liked.svg")}
                                alt="liked"
                                style={{ marginTop: "07px", height: "18px" }}
                              />
                              <p id="vote-post2">
                              {
                                       this.props.postData[this.state.position].shortMissionObject ? 
                                       this.props.postData[this.state.position].shortMissionObject.currentTime > this.props.postData[this.state.position].shortMissionObject.finaleTime ? 
                                             'Liked' : 'Voted'
                                                     :
                                                 'Liked' 
                                               }
                              </p>
                            </div>
                          ) : (
                            <div
                              className="row vote_button4"
                              onClick={e =>
                                this.vote(e, localStorage.post_id, true)
                              }
                            >
                              <img
                                id="like1"
                                src={require("../../../logo/like.svg")}
                                alt="liked"
                                style={{ marginTop: "07px", height: "18px" }}
                              />
                              <p id="vote-post1">
                              {
                                       this.props.postData[this.state.position].shortMissionObject ? 
                                       this.props.postData[this.state.position].shortMissionObject.currentTime > this.props.postData[this.state.position].shortMissionObject.finaleTime ? 
                                             'Like' : 'Vote'
                                                     :
                                                 'Like' 
                                               }
                              </p>
                            </div>
                          )}

                          <img
                            src={require("../../../logo/more.svg")}
                            alt="more"
                            className="more_opt"
                            onClick={e => this.handle_menu(e)}
                          />

                          <Menu
                            style={{ marginTop: "40px" }}
                            id="simple-menu"
                            anchorEl={this.state.anchor}
                            keepMounted
                            open={Boolean(this.state.anchor)}
                            onClose={e => this.handleClose(e)}
                          >{
                            JSON.parse(localStorage.getItem("current_user")) ?
                            JSON.parse(localStorage.getItem("current_user"))
                            .id ===  this.state.postData[this.state.position]
                            .shortUserObject.id ?
                            <>
                            <MenuItem onClick={e => this.editPost()}>
                          <p>Edit</p>
                        </MenuItem>

                        <MenuItem
                          onClick={e =>
                            this.setState({
                              openDelete: true,
                              action: "delete"
                            })
                          }
                        >
                          <p>Delete</p>
                        </MenuItem>
                            </>

                            :
                            <MenuItem  onClick={e=>this.reportPost}>
                                <p> Report </p>
                            </MenuItem>
                        :
                            <MenuItem  onClick={e=>this.reportPost(e)}>
                                <p> Report </p>
                            </MenuItem>
                          }
                           
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="container"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                   <img 
                  className='prev-icon'
                  alt='next'
                  src={require('../../../logo/lft.svg')} 
                  onClick={e => this.imageSliderLeft(e)}/>

                  <img
                    onDragStart={e => this.onDragPhoto(e)}
                    onDrag={e => this.onDragPhoto(e)}
                    onContextMenu={e => this.clickRight(e)}
                    style={{ maxHeight: `${window.innerHeight - 260}px` }}
                    src={
                      this.state.postData[this.state.position]
                        .imageProperties[0].medium
                        ? this.state.postData[this.state.position]
                            .imageProperties[0].medium
                        : this.state.postData[this.state.position]
                            .imageProperties[0].originalUrl
                    }
                    alt='Post'
                  />

<img 
                  className='next-icon'
                  alt='next'
                  src={require('../../../logo/rgt.svg')} 
                  onClick={e => this.imageSliderRight(e)}/>
                </div>

                {/* ----------------------------sec-B-------------------------------------------------------------- */}



                {
                                       this.props.postData[this.state.position].specialText ? 
                                        <div className='row justify-content-center'>
                                       <div className='mt-4' style={{border:'1px solid #707070', borderRadius:'60px'}}>
                                       <div 
                                           className='row justify-content-center ml-3 mr-4' 
                                           onClick={this.goToMission} 
                                           style={{cursor:'pointer'}}>
                                        {this.props.postData[this.state.position].specialImage ? 
                                         <img 
                                         style={{height:'45px', width:'45px',padding:'10px'}}
                                            src={this.props.postData[this.state.position].specialImage}
                                            alt='contestLogo'
                                            /> : ''}
                                            
                                            <p
                                                className='winner'
                                            >
                                            {this.props.postData[this.state.position].specialText}</p>
                                    </div>
                                    </div>
                                    </div>
                                
                                    :
                                    ''
    }
    {
      this.props.postData[this.state.position].likeCount === '0' ? '' : 

    <div className='Rectangle-1999' onClick={()=>{this.setState({vote_score_dialog : true})}}>
            <div className='row' style={{ margin: "5px 10px 0px 10px"}}>
              <div>
                <img className='grp-icon' src={require('../../../logo/Group1342.svg')} alt='Group Icon' />
              </div>
              
              <div>
                <p className='votescore'>Vote Score - {parseFloat(this.props.postData[this.state.position].voteScore).toFixed(3)}</p>
                <p className='votescore_desc'>Learn More</p>
              </div>
            </div>
        </div>
      }

    {/* --------------------------------------------------- */}
                <div
                  style={{
                    textAlign: "start",
                    marginTop: "50px",
                    marginLeft: "40px"
                  }}
                >
                  <p id="title-post">
                    {this.state.postData[this.state.position].title}
                  </p>

                  <p id="desc-post">
                    {this.state.postData[this.state.position].description}
                  </p>

                  {/* social-share */}
                  <div onClick={e => this.clickOnShare()} className="row ">
                  

                    <WhatsappShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      title=""
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                    >
                      <WhatsappIcon round size="30px" />
                    </WhatsappShareButton>

                    <FacebookShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      quote=""
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                    >
                      <FacebookIcon round size="30px" />
                    </FacebookShareButton>

                    <LinkedinShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                    >
                      <LinkedinIcon round size="30px" />
                    </LinkedinShareButton>

                 
                   
                  

                    <TwitterShareButton
                      style={{ outline: "none", cursor: "pointer" }}
                      className="sc"
                      url={
                        "https://wallmag.io/post/" +
                        this.state.postData[this.state.position].slug
                      }
                      title=""
                    >
                      <TwitterIcon round size="30px" />
                    </TwitterShareButton>
                  </div>
                </div>

                {
                this.state.comments === true ? (
                  user_comments.map((value, index) => {
                    return (
                      <>
                        <div className="row">
                          <div
                            className="col-10 col-sm-10 col-md-10 col-lg-6 col-xl-7"
                            style={{ textAlign: "start" }}
                          >
                            <div
                              className="row"
                              style={{ marginTop: "40px", marginLeft: "32px" }}
                            >
                              <img
                                src={value.shortUserObject.profilePicture}
                                onError={(e) => {
                                  e.target.onerror = null
                                  e.target.src = 'https://firebasestorage.googleapis.com/v0/b/fir-image-7c44c.appspot.com/o/images%2Fuser.svg?alt=media&token=d8b4d1af-fe6b-4bdc-839d-96d553248abf'
                                }}
                                alt='Profile'
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "60px",
                                  objectFit: "cover"
                                }}
                              />

                              <div style={{ marginTop: "2px" }}>
  

                                <div>
                                  {value.shortUserObject.badge === "0" ? (
                                    <>
                                      <p
                                        className="user-name2"
                                        style={{ cursor: "pointer" }}
                                        onClick={e =>
                                          this.goToProfile(
                                            1,
                                            value.shortUserObject.slug
                                          )
                                        }
                                      >
                                        {value.shortUserObject.name}
                                      </p>
                                    </>
                                  ) : value.shortUserObject.badge === "1" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/1.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : value.shortUserObject.badge === "2" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/1.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : value.shortUserObject.badge === "3" ? (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/3.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="row">
                                        <p
                                          className="user-name"
                                          style={{ cursor: "pointer" }}
                                          onClick={e =>
                                            this.goToProfile(
                                              1,
                                              value.shortUserObject.slug
                                            )
                                          }
                                        >
                                          {value.shortUserObject.name}
                                        </p>
                                        <img
                                          src={require("../../../logo/4.png")}
                                          alt='Logo'
                                          style={{
                                            height: "28px",
                                            width: "20px",
                                            marginTop: "-5px",
                                            marginLeft: "5px"
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>

                                <p
                                  className="post-date"
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "-10px"
                                  }}
                                >
                                  <TimeAgo
                                    date={parseInt(value.dateCreated)}
                                    formatter={formatter}
                                  />
                                
                                </p>
                              </div>

                              <div className="container">
                                <p className="user-comm">{value.title}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ///////////////////////////////////////////////////////////////////////// */}

                        <div className="row" style={{ marginLeft: "84px" }}>
                          {!ids.has(value.id) ? (
                            <>
                              <img
                                src={require("../../../logo/like.svg")}
                                alt='Likes'
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer"
                                }}
                                onClick={e =>
                                  this.likeComment(e, value.id, true)
                                }
                              />
                              {value.likeCount === "1" ? (
                                <p
                                  className="small_text"
                                  onClick={e =>
                                    this.openLikeUsersList(e, value.id, true)
                                  }
                                >
                                  {value.likeCount} like
                                </p>
                              ) : (
                                <p
                                  className="small_text"
                                  onClick={e =>
                                    this.openLikeUsersList(e, value.id, true)
                                  }
                                >
                                  {value.likeCount} likes
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <img
                                src={require("../../../logo/liked.svg")}
                                alt='Liked'
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer"
                                }}
                                onClick={e =>
                                  this.likeComment(e, value.id, false)
                                }
                              />
                              {value.likeCount === "1" ? (
                                <p
                                  className="small_text"
                                  onClick={e =>
                                    this.openLikeUsersList(e, value.id, true)
                                  }
                                >
                                  {value.likeCount} like
                                </p>
                              ) : (
                                <p
                                  className="small_text"
                                  onClick={e =>
                                    this.openLikeUsersList(e, value.id, true)
                                  }
                                >
                                  {value.likeCount} likes
                                </p>
                              )}
                            </>
                          )}

{JSON.parse(localStorage.getItem("current_user")).id ===
                        value.shortUserObject.id ? (
                          <img
                            onClick={e => {
                              this.setState({
                                openDelete: true,
                                action: "c_delete",
                                comment_id: value.id
                              });
                            }}
                            className="comment_more_opt"
                            src={require("../../../logo/comment_delete.svg")}
                            alt="comment_delete"
                          />
                        ) : reported.has(value.id) ? (
                          <p
                            onClick={e => {
                              this.reportComment(e, false, value.id);
                            }}
                            className="reported"
                          >
                            {" "}
                            UnReport
                          </p>
                        ) : (
                          <p
                            onClick={e => {
                              this.setState({
                                openDelete: true,
                                action: "c_report",
                                is_reported: true,
                                comment_id: value.id
                              });
                            }}
                            className="reported"
                          >
                            {" "}
                            Report
                          </p>
                        )}
                        </div>
                        

                        {/* ////////////////////////////////////////////////////////////////////// */}
                      </>
                    );
                  })
                ) : (
                  <></>
                )}

                
                



                {/*----- Load More Comments ------*/}
                {this.state.hasMore === true ? (
                  <div className="container-fluid">
                    <p
                      onClick={e => this.loadMoreComments(e)}
                      className="load_more"
                    >
                      Load Older Comments
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {/* ----------------------------------------------------------------------------------- */}
                <div className="row">
                  {this.state.progress === false ? (
                    <>
                      <div
                        style={{
                          textAlign: "start",
                          background: "#000",
                          borderRadius: "6px",
                          marginLeft: "62px"
                        }}
                      >
                        <div
                          className="row"
                          style={{ marginTop: "15px", marginBottom: "40px" }}
                        >
                          {/* <img 
                                                    src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                                    style={{height:'40px', width:'40px', borderRadius:'60px', objectFit:'cover'}}
                                                /> */}
                          <div style={{ marginTop: "2px" }}>
                            <textarea
                              name="desc"
                              id="textarea_desc"
                              placeholder="Write Something Nice"
                              cols="40"
                              rows="5"
                              style={{
                                marginLeft: "20px",
                                border: "none",
                                outline: "none",
                                borderRadius: "6px",
                                padding: "10px"
                              }}
                              onChange={e => this.change(e)}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          borderRadius: "60px",
                          background: "white",
                          height: "40px",
                          width: "40px",
                          marginLeft: "40px",
                          marginTop: "20px",
                          cursor: "pointer"
                        }}
                        onClick={e => this.addNewComment(localStorage.post_id)}
                      >
                        <img
                          src={require("../../../logo/arrow.svg")}
                          alt='Arrow'
                          height="20"
                          width="20"
                          style={{ marginTop: "10px", marginLeft: "10px" }}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="row"
                      style={{
                        marginLeft: `${(window.innerWidth * 20) / 100}px`
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </div>
                  )}
                </div>

                {/* ///////////////////////////////////////////////////////////////////////// */}

                {/* --------------------------------sec-B--------------------------------------- */}
              </div>
            </div>
          )
        
        
        }

        {/* dialog for mobile */}

        <Dialog
          open={this.state.dialog_mob}
          onClose={() => this.setState({dialog_mob : false})}
          disableBackdropClick={true}
          fullScreen
        >
          <DialogTitle>
            <AppBar
              style={{
                background: "white",
                paddingRight: "20px",
                paddingLeft: "10px"
              }}
            >
              <div
                className="row"
                style={{ justifyContent: "space-between", marginTop: "10px" }}
              >
                <p id="totalLikers">{likes.length} Likes</p>

                <img
                  src={require("../../../logo/cancel.svg")}
                  alt='Cancel Logo'
                  style={{ marginTop: "-10px", cursor: "pointer" }}
                  onClick={e => this.setState({ dialog_mob: false })}
                />
              </div>
            </AppBar>
          </DialogTitle>

          <List style={{ width: "280px", marginTop: "30px" }}>
            {likes ? (
              likes.map((value, indexNo) => {
                return (
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        src={value.photoUrl}
                        alt="user"
                        style={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "60px",
                          objectFit: "cover",
                          marginTop: "-16px"
                        }}
                      />
                    </ListItemAvatar>
                    <div>
                      {value.badge === "0" ? (
                        <>
                          <p
                            id="nameOfLikersNoBadge"
                            style={{ cursor: "pointer" }}
                            onClick={e => this.goToProfile(1, value.slug)}
                          >
                            {value.displayName ? value.displayName.length > 16
                                ? value.displayName.slice(0, 16) + "..."
                                : value.displayName : ''}
                          </p>
                          <p id="desig">
                            Joined{" "}
                            <TimeAgo
                              date={parseInt(value.dateCreated)}
                              formatter={formatter}
                            />
                          </p>
                        </>
                      ) : value.badge === "1" ? (
                        <>
                          <div className="row">
                            <p
                              id="nameOfLikers"
                              style={{ cursor: "pointer" }}
                              onClick={e => this.goToProfile(1, value.slug)}
                            >
                              {value.displayName ? value.displayName.length > 16
                                ? value.displayName.slice(0, 16) + "..."
                                : value.displayName : ''}
                            </p>
                            <img
                              src={require("../../../logo/1.png")}
                              alt='Logo'
                              style={{
                                height: "28px",
                                width: "20px",
                                marginTop: "-5px",
                                marginLeft: "5px"
                              }}
                            />
                          </div>
                          <p id="desig">1 Vote = 5 Votes</p>
                        </>
                      ) : value.badge === "2" ? (
                        <>
                          <div className="row">
                            <p
                              id="nameOfLikers"
                              style={{ cursor: "pointer" }}
                              onClick={e => this.goToProfile(1, value.slug)}
                            >
                              {value.displayName ? value.displayName.length > 16
                                ? value.displayName.slice(0, 16) + "..."
                                : value.displayName : ''}
                            </p>
                            <img
                              src={require("../../../logo/1.png")}
                              alt='Logo'
                              style={{
                                height: "28px",
                                width: "20px",
                                marginTop: "-5px",
                                marginLeft: "5px"
                              }}
                            />
                          </div>
                          <p id="desig">1 Vote = 10 Votes</p>
                        </>
                      ) : value.badge === "3" ? (
                        <>
                          <div className="row">
                            <p
                              id="nameOfLikers"
                              style={{ cursor: "pointer" }}
                              onClick={e => this.goToProfile(1, value.slug)}
                            >
                              {value.displayName ? value.displayName.length > 16
                                ? value.displayName.slice(0, 16) + "..."
                                : value.displayName : ''}
                            </p>
                            <img
                              src={require("../../../logo/3.png")}
                              alt='Logo'
                              style={{
                                height: "28px",
                                width: "20px",
                                marginTop: "-5px",
                                marginLeft: "5px"
                              }}
                            />
                          </div>
                          <p id="desig">1 Vote = 20 Votes</p>
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <p
                              id="nameOfLikers"
                              style={{ cursor: "pointer" }}
                              onClick={e => this.goToProfile(1, value.slug)}
                            >
                              {value.displayName ? value.displayName.length > 16
                                ? value.displayName.slice(0, 16) + "..."
                                : value.displayName : ''}
                            </p>
                            <img
                              src={require("../../../logo/4.png")}
                              alt='Logo'
                              style={{
                                height: "28px",
                                width: "20px",
                                marginTop: "-5px",
                                marginLeft: "5px"
                              }}
                            />
                          </div>
                          <p id="desig">1 Vote = 100 Votes</p>
                        </>
                      )}
                    </div>

                    {JSON.parse(localStorage.getItem("current_user")).id ===
                    value.id ? (
                      <></>
                    ) : followingIds.has(value.id) ? (
                      <div
                        className="following_dialog"
                        style={{ marginLeft: "120px", cursor: "pointer" }}
                        onClick={e => this.followUser(e, value.id, false)}
                      >
                        <p id="following_dialog_text"> Following </p>
                      </div>
                    ) : (
                      <div
                        className="follow_dialog"
                        style={{ marginLeft: "120px", cursor: "pointer" }}
                        onClick={e => this.followUser(e, value.id, true)}
                      >
                        <p id="foll_dialog_text"> Follow </p>
                      </div>
                    )}
                  </ListItem>
                );
              })
            ) : (
              <></>
            )}
          </List>
        </Dialog>

        {/* dialog for large screens (tab, laptop, pc) */}

        <Dialog
          open={this.state.dialog}
          onClose={() => this.setState({dialog : false})}
          disableBackdropClick={true}
        >
          <DialogTitle>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <p id="totalLikers">{likes.length} Likes</p>

              <img
                src={require("../../../logo/cancel.svg")}
                alt='Cancel Logo'
                style={{ marginTop: "-10px", cursor: "pointer" }}
                onClick={e => this.setState({ dialog: false })}
              />
            </div>
          </DialogTitle>

          <List style={{ width: "500px" }}>
            {likes ? (
              likes.map((value, indexNo) => {
                return (
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        src={value.photoUrl}
                        alt="user"
                        style={{
                          height: "52px",
                          width: "52px",
                          borderRadius: "60px",
                          objectFit: "cover"
                        }}
                      />
                    </ListItemAvatar>

                    {JSON.parse(localStorage.getItem("current_user")).id ===
                    value.id ? (
                      <div>
                        {value.badge === "0" ? (
                          <>
                            <p
                              id="nameOfLikersNoBadge"
                              style={{ cursor: "pointer" }}
                              onClick={e => this.goToProfile(1, value.slug)}
                            >
                              {value.displayName ? value.displayName.length > 16
                                ? value.displayName.slice(0, 16) + "..."
                                : value.displayName : ''}
                            </p>

                            <p id="desig">
                              Joined{" "}
                              <TimeAgo
                                date={parseInt(value.dateCreated)}
                                formatter={formatter}
                              />
                            </p>
                          </>
                        ) : value.badge === "1" ? (
                          <>
                            <div className="row">
                              <p
                                id="nameOfLikers"
                                style={{ cursor: "pointer" }}
                                onClick={e => this.goToProfile(1, value.slug)}
                              >
                                {value.displayName ? value.displayName.length > 16
                                  ? value.displayName.slice(0, 16) + "..."
                                  : value.displayName : ''}
                              </p>
                              <img
                                src={require("../../../logo/1.png")}
                                alt='Logo'
                                style={{
                                  height: "28px",
                                  width: "20px",
                                  marginLeft: "5px"
                                }}
                              />
                            </div>
                            <p id="desig">1 Vote = 5 Votes</p>
                          </>
                        ) : value.badge === "2" ? (
                          <>
                            <div className="row">
                              <p
                                id="nameOfLikers"
                                style={{ cursor: "pointer" }}
                                onClick={e => this.goToProfile(1, value.slug)}
                              >
                                {value.displayName ? value.displayName.length > 16
                                  ? value.displayName.slice(0, 16) + "..."
                                  : value.displayName : ''}
                              </p>
                              <img
                                src={require("../../../logo/2.png")}
                                alt='Logo'
                                style={{
                                  height: "28px",
                                  width: "20px",
                                  marginLeft: "5px"
                                }}
                              />
                            </div>
                            <p id="desig">1 Vote = 10 Votes</p>
                          </>
                        ) : value.badge === "3" ? (
                          <>
                            <div className="row">
                              <p
                                id="nameOfLikers"
                                style={{ cursor: "pointer" }}
                                onClick={e => this.goToProfile(1, value.slug)}
                              >
                                {value.displayName ? value.displayName.length > 16
                                  ? value.displayName.slice(0, 16) + "..."
                                  : value.displayName : ''}
                              </p>
                              <img
                                src={require("../../../logo/3.png")}
                                alt='Logo'
                                style={{
                                  height: "28px",
                                  width: "20px",
                                  marginLeft: "5px"
                                }}
                              />
                            </div>
                            <p id="desig">1 Vote = 20 Votes</p>
                          </>
                        ) : (
                          <>
                            <div className="row">
                              <p
                                id="nameOfLikers"
                                style={{ cursor: "pointer" }}
                                onClick={e => this.goToProfile(1, value.slug)}
                              >
                                {value.displayName ? value.displayName.length > 16
                                  ? value.displayName.slice(0, 16) + "..."
                                  : value.displayName : ''}
                              </p>
                              <img
                                src={require("../../../logo/4.png")}
                                alt='Logo'
                                style={{
                                  height: "28px",
                                  width: "20px",
                                  marginLeft: "5px"
                                }}
                              />
                            </div>
                            <p id="desig">1 Vote = 100 Votes</p>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <div>
                          {value.badge === "0" ? (
                            <>
                              <p
                                id="nameOfLikersNoBadge"
                                style={{ cursor: "pointer" }}
                                onClick={e => this.goToProfile(1, value.slug)}
                              >
                                {value.displayName ? value.displayName.length > 16
                                  ? value.displayName.slice(0, 16) + "..."
                                  : value.displayName : ''}
                              </p>
                              <p id="desig">
                                Joined{" "}
                                <TimeAgo
                                  date={parseInt(value.dateCreated)}
                                  formatter={formatter}
                                />
                              </p>
                            </>
                          ) : value.badge === "1" ? (
                            <>
                              <div className="row">
                                <p
                                  id="nameOfLikers"
                                  style={{ cursor: "pointer" }}
                                  onClick={e => this.goToProfile(1, value.slug)}
                                >
                                  {value.displayName? value.displayName.length > 16
                                    ? value.displayName.slice(0, 16) + "..."
                                    : value.displayName : ''}
                                </p>
                                <img
                                  src={require("../../../logo/1.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                              <p id="desig">1 Vote = 5 Votes</p>
                            </>
                          ) : value.badge === "2" ? (
                            <>
                              <div className="row">
                                <p
                                  id="nameOfLikers"
                                  style={{ cursor: "pointer" }}
                                  onClick={e => this.goToProfile(1, value.slug)}
                                >
                                  {value.displayName ? value.displayName.length > 16
                                    ? value.displayName.slice(0, 16) + "..."
                                    : value.displayName : ''}
                                </p>
                                <img
                                  src={require("../../../logo/2.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                              <p id="desig">1 Vote = 10 Votes</p>
                            </>
                          ) : value.badge === "3" ? (
                            <>
                              <div className="row">
                                <p
                                  id="nameOfLikers"
                                  style={{ cursor: "pointer" }}
                                  onClick={e => this.goToProfile(1, value.slug)}
                                >
                                  {value.displayName ? value.displayName.length > 16
                                    ? value.displayName.slice(0, 16) + "..."
                                    : value.displayName : ''}
                                </p>
                                <img
                                  src={require("../../../logo/3.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                              <p id="desig">1 Vote = 20 Votes</p>
                            </>
                          ) : (
                            <>
                              <div className="row">
                                <p
                                  id="nameOfLikers"
                                  style={{ cursor: "pointer" }}
                                  onClick={e => this.goToProfile(1, value.slug)}
                                >
                                  {value.displayName ? value.displayName.length > 16
                                    ? value.displayName.slice(0, 16) + "..."
                                    : value.displayName : ''}
                                </p>
                                <img
                                  src={require("../../../logo/4.png")}
                                  alt='Logo'
                                  style={{
                                    height: "28px",
                                    width: "20px",
                                    marginLeft: "5px"
                                  }}
                                />
                              </div>
                              <p id="desig">1 Vote = 100 Votes</p>
                            </>
                          )}
                        </div>

                        {followingIds.has(value.id) ? (
                          <div
                            className="following_dialog"
                            style={{ marginLeft: "350px", cursor: "pointer" }}
                            onClick={e => this.followUser(e, value.id, false)}
                          >
                            <p id="following_dialog_text"> Following </p>
                          </div>
                        ) : (
                          <div
                            className="follow_dialog"
                            style={{ marginLeft: "350px", cursor: "pointer" }}
                            onClick={e => this.followUser(e, value.id, true)}
                          >
                            <p id="foll_dialog_text"> Follow </p>
                          </div>
                        )}
                      </>
                    )}
                  </ListItem>
                );
              })
            ) : (
              <></>
            )}
          </List>
        </Dialog>

        {/* Delete Dialog , Report Dialog*/}

        <Dialog open={this.state.openDelete} close={this.closeDialog}>
          <DialogTitle>
            {this.state.action === "report" ? (
              <p className="title_dial">
                Are you sure you want to report this Post?
              </p>
            ) : this.state.action === "delete" ? (
              <p className="title_dial">
                Are you sure you want to delete this Post?{" "}
              </p>
            ) : this.state.action === "c_delete" ? (
              <p className="title_dial">
                Are you sure you want to delete this Comment?
              </p>
            ) : (
              <p className="title_dial">
                Are you sure you want to report this Comment?
              </p>
            )}
          </DialogTitle>

          <DialogContent>
            {this.state.action === "report" ? (
              <p className="title_desc">
                Reporting this post might remove this image or user permanently
                from WallMag
              </p>
            ) : this.state.action === "delete" ? (
              <p className="title_desc">
                Once a post is deleted it cannot be revert back
              </p>
            ) : this.state.action === "c_delete" ? (
              <p className="title_desc">
                Once a comment is deleted it cannot be revert back
              </p>
            ) : (
              <p className="title_desc">
                Reporting this comment might remove this comment permanently
                from WallMag
              </p>
            )}
          </DialogContent>

          <DialogActions style={{ marginRight: "10px" }}>
            <ThemeProvider theme={theme}>
              <Button
                className="diaog_action"
                style={{ outline: "none", color: "#00000070" }}
                onClick={e => this.closeDialog()}
              >
                Cancel
              </Button>
            </ThemeProvider>

            {this.state.action === "report" ? (
              <ThemeProvider theme={theme}>
                <Button
                  className="diaog_action"
                  style={{ outline: "none", color: "#FF0000" }}
                  onClick={e => this.reportPost(e)}
                >
                  Report
                </Button>
              </ThemeProvider>
            ) : this.state.action === "delete" ? (
              <ThemeProvider theme={theme}>
                <Button
                  className="diaog_action"
                  style={{ outline: "none", color: "#FF0000" }}
                  onClick={e => this.deletePost(e)}
                >
                  Delete
                </Button>
              </ThemeProvider>
            ) : this.state.action === "c_delete" ? (
              <ThemeProvider theme={theme}>
                <Button
                  className="diaog_action"
                  style={{ outline: "none", color: "#FF0000" }}
                  onClick={e => this.deleteComment(e)}
                >
                  Delete
                </Button>
              </ThemeProvider>
            ) : (
              <ThemeProvider theme={theme}>
                <Button
                  className="diaog_action"
                  style={{ outline: "none", color: "#FF0000" }}
                  onClick={e =>
                    this.reportComment(e, true, this.state.comment_id)
                  }
                >
                  Report
                </Button>
              </ThemeProvider>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.verify_user_dialog}
          onClose={this.closeDialog}
          disableBackdropClick
          >

            <DialogTitle>
            <p className="verifyTitle" >Please verify your email address </p>
            </DialogTitle>

            <DialogContent>
            <p className="verifyContent">
            In order to perform this action you need to verify your email address 
             <b className="verifyEmail"> {JSON.parse(localStorage.getItem("current_user")) ? 
               JSON.parse(localStorage.getItem("current_user")).email : ''} </b> from the verification email sent to you.
            </p>
          </DialogContent>

          <DialogActions>
            <p className='okButton' onClick={this.closeDialog}>
              Okay
            </p>
          </DialogActions>


        </Dialog>    



  {/* Vote Score  */}
   <Dialog
                open={this.state.vote_score_dialog}
                onClose={this.closeDialog}
                disableBackdropClick
                maxWidth = 'md'
                >
            <div className='share-main-div'>

                <img src={require('../../../logo/cancel.svg')} alt='cancel' style={{position:'absolute', top:'30px', right:'30px',cursor:'pointer'}} onClick={this.closeDialog}/>
                <div className='row' style={{margin:'0px 0px 0px 10px'}}>
                    <img className='grp-icon' src={require('../../../logo/Group1348.svg')} alt='Group icon' />
                    <p className='image-vote-score'>
                      Image Vote Score - {parseFloat(this.props.postData[this.state.position].voteScore).toFixed(3)}</p>
                </div>    

                <div className='row' style={{margin:'40px 0px 0px 15px'}}>
                    <img className='pub-icon' src={require('../../../logo/audience.svg')} alt='Group icon' />
                    <p onClick={(e)=>this.publicVotes(e,this.state.postData[this.state.position].id, true)} className='public-vote-score'>
                      Public Votes - {this.props.postData[this.state.position].publicVote} Votes ({parseFloat(this.props.postData[this.state.position].publicVoteScore).toFixed(3)})
                      </p>
                </div>    
              
                <div className='row' style={{margin:'20px 0px 0px 15px'}}>
                    <img className='pub-icon' src={require('../../../logo/star(1).svg')} alt='Group icon' />
                    <p onClick={e => this.juryVotes(e,this.state.postData[this.state.position].id, true)} className='public-vote-score'>
                      Jury Votes - {this.props.postData[this.state.position].juryVote} Votes ({parseFloat(this.props.postData[this.state.position].juryVoteScore).toFixed(3)}))
                      </p>
                </div>                   


                {/* <div className='row' style={{margin:'30px 0px 0px 15px'}} onClick={() => this.setState({share_this_image : true})}>
                    <p className='share-text'><u>Share this image to get more public votes</u></p>
                </div>                    */}

                {/* {
                     ids.has(data.id) ? 
                     <div>
                     <div
                     style={{position:'relative'}}
                     >
                    <img className='like-icon' src={require('../../../logo/like.svg')} alt='Group icon' />
                     
                     </div>
            <div 
              className='row Rectangle-1481-voted' 
              style={{margin:'40px 0px 0px 15px'}} 
              onClick={e => this.vote(e,data.id, false)}
              >
                    <p className='vote-this-image-voted'>Already Voted</p>
                </div>      
                </div>      
                  :
                  <div>
                     <div
                     style={{position:'relative'}}
                     >
                    <img className='like-icon' src={require('../../../logo/liked.svg')} alt='Group icon' />
                     </div>
                  <div className='row Rectangle-1481' style={{margin:'40px 0px 0px 15px'}} onClick={e => this.vote(e,data.id, true)}>
                    
                    <p className='vote-this-image'>Vote this image</p>
                </div>      
                </div>      

  } */}
               <div className='row' style={{margin:'30px 0px 0px 15px'}}>
               {/* <img className='pub-icon pub-icon-mob' src={require('../../../logo/Group1348.svg')} alt='Group icon' /> */}
                    <p className='share-text st-2'><u>Learn more </u></p>
                </div>                
            </div>       

            </Dialog>  



  {/* Vote Score Mobile */}
    <Dialog
                open={this.state.vote_score_dialog_mob}
                onClose={this.closeDialog}
                disableBackdropClick
                fullScreen
                >
            <div className='share-main-div'>

                <img src={require('../../../logo/cancel.svg')} alt='cancel' style={{position:'absolute', top:'30px', right:'30px',cursor:'pointer'}} onClick={this.closeDialog}/>
                <div  style={{margin:'0px 0px 0px 10px', textAlign:'center'}}>
                    <img className='grp-icon' src={require('../../../logo/Group1348.svg')} alt='Group icon' />
                    <p className='image-vote-score'>Image Vote Score - {parseFloat(this.props.postData[this.state.position].voteScore).toFixed(3)}</p>
                </div>    

                <div  style={{margin:'40px 0px 0px 15px', textAlign:'center'}}>
                    <img className='pub-icon' src={require('../../../logo/audience.svg')} alt='Group icon' />
                    <p 
                      className='public-vote-score'
                      onClick ={e => this.publicVotes(e, this.state.postData[this.state.position].id, false)}
                      >
                      
                      Public Votes - {this.props.postData[this.state.position].publicVote} Votes ({parseFloat(this.props.postData[this.state.position].publicVoteScore).toFixed(3)})</p>
                </div>    

                <div  style={{margin:'20px 0px 0px 15px', textAlign:'center'}}>
                    <img className='pub-icon' src={require('../../../logo/star(1).svg')} alt='Group icon' />
                    <p 
                      className='public-vote-score'
                      onClick={e => this.juryVotes(e,this.state.postData[this.state.position].id, false)}
                    >
                      Jury Votes - {this.props.postData[this.state.position].juryVote} Votes ({parseFloat(this.props.postData[this.state.position].juryVoteScore).toFixed(3)}))</p>
                </div>                   

{/* 
                <div className='row' style={{margin:'30px 0px 0px 15px'}}  onClick={() => this.setState({share_this_image : true})}>
                    <p className='share-text'><u>Share this image to get more public votes</u></p>
                </div>                    */}


                {/* {
                     ids.has(data.id) ? 

                     <div>

            <div 
              style={{position:'relative'}}
              >
              
                    <img className='like-icon' src={require('../../../logo/like.svg')} alt='Group icon' />
                </div>      

                <div 
              className='row Rectangle-1481-voted' 
              style={{margin:'40px 0px 0px 15px'}}
              onClick={e => this.vote(e,data.id, false)}
              >
              
                    <p className='vote-this-image-voted'>Vote this image</p>
                
                
                </div>     
                </div>      

                              :
                    <div>
            <div 
              style={{position:'relative  '}}
              >
                <img className='like-icon' src={require('../../../logo/liked.svg')} alt='Group icon' />
                </div>      

                <div 
              className='row Rectangle-1481' 
              style={{margin:'40px 0px 0px 15px'}}
              onClick={e => this.vote(e,data.id, true)}
              >
                   <p className='vote-this-image'>Vote this image</p>
                </div>    
                </div>      

                } */}
               <div className='row' style={{margin:'30px 0px 0px 15px'}}>
               {/* <img className='pub-icon pub-icon-mob' src={require('../../../logo/Group1348.svg')} alt='Group icon' /> */}
                    <p className='share-text st-2'><u>Learn more </u></p>
                </div>                
            </div>       

            </Dialog>        
          



                      {/* share Popup */}
            
                      <Dialog 
                    fullWidth
                        open={this.state.share_this_image} 
                        onClose={() => this.setState({share_this_image : false})}
                        disableBackdropClick={true}>


                    <DialogTitle>
                        <div className='row image_uploaded' style={{justifyContent:'flex-end'}}>
                        
                            <img 
                            className='cancel'
                            alt='cancel'
                                src={require('../../../logo/cancel.svg')} 
                                style={{ cursor:'pointer'}} 
                                onClick={() => this.setState({share_this_image : false})}/>

                        </div>

                    <div  style={{textAlignLast:'center'}}>
                        <p id='share'><b>Share</b> your post to get <b>maximum pulic votes</b></p>
                        {/* */}
                    </div>        
                    </DialogTitle>     


                    <div style={{alignSelf:'center'}}>
                     <img className='img-fluid image_uploaded' alt='icon'  src={ this.state.postData[this.state.position]
                          .imageProperties[0].medium
                          ? this.state.postData[this.state.position]
                              .imageProperties[0].medium
                          : this.state.postData[this.state.position]
                              .imageProperties[0].originalUrl} style={{ width: '400px' }} />
                    </div>

                    <div  style={{textAlignLast:'center', padding:'10px'}}>
                        
                        <p className='share_'>
                          Your 20% winning depends upon public & community votes
                        </p>
                    </div>

                    {
                        window.innerWidth > 900 ?
                        <div onClick={e=>this.clickOnFB()} className='row icons_dad' style={{alignSelf:'center', padding:'10px', justifyContent:'center'}}>
                        
                        <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' title={data.title} url={'https://wallmag.io/post/'+data.slug}>
                            <WhatsappIcon className='icons' round size='40px' />
                        </WhatsappShareButton>

                        <FacebookShareButton     style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={'https://wallmag.io/post/'+data.slug}>
                                <FacebookIcon  className='icons' round size='40px'/>
                        </FacebookShareButton> 
                      

                                                  
                        <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug}>
                            <LinkedinIcon className='icons' round size='40px' />
                        </LinkedinShareButton>
 
                                                      
                        <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug} title={data.title}>
                            <TwitterIcon className='icons' round size='40px' />
                        </TwitterShareButton>

                        </div>
    

                        :
                    
                    <div  onClick={e=>this.clickOnFB()} className='row icons_dad' style={{alignSelf:'center', padding:'10px', justifyContent:'center'}}>

                  
                                                  <WhatsappShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' title={data.title} url={'https://wallmag.io/post/'+data.slug}>
                                                      <WhatsappIcon className='icons' round size='30px' />
                                                  </WhatsappShareButton>

                                               <FacebookShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' quote='' url={'https://wallmag.io/post/'+data.slug}>
                                                      <FacebookIcon className='icons' round size='30px'/>
                                                  </FacebookShareButton> 


                                                  <LinkedinShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug}>
                                                      <LinkedinIcon className='icons' round size='30px' />
                                                  </LinkedinShareButton>
                                              
                                               
                                                  <TwitterShareButton  style={{outline:'none', cursor:'pointer'}} className='sc' url={'https://wallmag.io/post/'+data.slug} title={data.title}>
                                                      <TwitterIcon className='icons' round size='30px' />
                                                  </TwitterShareButton>
                    </div>

        }


                    <div  onClick={e=>this.clickOnCopyToClip()}  style={{ marginBottom:'40px', textAlignLast:'center'}}>
                    <CopyToClipboard text={'https://wallmag.io/post/'+data.slug}
                        onCopy={() => cogoToast.info('Post link has been copied')}>
                        <p className='share_' style={{cursor:'pointer'}}>
                                            <u>Copy share link</u>
                                        </p>
                        </CopyToClipboard>
                       
                    </div>

                    </Dialog>

      </>
    );
  }
}
