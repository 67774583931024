import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import NavLink from "react-router-dom/NavLink";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import "../../Styles/Mob_Header.css";
import firebase from "../Login/firebaseConfig";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default class MobHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      theposition: 0,
      heading: this.props.heading,
      sub_heading: this.props.sub_heading,
      url: this.props.url,
      userName: this.props.user,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      heading: props.heading,
      sub_heading: props.sub_heading,
    });
    this.forceUpdate();
  }

  saveLoc = () => {
    localStorage.setItem("loc", window.location.href);
  };
  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  // logout
  logOut = (e) => {
    e.preventDefault();

    firebase.auth().signOut();
    localStorage.clear();
  };

  // Listening Scroll Position

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    this.setState({
      theposition: scrolled,
    });
  };

  render() {
    const toggleDrawer = (side, open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      this.setState({ ...this.state, [side]: open });
    };

    const fullList = (side) => (
      <div
        className={useStyles.fullList}
        role="presentation"
        onClick={toggleDrawer(side, false)}
        onKeyDown={toggleDrawer(side, false)}
      >
        {localStorage.current_user ? (
          <>
            {/* <img  
                                  height='30px'
                                  width='30px'
                                  src={(JSON.parse(localStorage.getItem('current_user'))).photoUrl} 
                                  style ={{borderRadius:'80px' , cursor:'pointer', objectFit:'cover'}} 
                                  onClick ={e=>this.handle_menu(e)}
                                  /> */}

            <NavLink
              style={{ textDecoration: "none" }}
              to={`/${JSON.parse(localStorage.getItem("current_user")).slug}`}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <p color="default" className="wall_web_links">
                    My Profile
                  </p>
                </div>
              </div>
            </NavLink>

            <NavLink style={{ textDecoration: "none" }} to="/edit-profile">
              <div className="container">
                <div className="row justify-content-center">
                  <p color="default" className="wall_web_links">
                    {" "}
                    Edit-profile{" "}
                  </p>
                </div>
              </div>
            </NavLink>

            <NavLink
              style={{ textDecoration: "none" }}
              onClick={(e) => this.logOut(e)}
              to="/login"
            >
              <div className="container">
                <div className="row justify-content-center">
                  <p color="default" className="wall_web_links">
                    {" "}
                    Logout{" "}
                  </p>
                </div>
              </div>
            </NavLink>
          </>
        ) : (
          <NavLink
            style={{ textDecoration: "none" }}
            onClick={this.saveLoc}
            to="/login"
          >
            <div className="container">
              <div className="row justify-content-center">
                <p color="default" className="wall_web_links">
                  {" "}
                  Login{" "}
                </p>
              </div>
            </div>
          </NavLink>
        )}
      </div>
    );

    return (
      <div
        className="wall_web_small"
        //     style ={this.state.url==='' ?
        //     {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
        //                              transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
        //                              , url(${require('../../logo/home_top.jpg')})`,
        //                                 height:'360px' , width:'100%' ,backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat'  }

        //     :
        //     {backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
        //                              transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
        //                              , url(${require('../../logo/home_top.jpg')})`,
        //                                 height:'360px' , width:'100%' , backgroundSize:'cover' , backgroundPosition:'center center' , backgroundRepeat : 'no-repeat',

        // }}
      >
        {this.state.theposition < 0.000001 ? (
          <AppBar style={{ background: "transparent", boxShadow: "none", marginRight: "10px" }}>
            <Toolbar>
              <div className="container-fluid justify-content-start">
                <div className="row justify-content-start">
                  <NavLink to="/">
                    <img
                      src={require("../../logo/wallmag_white.png")}
                      alt="Logo"
                      height="20px"
                    />
                  </NavLink>
                </div>
              </div>

              <div className="justify-content-end">
                <MenuIcon
                  variant="permanent"
                  color="inherit"
                  onClick={toggleDrawer("top", true)}
                />

                <Drawer
                  anchor="top"
                  open={this.state.top}
                  onClose={toggleDrawer("top", false)}
                >
                  {fullList("top")}
                </Drawer>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          <AppBar
            style={{ background: "black", borderBottom: "1px solid #ffffff36" }}
          >
            <Toolbar>
              <div className="container-fluid justify-content-start">
                <div className="row justify-content-start">
                  <NavLink to="/">
                    <img
                      src={require("../../logo/wallmag_white.png")}
                      alt="Logo"
                      height="20px"
                    />
                  </NavLink>
                </div>
              </div>

              <div className="justify-content-end">
                <MenuIcon
                  variant="permanent"
                  color="inherit"
                  onClick={toggleDrawer("top", true)}
                />

                <Drawer
                  anchor="top"
                  open={this.state.top}
                  onClose={toggleDrawer("top", false)}
                >
                  {fullList("top")}
                </Drawer>
              </div>
            </Toolbar>
          </AppBar>
        )}
        {/* <div className="container-fluid mob_header_div">
          <div className="row ">
            <div
              className="col-1 col-sm-1 col-md-2"
              style={{ marginTop: "20px" }}
            ></div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-12 col-xl-12 mob_heading">
              <div className="row justify-content-center ">
                <p id="mob_header_heading"> {this.state.heading} </p>
              </div>
            </div>
            <div className="col-1 col-sm-1 col-md-2"></div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-12 col-xl-12 mob_sub_heading">
              <div className="row justify-content-center ">
                <p style={{ width: "260px" }} id="mob_header_sub_heading">
                  {" "}
                  {this.state.sub_heading}{" "}
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
