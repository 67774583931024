import React from "react";
import ProfilePageHeader from "../Header/ProfilePageHeader";
import "../../../Styles/Profile.css";
import ProfilePageHeaderMob from "../Header/ProfilePageHeaderMob";
import axios from "axios";
import {
  get_my_post,
  is_like,
  startup_call,
  get_user_by_slug,
  follow_user,
  get_other_user_post
} from "../../common/AppConstants";
import FooterWallmag from "../../Footer/Footer";
import Dialog from "@material-ui/core/Dialog";
import { CircularProgress, Button, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Img from "react-image";
import cogoToast from "cogo-toast";
import firebase from "../../Login/firebaseConfig";
import StackGrid from "react-stack-grid";
import ReactGA from "react-ga";
import NotFound from "../../NotFound/NotFound";
import Helmet from 'react-helmet';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NavLink from 'react-router-dom/NavLink';
import PostViewer from "../PostViewer/PostViewer";

var user_exist = true
var position = 0;
var post_id = 0;
var dim = [];
var userPresent = false;

var data = [];
var ids = new Set();
var followingIds = new Set();

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      im_url: "",
      data: "",
      dialogOpen: false,
      slug: this.props.match.params.userId,
      otherUserData: "",
      otherUserPost: "",
      loading: true,
      hasMore: false,
      nextPageToken: "",
      post_url: "",
      ren: false,
      no_image: false,
      uploadDialog : false,
      anchor : null,
      verify_user_dialog : false,
      page_load : false,
      // theposition: window.pageYOffset
    };
    if (this.props.location.pathname.includes("issue1")) {
      this.props.history.push("/photobook/issue1");
      // window.location.reload()
    }

    if (this.props.location.pathname.includes("@")) {
    } else {
      if (this.props.location.pathname.includes("/post/")) {
        this.props.history.replace(this.props.location.pathname.concat("@"));
        window.location.reload();
      }
    }

    ReactGA.initialize("UA-117934873-2", {
      gaOptions: {
        userId: JSON.parse(localStorage.getItem("current_user"))
          ? JSON.parse(localStorage.getItem("current_user")).displayName +
            "_" +
            JSON.parse(localStorage.getItem("current_user")).id
          : "Guest_User"
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.performance) {
      if (performance.navigation.type == 1) {
        //   "This page is reloaded"

        if (JSON.parse(localStorage.getItem("current_user"))) {
          axios
            .get(startup_call)
            .then(response => {
              // ////console.log(response)
              localStorage.setItem(
                "likedPostIds",
                JSON.stringify(response.data.likedPostIds)
              );
              localStorage.setItem(
                "myPostIds",
                JSON.stringify(response.data.myPostIds)
              );
              localStorage.setItem(
                "followingIds",
                JSON.stringify(response.data.followingIds)
              );
              localStorage.setItem(
                "followersIds",
                JSON.stringify(response.data.followersIds)
              );
              localStorage.setItem(
                "bookmarkedPostIds",
                JSON.stringify(response.data.bookmarkedPostIds)
              );
              localStorage.setItem(
                "reportedPostIds",
                JSON.stringify(response.data.reportedPostIds)
              );
            })
            .catch(error => {
              // ////console.log(error);
            });
        } else {
          var headers = {
            Authorization:
              "Bearer " + new Date().getTime() + "_GpyTJy7zgV5qaGaBH1VF"
          };

          axios
            .get(startup_call, { headers: headers })
            .then(response => {
              // ////console.log(response)
              localStorage.setItem(
                "likedPostIds",
                JSON.stringify(response.data.likedPostIds)
              );
              localStorage.setItem(
                "myPostIds",
                JSON.stringify(response.data.myPostIds)
              );
              localStorage.setItem(
                "followingIds",
                JSON.stringify(response.data.followingIds)
              );
              localStorage.setItem(
                "followersIds",
                JSON.stringify(response.data.followersIds)
              );
              localStorage.setItem(
                "bookmarkedPostIds",
                JSON.stringify(response.data.bookmarkedPostIds)
              );
              localStorage.setItem(
                "reportedPostIds",
                JSON.stringify(response.data.reportedPostIds)
              );
            })
            .catch(error => {
              // ////console.log(error);
            });
        }
      } else {
        // ////console.log("This page is not reloaded");
      }
    }
  }

     // Menu
     handle_menu =(event)=>{
      this.setState({
        anchor : event.currentTarget
      })
    }

    // Menu Close
    handleClose=(e)=>{

      e.preventDefault()

      this.setState({
        anchor : null
      })
    }

  // Right Click on an Image
  clickRight = e => {
    e.preventDefault();
  };

  // Drag a photo
  onDragPhoto = e => {
    e.preventDefault();
  };

  // image Loading effect
  imageLoading = () => {
    this.setState({
      loading: false
    });
    if (document.getElementById("pre")) {
      document.getElementById("pre").remove();
    }
  };

  onBackButtonEvent = e => {
    e.preventDefault();
    this.setState({
      dialogOpen: false
    });
    this.goBack();
  };

  //  2) browser back button event

  onBackButtonEvent = e => {
    e.preventDefault();
    this.setState({
      dialogOpen: false
    });
  };
  componentWillUnmount = () => {
    localStorage.removeItem("other_user");
  };

  componentDidMount = () => {
    window.scroll(0, 0);

    localStorage.setItem('loc',window.location.href)

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        localStorage.setItem("user", user.ra);
      }
    });

    //  1) browser back button event
    window.onpopstate = this.onBackButtonEvent;

    followingIds = new Set(JSON.parse(localStorage.getItem("followingIds")));

    if (JSON.parse(localStorage.getItem("current_user"))) {
      user_exist = true
      this.startUpsetUp();

      if (
        this.state.slug ===
        JSON.parse(localStorage.getItem("current_user")).slug
      ) {
        this.clickTimeout = null;

        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer '+localStorage.user
        // }

        axios
          .get(get_my_post, {
            params: {
              limit: 12
            }
          })
          .then(res => {
            ////console.log(res);

            if (res.data.items) {
              data = res.data.items;

              this.setState({
                data: res.data.items,
                nextPageToken: res.data.nextPageToken,
                hasMore: true,
                page_load : true,
              });

              localStorage.setItem(
                "short_user",
                JSON.stringify(res.data.items[0].shortUserObject)
              );

              this.setState({
                ren: true
              });

              this.forceUpdate();
            } else {
              this.setState({
                hasmore: false,
                no_image: true,
                page_load : true,
              });
              data = res.data;
            }
          })
          .catch(error => {
            // ////console.log(error)
            // if(error.response.status===401){
            //     cogoToast.error('Something went wrong!')
            //     firebase.auth().signOut()
            //     localStorage.clear()
            //      return <Redirect to='/login' />
            // }
          });
      } else {
        axios
          .get(get_user_by_slug, {
            params: {
              slug: this.state.slug
            }
          })
          .then(res => {
            userPresent = true;
            this.setState({
              otherUserData: res.data,
              page_load : true
            });
            user_exist = true

            this.forceUpdate()

            // get Other user posts
            this.otherUserPost();
          })
          .catch(error => {
            // ////console.log(error.response)
            if (error.response.status === 404) {
              user_exist = false
              this.forceUpdate()
            }
          });
      }
    } else {
      var headers = {
        Authorization:
          "Bearer " + new Date().getTime() + "_GpyTJy7zgV5qaGaBH1VF"
      };

      axios
        .get(
          get_user_by_slug,
          {
            params: {
              slug: this.state.slug
            }
          },
          { headers }
        )
        .then(res => {
          // ////console.log(res)

          userPresent = true;
          user_exist = true;

          this.setState({
            otherUserData: res.data,
            page_load : true,
          });
          this.forceUpdate()
          // get Other user posts
          this.otherUserPost();
        })
        .catch(error => {
          // ////console.log(error.response)
          if (error.response.status === 404) {
            user_exist = false
            this.forceUpdate()
          }
        });
    }
  };

  // Login First
  loginFirst = e => {
    e.preventDefault();
    localStorage.setItem('loc',window.location.href)

    window.location.assign("/login");
  };

  // loadMore
  loadmore = e => {
    this.setState({
      // theposition: window.pageYOffset
    });

    axios
      .get(get_my_post, {
        params: {
          limit: 12,
          cursor: this.state.nextPageToken
        }
      })
      .then(res => {
        // ////console.log(res)
        if (!res.data.items) {
          this.setState({
            hasMore: false
          });
          cogoToast.warn("No More Images");
        } else {
          data = data.concat(res.data.items);

          this.forceUpdate();

          this.setState({
            data: res.data.items,
            nextPageToken: res.data.nextPageToken
          });

          localStorage.setItem(
            "short_user",
            JSON.stringify(res.data.items[0].shortUserObject)
          );
        }
      })
      .catch(error => {
        // ////console.log(error)
        // if(error.response.status===401){
        //     cogoToast.error('Something went wrong!')
        //     firebase.auth().signOut()
        //     localStorage.clear()
        //      return <Redirect to='/login' />
        // }
      });
  };

  // Load More For Other Users
  loadmoreOther = e => {
    axios
      .get(get_other_user_post, {
        params: {
          userId: this.state.otherUserData.id,
          cursor: this.state.nextPageToken,
          limit: 12
        }
      })
      .then(response => {
        if (response.data.items) {
          data = data.concat(response.data.items);
          this.setState({
            otherUserPost: response.data.items,
            nextPageToken: response.data.nextPageToken
          });
          localStorage.setItem(
            "short_user",
            JSON.stringify(response.data.items[0].shortUserObject)
          );

          this.forceUpdate();
        } else {
          this.setState({
            hasMore: false
          });
        }
      });
  };

  // other user post
  otherUserPost = () => {
    // // ////console.log(this.state.otherUserData.slug)
    localStorage.setItem(
      "other_user",
      JSON.stringify(this.state.otherUserData)
    );

    this.setState({ hasMore: true });

    axios
      .get(get_other_user_post, {
        params: {
          userId: this.state.otherUserData.id,
          limit: 12
        }
      })
      .then(response => {
        // ////console.log(response.data)
        if (response.data.items) {
          // ////console.log(response)
          data = response.data.items;
          this.setState({
            otherUserPost: response.data.items,
            nextPageToken: response.data.nextPageToken
          });
          localStorage.setItem(
            "short_user",
            JSON.stringify(response.data.items[0].shortUserObject)
          );
        } else {
          this.setState({
            hasMore: false,
            no_image: true,
            otherUserPost: "nothing"
          });
        }
      });
  };

  // startUp setup

  startUpsetUp = () => {
    ids = new Set(JSON.parse(localStorage.getItem("likedPostIds")));
  };

  // follow user

  followUser = (e, userId, isFollow) => {
    e.preventDefault();
    axios
      .put(follow_user, {
        isFollow: isFollow,
        userId: userId
      })
      .then(response => {
        if (isFollow === true) {
          followingIds.add(userId);
        } else {
          followingIds.delete(userId);
        }
        localStorage.setItem(
          "followingIds",
          JSON.stringify(Array.from(followingIds))
        );

        this.forceUpdate();

        axios
          .get(get_user_by_slug, {
            params: {
              slug: this.state.slug
            }
          })
          .then(res => {
            this.setState({
              otherUserData: res.data,
              page_load : true
            });
            user_exist = true


            this.forceUpdate();
          })
          .catch(error => {
            if (error.response.status === 404) {
              user_exist = false
              this.forceUpdate()
            }
          });
      })
      .catch(error => {
        // ////console.log(error)
      });
  };

  // Select Image
  pick_image = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      let reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result });
        this.setState({ im_url: image, file_props_count: 0 });

        // ////console.log(this.state.image)
        // ////console.log(this.state.im_url)
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // handle clicks
  handleClicks = (like, id, image, slug, post_data, position) => {
    if (JSON.parse(localStorage.getItem("current_user"))) {
      post_id = id;
      // position = position

      localStorage.setItem(
        "short_user",
        JSON.stringify(post_data.shortUserObject)
      );
      localStorage.setItem("imageUrl", image);
      localStorage.setItem("post_data", JSON.stringify(post_data));
      localStorage.setItem("post_id", id);
      // ////console.log(this.props);
      // this.props.history.push((this.props.match.url).replace(`/${this.props.match.params.userId}` , `/post/${slug}`));

      window.history.pushState("", "", "/post/" + slug);

      this.setState({
        post_url: slug,
        dialogOpen: true,
        postData: data,
        pos: position
      });
    } else {
      // window.location.assign('/post/'+slug)
      post_id = id;
      // position = position

      localStorage.setItem(
        "short_user",
        JSON.stringify(post_data.shortUserObject)
      );
      localStorage.setItem("imageUrl", image);
      localStorage.setItem("post_data", JSON.stringify(post_data));
      localStorage.setItem("post_id", id);
      // this.props.history.push((this.props.match.url).replace(`/${this.props.match.params.userId}` , `/post/${slug}`));
      window.history.pushState("", "", "/post/" + slug);

      this.setState({
        post_url: slug,
        dialogOpen: true,
        postData: data,
        pos: position
      });
    }
  };

  //    post Dialog
  handlePostDialog = () => {
    this.setState({
      dialogOpen: false
    });
  };




  sendCode=()=>{
    firebase.auth().currentUser.sendEmailVerification()

    cogoToast.success('Verification Code ha been Send !!!')
  }


  //    Vote Image

  vote = (e, post_id, like, indexNo) => {
    e.preventDefault();
    firebase.auth().onAuthStateChanged((user)=>{

      if(user.emailVerified === false){
        var actionCodeSettings = {
          url: 'https://wallmag.io/',
        };
        firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
          .then(function() {
            // Verification email sent.
          })
          .catch(function(error) {
            // Error occurred. Inspect error.code.
          });
        
        this.setState({
          verify_user_dialog : true
        })

      }else{
        localStorage.setItem("user_ver", 'true');

    if (like === true) {
      data[indexNo].likeCount = parseInt(data[indexNo].likeCount) + 1;
      ids.add(post_id);
    } else {
      data[indexNo].likeCount = parseInt(data[indexNo].likeCount) - 1;
      ids.delete(post_id);
    }

    localStorage.setItem("likedPostIds", JSON.stringify(Array.from(ids)));
    this.forceUpdate();

    // const headers = {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer '+localStorage.user
    //     }

    const params = {
      postId: post_id,
      isLike: like
    };

    axios
      .put(is_like, params)
      .then(response => {})
      .catch(error => {
        if (like === true) {
          data[indexNo].likeCount = parseInt(data[indexNo].likeCount) - 1;
          ids.delete(post_id);
        } else {
          data[indexNo].likeCount = parseInt(data[indexNo].likeCount) + 1;
          ids.add(post_id);
        }

        localStorage.setItem("likedPostIds", JSON.stringify(Array.from(ids)));
        this.forceUpdate();
      });
    }}
    
    )
  };

  //View Image
  viewImage = (e, pos) => {
    e.preventDefault();
    // ////console.log('single',pos)
  };

  // dialog close
  handleClose = () => {
    window.history.back();
    this.setState({
      dialogOpen: false,
      uploadDialog : false
    });
  };

  // Menu For Upload
  handleClose2 = () => {
    this.setState({
      dialogOpen: false,
      uploadDialog : false,
      anchor : false
    });
  };


  uploadDialogClose = () => {
    this.setState({
      dialogOpen: false,
      uploadDialog : false,
      verify_user_dialog : false
    });
  };

  // back
  back = () => {
    this.props.history.push("/profile");

    this.setState({
      dialogOpen: false
    });
  };

  render() {
    // window.scrollTo(0, this.state.theposition)
  
    return (
      user_exist === false
      ?
<>
<NotFound/>
</>
:

!this.state.page_load ? 
<div style={{position:'relative', height:`${window.innerHeight}px`}}>
   <CircularProgress style={{position:'absolute', top:'50%',left:'47%'}} />
</div>
:
      <>
        {JSON.parse(localStorage.getItem("current_user")) ? (
          <div style={{ background: "white" }}>
            {/* Header For Web */}
            {this.state.slug ===
            JSON.parse(localStorage.getItem("current_user")).slug ? (
              <>
                <div className="web_profile_header">
                  <ProfilePageHeader data="" />
                </div>

                {/* Header for Mobile */}

                <div className="mob_profile_header">
                  <ProfilePageHeaderMob data="" />
                </div>
              </>
            ) : this.state.otherUserData ? (
              <>
                <div className="web_profile_header">
                  <ProfilePageHeader data={this.state.otherUserData} />
                </div>

                {/*   Header for Mobile  */}
                <div className="mob_profile_header">
                  <ProfilePageHeaderMob data={this.state.otherUserData} />
                </div>
              </>
            ) : (
              <></>
            )}
            {this.state.slug ===
            JSON.parse(localStorage.getItem("current_user")).slug ? (
              <></>
            ) : (
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-4 col-lg-4 col-xl-4"></div>

                  <div
                    className="col-md-4 col-lg-4 col-xl-4"
                    style={{ position: "absolute", top: "510px" }}
                  >
                    <div className="row justify-content-center">
                      {new Set(
                        JSON.parse(localStorage.getItem("followingIds"))
                      ).has(this.state.otherUserData.id) ? (
                        userPresent === true ? (
                          <div
                            className=" follow_button"
                            onClick={e =>
                              this.followUser(
                                e,
                                this.state.otherUserData.id,
                                false
                              )
                            }
                          >
                            <p id="follow-text">Followed</p>
                          </div>
                        ) : (
                          <></>
                        )
                      ) : userPresent === true ? (
                        <div
                          className=" follow_button"
                          onClick={e =>
                            this.followUser(
                              e,
                              this.state.otherUserData.id,
                              true
                            )
                          }
                        >
                          <p id="follow-text">Follow</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xl-4"></div>
                </div>
              </div>
            )}
            {this.state.otherUserData === "" ? (
              <>
                {JSON.parse(localStorage.getItem("current_user")).title ===
                  "" ||
                !JSON.parse(localStorage.getItem("current_user")).title ? (
                  <></>
                ) : (
                  <>
                    <br></br> <br></br> <br></br> <br></br>
                    <p className="headings">About Me</p>
                    {/*------------ About Me Section -----------*/}
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-xl-4 col-lg-3 col-md-3"></div>

                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <p className="about">
                            {
                              JSON.parse(localStorage.getItem("current_user"))
                                .title
                            }
                          </p>
                        </div>

                        <div className="col-xl-4 col-lg-3 col-md-3"></div>
                      </div>
                    </div>
                  </>
                )}

                {/*----------- About Me Ends Here---------- */}
              </>
            ) : (
              <>
                {this.state.otherUserPost.title === "" ||
                !this.state.otherUserPost.title ? (
                  <> </>
                ) : (
                  <>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    <p className="headings">
                      About{" "}
                      {this.state.otherUserData.displayName.slice(
                        0,
                        this.state.otherUserData.displayName.indexOf(" ")
                      )}
                    </p>
                    {/*------------ About Me Section -----------*/}

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-xl-4 col-lg-3 col-md-3"></div>

                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <p className="about">
                            {this.state.otherUserData.title}
                          </p>
                        </div>

                        <div className="col-xl-4 col-lg-3 col-md-3"></div>
                      </div>
                    </div>

                    {/*----------- About Me Ends Here---------- */}
                  </>
                )}
              </>
            )}
            <br></br> <br></br> <br></br>
            {this.state.otherUserPost === "" ? (
              <div>
                {data.length ? (
                  <>
                   
                    <button
                      style={{ outline: "none" }}
                      className="learnmorebtn"
                      // onClick={e => this.props.history.push("/upload")}
                      onClick ={e=>this.handle_menu(e)}

                    >
                      {" "}
                      Upload A Photo
                    </button>

                    <Menu
                                    onBackdropClick = {e=>this.handleClose2(e)}
                                    style={{marginTop:'40px'}}
                                    id="simple-menu"
                                    anchorEl={this.state.anchor}
                                    keepMounted
                                    open={Boolean(this.state.anchor)}
                                    onClose={e=>this.handleClose2(e)}
                                    >
                                      
                                  <NavLink to={`/upload`}  style={{textDecoration:'none'}}>
                                  <MenuItem>
                                      Upload To Profile
                                  </MenuItem>
                                  </NavLink>


                                  <NavLink to={'/missions'}  style={{textDecoration:'none'}}> 
                                  <MenuItem>
                                        Upload in Awards & Contests
                                  </MenuItem>
                                  </NavLink>                                


                                  </Menu>
                    <p style={{marginTop:'60px'}} className="headings">My Photography</p>
                  </>
                ) : (
                  <></>
                )}

                <div style={{ marginTop: "80px" }}>
                  {data.length ? (
                    <div style={{ justifyContent: "center" }}>
                      <StackGrid
                        columnWidth={
                          window.innerWidth > 767
                            ? (window.innerWidth * 25) / 100
                            : (window.innerWidth * 45) / 100
                        }
                      >
                        {data.map((value, i) => {
                          dim.push({
                            width: (window.innerWidth * 33) / 100,
                            height:
                              value.imageProperties[0].originalHeight === 0
                                ? (500 * ((window.innerWidth * 33) / 100)) / 500
                                : (value.imageProperties[0].originalHeight *
                                    ((window.innerWidth * 33) / 100)) /
                                  value.imageProperties[0].originalWidth
                          });

                          return (
                            <div id="outer-box">
                              <div id="inner-box" className="container">
                                {!ids.has(value.id) ? (
                                  <div
                                    className="row vote_button"
                                    onClick={e =>
                                      this.vote(e, value.id, true, i)
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "30px",
                                      cursor: "pointer"
                                    }}
                                  >
                                    <img
                                      id="like"
                                      src={require("../../../logo/like.svg")}
                                      alt="liked"
                                      style={{
                                        marginTop: "07px",
                                        height: "18px"
                                      }}
                                    />

                                    <p id="like-post">
                                       {
                                       value.shortMissionObject ? 
                                        value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                        'Like' : 'Vote'
                                        :
                                        'Like' 
                                      }
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    className="row vote_button2"
                                    onClick={e =>
                                      this.vote(e, value.id, false, i)
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "30px",
                                      cursor: "pointer"
                                    }}
                                  >
                                    <img
                                      id="like"
                                      src={require("../../../logo/liked.svg")}
                                      alt="liked"
                                      style={{
                                        marginTop: "07px",
                                        height: "18px"
                                      }}
                                    />

                                    <p id="liked-post">
                                    {
                                       value.shortMissionObject ? 
                                        value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                        'Liked' : 'Voted'
                                        :
                                        'Liked' 
                                      }
                                       </p>
                                  </div>
                                )}
                              </div>

                              {!ids.has(value.id) ? (
                                <Img
                                  className="img-raised"
                                  alt="Post"
                                  onDragStart={e => this.onDragPhoto(e)}
                                  onDrag={e => this.onDragPhoto(e)}
                                  onContextMenu={e => this.clickRight(e)}
                                  src={[
                                    value.imageProperties[0].medium
                                      ? value.imageProperties[0].medium
                                      : value.imageProperties[0].originalUrl
                                  ]}
                                  loader={
                                    <img
                                      alt="post"
                                      src={
                                        "https://placehold.it/" +
                                        `${parseInt(
                                          (window.innerWidth * 33) / 100
                                        )}` +
                                        "x" +
                                        parseInt(
                                          (value.imageProperties[0]
                                            .originalHeight *
                                            ((window.innerWidth * 33) / 100)) /
                                            value.imageProperties[0]
                                              .originalWidth
                                        ) +
                                        "/00000090/00000090"
                                      }
                                      style={{
                                        padding: "5px",
                                        borderRadius: "8px",
                                        background: `#00000000`,
                                        cursor: "pointer"
                                      }}
                                    />
                                  }
                                  decode={false}
                                  onLoad={e => this.imageLoading()}
                                  style={{
                                    padding: "5px",
                                    borderRadius: "8px",
                                    background: `#00000000`,
                                    cursor: "pointer"
                                  }}
                                  onClick={evt =>
                                    this.handleClicks(
                                      this.state.like,
                                      value.id,
                                      value.imageProperties[0].medium
                                        ? value.imageProperties[0].medium
                                        : value.imageProperties[0].originalUrl,
                                      value.slug,
                                      value,
                                      i
                                    )
                                  }
                                />
                              ) : (
                                <>
                                  <div
                                    className="row vote_button3"
                                    onClick={e =>
                                      this.vote(e, value.id, false, i)
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "30px",
                                      cursor: "pointer"
                                    }}
                                  >
                                    <img
                                      id="like"
                                      src={require("../../../logo/liked.svg")}
                                      alt="liked"
                                      style={{
                                        marginTop: "07px",
                                        height: "18px"
                                      }}
                                    />
                                  </div>

                                  <img
                                    className="img-raised"
                                    onDragStart={e => this.onDragPhoto(e)}
                                    onDrag={e => this.onDragPhoto(e)}
                                    onContextMenu={e => this.clickRight(e)}
                                    src={
                                      value.imageProperties[0].medium
                                        ? value.imageProperties[0].medium
                                        : value.imageProperties[0].originalUrl
                                    }
                                    alt="Post"
                                    onLoad={e => this.imageLoading()}
                                    style={{
                                      padding: "5px",
                                      borderRadius: "8px",
                                      background: `#00000000`,
                                      cursor: "pointer"
                                    }}
                                    onClick={evt =>
                                      this.handleClicks(
                                        this.state.like,
                                        value.id,
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0]
                                              .originalUrl,
                                        value.slug,
                                        value,
                                        i
                                      )
                                    }
                                  />
                                </>
                              )}
                            </div>
                          );
                        })}
                      </StackGrid>
                    </div>
                  ) : this.state.no_image === false ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <>
                      <div className="row" style={{ justifyContent: "center" }}>
                        <img
                          style={{ height: "80px", width: "80px" }}
                          src={require("../../../logo/frame.svg")}
                          alt="walllmag"
                        />
                      </div>
                      <p className="headings2">No Photos yet </p>
                      <p className="sub_heading">
                        Upload your best photographs to gather votes and get it
                        ranked. Have fun!
                      </p>

                      <div style={{ marginTop: "60px" }}>
                        <button
                          style={{ outline: "none" }}
                          className="learnmorebtn"
                          // onClick={e => this.props.history.push("/upload")}
                          onClick ={e=>this.handle_menu(e)}
                        >
                          {" "}
                          Upload A Photo
                        </button>


                        <Menu
                                    onBackdropClick = {e=>this.handleClose2(e)}
                                    style={{marginTop:'40px'}}
                                    id="simple-menu"
                                    anchorEl={this.state.anchor}
                                    keepMounted
                                    open={Boolean(this.state.anchor)}
                                    onClose={e=>this.handleClose2(e)}
                                    >
                                      
                                  <NavLink to={`/upload`}  style={{textDecoration:'none'}}>
                                  <MenuItem>
                                      Upload To Profile
                                  </MenuItem>
                                  </NavLink>


                                  <NavLink to={'/missions'}  style={{textDecoration:'none'}}> 
                                  <MenuItem>
                                        Upload in Awards & Contests
                                  </MenuItem>
                                  </NavLink>                                


                                  </Menu>

                      </div>
                    </>
                  )}

                  {this.state.hasMore ? (
                    <div style={{ marginTop: "60px" }}>
                      <button
                        style={{ outline: "none" }}
                        className="learnmorebtn"
                        onClick={() => {
                          this.loadmore();
                        }}
                      >
                        {" "}
                        LOAD MORE
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div style={{ marginTop: "80px" }}>
                  {data.length ? (
                    <div style={{ justifyContent: "center" }}>
                      <p className="headings" style={{ marginBottom: "80px" }}>
                        {this.state.otherUserData.displayName.slice(
                          0,
                          this.state.otherUserData.displayName.indexOf(" ")
                        )}
                        's Photography
                      </p>
                      <StackGrid
                        columnWidth={
                          window.innerWidth > 767
                            ? (window.innerWidth * 25) / 100
                            : (window.innerWidth * 45) / 100
                        }
                      >
                        {data.map((value, i) => {
                          if(value.imageProperties){
                          dim.push({
                            width: (window.innerWidth * 33) / 100,
                            height:
                              value.imageProperties[0].originalHeight === 0
                                ? (500 * ((window.innerWidth * 33) / 100)) / 500
                                : (value.imageProperties[0].originalHeight *
                                    ((window.innerWidth * 33) / 100)) /
                                  value.imageProperties[0].originalWidth
                          });

                          return (
                            <>
                              <div id="outer-box">
                                <div id="inner-box" className="container">
                                  {!ids.has(value.id) ? (
                                    <div
                                      className="row vote_button"
                                      onClick={e =>
                                        this.vote(e, value.id, true, i)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../../logo/like.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                      <p id="like-post">
                                      {
                                       value.shortMissionObject ? 
                                        value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                        'Like' : 'Vote'
                                        :
                                        'Like' 
                                      }
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="row vote_button2"
                                      onClick={e =>
                                        this.vote(e, value.id, false, i)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../../logo/liked.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                      <p id="liked-post">
                                      {
                                       value.shortMissionObject ? 
                                        value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                        'Liked' : 'Voted'
                                        :
                                        'Liked' 
                                      }
                                      </p>
                                    </div>
                                  )}
                                </div>

                                {!ids.has(value.id) ? (
                                  <Img
                                    className="img-raised"
                                    alt="Post"
                                    onDragStart={e => this.onDragPhoto(e)}
                                    onDrag={e => this.onDragPhoto(e)}
                                    onContextMenu={e => this.clickRight(e)}
                                    src={[
                                      value.imageProperties[0].medium
                                        ? value.imageProperties[0].medium
                                        : value.imageProperties[0].originalUrl
                                    ]}
                                    loader={
                                      <img
                                        alt="post"
                                        src={
                                          "https://placehold.it/" +
                                          `${parseInt(
                                            (window.innerWidth * 33) / 100
                                          )}` +
                                          "x" +
                                          parseInt(
                                            (value.imageProperties[0]
                                              .originalHeight *
                                              ((window.innerWidth * 33) /
                                                100)) /
                                              value.imageProperties[0]
                                                .originalWidth
                                          ) +
                                          "/00000090/00000090"
                                        }
                                        style={{
                                          padding: "5px",
                                          borderRadius: "8px",
                                          background: `#00000000`,
                                          cursor: "pointer"
                                        }}
                                      />
                                    }
                                    decode={false}
                                    onLoad={e => this.imageLoading()}
                                    style={{
                                      padding: "5px",
                                      borderRadius: "8px",
                                      background: `#00000000`,
                                      cursor: "pointer"
                                    }}
                                    onClick={evt =>
                                      this.handleClicks(
                                        this.state.like,
                                        value.id,
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0]
                                              .originalUrl,
                                        value.slug,
                                        value,
                                        i
                                      )
                                    }
                                  />
                                ) : (
                                  <>
                                    <div
                                      className="row vote_button3"
                                      onClick={e =>
                                        this.vote(e, value.id, false, i)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../../logo/liked.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                    </div>

                                    <img
                                      className="img-raised"
                                      onDragStart={e => this.onDragPhoto(e)}
                                      onDrag={e => this.onDragPhoto(e)}
                                      onContextMenu={e => this.clickRight(e)}
                                      src={
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0].originalUrl
                                      }
                                      alt="Post"
                                      onLoad={e => this.imageLoading()}
                                      style={{
                                        padding: "5px",
                                        borderRadius: "8px",
                                        background: `#00000000`,
                                        cursor: "pointer"
                                      }}
                                      onClick={evt =>
                                        this.handleClicks(
                                          this.state.like,
                                          value.id,
                                          value.imageProperties[0].medium
                                            ? value.imageProperties[0].medium
                                            : value.imageProperties[0]
                                                .originalUrl,
                                          value.slug,
                                          value,
                                          i
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          );
  }})}
                      </StackGrid>
                    </div>
                  ) : this.state.no_image === false ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <>
                      <p className="headings2">No Photos yet</p>
                    </>
                  )}

                  {this.state.hasMore ? (
                    <div style={{ marginTop: "60px" }}>
                      <button
                        style={{ outline: "none" }}
                        className="learnmorebtn"
                        onClick={() => {
                          this.loadmoreOther();
                        }}
                      >
                        {" "}
                        LOAD MORE
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
            <div   style={{ marginTop: "130px" }}>
              <FooterWallmag />
            </div>
           
          </div>
        ) : (
          <div style={{ background: "white" }}>
            {this.state.otherUserData === "" ? (
              <></>
            ) : (
              <>
                <div className="web_profile_header">
                  <ProfilePageHeader data={this.state.otherUserData} />
                </div>
                {/*   Header for Mobile  */}
                <div className="mob_profile_header">
                  <ProfilePageHeaderMob data={this.state.otherUserData} />
                </div>
                {/* ----------------- */}
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-4 col-lg-4 col-xl-4"></div>

                    <div
                      className="col-md-4 col-lg-4 col-xl-4"
                      style={{ position: "absolute", top: "510px" }}
                    >
                      <div className="row justify-content-center">
                        <div
                          className=" follow_button"
                          onClick={e => this.loginFirst(e)}
                        >
                          <p id="follow-text">Follow</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-xl-4"></div>
                  </div>
                </div>
                {/* ------------------- */}
                {this.state.otherUserData.title ? (
                  this.state.otherUserData.title === "" ? (
                    <></>
                  ) : (
                    <>
                      <br></br> <br></br> <br></br> <br></br>
                      <p className="headings">
                        About{" "}
                        {this.state.otherUserData.displayName.slice(
                          0,
                          this.state.otherUserData.displayName.indexOf(" ")
                        )}
                      </p>
                      {/*------------ About Me Section -----------*/}
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-xl-4 col-lg-3 col-md-3"></div>

                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <p className="about">
                              {this.state.otherUserData.title}
                            </p>
                          </div>

                          <div className="col-xl-4 col-lg-3 col-md-3"></div>
                        </div>
                      </div>
                      {/*----------- About Me Ends Here---------- */}
                    </>
                  )
                ) : (
                  <></>
                )}
                <br></br> <br></br> <br></br>
                <div style={{ marginTop: "80px" }}>
                  {data.length ? (
                    <div style={{ justifyContent: "center" }}>
                      <p className="headings" style={{ marginBottom: "80px" }}>
                        {this.state.otherUserData.displayName.slice(
                          0,
                          this.state.otherUserData.displayName.indexOf(" ")
                        )}
                        's Photography
                      </p>
                      <StackGrid
                        columnWidth={
                          window.innerWidth > 767
                            ? (window.innerWidth * 25) / 100
                            : (window.innerWidth * 45) / 100
                        }
                      >
                        {data.map((value, i) => {
                          if(value.imageProperties){
                          dim.push({
                            width: (window.innerWidth * 33) / 100,
                            height:
                              value.imageProperties[0].originalHeight === 0
                                ? (500 * ((window.innerWidth * 33) / 100)) / 500
                                : (value.imageProperties[0].originalHeight *
                                    ((window.innerWidth * 33) / 100)) /
                                  value.imageProperties[0].originalWidth
                          });

                          return (
                            <>
                              <div id="outer-box">
                                <div id="inner-box" className="container">
                                  {!ids.has(value.id) ? (
                                    <div
                                      className="row vote_button"
                                      onClick={e => this.loginFirst(e)}
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../../logo/like.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                      <p id="like-post">
                                      {
                                       value.shortMissionObject ? 
                                        value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                        'Like' : 'Vote'
                                        :
                                        'Like' 
                                      }
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="row vote_button2"
                                      onClick={e => this.loginFirst(e)}
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../../logo/liked.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                      <p id="liked-post">
                                      {
                                       value.shortMissionObject ? 
                                        value.shortMissionObject.currentTime > value.shortMissionObject.finaleTime ? 
                                        'Liked' : 'Voted'
                                        :
                                        'Liked' 
                                      }
                                      </p>
                                    </div>
                                  )}
                                </div>

                                {!ids.has(value.id) ? (
                                  <Img
                                    className="img-raised"
                                    alt="Post"
                                    onDragStart={e => this.onDragPhoto(e)}
                                    onDrag={e => this.onDragPhoto(e)}
                                    onContextMenu={e => this.clickRight(e)}
                                    src={[
                                      value.imageProperties[0].medium
                                        ? value.imageProperties[0].medium
                                        : value.imageProperties[0].originalUrl
                                    ]}
                                    loader={
                                      <img
                                        alt="post"
                                        src={
                                          "https://placehold.it/" +
                                          `${parseInt(
                                            (window.innerWidth * 33) / 100
                                          )}` +
                                          "x" +
                                          parseInt(
                                            (value.imageProperties[0]
                                              .originalHeight *
                                              ((window.innerWidth * 33) /
                                                100)) /
                                              value.imageProperties[0]
                                                .originalWidth
                                          ) +
                                          "/00000090/00000090"
                                        }
                                        style={{
                                          padding: "5px",
                                          borderRadius: "8px",
                                          background: `#00000000`,
                                          cursor: "pointer"
                                        }}
                                      />
                                    }
                                    decode={false}
                                    onLoad={e => this.imageLoading()}
                                    style={{
                                      padding: "5px",
                                      borderRadius: "8px",
                                      background: `#00000000`,
                                      cursor: "pointer"
                                    }}
                                    onClick={evt =>
                                      this.handleClicks(
                                        this.state.like,
                                        value.id,
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0]
                                              .originalUrl,
                                        value.slug,
                                        value,
                                        i
                                      )
                                    }
                                  />
                                ) : (
                                  <>
                                    <div
                                      className="row vote_button3"
                                      onClick={e =>
                                        this.vote(e, value.id, false, i)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "15px",
                                        left: "30px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <img
                                        id="like"
                                        src={require("../../../logo/liked.svg")}
                                        alt="liked"
                                        style={{
                                          marginTop: "07px",
                                          height: "18px"
                                        }}
                                      />
                                    </div>

                                    <img
                                      className="img-raised"
                                      onDragStart={e => this.onDragPhoto(e)}
                                      onDrag={e => this.onDragPhoto(e)}
                                      onContextMenu={e => this.clickRight(e)}
                                      src={
                                        value.imageProperties[0].medium
                                          ? value.imageProperties[0].medium
                                          : value.imageProperties[0].originalUrl
                                      }
                                      alt="Post"
                                      onLoad={e => this.imageLoading()}
                                      style={{
                                        padding: "5px",
                                        borderRadius: "8px",
                                        background: `#00000000`,
                                        cursor: "pointer"
                                      }}
                                      onClick={evt =>
                                        this.handleClicks(
                                          this.state.like,
                                          value.imageProperties[0].medium
                                            ? value.imageProperties[0].medium
                                            : value.imageProperties[0]
                                                .originalUrl,
                                          value.slug,
                                          value,
                                          i
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          );
                        }})}
                      </StackGrid>
                    </div>
                  ) : this.state.no_image === false ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <>
                      <p className="headings2">No Photos yet</p>
                    </>
                  )}

                  {this.state.hasMore ? (
                    <div style={{ marginTop: "60px" }}>
                      <button
                        style={{ outline: "none" }}
                        className="learnmorebtn"
                        onClick={() => {
                          this.loadmoreOther();
                        }}
                      >
                        {" "}
                        LOAD MORE
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}

            <div style={{ marginTop: "130px" }}>
              <FooterWallmag />
            </div>
          </div>
        )}

        {window.outerWidth < 768 ? (
          <Dialog
            className="dial"
            open={this.state.dialogOpen}
            onClose={this.handleClose}
            fullScreen
            PaperProps={{
              style: {
                backgroundColor: "black",
                boxShadow: "none"
              }
            }}
          >
            <div
              className="row"
              style={{
                justifyContent: "flex-end",
                position: "sticky",
                top: "10px"
              }}
            >
              <img
                alt="close"
                style={{
                  height: "20px",
                  width: "20px",
                  marginTop: "10px",
                  marginRight: "40px",
                  cursor: "pointer"
                }}
                src={require("../../../logo/close.svg")}
                onClick={this.handleClose}
              />
            </div>
            <div style={{ background: "black" }}>
              <PostViewer
                props={this.props}
                url={this.state.post_url}
                id={post_id}
                position={this.state.pos}
                postData={this.state.postData}
              />
            </div>
          </Dialog>
        ) : (
          <Dialog
            className="dial"
            open={this.state.dialogOpen}
            onClose={this.handleClose}
            fullScreen
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none"
              }
            }}
          >
            <div
              className="row"
              style={{
                justifyContent: "flex-end",
                position: "sticky",
                top: "10px"
              }}
            >
              <img
                alt="close"
                style={{
                  height: "20px",
                  width: "20px",
                  marginTop: "10px",
                  marginRight: "40px",
                  cursor: "pointer"
                }}
                src={require("../../../logo/close.svg")}
                onClick={this.handleClose}
              />
            </div>
            <div
              style={{
                background: "black",
                marginLeft: `${window.outerWidth <1200 ? '50px':'200px'}`,
                marginRight: `${window.outerWidth <1200 ? '50px':'200px'}`,
              }}
            >
              <PostViewer
                props={this.props}
                url={this.state.post_url}
                id={post_id}
                position={this.state.pos}
                postData={this.state.postData}
              />
            </div>
          </Dialog>
        )}


        <Dialog
          open={this.state.verify_user_dialog}
          onClose={this.uploadDialogClose}
          disableBackdropClick
          >

            <DialogTitle>
            <p className="verifyTitle" >Please verify your email address </p>
            </DialogTitle>

            <DialogContent>
            <p className="verifyContent">
            In order to perform this action you need to verify your email address 
             <b className="verifyEmail"> {JSON.parse(localStorage.getItem("current_user")) ? 
               JSON.parse(localStorage.getItem("current_user")).email : ''} </b> from the verification email sent to you.
            </p>
          </DialogContent>

          <DialogActions>
            <p className='okButton' onClick={this.uploadDialogClose}>
              Okay
            </p>
          </DialogActions>


        </Dialog>    

        <Dialog
          open={this.state.uploadDialog}
          onClose={this.uploadDialogClose}
          disableBackdropClick
          fullWidth
          >
            <div style={{position:'relative', overflow:'hidden'}}>
              
              <img 
                style={{position:'absolute', top:'10px', right:'10px', cursor : 'pointer', opacity:'0.8'}} 
                src={require('../../../logo/cancel.svg')} 
                alt='cancel' 
                onClick={() => this.setState({uploadDialog : false})}
                
                />
              
              <div style={{marginTop:'50px', padding:'5px'}}>
                <div className='row'>
                  <img className='dial-img' src={require('../../../logo/cam.svg')} alt='' />
                <p className="upDialText" onClick={e => this.props.history.push("/upload")}>Upload To Profile</p>
                </div>
                
                <div className='row' style={{marginTop:'10px'}}>
                <img className='dial-img' src={require('../../../logo/trophy.svg')} alt=''/>
                <p className="upDialText" onClick={e => this.props.history.push("/")}>Upload in Awards & Contests</p>
              </div>
              </div>
              
            </div>
            

        </Dialog>
      </>
    );
  }
}

export default ProfilePage;
