import { ALL_POST } from './../types'
// import axios from 'axios'
import { Wallmag_All,wallmag_rem_post } from './AllWallmagPostsAction';


// const AllPostApi = 'https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getFeedsByEntityId?entityId=wallpapers&limit=9';

export const All_Post = (data)=>{
  return {
    type: ALL_POST,
    data,
  }
}

export const all_posts  = (data) => {
    return (dispatch) => {
      dispatch(All_Post(data))
      dispatch(Wallmag_All(data))


        // return axios.get(AllPostApi )
        //   .then(response => {
        //     dispatch(All_Post (response.data));
        //     dispatch(Wallmag_All(response.data))
        //   })
        //   .catch(error => {
        //     console.log(error.message);
        //   });
      };
  };

  export const rem_post = (data) =>{
    return (dispatch) =>{
      dispatch(All_Post(data))
      dispatch(wallmag_rem_post(data))
    }
  }