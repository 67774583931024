import React from 'react';
import MobHeader from "../IssuePages/Header/Mob_Header";
import Header from "../IssuePages/Header/Header";
import Axios from 'axios';
import { get_mission_by_slug } from '../common/AppConstants';
import { CircularProgress } from '@material-ui/core';


var mapObj = {
    '<p':`<p style="color:white;"`,
    '<h1':`<h1 style="color:white;"`,
    '<h2':`<h2 style="color:white;"`,
    '<h3':`<h3 style="color:white;"`,
    '<h4':`<h4 style="color:white;"`,
    '<h5':`<h5 style="color:white;"`,
    '<h6':`<h6 style="color:white;"`,
    '<strong':`<strong style="color:white;"`,
    '<span style="color: rgb(0, 0, 0);':`<span style="color:white;"`,
  };

var prizeText = ''

var mission_data = [];
var pageRender = false;

class Ranking extends React.Component{

    constructor(props){
        super(props)
        
        this.state = {
            mission_tag : props.match.params.missionTag
        }
    }


    componentDidMount(){
        
        this.getMissionDetails()
    }


    
    // Getting Current Mision Details 

       getMissionDetails = () => {
           Axios.get(get_mission_by_slug+this.state.mission_tag)
           .then(res => {
                mission_data = res.data;
                pageRender = true;

                this.forceUpdate()
           })
           .catch( error => {

           })
       }

    render(){
        return(
            <>
                {
                    pageRender === false ? 
                        <CircularProgress color='secondary' />

                    :

                    window.innerWidth < 768 ? (

                        // Mob Header
                        
                        <MobHeader
                            url={mission_data.coverUrl}
                            heading='Ranking Board'
                            sub_heading={mission_data.title}
                            logo={mission_data.headerLogo}
                            share = {true}
                        />

                    ) : (

                        // Web Header
                        
                        <Header
                            url={mission_data.coverUrl}
                            heading='Ranking Board'
                            sub_heading={mission_data.title}
                            logo={mission_data.headerLogo}
                            share = {true}
                        />

                    )
                }

                {/* Body Part Starts Here */}



                {/* Body Part Ends Here */}
            </>
            
        )
    }
}

export default Ranking;