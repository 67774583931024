import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import {composeWithDevTools } from 'redux-devtools-extension';
import HomeReducer from './reducer/HomeReducer';
import StoryReducer from './reducer/StoryReducer'
import AllPostReducer from './reducer/AllPostReducer'
import CatrgoryPostReducer from "./reducer/CategoryPostReducer";
import AllWallmagPostReducer from './reducer/AllWallmagPostsReducer'
import RelatedFeedsReducer from './reducer/RelatedFeedsReducer'


const rootReducer = combineReducers({
home:HomeReducer,
story_cat : StoryReducer,
all_post : AllPostReducer,
Category_Posts : CatrgoryPostReducer,
wallmag_all_posts : AllWallmagPostReducer,
rel_feeds_data : RelatedFeedsReducer,
});


//  let composeEnhancers = compose;
// if (process.env.NODE_ENV === 'development') {
//   composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// }

const configureStore = () => {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  // return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk,logger)));
};

export default configureStore;