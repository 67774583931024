/* eslint-disable */
import React from "react";
import HomeTop from "./HomeTop/HomeTop";
import ListOfPost from "./ListOfPost/ListOfPost";
import { home_top } from "./../../Store/action/HomeAction";
import { connect } from "react-redux";
import { loadProgressBar } from "axios-progress-bar";
import "../../../node_modules/axios-progress-bar/dist/nprogress.css";
import { story_category } from "./../../Store/action/StoryAction";
import HomeMobHeader from "../Header/Home_Mob_Header";
import "../../Styles/Home.css";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import Home_Header from "../Header/Home_Header";
import Contests from "./Contests";
import Axios from "axios";
import { get_all_active_missions, startUp_call_web } from "../common/AppConstants";

var load = false;
var metatags_names = [];
var metatags_props = [];
var data = [];
var activeMissions = [];

class Home extends React.Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem("other_user_slug")) {
      var user_slug = localStorage.getItem("other_user_slug");
      localStorage.removeItem("other_user_slug");
      this.props.history.push("/" + user_slug);
    }
    this.container = React.createRef();
    this.state = {
      data: {},
      load: false,
      scrollableHeader: false,
      fixedHeader: false,
      show_loader: true,
      progress: 0,
      dialog_login: false,
      meta: false,
      home: false,
    };
    ReactGA.initialize("UA-117934873-2", {
      gaOptions: {
        userId: JSON.parse(localStorage.getItem("current_user"))
          ? JSON.parse(localStorage.getItem("current_user")).displayName +
            "_" +
            JSON.parse(localStorage.getItem("current_user")).id
          : "Guest_User",
      },
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    localStorage.setItem("page", true);
  }

  componentDidMount() {
    // if (this.props.load) {
    // } else {
    // }
    this.getAllMissions();
    this.getHomePage();
    load = true;
  }

  // Get all active missions
  getAllMissions = async () => {
    await Axios.get(get_all_active_missions)
      .then((res) => {
        activeMissions = res.data;
        this.forceUpdate();
      })
      .catch((error) => {
        
      });
  };

  // Home Page All Components
  getHomePage = async () => {
   const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.user,
    };

    await Axios.get(
      startUp_call_web,
      {
        headers: headers,
      }
    )
      .then((res) => {
        data = res.data;
        console.log("data from home", data);
        this.setState({
          home: true,
        });
        metatags_names = Object.entries(res.data.metaTags.names);
        metatags_props = Object.entries(res.data.metaTags.properties);
        this.setState({
          home: true,
        });
      })
      .catch((error) => {
        console.log("Home error", error)
      });
  };

  // Login Dialog open
  openLogin = (e) => {
    e.preventDefault();
    this.setState({
      dialog_login: true,
    });
  };

  // Login Dialog close
  closeLogin = (e) => {
    e.preventDefault();
    this.setState({
      dialog_login: false,
    });
  };

  // go To Mission
  goToMission = (e) => {
    e.preventDefault();
    this.props.history.push("/missions/weddingraphy2019");
  };

  render() {
    return (
      (
        <div style={{ background: "white" }}>
          {this.state.home === true ? (
            <Helmet>
              {metatags_names.map((res) => {
                return <meta name={res[0]} content={res[1]} />;
              })}

              {metatags_names.map((res) => {
                return res[0] === "title" ? <title>{res[1]}</title> : "";
              })}

              {metatags_props.map((res) => {
                return <meta property={res[0]} content={res[1]} />;
              })}
            </Helmet>
          ) : (
            <></>
          )}
          {/* ------------------------------------------------------------------------------------------- */}
          {/* ---------------------------------- Progress Bar ------------------------------------------- */}
          {loadProgressBar()}
          <div>
            {/* ----------------------------------------------------------------------------------------- */}
            {/* ---------------------------------- Header ----------------------------------------------- */}
            <div className="mob_top">
              <HomeMobHeader />
            </div>
            <div className="web_top">
              <Home_Header />
            </div>
            {/* ------------------------------------------------------------------------------------------ */}
            {/* ---------------------------------- Awards ------------------------------------------------ */}
            <div>
              {activeMissions.items && data.missions ? (
                <>
                  <div className="container rs_div">
                    <div className="row justify-content-center">
                      <p id="featuredstories2">Photography Awards</p>
                    </div>
                  </div>
                  <Contests data={activeMissions} />
                </>
              ) : (
                ""
              )}
              {load === true ? (
                <div>
                  {/* -------------------------------------------------------------------------------------- */}
                  {/* ----------------------------- List of Posts (Magazine) ------------------------------- */}
                  {data.stories ? (
                    <div>
                      <ListOfPost props={this.props} data={data} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    story_category_data: state.story_cat.story_category,
    load: state.story_cat.load,

    // this is  for condition of load before another compo
    home_top_load: state.home.home_top_load,
  };
};
export default connect(mapStateToProps, { home_top, story_category })(Home);
