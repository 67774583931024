/* eslint-disable */
import React from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import NavLink from 'react-router-dom/NavLink'
import "./../../../Styles/ListOfPost.css";
import { Chips } from "../../chips/Chips";
import {connect} from "react-redux"
import {all_posts, rem_post} from '../../../Store/action/AllPostAction'
import {wallmag_all, wallmag_rem_post} from '../../../Store/action/AllWallmagPostsAction'
import {story_category} from "../../../Store/action/StoryAction";
import Download from "../../Download/Download";
import FooterWallmag from "../../Footer/Footer";
import { Button, Card } from "@material-ui/core";



class ListOfPost extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      data: this.props.props,
      catData: this.props.data.categories,
      listData : this.props.data.stories,
      load: false,
      cursor: "",
      index: 0,
      width: window.innerWidth,
      cursorToken: ""
    };

    //console.log(this.state.catData)

  }
  

 

  componentDidMount() {
    
    if(this.props.all_post_load){

    }
    else{this.props.all_posts(this.state.listData)}
    
    if(this.props.load){}
    else{
      this.props.story_category(this.state.catData)
    }

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    
  }

  



  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  get_more_feed = token => {
    this.setState({
      load : true
    })
    return axios
      .get(
        `https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getFeedsByEntityId?entityId=wallpapers&limit=9&&cursor=${this.props.token}`
      )
      .then(response => {
        
              this.props.rem_post(response.data)
           
      })
      .catch(error => {
        // //console.log(error.message);
      });
      
  };
 

  render() { 

   

    let child_flex_css = this.state.width >650  && this.state.width <1100 ? true : false
    
    return (

      <>
        {
          this.props.wall_load === true ?


        <div id='main_div'>

                    <div className='container rs_div'>
                      <div className='row justify-content-center'>
                         <p id='featuredstories'>Magazine</p>
                      </div>
                    </div>
            
          
        
        <Grid  container className="layouthomepage"  >
        <div id='main_div' className="parent_flex">
          {this.props.all_post_load
            ?  this.props.all_post_data.map((vale , i) => {
              //  return vale.items.map((val,i )=> { //child_flex
                  return (
                      <div  className={`${child_flex_css ?  vale.length%2 !==0 && vale.length-1 === i ? "last_child_flex" : "child_flex": "child_flex" } `}>
             
                 

                      <ul className="image-grid">
                        <li className="image-grid__item">
                          <NavLink to={`story/${vale.seoUrl}`} className="grid-item">
                            <div
                              className="grid-item__image"
                              style={{
                                background: `linear-gradient( to top , rgba(0,0,0,1),rgba(0,0,0,0), transparent), url(${vale.fileProperty && // ch
                                  vale.fileProperty
                                    .publicUrl}) center center no-repeat`,
                                backgroundSize: "cover" 
                              }}
                            />
                           
                          </NavLink>
                          <div className="container toppost_bottoms">
                          {/* <NavLink to={`story/${vale.seoUrl}`} style={{textDecoration:'none'}}> */}
                            <div className="row justify-content-center align-content-end toppost_bottoms_text">
                              {" "}
                              <NavLink to={`story/${vale.seoUrl}`} style={{textDecoration:'none'}}>
                              <p className="post_topic">{vale.title}</p> {/*j*/}
                              </NavLink>
                            </div>
                            
                            {/* </NavLink> */}
                             {this.props.load &&
                              this.props.story_category_data.items.map(valee =>
                                vale.linkedEntityIds.map((valle, i) =>
                                  valee.id === valle ? (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 14,
                                        borderRadius: '5px'
                                      }}
                                    >
                                       <Chips title={valee.name} color={valee.color} />{" "} 
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )
                              )} 
                          </div>
                        </li>
                      </ul>
                      
                    </div>
                  );
               
              })
            : ""}           
            
             </div>
         
          <Grid item xs={12} sm={12} lg={12} md={12}
                style={{ textAlign: "center" }} >
              <button
                style={{outline:'none'}}
                className="loadmorebtn"
                onClick={() => {
                  this.get_more_feed(this.state.cursorToken);
                }}
              >
              {" "}
              LOAD MORE
            </button>
          </Grid>
        </Grid>



  {/* For Photographers  */}
 {/* <div className='container' style={{ marginTop:'128px'}}>
            <div className='row' style={{background:'black',textAlignLast:'center', marginLeft:'0px', marginRight:'0px'}}>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <p className='for_photographers'>
                  For Photographers
                </p>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <p className='tagline'>
                  Supporting you through out your career
                </p>
              </div>



           
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

              <div style={{marginTop:`${window.innerWidth<768 ? '20px' : '60px'}`, textAlignLast:'left'}} className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                <Card onClick={e=> this.props.props.history.push('/missions')} style={{background:'#272727', cursor:'pointer'}}>
                <div className='row' style={{justifyContent:'space-between'}}>
                  <p className='photographers_heading'>
                      Photography Awards                    
                  </p>
                  <img
                     className='image-ahead'
                     src={require('../../../logo/ahead.png')} 
                     alt=''/>
                  </div>
                  <p  className='photographers_tagline'>
                     Apply to industry biggest photography recognition                
                  </p>
                </Card>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>
               

              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

              <div style={{marginTop:'37px', textAlignLast:'left'}} className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                <Card onClick={e=> this.props.props.history.push('/missions')}  style={{background:'#272727', cursor:'pointer'}}>
                <div className='row' style={{justifyContent:'space-between'}}>
                  <p className='photographers_heading'>
                    Earn Through Photo Contest                   
                  </p>
                  <img
                     className='image-ahead'
                     src={require('../../../logo/ahead.png')} 
                     alt=''/>
                  </div>

                  <p  className='photographers_tagline'>
                    Participate in brand sponsored photo contests               
                  </p>
                </Card>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

               
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

                <div style={{marginTop:'37px', textAlignLast:'left'}} className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                  <Card style={{background:'#272727', cursor:'pointer'}}>
                  <div className='row' style={{justifyContent:'space-between'}}>
                  <p className='photographers_heading'>
                      Get hired for Photoshoots                 
                  </p>
                  <img
                     className='image-ahead'
                     src={require('../../../logo/ahead.png')} 
                     alt=''/>
                  </div>

                    <p  className='photographers_tagline'>
                        Connect with global brands for photo and video needs
                    </p>
                  </Card>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

              
              
                <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

              <div style={{marginTop:'37px',marginBottom:'75px', textAlignLast:'left'}} className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                <Card onClick={e=> this.props.props.history.push('/sell-images')}  style={{background:'#272727', cursor:'pointer'}}>
                  <div className='row' style={{justifyContent:'space-between'}}>
                  <p className='photographers_heading'>
                      Sell Photos             
                  </p>
                  <img
                     className='image-ahead'
                     src={require('../../../logo/ahead.png')} 
                     alt=''/>
                  </div>

                  <p  className='photographers_tagline'>
                      Sell Images securely through Marketplace               
                  </p>
                </Card>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>


             

            </div>  
          </div>  




 

<div className='container' style={{ marginTop:'95px'}}>
            <div className='row'  style={{background:'#0f0039',textAlignLast:'center', marginLeft:'0px', marginRight:'0px'}}>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <p className='for_photographers'>
                  For Brands
                </p>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <p className='tagline'>
                  We work with top talent to create new campaigns and projects
                </p>
              </div>



          
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

              <div style={{marginTop:`${window.innerWidth<768 ? '20px' : '60px'}`, textAlignLast:'left'}} className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                <Card style={{background:'#34206a', cursor:'pointer'}}>
                <div className='row' style={{justifyContent:'space-between'}}>
                  <p className='photographers_heading'>
                    Licence Beautiful Photography                   
                  </p>
                  <img
                     className='image-ahead'
                     src={require('../../../logo/ahead.png')} 
                     alt=''/>
                  </div>
                  <p  className='photographers_tagline'>
                      Photos which suits your brand              
                  </p>
                </Card>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>
           

              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

              <div style={{marginTop:'37px', textAlignLast:'left'}} className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                <Card onClick={e=> this.props.props.history.push('/book-photoshoot')} style={{background:'#34206a', cursor:'pointer'}}>
                <div className='row' style={{justifyContent:'space-between'}}>
                  <p className='photographers_heading'>
                      Book Photoshoot 
                  </p>
                  <img
                     className='image-ahead'
                     src={require('../../../logo/ahead.png')} 
                     alt=''/>
                  </div>

                  <p  className='photographers_tagline'>
                      Produce professional photo and video with our talent             
                  </p>
                </Card>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

                
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

                <div style={{marginTop:'37px', marginBottom:'75px', textAlignLast:'left'}} className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                  <Card style={{background:'#34206a', cursor:'pointer'}}>
                  <div className='row' style={{justifyContent:'space-between'}}>
                  <p className='photographers_heading'>
                    Promote your brand                 
                  </p>
                  <img
                     
                     className='image-ahead'
                     src={require('../../../logo/ahead.png')} 
                     alt=''/>
                  </div>

                    <p  className='photographers_tagline'>
                      promote your brand with photography contest
                    </p>
                  </Card>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'> </div>

               

            </div>  
          </div>  
  */}







        {/* Download From AppStore & Playstore */}
        <div style={{marginBottom:'100px'}}>
            <Download />
         </div>

 {/* Wedding Photography Awards */}
 
{/* Wedding Photography Awards */}
 


  {/* WildLife Photography */}
              <div
                    className='container-fluid'
                    style={{
                    backgroundImage:`linear-gradient( to top , rgba(0,0,0,.5),
                    transparent),linear-gradient( to bottom , rgba(0,0,0,.5), transparent)
                    , url(${require('../../../logo/quality.jpg')})`,
                        height:'580px' , width:'100%' , backgroundSize:'cover' , 
                        backgroundPosition:'center center' , 
                        backgroundRepeat : 'no-repeat' ,
                        display:'none'
                }} >

                    <div className='row'>

                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 wl_div'>
                                <p className='wildlife'>#WildLife Photography</p>
                       </div>



                       <div className='col-xl-3 col-lg-2'></div>


                       <div className='col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12'>
                            <p className='top_20'>
                              Top 20 winners of Wildlife photography mission will get mentorship under 
                              top wildlife photographers, a place in our magazine and unbeatable 
                              experience sponsored by WallMag.
                            </p>
                        </div>

                        <div className='col-xl-3 col-lg-2 col-md-12'></div>


                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <button className="loadmorebtn">
                               Coming Soon
                            </button>
                        </div>
                    </div>
                        



                    </div>




{/* Footer of the Website */}

        <div  style={{marginTop:'-48px'}}>
          <FooterWallmag />
        </div>

        </div>

            :
        
        <></>
      }
      
      </>
    
          
    
    
      );
  }
}

const mapStateToProps = state => {
  return {
    all_post_data: state.all_post.all_post_data,
    all_post_load : state.all_post.all_post_load,
    token: state.all_post.token,
    story_category_data: state.story_cat.story_category,
    load : state.story_cat.load,
    wall_data: state.wallmag_all_posts.wallmag_post_data,
    wall_load:state.wallmag_all_posts.wallmag_post_load ,
  };
};
export default connect(mapStateToProps,{all_posts,rem_post,story_category,wallmag_all,wallmag_rem_post})(ListOfPost);
