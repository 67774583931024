import React from 'react'
import Header from '../Header/Header'
import '../../Styles/Certification.css'
import { TextField} from '@material-ui/core';
import Axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button } from '@material-ui/core';
import MobHeader from '../Header/Mob_Header';
import firebase from '../Login/firebaseConfig'
import FooterWallmag from '../Footer/Footer';



var api = 'https://wallmag-2.appspot.com/_ah/api/growMagApi/v1/growMag/'
export default class Certification extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            v_code : '' , 
            open_s : false,
            open_f : false ,
            name: '',
            email: '',
            message: '',
            position: '',
            duration: '',
            phoneNumber: '',
            certification : ''
        }

    }

    change = (e)=>{
        this.setState({
          [e.target.name] : e.target.value,
      
        })
    }

    // verifying GrowMag Certification

    get_verification(){
        firebase.auth().onAuthStateChanged( function(user){
            if(user){
                localStorage.setItem(
                    'user' , user.ra
                )
            }})
        
            Axios.get(api +this.state.v_code) 
                 .then(response =>{

                     this.setState({
                         open_s : true ,
                         certification : 'Certificate Verified' ,
                         name : response.data.name , 
                         email : response.data.email ,
                         message : response.data.message ,
                         position : response.data.position ,
                         duration : response.data.duration ,
                         phoneNumber : response.data.phoneNumber ,

                        })
                 }).catch(error => {
                    this.setState({
                        open_f : true ,
                        certification : 'Certificate Verification Failed'
                    })
                  })
    }


    handleClose_s = () => {
        this.setState({ 
            open_s : false,
              v_code : '' , 
            name: '',
            email: '',
            message: '',
            position: '',
            duration: '',
            phoneNumber: '',
            certification : ''

        });
      };

      handleClose_f = () => {
        this.setState({ 
            open_f : false,
              v_code : '' , 
           });
      };
    

    render(){
      
        return(

            <div style={{background:"white"}}>

                {/* less then 768 */}
                <div className='verify_small'>
                    <MobHeader url='' heading='GrowMag Certificate Verification' 
                            sub_heading='We hope you enjoyed our GrowMag internship program'    />

                    <div className='container-fluid certification_div'>
                       
                        <div className='row justify-content-center'>
                            <p id='Verify_your_digital_Certificate'> Verify your digital Certificate </p>
                        </div>

                        <hr className='underline_certificate'/>

                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 certificate_text_field'>
                     
                        <TextField
                                name='v_code'
                                value={this.state.v_code}
                                onChange={e => this.change(e)}
                                id="outlined-with-placeholder"
                                label="10 digit verification code"
                                // placeholder="10 digit verification code"
                                // fullWidth
                                style={{MaxWidth :'500px' , backgroundColor:'#f9f9f9'}}
                                // className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                />
                            </div>    

            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 button_verify'>
            <button
              className="verify"
               onClick={() => {
                 this.get_verification()
               }}
            >
              {" "}
              Verify
            </button>
            </div>

            <div className='container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 button_verify'>
                <div className='row'>
                  <div className='col-1 col-sm-1 col-md-2 col-lg-3 col-xl-3'></div>
                  <div className='col-10 col-sm-10 col-md-8 col-lg-6 col-xl-6'>
                    <p id='team_wallmag'>
                    Please contact us on team@wallmag.io. if you are not able to verify and you were part of our internship program
                    </p>
                    </div>
                    <div className='col-1 col-sm-1 col-md-2 col-lg-3 col-xl-3'></div>
                </div>
            </div>


                    </div>
                    
                    <div>
                        <FooterWallmag />
                    </div>

                </div>




                    
                    {/* greater then 767 */}


                    <div className='verify_large'>
                        <Header url='' heading='GrowMag Certificate Verification' 
                                sub_heading='We hope you enjoyed our GrowMag internship program'    />

                        <div className='container-fluid certification_div'>
                       
                        <div className='row justify-content-center'>
                            <p id='Verify_your_digital_Certificate'> Verify your digital Certificate </p>
                        </div>

                        <hr className='underline_certificate'/>

                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 certificate_text_field'>
                     
                        <TextField
                                name='v_code'
                                value={this.state.v_code}
                                onChange={e => this.change(e)}
                                id="outlined-with-placeholder"
                                label="10 digit verification code"
                                // placeholder="10 digit verification code"
                                // fullWidth
                                style={{width :'500px' , backgroundColor:'#f9f9f9'}}
                                // className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                />
                            </div>    

            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 button_verify'>
            <button
              className="verify"
               onClick={() => {
                 this.get_verification()
               }}
            >
              {" "}
              Verify
            </button>
            </div>

            <div className='container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 button_verify'>
                <div className='row'>
                  <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'></div>
                  <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                    <p id='team_wallmag'>
                    Please contact us on team@wallmag.io. if you are not able to verify and you were part of our internship program
                    </p>
                    </div>
                    <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'></div>
                </div>
            </div>


                    </div>
                    
                    <div>
                        <FooterWallmag />
                    </div>

                </div>



 {/* Dialog for certification Failed */}

    <Dialog
      disableBackdropClick
      open={this.state.open_f}
      onClose={this.handleClose_f}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">

                    <div className='container'>
                       <div className='row justify-content-center'>
                          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' >     
                                
                                    <DialogTitle> 
                                        <p id='title_certification_failed'> 
                                             {this.state.certification} 
                                        </p> 
                                    </DialogTitle>
                            
                       </div>
                       
                       <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justify-content-center'>   
                        <div className='row justify-content-center'>
                            <img src={require('../../logo/failed.svg')}  alt='icon'  height='30px' style={{marginTop:'-18px'}}/> 
                            </div>
                        </div>          
                    </div>
                </div> 
               
                    <DialogContent>
                        <DialogContentText id="name">
                                <p id='student_details'> Please Try Again</p>
                                <p id='student_details'> For queries contact team@wallmag.io</p>
                        </DialogContentText>

                        {/* <div className='container'>
                         <div className='row justify-content-center'>
                             <Avatar> 
                                 <img src={require('../../logo/failed.svg')} /> 
                            </Avatar> 
                         </div>
                        </div> */}
                    </DialogContent>
                <DialogActions>
         <Button onClick={this.handleClose_f} color="light" >
             Ok
      </Button>
    </DialogActions>
  </Dialog>



  <Dialog
      disableBackdropClick
      open={this.state.open_s}
      onClose={this.handleClose_s}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
  
  <div className='container'>
                       <div className='row justify-content-center'>
                          
                       <DialogTitle> <p id='title_certification_success'> {this.state.certification} </p> </DialogTitle>
                       { window.innerWidth < 475 ?
                            <img src={require('../../logo/success.svg')}  alt='icon'  height='40px' style={{marginTop:-18}} /> 
                                        :
                          <img src={require('../../logo/success.svg')} alt='icon'  height='40px' style={{marginTop:12}} /> 
                       } 
                    </div>
                </div> 
               
               
                    <DialogContent>
                        <DialogContentText id="name">
            
                            <p id='student_details'> <b> Name </b> :  {this.state.name} </p>
                            {/* <p id='student_details'>  Email : {this.state.email}  </p> */}
                            <p id='student_details'>  <b> Duration </b>:  {this.state.duration} </p>
                            <p id='student_details'>  <b> Position </b>: {this.state.position}  </p>
                            {/* <p id='student_details'>  Phone No. :  {this.state.phoneNumber} </p> */}
                            <p id='student_details'>   {this.state.message}  </p>
                
                        </DialogContentText>
                       
                    </DialogContent>
         
    <DialogActions>
         <Button onClick={this.handleClose_s} color="light" >
             Ok
      </Button>
    </DialogActions>
  </Dialog>





            </div>

        )
    }
}