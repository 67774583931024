import React from 'react'
import '../../Styles/Download.css'

export default class Download extends React.Component{

    render(){
        return(
            <div className='container parent_container'>
                <img id='award' src={require('../../logo/award.png')} alt='award'></img>

                <div className='row justify-content-center child_container'>
                      <a
                        href='https://play.google.com/store/apps/details?id=io.wallmag.android&hl=en_IN' 
                        target="_blank"
                        rel="noopener noreferrer"
                        > 
                         <img id='gp' src={require('../../logo/gp.png')} alt='WallMagAndroid'/>
                        </a>
                         {/* <a> 
                             <img id='appstore' src={require('../../logo/appstore.png')} alt='WallMagAndroid'/>    
                         </a>  */}
                </div>
                
                
            <div id='child2'>
                    <p id='Download-our-Award-winning-mobile-apps'>Download our Award winning mobile app</p>
            </div>
            
            
            </div>
        )
    }
}