import React from 'react';
import Countdown from "react-countdown";
import './SendOtp.css';

class Otp extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            resend : props.resend,
            sendOtp : props.sendOtp
        }
    }
    

    shouldComponentUpdate(nextProps, nextState){
        if(!nextProps.resend == nextState.resend){
            return true
        }
        return false
    }

    render(){

        const Completionist = () => <span></span>;

        // Renderer callback with condition
        const renderer = ({ seconds, completed }) => {
        if (completed) {
            // Render a complete state
            this.setState({resend : true})
            return <Completionist />;
        } else {
            // Render a countdown
            return (
            <span>
                {seconds}
            </span>
            );
        }};

        return(
                    this.state.resend === true ? 
                        <p className='resend-otp' onClick={() => this.state.sendOtp()}>
                            Resend
                        </p>
                    :
                        <p className='resend'>
                        Resend(<Countdown date={Date.now() + 2000} renderer={renderer} />)
                        </p>
        )}
}

export default Otp;