/* eslint-disable */
import React from "react"
import Grid from "@material-ui/core/Grid"
import axios from "axios"
import NavLink from 'react-router-dom/NavLink'
import "../../Styles/ListOfPost.css"
import { Chips } from "../chips/Chips"
import {connect} from "react-redux"
import {all_posts, rem_post} from '../../Store/action/AllPostAction'
import {story_category} from '../../Store/action/StoryAction'
import {category_post,rem_cat_post} from '../../Store/action/CategoryPostAction'
import {wallmag_all, wallmag_rem_post} from '../../Store/action/AllWallmagPostsAction'
import Category from './Category'
import Download from '../Download/Download'
import FooterWallmag from '../Footer/Footer'
import Header from "../Header/Header"
import MobHeader from '../Header/Mob_Header'
import { loadProgressBar } from 'axios-progress-bar'
import ReactGA from 'react-ga';
import cogoToast from 'cogo-toast';

var load = false

class CategoryPost extends React.Component {

  constructor(props){
    super(props)
    
    this.state = {
        data: [],
        cursor: "",
        index: 0,
        width: window.innerWidth,
        cursorToken: "",
        cat_ID : this.props.match.params.categoryName ,        
      };
      
      ReactGA.initialize('UA-117934873-2', {
        gaOptions: {
          userId: JSON.parse(localStorage.getItem('current_user')) ? JSON.parse(localStorage.getItem('current_user')).displayName+'_'+JSON.parse(localStorage.getItem('current_user')).id : 'Guest_User' 
        }
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
}


  

componentWillMount() {
  this.unlisten = this.props.history.listen((location, action) => {
    window.scrollTo(0,0)
  });
}

componentWillUnmount() {
    this.unlisten();
}

componentDidMount() {

    window.scrollTo(0,0)

    if(this.props.all_post_load){}
    else{this.props.all_posts()}
    
    if(this.props.load){}
    else{this.props.story_category()}

    if(this.props.category_post_data[this.props.match.params.categoryName]){}
    else{this.props.category_post(this.props.match.params.categoryName)}

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    
    load = true
  }
  

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  get_more_feed = token => {

   

    return axios
      .get(
        `https://wallmag-2.appspot.com/_ah/api/wallMagFeedApi/v1/getFeedsByEntityIdWall?entityId=${this.state.cat_ID}&limit=9&cursor=${this.props.category_post_data[this.state.cat_ID].token}`
      )
      .then(response => {
              if(response.data.items){
                this.props.rem_cat_post(response.data, this.state.cat_ID)
              }else{
                cogoToast.warn('No More Feeds')
              }
              
      })
      .catch(error => {
        
        if(error.response.status === 404){
          this.props.history.replace('/page-not-found')
        }
      });
      
  };
 
  

  render() { 

    
    
    
   if(this.state.cat_ID === this.props.match.params.categoryName){}
   else{ this.setState({cat_ID : this.props.match.params.categoryName})
  
   if(this.props.category_post_data[this.props.match.params.categoryName]){}
   else{this.props.category_post(this.props.match.params.categoryName)} }
    
    let child_flex_css = this.state.width >650  && this.state.width <1100 ? true : false


    return (

      //  key will preserve list items where the key has not changed,
      //  this avoiding a re-render of the entire list.

        <div id='main_div' key={this.props.match.params.categoryName}>

        {loadProgressBar()}



        {this.props.category_post_data[this.props.match.params.categoryName]     
                     ?

            this.props.load &&
              this.props.story_category_data.items.map(valee =>
                this.props.category_post_data[this.props.match.params.categoryName][0].linkedEntityIds.map((valle, i) =>
                  valee.id === valle ? (
                    <div
                    >
                       <div className='mob_top_category'>
                          <MobHeader heading={valee.name} sub_heading={valee.description} url={valee.coverFileProperty.originalUrl}/>
                        </div>
                        
                <div className='web_top_category'>
                    <Header heading={valee.name} sub_heading={valee.description}  url={valee.coverFileProperty.originalUrl}/>
                </div>
                    </div>
                 
                 
                 ) : (
                    <></>
                  )
                )
              )


            :''}
                    


        <Grid  container className="layouthomepage justify-content-center">
            <div className='container-fluid topMain'>
            {this.props.category_post_data[this.props.match.params.categoryName]     
                                         ?

            this.props.load &&
              this.props.story_category_data.items.map(valee =>
                this.props.category_post_data[this.props.match.params.categoryName][0].linkedEntityIds.map((valle, i) =>
                  valee.id === valle ? (
                    <div
                    >
                
                    </div>
                  ) : (
                    <></>
                  )
                )
              )


            :''}
              
                                     
            </div>



        <div id='main_div' className="parent_flex">
         

          {this.props.category_post_data[this.props.match.params.categoryName]     
            ? this.props.category_post_data[this.props.match.params.categoryName].map((vale , i) => {
                  return (
                      <div  className={`${child_flex_css ?  vale.length%2 !==0 && vale.length-1 === i ? "last_child_flex" : "child_flex": "child_flex" } `}>

                    <ul className="image-grid">
                        <li className="image-grid__item">
                          <NavLink to={`/story/${vale.seoUrl}`} className="grid-item">
                            <div
                              className="grid-item__image"
                                  style={{
                                          background: `linear-gradient( to top , rgba(0,0,0,1),rgba(0,0,0,0), transparent), url(${vale.fileProperty && // ch
                                          vale.fileProperty
                                          .publicUrl}) center center no-repeat`,
                                           backgroundSize: "cover" 
                                        }}
                            />
                           
                          </NavLink>
                          
                          
                            <div className="container toppost_bottoms">
                            
                            <NavLink to={`/story/${vale.seoUrl}`} style={{textDecoration:'none'}}>
                            
                              <div className="row justify-content-center align-content-end toppost_bottoms_text">
                                {" "}
                                <p className="post_topic">{vale.title}</p>
                              </div>
                              
                            </NavLink>


                            {this.props.load &&
                              this.props.story_category_data.items.map(valee =>
                                vale.linkedEntityIds.map((valle, i) =>
                                  valee.id === valle ? (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 14,
                                        borderRadius: '5px'
                                      }}
                                    >
                                     {/* Title of the page */}
                                      {/* <div style={{display:'none'}}> {document.title = 'WallMag | '+valee.name} </div> */}
                                      
                                       <Chips title={valee.name} color={valee.color}/>{" "} 
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )
                              )}
                          </div>
                        </li>
                      </ul>
                      
                    </div>
                  );
               
              })
            : ""}                      </div>
</Grid>

        {this.props.all_post_load 
                       ?
                       
<div>
          <Grid
            item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              style={{ textAlign: "center" }}
           >
            
            <button
            style={{outline:'none'}}
              className="loadmorebtn"
              onClick={() => {
                this.get_more_feed(this.state.cursorToken);
              }}
            >
              {" "}
              LOAD MORE
            </button>
          </Grid>


{/* for space between loadMore button and Categories */}
        <div className='extra_space_div'>

           </div>   
      
         <div>
            <Category clr="black"/>
        </div> 
     
    <div>
            <Download />
      </div>

    <div>
        <FooterWallmag />
       
    </div>
    </div> 
    :("")
    }
      </div>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    all_post_data: state.all_post.all_post_data,
    all_post_load : state.all_post.all_post_load,
    token: state.all_post.token,
    story_category_data: state.story_cat.story_category,
    load : state.story_cat.load,
    category_post_data : state.Category_Posts,
    wall_data: state.wallmag_all_posts.wallmag_post_data,
    wall_load:state.wallmag_all_posts.wallmag_post_load ,

  };
};


export default connect(mapStateToProps,
                            {all_posts,rem_post, story_category, category_post 
                              , rem_cat_post, wallmag_all, wallmag_rem_post})(CategoryPost);