import React from 'react'
import Header from '../IssuePages/Header/Header'
import './../../Styles/Awards/HomeScreen.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterWallmag from '../Footer/Footer';
import MobHeader from '../IssuePages/Header/Mob_Header';



class HomeScreen extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            key : 'home',
            first : true,
            second : false,
            third : false
        }
    }

    render(){
        return(
            <>
{
    window.innerWidth < 768 ?
    
    

    <>

    {/* HeADER of the page */}


        <MobHeader
                url={require('../../logo/awardHeader.jpg')}
                heading='A Global Awards
                for Emerging Talent '
                sub_heading='Push the Boundaries of Visual Storytelling'
        />

    {/* Header ends Here */}

    {/* Body Part Starts from here */}
        <div className='container' style={{marginTop:'72px'}}>
            
            <div className='row' style={{justifyContent:'center'}}>
                <p className='Candid2019'>#Candid2019</p>
            </div>



            <div className='row' style={{justifyContent:'center', marginTop:'32px'}}>
                <div className='img-wrap' style={{height:'260', width:'300px'}}>
                    <img src="https://www.abc.net.au/cm/rimage/11133656-16x9-xlarge.jpg?v=2" alt='#Candid2019' style={{borderRadius:'6px'}} />
                </div>
            </div>       



            <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>
                <p className='Candid-Photography-Awards-2019'>Candid Photography Awards 2019</p>    
            </div>      
{/* ------------------------------------ */}
            <div style={{alignSelf:'center', marginTop:'20px'}}>
                    <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
                </div>
{/* ------------------------------ */}
            <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>


                <div>
                    <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
                    <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
                    <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
                </div>
            </div>   
{/* ------------------------------ */}

            <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                <div>
                    <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
                </div>

                <div>
                    <p className='small_desc'>Starting: 1 October, 2019</p>        
                </div>
            </div>  

{/* ------------------------------ */}
            
            <div className='row' style={{justifyContent:'center'}}>
                <div>
                    <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
                </div>

                <div>
                    <p className='small_desc'>Only for wedding photographers</p>        
                </div>
            </div>  
{/* ------------------------------ */}

            <div style={{marginTop:'40px'}}>
                    <button
                        style={{outline:'none'}}
                        className="learnmorebtn"
                        onClick={() => {
                            this.loadmore();
                        }}
                        >
                        {" "}
                        LEARN MORE
                    </button>
                </div>

{/* ------------------------------ */}












{/* #Mobiographer2019 */}

            <div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                <p className='Candid2019'>#Mobiographer2019</p>
            </div>



            <div className='row' style={{justifyContent:'center', marginTop:'32px'}}>
                <div className='img-wrap' style={{height:'260', width:'300px'}}>
                    <img src="https://www.abc.net.au/cm/rimage/11133656-16x9-xlarge.jpg?v=2" alt='#Candid2019' style={{borderRadius:'6px'}} />
                </div>
            </div>       



            <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>
                <p className='Candid-Photography-Awards-2019'>Mobile Photography Awards 2019</p>    
            </div>      

{/* ------------------------------------ */}
            <div style={{alignSelf:'center', marginTop:'20px'}}>
                    <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
                </div>
{/* ------------------------------ */}
            <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>


                <div style={{marginLeft:'0px'}}>
                    <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
                    <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
                    <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
                </div>
            </div>   
{/* ------------------------------ */}

            <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                <div>
                    <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
                </div>

                <div>
                    <p className='small_desc'>Starting: 1 October, 2019</p>        
                </div>
            </div>  

{/* ------------------------------ */}
            
            <div className='row' style={{justifyContent:'center'}}>
                <div>
                    <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
                </div>

                <div>
                    <p className='small_desc'>Only for mobile photographers</p>        
                </div>
            </div>  
{/* ------------------------------ */}

            <div style={{marginTop:'40px'}}>
                    <button
                        style={{outline:'none'}}
                        className="learnmorebtn"
                        onClick={() => {
                            this.loadmore();
                        }}
                        >
                        {" "}
                        LEARN MORE
                    </button>
                </div>

{/* ------------------------------ */}
















{/* #Fashiongraphy2019 */}

<div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                <p className='Candid2019'>#Fashiongraphy2019</p>
            </div>



            <div className='row' style={{justifyContent:'center', marginTop:'32px'}}>
                <div className='img-wrap' style={{height:'260', width:'300px'}}>
                    <img src="https://www.abc.net.au/cm/rimage/11133656-16x9-xlarge.jpg?v=2" alt='#Candid2019' style={{borderRadius:'6px'}} />
                </div>
            </div>       



            <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>
                <p className='Candid-Photography-Awards-2019'>Fashion Photography Awards 2019</p>    
            </div>      

{/* ------------------------------------ */}
            <div style={{alignSelf:'center', marginTop:'20px'}}>
                    <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
                </div>
{/* ------------------------------ */}
            <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>


                <div style={{marginLeft:'0px'}}>
                    <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
                    <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
                    <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
                </div>
            </div>   
{/* ------------------------------ */}

            <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                <div>
                    <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
                </div>

                <div>
                    <p className='small_desc'>Starting: 1 October, 2019</p>        
                </div>
            </div>  

{/* ------------------------------ */}
            
            <div className='row' style={{justifyContent:'center'}}>
                <div>
                    <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
                </div>

                <div>
                    <p className='small_desc'>Only for mobile photographers</p>        
                </div>
            </div>  
{/* ------------------------------ */}

            <div style={{marginTop:'40px'}}>
                    <button
                        style={{outline:'none'}}
                        className="learnmorebtn"
                        onClick={() => {
                            this.loadmore();
                        }}
                        >
                        {" "}
                        LEARN MORE
                    </button>
                </div>

{/* ------------------------------ */}








        <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
             <div className="vl"></div>
        </div>

        <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
        </div>
        




        </div>
    {/* Body part ends here */}


    {/* Footer here */}
        <div  style={{marginTop:'160px'}}>
            <FooterWallmag />
        </div>
    {/* ---------------------  */}
            

    </>
        
    

                                :
// for large scrrens (greater than 767)

            <>

            {/* HeADER of the page */}


                <Header
                        url={require('../../logo/awardHeader.jpg')}
                        heading='A Global Awards
                        for Emerging Talent '
                        sub_heading='Push the Boundaries of Visual Storytelling'
                />

            {/* Header ends Here */}

            {/* Body Part Starts from here */}
                <div className='container' style={{marginTop:'72px'}}>
                    
                    <div className='row' style={{justifyContent:'center'}}>
                        <p className='Candid2019'>#Candid2019</p>
                    </div>



                    <div className='row' style={{justifyContent:'center', marginTop:'32px'}}>
                        <div className='img-wrap' style={{height:'260', width:'600px'}}>
                            <img src="https://www.abc.net.au/cm/rimage/11133656-16x9-xlarge.jpg?v=2" alt='#Candid2019' style={{borderRadius:'6px'}} />
                        </div>
                    </div>       



                    <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>
                        <p className='Candid-Photography-Awards-2019'>Candid Photography Awards 2019</p>    
                    </div>      

{/* ------------------------------ */}
                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div style={{alignSelf:'center'}}>
                            <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
                        </div>

                        <div style={{marginLeft:'40px'}}>
                            <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
                            <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
                            <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
                        </div>
                    </div>   
{/* ------------------------------ */}

                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div>
                            <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Starting: 1 October, 2019</p>        
                        </div>
                    </div>  

{/* ------------------------------ */}
                    
                    <div className='row' style={{justifyContent:'center'}}>
                        <div>
                            <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Only for wedding photographers</p>        
                        </div>
                    </div>  
{/* ------------------------------ */}

                    <div style={{marginTop:'40px'}}>
                            <button
                                style={{outline:'none'}}
                                className="learnmorebtn"
                                onClick={() => {
                                    this.loadmore();
                                }}
                                >
                                {" "}
                                LEARN MORE
                            </button>
                        </div>

{/* ------------------------------ */}












{/* #Mobiographer2019 */}

                    <div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                        <p className='Candid2019'>#Mobiographer2019</p>
                    </div>



                    <div className='row' style={{justifyContent:'center', marginTop:'32px'}}>
                        <div className='img-wrap' style={{height:'260', width:'600px'}}>
                            <img src="https://www.abc.net.au/cm/rimage/11133656-16x9-xlarge.jpg?v=2" alt='#Candid2019' style={{borderRadius:'6px'}} />
                        </div>
                    </div>       



                    <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>
                        <p className='Candid-Photography-Awards-2019'>Mobile Photography Awards 2019</p>    
                    </div>      

{/* ------------------------------ */}
                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div style={{alignSelf:'center'}}>
                            <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
                        </div>

                        <div style={{marginLeft:'40px'}}>
                            <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
                            <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
                            <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
                        </div>
                    </div>   
{/* ------------------------------ */}

                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div>
                            <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Starting: 1 October, 2019</p>        
                        </div>
                    </div>  

{/* ------------------------------ */}
                    
                    <div className='row' style={{justifyContent:'center'}}>
                        <div>
                            <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Only for mobile photographers</p>        
                        </div>
                    </div>  
{/* ------------------------------ */}

                    <div style={{marginTop:'40px'}}>
                            <button
                                style={{outline:'none'}}
                                className="learnmorebtn"
                                onClick={() => {
                                    this.loadmore();
                                }}
                                >
                                {" "}
                                LEARN MORE
                            </button>
                        </div>

{/* ------------------------------ */}
















{/* #Fashiongraphy2019 */}

<div className='row' style={{justifyContent:'center', marginTop:'110px'}}>
                        <p className='Candid2019'>#Fashiongraphy2019</p>
                    </div>



                    <div className='row' style={{justifyContent:'center', marginTop:'32px'}}>
                        <div className='img-wrap' style={{height:'260', width:'600px'}}>
                            <img src="https://www.abc.net.au/cm/rimage/11133656-16x9-xlarge.jpg?v=2" alt='#Candid2019' style={{borderRadius:'6px'}} />
                        </div>
                    </div>       



                    <div className='row' style={{justifyContent:'center', marginTop:'21px'}}>
                        <p className='Candid-Photography-Awards-2019'>Fashion Photography Awards 2019</p>    
                    </div>      

{/* ------------------------------ */}
                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div style={{alignSelf:'center'}}>
                            <img src={require('../../logo/icon-awards.svg')} alt='award-icon' />
                        </div>

                        <div style={{marginLeft:'40px'}}>
                            <p className='Candid-Photography-WP-2019'>1st: INR 40,000 + Print Magazine Article + Discount Worth 5k</p>
                            <p className='Candid-Photography-WP-2019'>2nd: INR 5,000 + Print Magazine Article + Discount Worth 2k</p>
                            <p className='Candid-Photography-WP-2019'>3rd: INR 1,000 + Discount worth 1k</p>        
                        </div>
                    </div>   
{/* ------------------------------ */}

                    <div className='row' style={{justifyContent:'center', marginTop:'51px'}}>
                        <div>
                            <img src={require('../../logo/clock.svg')} alt='award-icon' style={{marginTop:'6px', height:'18px', width:'30px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Starting: 1 October, 2019</p>        
                        </div>
                    </div>  

{/* ------------------------------ */}
                    
                    <div className='row' style={{justifyContent:'center'}}>
                        <div>
                            <img src={require('../../logo/junk.svg')} alt='award-icon' style={{padding:'5px'}}/>
                        </div>

                        <div>
                            <p className='small_desc'>Only for mobile photographers</p>        
                        </div>
                    </div>  
{/* ------------------------------ */}

                    <div style={{marginTop:'40px'}}>
                            <button
                                style={{outline:'none'}}
                                className="learnmorebtn"
                                onClick={() => {
                                    this.loadmore();
                                }}
                                >
                                {" "}
                                LEARN MORE
                            </button>
                        </div>

{/* ------------------------------ */}





       
                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                     <div className="vl"></div>
                </div>

                <div className='row' style={{justifyContent:'center', marginTop:'40px'}}>
                        <img className='winning_image' src='https://travelphotographyhd.com/wp-content/uploads/2015/10/4-People-Photos-From-Nepal.jpg' alt='winning_image' />
                </div>
                




                </div>
            {/* Body part ends here */}


            {/* Footer here */}
                <div style={{marginTop:'160px'}}>
                    <FooterWallmag />
                </div>
            {/* ---------------------  */}
                    

            </>
                
                
                }


            </>
        )
    }
}

export default HomeScreen