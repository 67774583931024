import React, { Component } from "react";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";
import "./../../Styles/Awards/AwardDetails.css";

var count = 0;

export default class MissionTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      data: this.props.data,
    });
    this.forceUpdate();
    if (this.props.data.timelines) {
      for (var i = 0; i < this.props.data.timelines.length; i++) {
        if (this.props.data.timelines[i].title === "") {
          count = i;
          break;
        }
        // console.log("props data", this.props.data.timelines[i].date)
      }
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Timeline lineColor={"#ddd"}>
          {this.state.data.timelines
            ? this.props.data.timelines.map((value) =>
                value.date === "" ? (
                  ""
                ) : (
                  // (console.log("date", value.title)),
                  <TimelineItem
                    key="001"
                    dateText={<p className="mission-time">{value.date}</p>}
                    style={{ color: "#000" }}
                  >
                    <p className="tit-mission" style={{ color: "#fff" }}>
                      {value.title}
                    </p>
                    <p className="desc-mission" style={{ color: "#fff" }}>
                      {value.description}
                    </p>
                  </TimelineItem>
                )
              )
            : ""}
        </Timeline>
        {/* <Timeline lineColor={"#ddd"}>
          {this.state.data.timelines
            ? this.state.data.timelines.slice(0, count).map((value, indexNo) =>
(            console.log("props data", value.date),
                value.date === "" ? (
                  ""
                ) : (
                  <TimelineItem
                    key="001"
                    dateText={<p className="mission-time">{value.date}</p>}
                    style={{ color: "#000" }}
                  >    
                    <p className="tit-mission">{value.title}</p>
                    <p className="desc-mission">{value.description}</p>
                  </TimelineItem>
                )
              ))
            : ""}
        </Timeline> */}
      </div>
    );
  }
}
