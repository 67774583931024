import React, { Component } from 'react';
// import Iframe from 'react-iframe';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../../../node_modules/froala-design-blocks/dist/css/froala_blocks.css';
import './Team.css';

class Team extends Component {
  render() {
    return <section className='main'>
  <div className="container bgr">
    <div className="row text-center justify-content-center teams">
      <div className="col-8">
        <h1 className='people'>People Behind WallMag</h1>
        <p className="lead">We do what we love and love what we do</p>
      </div>
    </div>

    <div className="row-100"></div>

    <div className="row justify-content-center text-left teams">
      <div className="col-sm-6">
        <div className="row align-items-center">
          <div className="col-4">
            <img alt="imwage" className="img-fluid rounded" src="./imgs/people/8.jpg" />
          </div>

          <div className="col-8">
            <h3 className='emp_name'>Ajeet Kumar Meena</h3>
            <p className="lead">Founder & CEO</p>

            <p  className='about_emp'>Ajeet Meena is a product guy and founder of WallMag. He has a vast experience in developing products from scratch to a scale. His vision is to create the largest platform for visual consumer and photographers with WallMag.</p>
          </div>
        </div>
      </div>

      <div className="col-sm-6">
        <div className="row align-items-center">
          <div className="col-4">
            <img alt="imwage" className="img-fluid rounded" src="./imgs/people/7.jpg" />
          </div>

          <div className="col-8">
            <h3 className='emp_name'>Vijay Chouhan</h3>
            <p className="lead"> UI/UX Designer</p>

            <p className='about_emp'>Vijay Chouhan is Interaction Designer or UX/UI Designer of WallMag. He has experience in UX/UI Design and CG Arist in Cinematic and Video Game industry.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="row justify-content-center text-left teams">

    <div className="col-sm-6">
        <div className="row align-items-center">
          <div className="col-4">
            <img alt="imwage" className="img-fluid rounded" src="./imgs/people/Aarzu.jpg" />
          </div>

          <div className="col-8" style={{textAlign:'initial'}}>
            <h3 className='emp_name'>Aarzu Jain</h3>
            <p className="lead"> Marketing Manager</p>

            <p className='about_emp'>Aarzu is Marketing Manager at WallMag. She has a keen inclination towards digital marketing. She is a quick learner who has developed several skills while working with WallMag.
</p>
          </div>
        </div>
      </div>
    

      <div className="col-sm-6">
        <div className="row align-items-center">
          <div className="col-4">
            <img alt="iwmage" style={{height:'180px', width : '220px'}}  className="rounded" 
                src="./imgs/people/Ankit.jpg" />
          </div>

          <div className="col-8" style={{textAlign:'initial'}}>
            <h3 className='emp_name'>Ankit Singh Rawat</h3>
            <p className="lead"> Web Developer</p>

            <p className='about_emp'>Ankit is a Web Developer at WallMag. 
                                      He loves to take Challenges and
                                       work with new Opportunities
            </p>
          </div>
        </div>
      </div>
  
</div>


     
    <div className="row-70"></div>

  </div>
</section>

  }
}

export default Team
