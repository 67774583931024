import React from "react";
import "./AboutUsTablet.css";
import MobileHeader from "./MobileHeader";
import FooterWallmag from "../Footer/Footer";

function AboutUsTablet() {
  return (
    <div className="about-us-page-768">
      <div className="overlap-group8">
        <div className="spacer"></div>
        <div className="mob_top_about">
          <MobileHeader
            url=""
            heading="About Us"
            sub_heading="We are a global community of photographers and storytellers"
          />
        </div>
        <div className="overlap-group4">
          <img
            className="pexels-yogendra-singh-1438461 animate-enter"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d352661cdd20c8e72eaf/img/pexels-yogendra-singh-1438461-2@1x.png"
          />
          <h1 className="title montserrat-bold-white-98px animate-enter1">
            ABOUT
            <br />
            US
          </h1>
        </div>
        {/* <div className="group-1937">
          <img
            className="mask-group-208"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/mask-group-208@1x.png"
          />
        </div> */}
      </div>
      <p className="a-social-initiative montserrat-light-white-16px animate-enter2">
        A social initiative that started to&nbsp;&nbsp;empower artists all
        around the globe to do more and achieve more.&nbsp;&nbsp;Our commitment
        is to help artists so they can create a world full of possibilities for
        those who need them. That’s why we created WallMag, to empower artists
        to create real value among those, who <br />
        create!
      </p>
      <div className="overlap-group1">
        <div className="text-1 montserrat-bold-white-28px">
          &#34;Art must be life - <br />
          it must belong to everybody&#34;
          <br />
        </div>
        <div className="name montserrat-light-white-16px">Maria Abramovie</div>
      </div>
      <div className="overlap-group">
        <div className="text-2 montserrat-bold-white-56px">
          Artist are
          <br />
          suffering!
        </div>
        <p className="how-is-it-that-in-a montserrat-light-white-16px">
          How is it that in a world that&#39;s evolving so quickly that artists
          all around still suffering? Strangely enough, art is all around us all
          the time but artists all around us are suffering from their own
          passion. There seems to be an emptiness with the current suffering of
          an artist and today&#39;s generation of artists are disconnected from
          real value.{" "}
        </p>
      </div>
      <div className="overlap-group5">
        <div className="address montserrat-bold-white-42px">
          4 ways we <br />
          can empower <br />
          artists globally
        </div>
        {/* <p className="text-5 montserrat-light-white-16px">
          Some text would come here
        </p> */}
      </div>
      <div className="flex-row">
        <div className="group-2674">
          <div className="group-2674-1">
            <div className="group-2674-2">
              <div className="overlap-group6">
                <img
                  className="path-2967"
                  src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2967-1@1x.png"
                />
                <img
                  className="path-2968"
                  src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2968-1@1x.png"
                />
                <img
                  className="path-29"
                  src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2969-1@1x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="group-2675">
          <div className="group-2675-1">
            <div className="group-2675-2">
              <div className="overlap-group6-1">
                <img
                  className="path-29"
                  src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2970-1@1x.png"
                />
                <img
                  className="path-2971"
                  src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/path-2971-1@1x.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row-1">
        <p className="text-3 montserrat-light-white-14px">
          Delivery of affordable <br />
          learning resources
        </p>
        <div className="text-15 montserrat-light-white-14px">
          Recognition of
          <br />
          talented artists
        </div>
      </div>
      <div className="flex-row-2">
        <div className="group-2676">
          <div className="overlap-group7">
            <div className="spacer-1"></div>
            <div className="group-2676-1">
              <img
                className="icon-awesome-hand-holding-usd"
                src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/icon-awesome-hand-holding-usd-1@1x.png"
              />
            </div>
          </div>
        </div>
        <div className="group-2677">
          <img
            className="icon-awesome-id-card"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/icon-awesome-id-card-1@1x.png"
          />
        </div>
      </div>
      <div className="flex-row-3">
        <p className="text-4 montserrat-light-white-14px">
          Facilitation of&nbsp;&nbsp;
          <br />
          Monetization Resources
        </p>
        <div className="text-14 montserrat-light-white-14px">
          Identification
          <br />
          with digital presence
        </div>
      </div>
      <div className="overlap-group3">
        <div className="our-mission montserrat-bold-white-42px">
          Our Mission
        </div>
        <p className="our-mission-is-to-up montserrat-light-white-16px">
          Our mission is to uplift Global Art by connecting artists and
          businesses through World-Class infrastructure and Technology. Our
          commitment is to help artists so they can create a world full of
          possibilities for those who need them a world where businesses and
          individuals look forward to living.&nbsp;&nbsp;
        </p>
      </div>
      <div className="group-2764">
        <img
          className="nude-neutral-pr-feed-template5"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d352661cdd20c8e72eaf/img/nude-neutral-product-review-instagram-feed-template-5--2@1x.png"
        />
        <img
          className="nude-neutral-pr-m-feed-template"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d352661cdd20c8e72eaf/img/nude-neutral-product-review-instagram-feed-template-2@1x.png"
        />
        <img
          className="nude-neutral-pr-feed-template1"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d352661cdd20c8e72eaf/img/nude-neutral-product-review-instagram-feed-template-1--2@1x.png"
        />
        <img
          className="image-14"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d352661cdd20c8e72eaf/img/image-14-2@1x.png"
        />
      </div>
      <div className="group-2801">
        <img
          className="image-15"
          src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/6060d0f170400a2517c6a8bc/img/image-15-1@1x.png"
        />
        <div className="ajeet-kumar-meena montserrat-medium-white-20px">
          Ajeet Kumar Meena
        </div>
        <div className="managing-director montserrat-medium-white-14px">
          Managing Director
        </div>
      </div>
      <p className="just-like-a-human-br montserrat-light-white-16px">
        Just like a human brain works best when synapses talk to <br />
        each other seamlessly, we believe that the art sector can <br />
        deliver the best to our artist when businesses, change <br />
        makers and consumers exchange ideas and share best <br />
        practices. - We are that platform for artists.
      </p>
      {/* <img
        className="mask-group-213"
        src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/mask-group-213@1x.png"
      />
      <div className="wall-mag">WallMag</div>
      <div className="text-11">Where Photos Meet Stories</div>
      <p className="text-10 montserrat-light-white-16px">
        A community platform to empower photographers <br />
        of every kind through learning, improving and growing resources
        <br />
        so our photographers can do more and achieve more.
      </p>
      <div className="group-1986">
        <div className="navigatenext-24px">
          <div className="text-6">Join Membership for Free</div>
          <img
            className="path-2769"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/path-2769@1x.png"
          />
          <img
            className="path-2755"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/path-2755@1x.png"
          />
        </div>
      </div>
      <div className="group-1363">
        <div className="flex-col">
          <div className="the-company montserrat-bold-white-20px">The Company</div>
          <p className="text-7 montserrat-normal-white-14px">
            About Us
            <br />
            <br />
            The Team
            <br />
            <br />
            Our App
            <br />
            <br />
            WhatsApp Group
            <br />
            <br />
            Sitemap
          </p>
        </div>
        <Group420
          contactUs="Contact Us"
          text8={
            <>
              Get in Touch
              <br />
              <br />
              Advertise
              <br />
              <br />
              Careers
            </>
          }
        />
        <div className="flex-col-1">
          <div className="place montserrat-bold-white-20px">Legal</div>
          <div className="text-9 montserrat-normal-white-14px">
            Privacy Policy
            <br />
            <br />
            Terms of Use
          </div>
        </div>
      </div>
      <div className="overlap-group2">
        <div className="group-1507">
          <img
            className="lnk"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/lnk@1x.png"
          />
          <img
            className="group-1507-item"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/fb@1x.png"
          />
          <img
            className="group-1507-item"
            src="https://anima-uploads.s3.amazonaws.com/projects/605eb7aa25846d394e22f1f5/releases/606081c82aa427aaa8c9c411/img/path-2042@1x.png"
          />
        </div>
        <div className="group-1561">
          <div
            className="rectangle-1883 border-1px-dove-gray"
            onClick={() =>
              window.open("https://www.instagram.com/wallmag.photography/", "_blank")
            }
          >
            <img
              className="insta"
              src={require("../../logo/insta.svg")}
              alt="Instagram Logo"
            />
            <p className="text-12 montserrat-light-white-14px">
              We are active on Instagram
            </p>
          </div>
          <p className="text-13 montserrat-light-white-14px">© WallMag Global PVT LTD 2020</p>
        </div>
      </div> */}
      <div id="footer_content">
        <FooterWallmag />
      </div>
    </div>
  );
}

export default AboutUsTablet;

function Group420(props) {
  const { contactUs, text8 } = props;

  return (
    <div className="group-420">
      <div className="contact-us montserrat-bold-white-20px">{contactUs}</div>
      <div className="text-8 montserrat-normal-white-14px">{text8}</div>
    </div>
  );
}
